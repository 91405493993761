import React, { useState, useEffect, useRef, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './DocumentSidebar.css';
import { connect } from 'react-redux'
const mapStateToProps = (state) => {
  return {
    progress: state.progress,
    application: state.application,
    property: state.application.property,
    borrower: state.application.borrower
  }
}

const mapDispatchToProps = (dispatch) => ({
  
});


export default function DocumentSidebar ({changeMenu}) {
    const [menuExpand, setMenuExpand] = useState(true)
    const [currentCategory, setCurrentCategory] = useState(true)
    const categoryOptions = [
      {
          label: 'Identification',
          value: 'identification'
      },
      {
          label: 'Income',
          value: 'income'
      },
      {
          label: 'Assets & debts',
          value: 'assets'
      },
      {
          label: 'Property',
          value: 'property'
      },
      {
          label: 'Miscellaneous',
          value: 'miscellaneous'
      },
      // {
      //     label: 'Process documents',
      //     value: 'internalDocuments'
      // }
    ]
    const selectCategory = (item) => {
      setCurrentCategory(item.value)
      changeMenu(item.value)
    }
    let clickMenu = () => {
      setMenuExpand(!menuExpand)
    }
    return (
      <div style={{ flexWrap: false, }} className="document-sidebar sidenav-application">
        <div style={{width: '100%', display: 'flex', height: '36px', justifyContent: 'space-between', alignItems: 'center', padding: '0 12px'}} onClick={clickMenu}>
          <span className="sidebartext-app">Documents</span>
          <img className={menuExpand ? 'menuExpandIcon expand' : 'menuExpandIcon'} src="/images/menuExpandIcon.svg" />
        </div>
        <div className="sidebar-menu-item" style={{ height: menuExpand ? 'auto' : 0 }}>
          {
            categoryOptions.map(item => (
              <div className={`document-sidebar-children-item ${currentCategory === item.value ? 'children-active' : ''}`} onClick={() => selectCategory(item)}>{item.label}</div>
            ))
          }
        </div>
      </div>
    );
}
DocumentSidebar = connect(mapStateToProps, mapDispatchToProps)(DocumentSidebar)