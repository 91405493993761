
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux'
import { Document, Page, pdfjs } from 'react-pdf';
import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, Checkbox, Input, Modal, Spin } from 'antd';
import moment from 'moment';
import { useHistory } from "react-router-dom"
import './PDFPreview.css';
import './TextLayer.css';
import { borrower } from '../../Common';
// import "react-pdf/dist/esm/Page/TextLayer.css";

export function blobToURL(blob) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            resolve(base64data);
        };
    });
}

const mapStateToProps = (state) => {
    return {
        hasCoBorrower: state.application.hascoborrower === "withcoborrower",
        application: state.application,
    }
}

// Directly copied from form_marks.go
const signCoordinates = {
    "page2": {
        "borrower.signature": { Left: 149.938012, Top: 699.655642, Right: 448.457415, Bottom: 724.989162 },
        "borrower.signature.date": { Left: 492.877884, Top: 700.794918, Right: 576.247894, Bottom: 724.431574 },
        "coborrower.signature": { Left: 149.194504, Top: 740.631587, Right: 447.174893, Bottom: 764.115037 },
        "coborrower.signature.date": { Left: 494.044096, Top: 739.106156, Right: 575.960673, Bottom: 764.784832 },
    },
    "page6": {
        "borrower.signature": { Left: 154.992455, Top: 692.501348, Right: 412.125321, Bottom: 712.885257 },
        "borrower.signature.date": { Left: 455.208178, Top: 690.486024, Right: 592.914131, Bottom: 712.987128 },
        "coborrower.signature": { Left: 169.909857, Top: 737.242870, Right: 421.305854, Bottom: 758.380672 },
        "coborrower.signature.date": { Left: 465.925736, Top: 734.865661, Right: 585.810826, Bottom: 758.224112 },
    },
    "page7": {
        "borrower.signature": { Left: 150.868699, Top: 690.059578, Right: 414.187342, Bottom: 713.687426 },
        "borrower.signature.date": { Left: 456.687334, Top: 685.539165, Right: 547.043647, Bottom: 710.956520 },
        "coborrower.signature.date": { Left: 465.536239, Top: 726.710112, Right: 553.345275, Bottom: 756.527421 },
        "coborrower.signature": { Left: 169.022238, Top: 732.849779, Right: 423.397619, Bottom: 759.467932 },
    },
    "page8": {
        "borrower.signature": { Left: 156.361317, Top: 679.203119, Right: 409.458129, Bottom: 702.315068 },
        "borrower.signature.date": { Left: 454.912180, Top: 678.029100, Right: 587.751260, Bottom: 700.909247 },
        "coborrower.signature": { Left: 175.000186, Top: 723.278574, Right: 424.748534, Bottom: 748.862620 },
        "coborrower.signature.date": { Left: 468.006864, Top: 719.840562, Right: 590.132241, Bottom: 746.434448 },
    },
    "page9": {
        "borrower.signature": { Left: 152.011897, Top: 463.909080, Right: 407.620574, Bottom: 487.300107 },
        "borrower.signature.date": { Left: 452.712552, Top: 463.862488, Right: 588.684658, Bottom: 485.761183 },
        "coborrower.signature": { Left: 168.497061, Top: 512.377038, Right: 421.376242, Bottom: 534.365661 },
        "coborrower.signature.date": { Left: 463.001602, Top: 508.093347, Right: 601.608988, Bottom: 532.818064 },
    },
}
const PT_PX_RATIO = 1.46

let signMarks = [
    {
        page: 1,
        signid: "page2signature",
        pageName: "page2",
        type: 'signature',
        key: "borrower.signature",
        style: {
        }
    },
    {
        page: 1,
        signid: "page2signature",
        pageName: "page2",
        type: 'date',
        key: "borrower.signature.date",
        style: {
        }
    },
    {
        page: 5,
        signid: "page6signature",
        pageName: "page6",
        type: 'signature',
        key: "borrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 5,
        signid: "page6signature",
        pageName: "page6",
        type: 'date',
        key: "borrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 6,
        signid: "page7signature",
        pageName: "page7",
        type: 'signature',
        key: "borrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 6,
        signid: "page7signature",
        pageName: "page7",
        type: 'date',
        key: "borrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 7,
        signid: "page8signature",
        pageName: "page8",
        type: 'signature',
        key: "borrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 7,
        signid: "page8signature",
        pageName: "page8",
        type: 'date',
        key: "borrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 8,
        signid: "page9signature",
        pageName: "page9",
        type: 'signature',
        key: "borrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 8,
        signid: "page9signature",
        pageName: "page9",
        type: 'date',
        key: "borrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
]

let cosignMarks = [
    {
        page: 1,
        signid: "page2cosignature",
        pageName: "page2",
        type: 'signature',
        key: "coborrower.signature",
        style: {
        }
    },
    {
        page: 1,
        signid: "page2cosignature",
        pageName: "page2",
        type: 'date',
        key: "coborrower.signature.date",
        style: {
        }
    },
    {
        page: 5,
        signid: "page6cosignature",
        pageName: "page6",
        type: 'signature',
        key: "coborrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 5,
        signid: "page6cosignature",
        pageName: "page6",
        type: 'date',
        key: "coborrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 6,
        signid: "page7cosignature",
        pageName: "page7",
        type: 'signature',
        key: "coborrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 6,
        signid: "page7cosignature",
        pageName: "page7",
        type: 'date',
        key: "coborrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 7,
        signid: "page8cosignature",
        pageName: "page8",
        type: 'signature',
        key: "coborrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 7,
        signid: "page8cosignature",
        pageName: "page8",
        type: 'date',
        key: "coborrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 8,
        signid: "page9cosignature",
        pageName: "page9",
        type: 'signature',
        key: "coborrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 8,
        signid: "page9cosignature",
        pageName: "page9",
        type: 'date',
        key: "coborrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    }
]

function PDFPreview(props) {
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const history = useHistory()
    const pageRefs = useRef({});
    const [pdf, setPdf] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [showSignPad, setShowSignPad] = useState(false)
    const [currentSignature, setCurrentSignature] = useState('')
    const [currentSignId, setCurrentSignId] = useState('')
    const [loading, setLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [signItems, setSignItems] = useState({})
    const { visible, onClose } = props
    const documentRef = useRef(null);
    const [submitLoading, setSubmitLoading] = useState(false)
    const [signStarted,  setSignStarted] = useState(false)
    const [showRequestConfirmModal, setShowRequestConfirmModal] = useState(false)
    const [codeConsent, setCodeConsent] = useState(false)
    const [passcode, setPasscode] = useState('')
    const [sendingSignRequest, setSendingSignRequest] = useState(false)
    const containerRef = useRef(null)
    const WIDTH = 900
    const [form] = Form.useForm()

    const onPageLoadSuccess = (page) => {
    };

    /*To Prevent right click on screen*/
    document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
    });

    function insertSignature() {
        const foundList = signMarks.filter(x => x.signid === currentSignId)
        let newSignItems = JSON.parse(JSON.stringify(signItems))
        for (const { pageName, key, type } of foundList) {
            if (type === 'signature') {
                newSignItems[pageName][key] = form.getFieldValue("signature")
            } else if (type === 'date') {
                newSignItems[pageName][key] = moment().format("MM/DD/YYYY")
            }
        }
        setSignItems(newSignItems)
        setCurrentSignId("")
        setShowSignPad(false)
    }

    /*When document gets loaded successfully*/
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function verifySignItems(signItems) {
        for (const pageName in signItems) {
            for (const key in signItems[pageName]) {
                if (!signItems[pageName][key]) return [pageName, key]
            }
        }
        return true
    }

    function hasNextSign() {
        const verifyResult = verifySignItems(signItems)
        return Array.isArray(verifyResult)
    }

    function scrollNextSign() {
        const verifyResult = verifySignItems(signItems)
        if (Array.isArray(verifyResult)) {
            const [pageName, key] = verifyResult
            let found = signMarks.find(x => x.pageName === pageName && key === key)
            if (found && containerRef.current && pageRefs.current) {
                let pageOffset = pageRefs.current[found.page]?.offsetTop || 0
                containerRef.current.scrollTop = found.style.top + pageOffset - 50
            }
            return
        }
    }



    function onSubmit() {
        setSubmitLoading(true)

        let token = sessionStorage.getItem("ZeitroA")
        fetch('/borrower/hardmoneyloan/generateforms', {
          method: 'POST',
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
          },
          body: JSON.stringify({signItems})
        }).then(
          response => {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' +
                response.status);
                setSubmitLoading(false)
              return;
            }

            setSubmitLoading(false)
            setShowRequestConfirmModal(false)
            props.onSubmit(signItems)
          }
        )
          .catch(function (err) {
            console.log('Fetch Error :-S', err);
          });
    }

    function showSignRequestModal() {
        if (props.hasCoBorrower) {
            //geneerate 4 digit random code and send email to main and co borrower
            // emailSignRequest()
            setSignStarted(true)
            scrollNextSign()
        } else {
            setSignStarted(true)
            scrollNextSign()
        }
    }

    function openSignPad(signid) {
        setCurrentSignId(signid)
        setShowSignPad(true)
    }

    function emailSignRequest() {
        setSendingSignRequest(true)
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/borrower/hardmoneyloan/sendpasscodeandinvitecosigner', {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                borrowerEmail: props.application.borrower.email.toLowerCase(),
                coborrowerEmail: props.application.coborrower.email.toLowerCase(),
                borrowerFirstName: props.application.borrower.firstname,
                borrowerLastName: props.application.borrower.lastname,
                coborrowerFirstName: props.application.coborrower.firstname,
                coborrowerLastName: props.application.coborrower.lastname,
            }),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(res => {
            if (res.status !== 200) {
                alert("Looks like there was a problem. Status Code: " + res.status +", please try again later or contact your loan officer for assistance.")
                setSendingSignRequest(false)
                return;
            }
            res.json().then(js => {
                setPasscode(js.Code)
                setSendingSignRequest(false)
                setShowRequestConfirmModal(true)
                return
            })
        })
      
    }

    useEffect(() => {
        if (visible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [visible])


    useEffect(async () => {
        setLoading(true)

        if (props.hasCoBorrower) {
            signMarks = [
                ...signMarks,
                // ...cosignMarks,
            ]
        }

        for (const signMark of signMarks) {
            const { pageName, key } = signMark
            signMark.style = {
                top: signCoordinates[pageName][key].Top * PT_PX_RATIO,
                left: signCoordinates[pageName][key].Left * PT_PX_RATIO,
                width: (signCoordinates[pageName][key].Right - signCoordinates[pageName][key].Left) * PT_PX_RATIO,
                height: (signCoordinates[pageName][key].Bottom - signCoordinates[pageName][key].Top) * PT_PX_RATIO,
            }
        }

        let initSignItems = {}
        for (const { pageName, key, type } of signMarks) {
            if (!(pageName in initSignItems)) {
                initSignItems[pageName] = {}
            }
            if (!(key in initSignItems[pageName])) {
                initSignItems[pageName][key] = ''
            }
        }
        setSignItems(initSignItems)
        let token = sessionStorage.getItem("ZeitroA")

        const existingPdfBytes = await fetch('/borrower/hardmoneyloan/previewyeeleeform', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(res => res.arrayBuffer()).catch(function (err) {
            console.log('Fetch Error :-S', err);
        });
        const blob = new Blob([new Uint8Array(existingPdfBytes)]);

        const URL = await blobToURL(blob);
        setPdf(URL);
        setLoading(false)
    }, []);

    const renderRequestConfirmModal = () =>
        <Modal
            open={showRequestConfirmModal}
            closable={false}
            className="request-confirm-modal"
            footer={
                <Button
                    className={codeConsent ? 'confirmBtn' : 'confirmBtnDisabled'}
                    type="primary" 
                    disabled={!codeConsent} 
                    loading={submitLoading}
                    onClick={
                        () => {
                            onSubmit()
                        }
                    }
                >
                    Submit my signatures
                </Button>
            }
        >
            <div className="request-confirm-wrap">
                <div className='request-confirm-title bottomSpaceAdjuster'>
                    <div>Co-borrower signature requested</div>
                    <img src="/images/sendingEmail.png"/>
                </div>
                <p className='bottomSpaceAdjuster'>{`We have sent a signature request email to your co-borrower, ${props.application.coborrower.firstname +" "+ props.application.coborrower.lastname} [${props.application.coborrower.email}].`}</p>
                <p className='bottomSpaceAdjuster'>Please securely share the <span>4-digit verification code</span> below with your co-borrower, so they can access the application form. </p>
                <div className="verification-code bottomSpaceAdjuster">
                    <span>{passcode}</span>
                </div>
                <p className='bottomSpaceAdjuster'>This code is used to ensure the security of your sensitive data. You can also find this verification code in your email. </p>
                <p className='bottomSpaceAdjuster'>Once all signatures are received, the application will be submitted automatically. </p>
                <Checkbox className='bottomSpaceAdjuster' onChange={(e) => setCodeConsent(e.target.checked)}>I understand that I need to share the verificatin code with my co-borrower</Checkbox>
            </div>
        </Modal>

    const renderSignPad = () =>
        <Modal
            open={showSignPad}
            closable={false}
            className="sign-pad"
            title="Please type your name"
            footer={null}
        >
            <Form
                form={form}
                name='sign-pad'
                onFinish={insertSignature}
            >
                <Form.Item
                    name="signature"
                    rules={[{ required: true, message: 'Please sign your name' }]}
                >
                    <Input
                        className="signature"
                        style={{
                            height: 42,
                            lineHeight: '26px',
                            fontSize: 26,
                            fontFamily: 'smooch',
                            padding: 8,
                            borderRadius: 0,
                            border: '1px solid #325CEB',
                        }}
                        // onChange={onCurrentSignature}
                    />
                </Form.Item>
                <Form.Item
                    name="consent"
                    valuePropName="checked"
                    style={{ textAlign: 'left' }}
                    rules={[{ required: true, message: 'Please select the checkbox' }]}
                >
                    <Checkbox>I agree to electronically sign this document, understand this is a legal
                        representation of my signature, and agree to the <a className='signature-terms-link' onClick={() => setIsModalOpen(true)}>electronic signature terms</a>.</Checkbox>
                </Form.Item>

                <div className="action-button-group">
                    <Form.Item>
                        <Button
                            className="cancel-button"
                            onClick={() => {
                                setShowSignPad(false)
                                setCurrentSignId("")
                            }}
                        >Cancel</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType='submit' className="insert-button">Insert</Button>
                    </Form.Item>
                </div>
            </Form>
            <Modal
                title="Electronic Signature Terms"
                className='signature-terms-modal'
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={[<Button type="primary" onClick={() => setIsModalOpen(false)}>Got it</Button>]}
            >
                <p>By using the electronic signature feature on this website, you agree to the legal validity and binding nature of your electronic signature on Yeelee Capital loan application forms. Yeelee Capital reserves the right to implement authentication measures to verify the identity of the applicant when using electronic signatures.
You can withdraw consent by notifying Yeelee Capital in writing, but this may affect your loan application.We will retain electronic signature records as required by law.
You can request a copy of your signed documents.Yeelee Capital may update these terms. Continued use of electronic signatures implies acceptance of any changes.By using our electronic signature feature, you confirm your understanding and acceptance of these terms.</p>
            </Modal>
        </Modal>

    return (
        <>
            <div className="pdf-preview-main" style={{ display: visible ? 'block' : 'none' }}>
                <div className="top-nav">
                    <div>Please follow the instruction to provide your signatures and then click Submit.</div>
                    <CloseOutlined
                        onClick={onClose}
                        className="close-icon"
                    />
                </div>
                <center className="pdf-container" ref={containerRef}>
                {!loading ? <Document
                        className="pdf-content"
                        file={pdf}
                        ref={documentRef}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <Page
                                    inputRef={ref => {
                                        pageRefs.current[index] = ref;
                                    }}
                                    width={WIDTH}
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    renderTextLayer={false}
                                    onLoadSuccess={onPageLoadSuccess}
                                    onRenderSuccess={(props) => {

                                    }}
                                >
                                    <div
                                        className="page-sign-layer"
                                        style={{
                                            width: WIDTH,
                                            marginLeft: -WIDTH / 2,
                                            left: '50%',
                                        }}
                                    >
                                        {signMarks.map((signMark, signIdx) => {
                                            if (signMark.page === index) {
                                                let signContent = signItems[signMark.pageName][signMark.key]
                                                if (signMark.type === 'signature') {
                                                    return <>
                                                        <div
                                                            key={signMark.key}
                                                            className="sign-mark"
                                                            onClick={() => openSignPad(signMark.signid)}
                                                            style={{
                                                                position: 'absolute',
                                                                ...signMark.style
                                                            }}
                                                        >
                                                            {signContent}
                                                        </div>
                                                        {!signStarted? null : !signContent ? <div
                                                            className='sign-thumb-wrapper'
                                                            onClick={() => openSignPad(signMark.signid)}
                                                        >
                                                            <div
                                                                className='sign-thumb'
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: signMark.style.top,
                                                                    left: -140,
                                                                }}
                                                            >
                                                                Sign Here
                                                            </div>
                                                            <div
                                                                className="sign-thumb-triangle"
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: signMark.style.top,
                                                                    left: -40,
                                                                }}
                                                            ></div>
                                                        </div> : null}
                                                    </>
                                                } else if (signMark.type === 'date') {
                                                    return (
                                                        <div
                                                            key={signMark.key}
                                                            className={signMark.type === 'signature' ? "sign-mark" : "sign-mark-date"}
                                                            onClick={() => openSignPad(signMark.signid)}
                                                            style={{
                                                                position: 'absolute',
                                                                ...signMark.style
                                                            }}
                                                        >
                                                            {signContent}
                                                        </div>
                                                    )
                                                }
                                            }
                                            return null;
                                        })}
                                    </div>
                                </Page>
                            )
                        )}
                    </Document>: <div className="spin-wrapper"> <Spin /></div>}
                </center>

                {renderSignPad()}
                {renderRequestConfirmModal()}
                <div className="bottom-nav">
                    <Button
                        type="default"
                        disabled={loading}
                        onClick={onClose}
                        className='submit-btn'
                    >
                        Back to edit
                    </Button>
                    <Button
                        type="primary"
                        disabled={loading}
                        loading={submitLoading}
                        className='submit-btn'
                        onClick={signStarted? (hasNextSign() ? scrollNextSign : props.hasCoBorrower? emailSignRequest : onSubmit) : showSignRequestModal}
                    >
                        {sendingSignRequest? <Spin/>:(signStarted?(hasNextSign() ? 'Next' :props.hasCoBorrower? 'Invite Co-borrower' : 'Submit') : 'Proceed to sign')}
                    </Button>
                </div>
            </div>
        </>
    );
}

export default PDFPreview = connect(mapStateToProps)(PDFPreview);
