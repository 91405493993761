import TopNavBar from './TopNavbar'
import { LeftOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Input, Button } from 'antd'
import { SetUpOrChangePassword } from '../api/api'
import { useState } from 'react'
function SetUpPassword() {
    const subscription = useSelector(state => {
        return state.application
    })
    const [form] = Form.useForm()
    const [isOk, setIsOk] = useState(false)
    const validateRules = [
        {
            validator: (_, value) => {
                if (value && value.length < 8) {
                    return Promise.reject(new Error('Must be at least 8 characters .'))
                }
                return Promise.resolve()
            },
        },

    ]
    const onFinish = async (values) => {
        let token = sessionStorage.getItem("ZeitroA")
        const res = await fetch('/auth/setuporchangepassword', {
            method: 'post',
            headers: {
                Authorization: "Bearer " + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                CurrentPassword: '',
                Email: subscription.borrower.email,
                Password: values.password
            })
        })
        if (res.status === 500) {
            alert(' please try again later')
        } else {
            setIsOk(true)
        }
        
        // try {
        //     await SetUpOrChangePassword({
        //         CurrentPassword: '',
        //         Email: subscription.borrower.email,
        //         Password: values.password
        //     })
        //     setIsOk(true)
        //     console.log('Success1111:', values)  
        // } catch (error) {
            
        // }
    
    }
    return (
        <div className='d-flex ' style={{ height: '100vh', flexDirection: "column" }} >
            <TopNavBar >
                <div style={{
                    fontSize: 16,
                    fontWeight
                        : 500,

                }}>

                    <Link to='/application/myloans' style={{ color: '#222' }}>
                        <LeftOutlined />     Back
                    </Link>

                </div>
            </TopNavBar>
            <div style={{ width: '35%', margin: "auto", textAlign: 'center', minHeight: "60vh" }}>
                <div>
                    <img src='/password.svg'></img>
                </div>
                <div className='mb-4' style={{ color: "#222", fontSize: 32, fontWeight: 700 }}>
                    {isOk ? 'You are all set!' : 'Set up password'}
                </div>
                {isOk ? <div>
                    <div className='mt-2' style={{
                        color: '#6E6E70',
                        fontSize: 16,


                    }}>Your password has been successfully reset. You can now log in with it next time, or continue to log in using a verification code.</div>
                    <Button className='mt-4' type='primary' style={{ width: "100%", height: 44 }}>

                        <Link to='/application/myloans'>
                            Back to dashboard</Link>
                    </Button>

                </div> : <Form form={form} layout="vertical" className='mt-4' onFinish={onFinish}>
                    <Form.Item

                        label="Enter your password"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            ...validateRules
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Confirm your password"
                        name="password"
                        dependencies={['username']}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            }, ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('username') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(new Error('The new password that you entered do not match!'))
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Button type='primary' style={{ width: "100%", height: 44 }} htmlType="submit">Set up now</Button>
                </Form>}


            </div>
        </div>
    )

}
export default SetUpPassword