import { useState, useEffect } from 'react';
import {
    Modal,
    Input,
    message,
    Button,
    Checkbox,
    Spin
} from 'antd';
import "./Common.css";
import "./Overview.css";
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import * as com from "../../Common"
import { Link } from 'react-router-dom'
import { CalcHardMoneyLoanRate } from '../../Calculator/HardMoneyLoanCalculator/LoanRateResult';

const mapStateToProps = (state) => {
    return {
        application: state.application,
        property: state.application.property,
        hardmoney: state.application.hardmoneyloan,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateHardMoneyLoanAttribute: (t, verb) => {
        dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    }
});


export default function Overview(props) {
    const [messageApi, contextHolder] = message.useMessage();
    const [showContact, setShowContact] = useState(false)
    const [loading, setLoading] = useState(false)
    const [contactMessage, setContactMessage] = useState("")
    const [signStatus, setSignStatus] = useState("")
    const [showResendConfirmModal, setShowResendConfirmModal] = useState(false)
    const [showRequestConfirmModal, setShowRequestConfirmModal] = useState(false)
    const [codeConsent, setCodeConsent] = useState(false)
    const [passcode, setPasscode] = useState("")
    const [sendingSignRequest, setSendingSignRequest] = useState(false)
    const { TextArea } = Input;

    const statusList = [
        {
            name: 'Application Draft Started',
            done: true,
            operate: []
        },
        {
            name: 'Initial Application Submitted',
            done: true,
            operate: [
                <div className={signStatus==="cosigned"?"cosignedStatusWrap":signStatus==="primarysigned"?"signedStatusWrap":''}>
                    <div>{signStatus==="cosigned"?"Co-borrower signature received":signStatus==="primarysigned"?"Co-borrower signature requested":""}</div>
                    {signStatus!=="cosigned" && signStatus === "primarysigned" ?<span className='resendSignRequest' onClick={()=> setShowResendConfirmModal(true)}>Resend</span>:null}
                </div>,
                <Link to="/application/hardMoneyLoan/interview/main/borrower">View my application</Link>
            ]
        },
        {
            name: 'Processing',
            operate: []
        },
        {
            name: 'Underwriting',
            operate: []
        },
        {
            name: 'Approved',
            operate: []
        },
        {
            name: 'Closing',
            operate: []
        },
        {
            name: 'Funded',
            operate: []
        },
    ]

    useEffect(() => {
        getFormSignStatus()
    }, [])

    const getFormSignStatus = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/borrower/hardmoneyloan/getformsignstatus', {
          method: 'GET',
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
          },
        }).then(
          response => {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' +
                response.status);
              return;
            }
            response.json().then(js => {
                if (js.Status !== "OK"){
                    console.log("Error getting formsign status: ",js.message)
                    return
                }
                setSignStatus(js.SignStatus)
            })
          }
        )
          .catch(function (err) {
            console.log('Fetch Error :-S', err);
          });
    }

    function emailSignRequest() {
        setSendingSignRequest(true)
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/borrower/hardmoneyloan/sendpasscodeandinvitecosigner', {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                borrowerEmail: props.application.borrower.email.toLowerCase(),
                coborrowerEmail: props.application.coborrower.email.toLowerCase(),
                borrowerFirstName: props.application.borrower.firstname,
                borrowerLastName: props.application.borrower.lastname,
                coborrowerFirstName: props.application.coborrower.firstname,
                coborrowerLastName: props.application.coborrower.lastname,
            }),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(res => {
            if (res.status !== 200) {
                alert("Looks like there was a problem. Status Code: " + res.status +", please try again later or contact your loan officer for assistance.")
                setSendingSignRequest(false)
                return;
            }
            res.json().then(js => {
                setPasscode(js.Code)
                setSendingSignRequest(false)
                setShowResendConfirmModal(false)
                setShowRequestConfirmModal(true)
                return
            })
        })
      
    }

    const renderRequestConfirmModal = () =>
        <Modal
            open={showRequestConfirmModal}
            closable={false}
            className="request-confirm-modal"
            footer={
                <Button
                    className={codeConsent ? 'confirmBtn' : 'confirmBtnDisabled'}
                    type="primary" 
                    disabled={!codeConsent} 
                    onClick={
                        () => {
                            setShowRequestConfirmModal(false)
                        }
                    }
                >
                    Got it
                </Button>
            }
        >
            <div className="request-confirm-wrap">
                <div className='request-confirm-title bottomSpaceAdjuster'>
                    <div>Co-borrower signature requested</div>
                    <img src="/images/sendingEmail.png"/>
                </div>
                <p className='bottomSpaceAdjuster'>{`We have sent a signature request email to your co-borrower, ${props.application.coborrower.firstname +" "+ props.application.coborrower.lastname} [${props.application.coborrower.email}].`}</p>
                <p className='bottomSpaceAdjuster'>Please securely share the <span>4-digit verification code</span> below with your co-borrower, so they can access the application form. </p>
                <div className="verification-code bottomSpaceAdjuster">
                    <span>{passcode}</span>
                </div>
                <p className='bottomSpaceAdjuster'>This code is used to ensure the security of your sensitive data. You can also find this verification code in your email. </p>
                <p className='bottomSpaceAdjuster'>Once all signatures are received, the application will be submitted automatically. </p>
                <Checkbox className='bottomSpaceAdjuster' onChange={(e) => setCodeConsent(e.target.checked)}>I understand that I need to share the verificatin code with my co-borrower</Checkbox>
            </div>
        </Modal>

    const calcLoanAmount = () => {
        let lm = ""
        if (props.property.purpose === "purchase") {
            lm = props.hardmoney.initialloanamount
        }
        if (props.property.purpose === "purchaserehab"){
            lm = props.hardmoney.initialloanamount
        }
        if (props.property.purpose === "refinance") {
            lm = props.hardmoney.refinanceloanamount
        }
        if (props.property.purpose === "construction") {
            lm = props.hardmoney.constructionloanamount
        }
        return lm
    }
    const getLoanAmount = () => {
        let lm = calcLoanAmount()
        if (lm !== "") {
            return "$" + com.commaize(lm)
        }
        return "-"
    }
    const getAsIsValue = () => {
        if (props.property.purpose === "purchaserehab" && props.property.salesprice !== "") {
            return "$" + com.commaize(props.property.salesprice)
        }
        if (props.property.purpose !== "purchaserehab") {
            return "$" + com.commaize(props.hardmoney.refinancedetails.estimatedCurrentValue)
        }
        return "-"
    }
    const getLTV = () => {
        if (props.property.purpose === "purchaserehab" && props.property.salesprice !== "") {
            let sp = com.safeParseInt(props.property.salesprice)
            let lm = com.safeParseInt(calcLoanAmount())
            return Math.round(100 * lm / sp) + "%"
        }
        if (props.property.purpose !== "purchaserehab") {
            let sp = com.safeParseInt(props.hardmoney.refinancedetails.estimatedCurrentValue)
            let lm = com.safeParseInt(calcLoanAmount())
            return Math.round(100 * lm / sp) + "%"
        }
        return "-"
    }

    let rateResult = CalcHardMoneyLoanRate(props)

    const sendMail = () => {
        setLoading(true)
        let id = com.getUserId()
        let token = com.getUserToken()

        fetch('/borrower/sendmail', {
            method: 'POST',
            body: JSON.stringify({ id: parseInt(id), body: "<html><body>" + contactMessage + "</body></html>" }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    setLoading(false)
                    setShowContact(false)
                    messageApi.open({
                        type: 'error',
                        content: 'Looks like there was a problem sending email. Please try later.',
                    });
                    return;
                }
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.log(js.Error)
                        setLoading(false)
                        setShowContact(false)
                        messageApi.open({
                            type: 'error',
                            content: 'Looks like there was a problem sending email. Please try later.',
                        });
                    } else {
                        setLoading(false)
                        setShowContact(false)
                        messageApi.open({
                            type: 'success',
                            content: 'Your message has been sent.',
                        });
                    }

                });
            }
        )
            .catch(function (err) {
                setLoading(false)
                console.log('Fetch Error :', err);
            });
    }

    return (
        <div className="over-view text-left">
            {contextHolder}
            <Modal centered title="Contact my loan officer"
                open={showContact}
                onCancel={() => setShowContact(false)}
                footer={[
                    <Button key="back" onClick={() => setShowContact(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={sendMail}>
                        Send
                    </Button>,
                ]} >
                <div>
                    <TextArea rows={4} placeholder="Leave your message here" onChange={e => setContactMessage(e.target.value)} />
                </div>
            </Modal>
            <Modal
                open={showResendConfirmModal}
                closable={false}
                className='signSuccessModal'
                footer={null}
            >
                <div className="confirmModalTitle">Resend signature request?</div>
                <div className="confirmModalBodyText">Would you like to resend a signature request email to your co-borrower?</div>
                <div className="confirmModalBodyText">This will generate <b>a new 4-digit verification code</b> for them to access the application form.</div>
                <div className="w-100 d-flex justify-content-between">
                    <Button className="cancelModalBtn" type="primary" onClick={()=> setShowResendConfirmModal(false)}>Cancel</Button>
                    <Button className="confirmModalBtn" style={{width:"180px"}} type="primary" onClick={()=>{
                        emailSignRequest()
                    }}>{sendingSignRequest?<Spin />:"Resend"}</Button>
                </div>
            </Modal>
            {renderRequestConfirmModal()}
            <div className='over-view-content'>
                <div className='application-status'>
                    <div className='status-title' onClick={() => setShowContact(true)}>Application Status <a>Contact my loan officer</a></div>
                    <div className='status-list'>
                        {
                            statusList.map((status, statusIndex) =>
                                <div className='status-item' key={statusIndex}>
                                    <div style={{ color: props.step > statusIndex ? '#1F3988' : '#8895BC' }}>{status.name}</div>
                                    {
                                        status.operate.length > 0 && (
                                            <div className='operate' style={{ color: props.step > statusIndex ? '#0F62FE' : '' }}>
                                                {
                                                    status.operate.map((o, index) => o)
                                                }
                                            </div>
                                        )
                                    }
                                    <div className='status-tag' style={{ backgroundColor: props.step > statusIndex ? '#0F62FE' : '#DDE1E6' }}></div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className='my-loan-terms'>
                    <div className='loan-terms-title'><span>My Loan Terms</span><span>Estimated {props.hardmoney.mortgagetermmonths} months</span></div>
                    <div className='loan-terms'>
                        <div className='loan-terms-item'><span>Loan amount</span><span>{getLoanAmount()}</span></div>
                        <div className='loan-terms-item'><span>{props.property.purpose === "purchaserehab" ? "As-Is-Value" : "Current property value"}</span><span>{getAsIsValue()}</span></div>
                        <div className='loan-terms-item'><span>Loan-to-Value</span><span>{getLTV()}</span></div>
                    </div>
                    {rateResult.unqualified ?
                        <div className='loan-terms'>
                            <div className='loan-terms-item mb-2'>Cannot find a qualified rate:</div>
                            <div className='loan-terms-item'>{rateResult.unqualifiedReason}</div>
                        </div>
                        :
                        <>
                            <div className='loan-terms'>
                                <div className='loan-terms-item'><span>Estimated Interest Rate</span><span>{rateResult.floorRate}%</span></div>
                                <div className='loan-terms-item'><span>Estimated Monthly Payment</span><span>${com.commaize(rateResult.monthlyPayment)}</span></div>
                                <div className='loan-terms-item'><span>Estimated Closing Costs</span><span>${com.commaize(rateResult.closingCost)}</span></div>
                            </div>
                            <div className='loan-terms'>
                                <div className='loan-terms-item'><span>Target Closing Date</span><span>{props.hardmoney.targetclosingdate !== "" ? props.hardmoney.targetclosingdate : "-"}</span></div>

                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

Overview = connect(mapStateToProps, mapDispatchToProps)(Overview);
