import React, { useEffect, useState } from 'react';
import {
    message,
    Modal,
    Button,
    Checkbox,
    Spin
} from 'antd';
import { Link } from 'react-router-dom'
import "./Common.css";
import "./Overview.css";
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import './Submitted.css'


const mapStateToProps = (state) => {
    return {
        application: state.application,
        property: state.application.property,
        hardmoney: state.application.hardmoneyloan,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateHardMoneyLoanAttribute: (t, verb) => {
        dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    }
});

export default function Overview(props) {
    const [messageApi, contextHolder] = message.useMessage();
    const [showContact, setShowContact] = useState(false)
    const [loading, setLoading] = useState(false)
    const [contactMessage, setContactMessage] = useState("")
    const [signStatus, setSignStatus] = useState("")
    const [showResendConfirmModal, setShowResendConfirmModal] = useState(false)
    const [showRequestConfirmModal, setShowRequestConfirmModal] = useState(false)
    const [codeConsent, setCodeConsent] = useState(false)
    const [passcode, setPasscode] = useState("")
    const [sendingSignRequest, setSendingSignRequest] = useState(false)

    useEffect(() => {
        getFormSignStatus()
    }, [])

    const getFormSignStatus = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/borrower/hardmoneyloan/getformsignstatus', {
          method: 'GET',
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
          },
        }).then(
          response => {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' +
                response.status);
              return;
            }
            response.json().then(js => {
                if (js.Status !== "OK"){
                    console.log("Error getting formsign status: ",js.message)
                    return
                }
                setSignStatus(js.SignStatus)
            })
          }
        )
          .catch(function (err) {
            console.log('Fetch Error :-S', err);
          });
    }

    function emailSignRequest() {
        setSendingSignRequest(true)
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/borrower/hardmoneyloan/sendpasscodeandinvitecosigner', {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                borrowerEmail: props.application.borrower.email.toLowerCase(),
                coborrowerEmail: props.application.coborrower.email.toLowerCase(),
                borrowerFirstName: props.application.borrower.firstname,
                borrowerLastName: props.application.borrower.lastname,
                coborrowerFirstName: props.application.coborrower.firstname,
                coborrowerLastName: props.application.coborrower.lastname,
            }),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(res => {
            if (res.status !== 200) {
                alert("Looks like there was a problem. Status Code: " + res.status +", please try again later or contact your loan officer for assistance.")
                setSendingSignRequest(false)
                return;
            }
            res.json().then(js => {
                setPasscode(js.Code)
                setSendingSignRequest(false)
                setShowResendConfirmModal(false)
                setShowRequestConfirmModal(true)
                return
            })
        })
      
    }

    const renderRequestConfirmModal = () =>
        <Modal
            open={showRequestConfirmModal}
            closable={false}
            className="request-confirm-modal"
            footer={
                <Button
                    className={codeConsent ? 'confirmBtn' : 'confirmBtnDisabled'}
                    type="primary" 
                    disabled={!codeConsent} 
                    onClick={
                        () => {
                            setShowRequestConfirmModal(false)
                        }
                    }
                >
                    Got it
                </Button>
            }
        >
            <div className="request-confirm-wrap">
                <div className='request-confirm-title bottomSpaceAdjuster'>
                    <div>Co-borrower signature requested</div>
                    <img src="/images/sendingEmail.png"/>
                </div>
                <p className='bottomSpaceAdjuster'>{`We have sent a signature request email to your co-borrower, ${props.application.coborrower.firstname +" "+ props.application.coborrower.lastname} [${props.application.coborrower.email}].`}</p>
                <p className='bottomSpaceAdjuster'>Please securely share the <span>4-digit verification code</span> below with your co-borrower, so they can access the application form. </p>
                <div className="verification-code bottomSpaceAdjuster">
                    <span>{passcode}</span>
                </div>
                <p className='bottomSpaceAdjuster'>This code is used to ensure the security of your sensitive data. You can also find this verification code in your email. </p>
                <p className='bottomSpaceAdjuster'>Once all signatures are received, the application will be submitted automatically. </p>
                <Checkbox className='bottomSpaceAdjuster' onChange={(e) => setCodeConsent(e.target.checked)}>I understand that I need to share the verificatin code with my co-borrower</Checkbox>
            </div>
        </Modal>

    

    const statusList = [
        {
            name: 'Application Draft Started',
            done: true,
            operate: [
                <div className={signStatus==="cosigned"?"cosignedStatusWrap":signStatus==="primarysigned"?"signedStatusWrap":''}>
                    <div>{signStatus==="cosigned"?"Co-borrower signature received":signStatus==="primarysigned"?"Co-borrower signature requested":""}</div>
                    {signStatus!=="cosigned" && signStatus === "primarysigned" ?<span className='resendSignRequest' onClick={()=> setShowResendConfirmModal(true)}>Resend</span>:null}
                </div>,
                <Link to="/application/hardMoneyLoan/interview/main/borrower">View my application</Link>
            ]
        },
        {
            name: 'Initial Application Submitted',
            done: true,
            operate: [<Link to="/application/hardMoneyLoan/interview/main/documents">Gather documents requested in Documents</Link>]
        },
        {
            name: 'Pre-Approval Letter Received',
            operate: []
        },
    ]
    return <>
        <Modal
            open={showResendConfirmModal}
            closable={false}
            className='signSuccessModal'
            footer={null}
        >
            <div className="confirmModalTitle">Resend signature request?</div>
            <div className="confirmModalBodyText">Would you like to resend a signature request email to your co-borrower?</div>
            <div className="confirmModalBodyText">This will generate <b>a new 4-digit verification code</b> for them to access the application form.</div>
            <div className="w-100 d-flex justify-content-between">
                <Button className="cancelModalBtn" type="primary" onClick={()=> setShowResendConfirmModal(false)}>Cancel</Button>
                <Button className="confirmModalBtn" style={{width:"180px"}} type="primary" onClick={()=>{
                    emailSignRequest()
                }}>{sendingSignRequest?<Spin />:"Resend"}</Button>
            </div>
        </Modal>
        {renderRequestConfirmModal()}
        <div className='submitted-banner'>
            <div className='bannerleft'>
                <img src="/images/trophy.png" alt="" className="trophy-icon" />
            </div>
            <div className='bannerright'>
                <div className='title'>Congratulation!<br />You have submitted the initial application.</div>
                <div className="subtitle">
                    Our loan officer will review your application and get back to you soon.<br />
                    You will find any updates in the loan <a href="/application/hardMoneyLoan/interview/main/overview">Overview.</a><br />
                    Prepare to pay the processing fee and submit requested supporting documents in <a href="/application/hardMoneyLoan/interview/main/documents">Documents</a>.
                </div>
            </div>
        </div>

        <div className="over-view text-left pt-5">
            <div className='over-view-content'>
                <div className='application-status'>
                    <div className='status-title' onClick={() => setShowContact(true)}>Application Status </div>
                    <div className='status-list'>
                        {
                            statusList.map((status, statusIndex) =>
                                <div className='status-item' key={statusIndex}>
                                    <div style={{ color: status.done ? '#1F3988' : '#8895BC' }}>{status.name}</div>
                                    {
                                        status.operate.length > 0 && (
                                            <div className='operate' style={{ color: status.done ? '#0F62FE' : '' }}>
                                                {
                                                    status.operate.map((o, index) => o)
                                                }
                                            </div>
                                        )
                                    }
                                    <div className='status-tag' style={{ backgroundColor: status.done ? '#0F62FE' : '#DDE1E6' }}></div>
                                </div>
                            )
                        }

                        <div className='status-item'></div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

Overview = connect(mapStateToProps, mapDispatchToProps)(Overview);
