const initState = {
    currentInfo: {},

}
export default function commonReducer(state = initState, action) {
    const { type, data } = action
    switch (type) {
        case 'SUBSCRIPTION_INFO': {
            return {

                ...state,
                currentInfo: data
            }

        }


        default:
         break
    }
    return state
}