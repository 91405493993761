import React, { Component } from "react"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import * as com from './Common'
import { ResizeObserver } from '@juggle/resize-observer'
import LoginPage from "./newContent/page/LoginPage"
import Helmet from 'react-helmet'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import { State } from './State'
import Recaptcha, { RecaptchaV3, RecaptchaV3SiteKey } from './Recaptcha'
import zxcvbn from "zxcvbn"
import { tooltip } from './Common/Tooltip'
import { withRouter } from "react-router-dom";
import CodeVerification from "./Common/components/CodeVerification";
import { Spinner } from "react-bootstrap";
import FloatingLabelInput from "./Common/components/FloatingLabelInput";
import { connect } from 'react-redux'
import { updateIsHardMoneyLoan } from './Store/accountinfo';
import { Spin } from "antd";

export const loadPasswordCheck = (callback) => {
    const existingScript = document.getElementById('zxcvbn')

    if (!existingScript) {
        const script = document.createElement('script')
        script.src = '/js/zxcvbn.js'
        script.id = 'zxcvbn'
        document.body.appendChild(script)

        script.onload = () => {
            if (callback) callback()
        }
    }

    if (existingScript && callback) callback()
}

const mapStateToProps = (state) => {
    return {
        accountInfo: state.accountInfo,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateIsHardMoneyLoan: (payload) => {
      dispatch(updateIsHardMoneyLoan(payload))
    }
  });

export default class Login extends Component {
    constructor(props) {

        super(props)
        this.form = React.createRef()
        this.password = React.createRef()
        this.password1 = React.createRef()
        this.captcha = React.createRef()
        this.timer = null
        this.buttonpressed = false

        let searchparams = new URLSearchParams(window.location.search)
        this.customerid = searchparams.get("customerid")
        this.loid = searchparams.get("loid")
        this.where = searchparams.get("where")

        this.state = {
            loading: false,
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            password1: "",
            signin: window.location.pathname.indexOf("/services/signin") === 0 ||
                this.props.view === "bare",
            show: false,
            message: "",
            validated: false,
            captchasolved: true,
            passwordCheckReady: false,
            text: "",
            bgcolor: "rgb(232,235,239)",
            text2: "",
            bgcolor2: "rgb(232,235,239)",
            signingup: false,
            logingin: false,
            loid: "",
            LoanOfficerID: "",
            emailNotExisted: false,
            useEmail: false,
            usePassword: false,
            sendingCode: false,
            emailCode: "",
            verifingCode: false,
            showResult: false,
            verified: false,
            reCaptchaLoaded: false,
            checking: false,
        }
        let params = new URLSearchParams(window.location.search)
        let loid = params.get("loid")

        if (null != loid) {
            this.state.loid = loid
            localStorage.setItem("loid", loid)
        } else {
            let l = localStorage.getItem("loid")
            if (null != l)
                this.state.loid = l
        }
        this.local = window.location.hostname === "localhost"

        this.pattern = "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
        if (this.state.signin)
            this.pattern = "^.+$"

        this.myObserver = new ResizeObserver(entries => {
            if (entries.length > 0) {
                let bod = entries[0]

                let _width = bod.contentRect.width
                let _height = bod.contentRect.height

                let height = Math.max(_height, document.body.clientHeight, 380)
                if (this.state.signin) {
                    height = 500
                }
                let width = Math.max(_width, document.body.clientWidth)

                window.parent.postMessage({ source: "tools", payload: [width, height] }, '*')
            }
        })
        this.ref = React.createRef()
        if (["bare", "baresignup"].includes(this.props.view)) {
            if (window.drift) {
                window.drift.hide()
                window.drift = null
            }
        }
    }

    validateForm() {
        if (this.state.signin)
            return this.state.email.length > 0 && this.state.password.length > 8
        else
            return this.state.firstName.length > 0 && this.state.lastName.length > 0 && this.state.email.length > 0 && this.state.password.length > 8
                && this.state.password === this.state.password1
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        })
    }
    isEmail = () => {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(this.state.email)
    }
    checkEmail = () => {
        this.setState({ emailNotExisted: false, useEmail: false, usePassword: false, checking: true })
        fetch("/auth/checksigninmethod", {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                email: this.state.email.toLowerCase(),
                customerID: this.customerid,
            }),
            headers: {
                Cache: "no-cache"
            },
        }).then(response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status)
                return
            }
            response.json().then(js => {
                if (js.Status === "NotExisted") {
                    this.setState({ emailNotExisted: true, emailCode: "", checking: false })
                } else if (js.Status === "OK") {
                    if (js.Method === "email") {
                        this.setState({ useEmail: true, checking: false })
                        this.sendcode()
                    } else {
                        this.setState({ usePassword: true, checking: false })
                    }
                }
            })

        }).catch(error => {
            console.log(error)
            this.setState({ emailCode: "" })
        })
    }
    validatePassword = () => {

    }
    onPassword = e => {
        this.setState({ password: e.target.value })
        if (this.state.signin)
            return

        if (this.state.passwordCheckReady) {

            let ret = zxcvbn(e.target.value)

            switch (ret.score) {
                case 0:
                    this.setState({ text: "Insecure", bgcolor: "rgb(255,64,64)" })
                    break
                case 1:
                    this.setState({ text: "Too Weak", bgcolor: "rgb(255,128,64)" })
                    break
                case 2:
                    this.setState({ text: "Breakable", bgcolor: "rgb(160,160,64)" })
                    break
                case 3:
                    this.setState({ text: "Good enough", bgcolor: "rgb(128,180,64)" })
                    break
                case 4:
                    this.setState({ text: "Strong!!!", bgcolor: "rgb(64,180,64)" })
                    break
                default:
                    break
            }
            if (this.state.password1 && this.state.password1 !== "") {
                if (e.target.value !== this.state.password1) {
                    this.password1.current.setCustomValidity("Passwords should match!")
                    this.setState({ text2: "Mismatch", bgcolor2: "rgb(255,64,64)" })
                } else {
                    this.setState({ text2: "Identical!", bgcolor2: "rgb(64,180,64)" })
                }
            }
            if (ret.score < 3) {
                this.password.current.setCustomValidity("Password is too weak. Try longer mix of letters, numbers and special characters")
            } else {
                this.password.current.setCustomValidity("")
            }
        }
    }
    onPassword1 = e => {
        this.setState({ password1: e.target.value })
        if (e.target.value !== this.state.password) {
            this.setState({ validated: true })
            this.password1.current.setCustomValidity("Passwords should match!")
            this.setState({ text2: "Mismatch", bgcolor2: "rgb(255,64,64)" })
        } else {
            this.password1.current.setCustomValidity("")
            this.setState({ text2: "Identical!", bgcolor2: "rgb(64,180,64)" })
        }
    }

    checkCaptcha = (event) => {
        if (!window.grecaptcha || !this.buttonpressed)
            return false

        let captcha = this.local ? "XXX" : window.grecaptcha.getResponse()
        if (!captcha || captcha === "") {
            this.setState({ captchasolved: false })
            this["state"]["captchasolved"] = false
            this.setState({ validated: true })
            this.captcha.current.setCustomValidity("Please solve the Captcha")
            if (event) {
                event.preventDefault()
                event.stopPropagation()
            }
            this.captcha.current.required = true
            return false
        } else {

            this.setState({ captchasolved: true })
            this["state"].captchasolved = true
            this.captcha.current.setCustomValidity("")
            this.captcha.current.required = false
        }
        return true
    }

    getAccountInfo = () => {
        fetch('/auth/getaccountinfo', {
            method: 'POST',
            body: JSON.stringify({
                CustomerID: this.customerid
            }),
            headers: { 'Content-Type': 'application/json' },
        }).then(
            response => {
                if (response.status !== 200) {
                    return;
                }
                response.json().then(js => {

                    if (js.Status !== "OK") {

                    } else {
                        this.props.updateIsHardMoneyLoan(js.IsHardMoneyLoanCustomer)
                        sessionStorage.setItem("isHardMoneyLoanCustomer", js.IsHardMoneyLoanCustomer)
                    }
                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }

    componentDidMount() {

        if (this.customerid === "individualcustomers") {
            let token = sessionStorage.getItem("ZeitroA")
            fetch("/data/getprofile", {
                cache: 'no-cache',
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                    "X-RE": this.loid,
                },
            }).then(response => {
                let d = new Date()
                if (!response.ok) {
                    console.log("Auth fetch error, at /data/getprofile: " + d.toLocaleString())
                } else {

                    response.json().then(js => {
                        console.log(js)
                        if (js.Status !== "OK") {

                        } else {
                            sessionStorage.setItem("logoutpath", js.Profile.Domain + js.Profile.LoginPath)
                        }
                    })
                }
            }).catch(error => {
                let d = new Date()
                console.log("Revalidate catch error " + error + ", at: " + d.toLocaleString())
            })
        }

        loadPasswordCheck(() => {
            // Work to do after the library loads.
            this.setState({ passwordCheckReady: true })
        })
        this.timer = window.setInterval(this.checkCaptcha, 1000)

        if (this.props.view === "bare" || this.props.view === "baresignup") {
            this.myObserver.observe(this.ref.current)
            if (window.drift) {
                window.drift.hide()
                window.drift = null
            }
        }

        fetch('/auth/getlos', {
            method: 'POST',
            body: JSON.stringify({
                CustomerID: this.customerid
            }),
            headers: { 'Content-Type': 'application/json' },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('/auth/getlos:Looks like there was a problem. Status Code: ' +
                        response.status)
                    window.grecaptcha.reset()
                    return
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {

                    } else {
                        let los = js.LoanOfficers.map(({ FirstName, LastName, Email, ID }) => ({ ID, FirstName, LastName, Email }))
                        this.setState({ LoanOfficerOptions: los })
                    }
                })
            }
        ).catch((err) => {
            console.log('Fetch Error :', err)
            this.setState({ message: "Our servers are down. Please check later!", show: true })

            if (window.grecaptcha) {
                window.grecaptcha.reset()
            }
        })

        this.getAccountInfo()

    }

    componentWillUnmount() {
        window.clearInterval(this.timer)
    }

    handleClose = () => { this.setState({ show: false }) }

    sendcode = () => {
        this.setState({ sendingCode: true })
        fetch("/auth/generateonetimepasscode", {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                email: this.state.email.toLowerCase(),
                customerID: this.customerid,
                loID: sessionStorage.getItem("loid") === null ? "" : sessionStorage.getItem("loid"),
            }),
            headers: {
                Cache: "no-cache"
            },
        }).then(response => {
            let d = new Date()
            //console.log(JSON.stringify(response))
            if (!response.ok) {
                console.log(response)

            } else {
                console.log(response)

            }
            this.setState({ sendingCode: false })
        }).catch(error => {
            console.log(error)
        })
    }

    verifycode = (code) => {
        this.setState({ verifingCode: true })

        fetch("/auth/verifycodeandsignin", {
            cache: 'no-cache',
            method: 'POST',
            body: JSON.stringify({
                email: this.state.email.toLowerCase(),
                code: code,
                customerID: this.customerid,
            }),
            headers: {
                Cache: "no-cache"
            },
        }).then(response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status)
                this.setState({ showResult: true, verified: false, verifingCode: false, emailCode: "", message: "Code is incorrect or expired." })
                return
            }
            response.json().then(js => {

                if (js.Status !== "OK") {
                    console.log(js)
                    this.setState({ showResult: true, verified: false, verifingCode: false, emailCode: "", message: "Code is incorrect or expired." })
                } else {
                    sessionStorage.setItem("ZeitroA", js.Token)
                    let state = State.fromJson(JSON.parse(js.State))
                    let statejs = state.toJson()
                    sessionStorage.setItem("state", statejs)
                    sessionStorage.setItem("originalstate", statejs)
                    // this.setState({showResult: true, verified: true, verifingCode: false, emailCode:""})
                    this.redirectCustomer(js, state)
                }
            })

        }).catch(error => {
            console.log(error)
            this.setState({ code: "" })
        })
    }

    redirectCustomer = (js, state) => {
        // let params = new URLSearchParams(window.location.search);
        // let where = params.get("where")
        // path = com.hex2ascii(where)
        const isHardMoneyLoan = state.application.product.mortgageapplied === "hardmoneyloan"
        com.getReturnURLs()
        let path = ""

        if ("undefined" === typeof state.progress || "undefined" === typeof state.progress.stage ||
            "undefined" === typeof state.application.property.purpose ||
            state.application.property.purpose === null) {
            path = "/services/preapp?customerid=" + this.customerid
        } else {

            if (state.progress.lasttouch == null) {
                state.progress.stage = 'resetintervoew'
            } else {
                // let now = new Date()
                // let then = new Date(state.progress.lasttouch)
                // let days = (now - then) / (3600 * 24 * 1000)
                // if (days > 7)
                //     state.progress.stage = 'resetintervoew'
            }
            // console.log(state.progress.lasttouch)
            // console.log(state.progress.stage)
            //  path = "/application/interview/main"
            // switch (state.progress.stage) {
            //     case 'interview':
            //     default:
            //         path = "/application/interview/main"
            //         break
            //     case 'profile':
            //         path = "/application/profile"
            //         break
            //     case 'yourloans':
            //         path = "/application/myloans"
            //         break

            //     case 'tasks':
            //         path = "/application/tasks"
            //         break
            //     case 'documents':
            //         path = "/application/docs"
            //         break
            //     case 'contact':
            //         path = "/application/contact"
            //         break
            //     case 'resetintervoew':
            //         path =  "/services/preapp?customerid=" + this.customerid
            //         break;
            // }
        }
        if (this.where !== null) {
            path = "/application/" + this.where
        }
        if (isHardMoneyLoan) path = "/application/myloans"

        if (this.props.view === "bare") {
            window.parent.postMessage({ source: "redirect", payload: { path, token: js.Token } }, '*')
        } else {
            window.location.href = '/application/myloans'
        }
    }

    signin = (event, data) => {
        // let captcha = this.local ? "XXXX" : window.grecaptcha.getResponse()
        let captcha = window.grecaptcha.getResponse()
        if (!captcha || "" === captcha) {
            this.setState({ captchasolved: false })
            this["state"].captchasolved = false
            console.log("captcha invalid!")
            this.setState({ validated: true })
            this.captcha.current.setCustomValidity("Please solve the Captcha")
            return
        }
        let tosend = this.state.signin ?
            JSON.stringify({
                email: data.get("email").toLowerCase(), password: data.get("password"),
                captcha: captcha, customerid: this.customerid,
            })
            :
            JSON.stringify({
                email: data.get("email").toLowerCase(), password: data.get("password"), firstName: data.get("firstName"),
                lastName: data.get("lastName"), loid: data.get("loid"), customerid: this.customerid,
            })

        fetch('/auth/signin', {
            method: 'POST',
            body: tosend,
            headers: { 'Content-Type': 'application/json' },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    window.grecaptcha.reset()
                    return
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        this.setState({ message: js.Text, show: true, showResult: true, verified: false })
                        sessionStorage.removeItem("ZeitroA")
                        sessionStorage.removeItem("state")
                        if (!this.local && window.grecaptcha)
                            window.grecaptcha.reset()
                    } else {
                        sessionStorage.setItem("ZeitroA", js.Token)
                        let state = State.fromJson(JSON.parse(js.State))
                        if (state.application.status === 'loinitialized') {
                            state.application.status = 'interviewstarted'
                        }
                        let statejs = state.toJson()
                        sessionStorage.setItem("state", statejs)
                        sessionStorage.setItem("originalstate", statejs)
                        // load the prerequisite redirects

                        this.redirectCustomer(js, state)
                    }
                })
            }
        ).catch((err) => {
            console.log('Fetch Error :', err)
            this.setState({ message: "Our servers are down. Please check later!", show: true })

            if (window.grecaptcha) {
                window.grecaptcha.reset()
            }
        })
    }

    signup = (event, data) => {
        let captcha = (this.local) ? "XXXX" : window.grecaptcha.getResponse()
        if (!captcha || "" === captcha) {
            this.setState({ captchasolved: false })
            this["state"].captchasolved = false

            this.setState({ validated: true })
            this.captcha.current.setCustomValidity("Please solve the Captcha")
            return
        }
        let tosend = this.state.signin ?
            JSON.stringify({ email: data.get("email").toLowerCase(), password: data.get("password"), customerid: this.customerid })
            :
            JSON.stringify({
                email: data.get("email").toLowerCase(), password: data.get("password"), firstName: data.get("firstName"),
                lastName: data.get("lastName"), leadSource: data.get("leadSource"), customerid: this.customerid,
                captcha: captcha, loid: data.get("loid") || this.state.LoanOfficerID,
            })
        this.setState({ signingup: true })
        fetch('/auth/signup', {
            method: 'POST',
            body: tosend,
            headers: { 'Content-Type': 'application/json' },
        }).then(
            response => {

                if (response.status !== 200) {
                    this.setState({
                        message: 'Looks like there was a problem. Status Code: ' +
                            response.status, show: true
                    })
                    if (!this.local)
                        window.grecaptcha.reset()
                    return
                }
                // Examine the text in the response
                this.setState({ signingup: false })

                response.json().then(js => {

                    if (js.Status !== "OK") {
                        this.setState({ message: js.Text, show: true })
                        sessionStorage.removeItem("ZeitroA")
                        sessionStorage.removeItem("state")
                        if (!this.local)
                            window.grecaptcha.reset()

                    } else {

                        if (this.props.view === "baresignup") {
                            let evt = { source: "plainredirect", payload: "/account-thanks" }
                            console.log(evt)
                            window.parent.postMessage(evt, '*')
                        } else {
                            window.location.href = "/resources/validate"
                        }
                    }
                })
            }
        ).catch((err) => {
            this.setState({ signingup: false })
            console.log('Fetch Error :', err)
            this.setState({ message: "Our servers are experiencing problems. Please check later.", show: true })
            if (!this.local)
                window.grecaptcha.reset()

        })
    }
    handleSubmit = event => {
        this.buttonpressed = true

        let captcha = (this.local) ? "XXXX" : window.grecaptcha.getResponse()

        if (!captcha || captcha === "") {
            this.setState({ captchasolved: false })
            this["state"].captchasolved = false

            this.setState({ validated: true })
            this.captcha.current.setCustomValidity("Please solve the Captcha")
            event.preventDefault()
            this.captcha.current.required = true
            return false
        } else {
            this.setState({ captchasolved: true })
            this["state"].captchasolved = true
            if (!this.local) {
                this.captcha.current.setCustomValidity("")
                this.captcha.current.required = false
            }
        }

        if (this.form.current.reportValidity() === false) {
            event.preventDefault()
            this.setState({ validated: true })


            return false
        }
        event.preventDefault()
        this.setState({ validated: false })
        event.stopPropagation()

        const data = new FormData(event.target)
        if (this.state.signin) {
            this.signin(event, data)
        } else {
            this.signup(event, data)

        }
    }
    forgot = () => {
        if (this.props.view === "bare")
            return ""
        return (
            <div>
                <Row className="text-center mt-3">
                    <Col className="text-center bluish" > <Link to="/services/forgot-password" >Forgot your password?</Link></Col>
                </Row>
                <Row className="text-center mt-1">
                    <Col className="text-center bluish" > <Link to="/services/signup" >Sign up for a different account</Link></Col>
                </Row>
            </div>
        )
    }
    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }
    header = () => {
        if (this.props.view === "bare")
            return ""
        if (this.state.signin) {
            return (
                <div className="pt-4 text-center">
                    <h5> Sign In </h5>
                    <div className="my-0 pt-1 py-0 my-0 nav-link" style={{ fontSize: "14px" }}>to continue your loan application</div>
                </div>
            )
        } else {
            if (this.props.view === "baresignup")
                return ""

            return (
                <div className="pt-4">
                    <h5> Sign Up </h5>
                </div>
            )
        }
    }
    onInvite = e => {

    }

    showWarning = (text) => {
        return (
            <div className="d-flex justify-content-center"><div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: "rgba(255, 170, 41, 0.3)", padding: "10px", borderRadius: "4px", marginTop: "15px" }}>
                <div>

                    <img src="/images/warningSignYellow.png" style={{ width: "20px" }} />
                </div>



                <a md={11} style={{ whiteSpace: "pre-wrap", fontSize: "14px", marginLeft: "40px" }}>{text}</a>

            </div></div>

        )
    }

    reset = () => {
        this.setState({ email: "", useEmail: false, usePassword: false, showResult: false, verified: false })
    }



    login = (token) => {
        this.setState({ logingin: true })
        let tosend = JSON.stringify({
            email: this.state.email.toLowerCase(),
            password: this.state.password,
            token: token,
            customerid: this.customerid,
        })

        fetch('/auth/signin', {
            method: 'POST',
            body: tosend,
            headers: { 'Content-Type': 'application/json' },
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    window.grecaptcha.reset()
                    return
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        this.setState({ message: js.Text, show: true, showResult: true, verified: false, logingin: false })
                        sessionStorage.removeItem("ZeitroA")
                        sessionStorage.removeItem("state")
                        if (!this.local && window.grecaptcha)
                            window.grecaptcha.reset()
                    } else {
                        sessionStorage.setItem("ZeitroA", js.Token)
                        let state = State.fromJson(JSON.parse(js.State))
                        if (state.application.status === 'loinitialized') {
                            state.application.status = 'interviewstarted'
                        }
                        let statejs = state.toJson()
                        sessionStorage.setItem("state", statejs)
                        sessionStorage.setItem("originalstate", statejs)
                        // load the prerequisite redirects

                        this.redirectCustomer(js, state)
                    }
                })
            }
        ).catch((err) => {
            console.log('Fetch Error :', err)
            this.setState({ message: "Our servers are down. Please check later!", show: true, logingin: false })

            if (window.grecaptcha) {
                window.grecaptcha.reset()
            }
        })
    }

    handlePressEnter = (e) => {
        if (e.key === 'Enter') {
            this.checkEmail()
        }
    }


    render() {

        let dummy = (e) => {

        }

        if (this.props.view === "signin" || this.props.view === "bare") {
            return (
                <Spin spinning={this.state
                    .loading
                }>
                    <div id="loginbox" className="Login loginWrap " style={{ paddingBottom: "10px" }} ref={this.ref}>
                        <Helmet>
                            <title>Login</title>
                        </Helmet>
                        <div className="pt-4 text-center">
                            <h5> Sign In </h5>
                            <div className="my-0 py-0 my-0 nav-link" style={{ fontSize: "14px" }}>to continue your loan application</div>
                            {/* This form to trigger the login captcha, should not be here anymore, its usage not align with the following login method? */}
                            {/* <Form style={{ fontSize: '0.8em' }} ref={this.form} onSubmit={this.handleSubmit} noValidate validated={this.state.validated}> */}
                            <Row className="pt-5">
                                <Col >
                                    <FloatingLabelInput name="email" label="Email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} handleKeyPress={(e) => this.handlePressEnter(e)} />
                                </Col>
                            </Row>
                            {!this.state.useEmail && !this.state.usePassword && <Row className="pt-3">
                                <Col>
                                    <Button style={{ width: "100%" }} variant="zeitro-primary" disabled={!this.isEmail()} onClick={() => this.checkEmail()}>{this.state.checking ? <Spinner animation="border" /> : "Next"}</Button>
                                </Col>
                            </Row>}
                            {this.state.emailNotExisted && this.showWarning("Email does not exist.")}
                            {this.state.useEmail && <Row className="mt-2">
                                <Col className=" text-center">
                                    <div className="prequalSmallText pt-3">Please enter the code sent to your email</div>
                                    <div style={{ display: "flex", justifyContent: "center", padding: "20px 10px 0 10px" }}>
                                        {(this.state.verifingCode || this.state.sendingCode) ? <Spinner animation="border" size="lg" className="pt-4" /> :
                                            <div>
                                                <CodeVerification className="loginEmailVerify" value={this.state.emailCode} onChange={(e) => {
                                                    this.setState({ emailCode: e })
                                                    if (e.length === 4) {
                                                        this.verifycode(e)
                                                    }
                                                }} />
                                                <div>
                                                    <Button variant="link" className="pt-2" onClick={() => { this.reset() }}>Back</Button>
                                                    <Button variant="link" className="pt-2" onClick={() => { this.sendcode() }}>Resend</Button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Row>}

                            {this.state.usePassword && <Row className="pt-3"><Col >
                                <FloatingLabelInput className="mb-3" name="password" type="password" label="Password" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} />
                                <RecaptchaV3 />
                                <div><Button variant="link" onClick={() => { this.reset() }}>Back</Button></div>
                                <div><Button variant="zeitro-primary"
                                    onClick={() => {
                                        window.grecaptcha.ready(() => {
                                            window.grecaptcha.execute(RecaptchaV3SiteKey, { action: 'submit' }).then(token => {
                                                this.login(token)
                                            })
                                        })
                                    }}
                                >{this.state.logingin ? <Spinner animation="border" size="sm" /> : "Login"}</Button></div>
                            </Col></Row>}
                            {/* </Form> */}

                            {(this.state.showResult && !this.state.verified) && this.showWarning(this.state.message)}
                            {/* <Row className="text-center"><Col><Button variant="link" onClick={()=>{this.setState({useEmail: false, usePassword: false, showResult: false, verified: false})}}>Re-enter email</Button></Col></Row> */}
                        </div>
                        <div style={{ width: '92%', margin: 'auto' }} className="mt-3">
                            <LoginPage  setLoading={(e) => {

                                this.setState({
                                    loading: e
                                })
                            }}></LoginPage>
                        </div>
                    </div>
                </Spin>
            )
        }

        return (
            <div id="loginbox" className="Login loginbox " ref={this.ref}>
                <Helmet>
                    <title>{this.state.signin ? 'Zeitro Login' : 'Zeitro Sign Up'}</title>
                </Helmet>
                <Modal show={this.state.show} onHide={this.handleClose} onShow={this.onShow}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.signin ? 'Authentication Failed' : 'Sign Up Failed'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.message}</Modal.Body>
                    <Modal.Footer>
                        <Button id="Close1" variant="secondary" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                {this.header()}
                <div className="text-center px-5 pb-5">
                    {this.state.signingup ?
                        <div id="spinner" className="pt-5 mt-5">

                            <img alt="spinner" className="loader" src={com.logo ? `data:image/png;base64, ${com.logo}` : "/logo.png"}></img>
                        </div>
                        :
                        <Form style={{ fontSize: '0.8em' }} ref={this.form} onSubmit={this.handleSubmit} noValidate validated={this.state.validated}>
                            <Row className="mt-0 pt-4 ">
                                <Col className="text-left">
                                    <Form.Group controlId="email"  >
                                        <Form.Label className="text-left" >Email:</Form.Label>
                                        <Form.Control
                                            required
                                            name="email"
                                            size="sm"
                                            autoFocus
                                            autoComplete="email"
                                            type="email"
                                            defaultValue={this.state.email}
                                            onChange={this.handleChange}
                                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                                        />

                                        <Form.Control.Feedback type="invalid" >
                                            Please enter a valid email address!
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                </Col>
                            </Row>
                            {this.props.view === "signup" || this.props.view === "baresignup" ?
                                <div>
                                    <Row className="  text-right">

                                        <Col className="text-left">
                                            <Form.Group controlId="firstName"  >
                                                <Form.Label className="text-left" >First Name:</Form.Label>

                                                <Form.Control
                                                    name="firstName"
                                                    autoComplete="given-name"
                                                    required
                                                    size="sm"
                                                    pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                                    value={this.state.firstName}
                                                    onChange={this.handleChange}
                                                />

                                                <Form.Control.Feedback type="invalid" >
                                                    Please enter your first name!
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col className="text-left">
                                            <Form.Group controlId="lastName"  >
                                                <Form.Label className="text-left" >Last Name:</Form.Label>

                                                <Form.Control
                                                    size="sm"
                                                    name="lastName"
                                                    autoComplete="family-name"
                                                    pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                                                    required
                                                    value={this.state.lastName}
                                                    onChange={this.handleChange}
                                                />

                                                <Form.Control.Feedback type="invalid" >
                                                    Please enter your last name!
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                                : ""}

                            {this.props.view === "signin" || this.props.view === "bare" ?
                                <div>
                                    <Row className="mt-2">
                                        <Col className=" text-left">
                                            <Form.Group controlId="password"  >
                                                <Form.Label className="text-left" >Password:</Form.Label>

                                                <Form.Control
                                                    size="sm"
                                                    name="password"
                                                    ref={this.password}
                                                    required
                                                    autoComplete="password"
                                                    value={this.state.password}
                                                    onChange={this.onPassword}
                                                    type="password"
                                                    pattern={this.pattern}
                                                />

                                                <Form.Control.Feedback type="invalid" >
                                                    Password must be at least 8 charactes long and<br />contain large, small letters, and numbers!
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button variant="link" onClick={e => this.sendcode()}>Send Code</Button>
                                    </Row>

                                </div> : " "}
                            {this.props.view === "signup" || this.props.view === "baresignup" ?
                                <div>
                                    <Row>
                                        <Col className="  text-left">
                                            <Form.Group controlId="password"  >
                                                <Form.Label className="text-left" >Password:</Form.Label>

                                                <InputGroup size="sm" >
                                                    <Form.Control
                                                        size="sm"
                                                        name="password"
                                                        ref={this.password}
                                                        required
                                                        autoComplete="off"
                                                        value={this.state.password}
                                                        onChange={this.onPassword}
                                                        type="password"
                                                        pattern="^.+$"
                                                    //pattern={this.pattern}
                                                    />

                                                    <InputGroup.Append>
                                                        <InputGroup.Text className="pl-4" style={{ "whiteSpace": "nowrap", "textAlign": "right", "backgroundColor": this.state.bgcolor, "color": "white", "minWidth": "9em" }} size="sm">{this.state.text}</InputGroup.Text>
                                                    </InputGroup.Append>


                                                    <Form.Control.Feedback type="invalid" >{tooltip("Password is too weak",
                                                        <div>
                                                            At Zeitro we want to make sure that your sensitive data are protected. <p />The choice of a password is very important!<p />
                                                            Password should be hard to guess, and be long enough.
                                                            If password strength is shown to be too weak, add more characters, and make sure it contains large and small letters, numbers and special symbols.
                                                        </div>
                                                    )}
                                                    </Form.Control.Feedback>

                                                </InputGroup >

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="  ">
                                        <Col className="text-left" >
                                            <Form.Group controlId="password1" >
                                                <Form.Label className="text-left" >Validate:</Form.Label>

                                                <InputGroup size="sm" >

                                                    <Form.Control
                                                        ref={this.password1}
                                                        size="sm"
                                                        required
                                                        autoComplete="off"
                                                        value={this.state.password1}
                                                        onChange={this.onPassword1}
                                                        //pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
                                                        pattern="^.+$"
                                                        type="password"
                                                    />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text className="pl-4" style={{ "whiteSpace": "nowrap", "textAlign": "right", "backgroundColor": this.state.bgcolor2, "color": "white", "minWidth": "9em" }} size="sm">{this.state.text2}</InputGroup.Text>
                                                    </InputGroup.Append>

                                                    <Form.Control.Feedback type="invalid" >
                                                        Make sure validate and password fields are the same!
                                                    </Form.Control.Feedback>
                                                </InputGroup >

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="  ">
                                        <Col className="text-left" >
                                            <Form.Group controlId="leadSource" >
                                                <Form.Label className="text-left" >How did you hear about us?</Form.Label>

                                                <InputGroup size="sm" >

                                                    <Form.Control as="select" size="sm"
                                                        name="leadSource"
                                                        required
                                                        type="text"
                                                        defaultValue=""
                                                        onChange={this.handleChange}
                                                    >
                                                        <option value=""></option>
                                                        <option value="LoanOfficer">Loan Officer</option>
                                                        <option value="Facebook Ads">Facebook Ads</option>
                                                        <option value="LinkedIn">LinkedIn</option>
                                                        <option value="Instagram">Instagram</option>
                                                        <option value="Newsletter">Newsletter</option>
                                                        <option value="Post cards">Post cards</option>
                                                        <option value="Friends or Family">Friends or Family</option>
                                                        <option value="Internet Search">Internet Search</option>
                                                        <option value="Open House Flyer">Open House Flyer</option>
                                                        <option value="Other">Other</option>
                                                    </Form.Control>


                                                    <Form.Control.Feedback type="invalid" >
                                                        Please tell us how you've heard about us
                                                    </Form.Control.Feedback>
                                                </InputGroup >

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {this.state.leadSource === 'LoanOfficer' && <Row className="  ">
                                        <Col className="text-left" >
                                            <Form.Group controlId="LoanOfficerID" >
                                                <Form.Label className="text-left" >Select a loan officer</Form.Label>

                                                <InputGroup size="sm" >

                                                    <Form.Control as="select" size="sm"
                                                        name="LoanOfficerID"
                                                        required
                                                        type="text"
                                                        defaultValue=""
                                                        onChange={this.handleChange}
                                                    >
                                                        <option value=""></option>
                                                        {this.state.LoanOfficerOptions.map(lo => (<option key={lo.Email} value={lo.ID}>{`${lo.FirstName} ${lo.LastName} - ${lo.Email}`}</option>))}
                                                    </Form.Control>


                                                    <Form.Control.Feedback type="invalid" >
                                                        Please tell us how you've heard about us
                                                    </Form.Control.Feedback>
                                                </InputGroup >

                                            </Form.Group>
                                        </Col>
                                    </Row>}
                                    <Row hidden>
                                        <Col className="  text-left">
                                            <Form.Group controlId="loid"  >

                                                <InputGroup size="sm" >
                                                    <Form.Control
                                                        size="sm"
                                                        name="loid"
                                                        value={this.state.loid}
                                                        //onChange={this.onPassword}
                                                        type="text"
                                                    />

                                                </InputGroup >

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div> : ""}
                            {(false) ? "" :
                                <Row className="text-center mt-2  ">
                                    <Col className="text-left">
                                        <Recaptcha />
                                        <Form.Group controlId="hackid" >
                                            <Form.Control
                                                size="sm"
                                                ref={this.captcha}
                                                required={!this.state.captchasolved}
                                                value=""
                                                name="hack"
                                                hidden={true}
                                                type="text"
                                                onChange={dummy}

                                            />

                                            <Form.Control.Feedback type="invalid" >
                                                Please solve the Captcha!
                                            </Form.Control.Feedback>

                                        </Form.Group>

                                    </Col>
                                </Row>}
                            <Row className="pt-3 ">
                                <Col >
                                    <Button id="loginorsignup"
                                        type="submit" className="lpbutton" variant="zeitro-primary"
                                    >{this.state.signin ? 'Login' : 'Sign Up'} </Button>
                                </Col>
                            </Row>
                            <Row className="mt-3 ">
                            </Row>
                            {this.state.signin ? this.forgot() : ""}


                        </Form>}
                </div>
            </div>

        )
    }
}

Login = withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Login))
