import React, {useEffect, useState} from 'react';
import "./NonQMBorrowerQualifier.css";
import { Button } from 'antd';
import * as com from "../../Common.js"
import { PhoneOutlined, MailOutlined } from '@ant-design/icons';
import EmailVerification from '../../Application/EmailVerification';

const NonQMResult = ({ qualifiedProducts, loName,loPhone,loEmail, onFinish }) => {
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [uniqueProducts, setUniqueProducts] = useState([]);

  useEffect(()=>{

    const uniqueProducts = qualifiedProducts.reduce((acc, product) => {
      const existingProduct = acc.find(p => p.name === product.name);
      if (!existingProduct || product.rate < existingProduct.rate) {
        return [...acc.filter(p => p.name !== product.name), product];
      }
      return acc;
    }, []);
    setUniqueProducts(uniqueProducts);
  }, [])


  if (showEmailVerification) {
    return  <div  >
        <div className="header-1 text-center mb-4">Create an account</div>
        <div className='text-center'>To explore your best loan options and get connected with our experienced loan officers</div>
        <div className='nonqm-result-email-verification'>
            <EmailVerification mode="simple" purpose="nonqm" onFinish={onFinish}/>
        </div>
    </div>
  }




  return (
    <div className="nonqm-result">
    <div className="header-1 text-center mb-4">{uniqueProducts.length ===0 ?"Almost there! Explore your options by applying": "Programs you might be qualified for"}</div>
      <div className='text-left' style={{color:"#586570"}}> Thank you for your interest in securing financing! Based on the information provided, here are some programs you may qualify for. To proceed to an application, please click "Apply now" for next steps.</div>
      
      <div className="program-grid">
        {uniqueProducts.map((product, index) => (
          <div key={index} className="program-card">
            <div style={{fontWeight: 500, fontSize: 18}}>{product.name}</div>
            <div>Rate: {product.rate.toFixed(3)}%</div>
            <div>Monthyly payment: ${com.commaize(product.monthlyPayment)}</div>
          </div>
        ))}
        <div className="program-card" style={{ backgroundColor: '#EAEFFD', border: '1px solid #C3CDEA', lineHeight:1.4 }}>
            <div className='fw-500' style={{fontSize:18, color:"#474849"}}>Need more information?</div>
            <div className='d-flex align-items-baseline mt-2'><p style={{color:"#325CEB", fontWeight: 500, fontSize:18, marginRight:5}}>{loName}</p> <span style={{color:"#081265", fontSize: 10}}>LOAN OFFICER</span></div>
            <div><PhoneOutlined className='mr-3'/>{loPhone}</div>
            <div><MailOutlined className='mr-3'/>{loEmail}</div>
        </div>
      </div>
      

    <div className='text-left'>
      <Button type='primary' size='large'  style={{minWidth:150}} onClick={()=>setShowEmailVerification(true)}>Apply now</Button>
    </div>
    </div>
  );
};

export default NonQMResult;