import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Spinner } from "react-bootstrap";
import { connect } from 'react-redux'
import { Dropdown, Space, Modal, Skeleton } from 'antd';
//import Modal from 'react-bootstrap/Modal'
import { useNavigate } from "react-router-dom";
import * as act from "./Store/actions"
import * as prgr from "./Store/progress"
import * as com from "./Common.js"
import { State } from './State.js'


import { UpdateChangeProgressType } from "./Store/progress"
import { getCurrentState, updateState, initialState } from "./Store";
const mapStateToProps = (state) => {
    return {
        progress: state.progress,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeApplication: (application) => {
        dispatch(act.updateApplication(application))
    },
    updateChangeProgressType: (step, stage) => {
        dispatch(UpdateChangeProgressType(step, stage))
    },
    updateChangeProgress: (tab, index) => {
        dispatch(prgr.UpdateChangeProgress(tab, index))
    },
    updateChangeProgressType: (tab) => {
        dispatch(prgr.UpdateChangeProgressType(tab))
    },
    updateChangeVisitedStage: (step, stage) => {
        dispatch(prgr.UpdateChangeVisitedStage(step, stage))
    },
});
/* not sure if we will need this

function DeleteProfile(props) {
    const nukeTheProfile = a => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/auth/deleteprofile', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: ''
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");

                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        alert("Error: " + js.Text)
                        window.document.dispatchEvent(new Event('reauthenticate'), "");
                    } else {
                        sessionStorage.removeItem("ZeitroA")
                        sessionStorage.removeItem("state")
                        window.location.href="/"
                    }
                });
            }
        ).catch( (err) => {
            alert('Fetch Error :', err);
        });
    }

    return (
        <Modal      {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Do you really want to delete your account?</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-5">
                <p>
                    We are sad to see you go! We hope you will find the right loan for yourself.
                </p>
                <p>
                    Upon the deletion of your profile all the data will be lost, including the 1003 form, and all the supplemental documentation.
          </p>
                <p>
                    This operation is irreversible. Are you sure you want to do this?
                </p>
                <Row className="mt-5">
                    <Col className="text-center">
                        <Button variant="success" onClick={props.handleClose}>No, I'll keep working on my loan</Button>
                    </Col>
                    <Col className="text-center">
                        <Button variant="danger" onClick={nukeTheProfile}>Yes, delete my data, and the account</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}
*/
class YourLoans extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loans: [],
            loading: false,
            loadingOpenNew: false,
            customerid: com.getCustomerId()
        }
        let token = sessionStorage.getItem("ZeitroA")

        this.setState({ loading: true })
        fetch('/auth/getloans', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {


                    } else {

                        js.loans.forEach(loan => {
                            loan.state = JSON.parse(loan.state)
                            loan.lostate = JSON.parse(loan.lostate)
                        })
                        this.setState({ loans: js.loans, loanid: js.loanid })

                    }
                    this.setState({ loading: false })
                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
            this.setState({ loading: false })
        });
    }
    componentDidMount() {
        this.props.updateChangeProgressType("yourloans")
    }
    newApplication(loan) {
        let token = sessionStorage.getItem("ZeitroA")
        let js = { LoanID: loan.loanid }
        let isHardMoneyLoan = loan.state.application.product.mortgageapplied === "hardmoneyloan"
        fetch('/auth/createnewapplication', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify(js)
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        alert(js.Text)
                    } else {
                        let state = JSON.parse(js.State)

                        state.progress.stage = "interview"
                        state.progress.interviewfinished = false
                        state.progress.interviewstarted = false
                        state.progress.productselectionstarted = false
                        state.progress.interview.step = 0
                        state.progress.interview.max = 0
                        state.application.status = 'interviewstarted'
                        state.application.selection.selectedloan = null
                        state.application.property.hascontract = true
                        if (isHardMoneyLoan) {
                            state.application.product.mortgageapplied = "hardmoneyloan"
                        }
                        this.props.updateChangeApplication(state.application)
                        this.props.updateChangeVisitedStage("interviewstarted")
                        this.props.updateChangeProgress(0, "max")
                        this.props.updateChangeProgress(0, "interview")

                        getCurrentState()

                        //  redirect
                        if (isHardMoneyLoan) {
                            window.location.href = "/application/hardMoneyLoan/interview/main/borrower"
                            return
                        }
                        window.location.href = "/services/preapp?customerid=" + this.state.customerid
                    }

                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }
    openNewApplication() {
        this.setState({ loadingOpenNew: true })
        let token = sessionStorage.getItem("ZeitroA")
        const newS = JSON.parse((() => { let a = new State(); return a.toJson() })())

        let js = { LoanID: this.state.loanid, NewState: com.ascii2hex(JSON.stringify(newS)) }
        fetch('/auth/createnewapplication', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify(js)
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    this.setState({ loadingOpenNew: false })
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.log(js.Text)
                        alert("Looks like there was a problem. Please contact your loan officer for help.")
                    } else {
                        let st = JSON.parse(js.State)

                        // st.progress.stage = "interview"
                        // st.progress.interviewfinished = false
                        // st.progress.interviewstarted = false
                        // st.progress.productselectionstarted = false
                        // st.progress.interview.step = 0
                        // st.progress.interview.max = 0
                        // st.application.status = 'interviewstarted'
                        // st.application.selection.selectedloan = null

                        this.props.updateChangeApplication(st.application)
                        // this.props.updateChangeVisitedStage("interviewstarted")
                        // this.props.updateChangeProgress(0, "max")
                        // this.props.updateChangeProgress(0 , "interview")
                        getCurrentState()
                        this.setState({ loading: false })
                        sessionStorage.removeItem("state")
                        sessionStorage.removeItem("originalstate")
                        window.location.href = "/services/preapp?customerid=" + this.state.customerid
                    }

                });
            }
        ).catch((err) => {
            this.setState({ loadingOpenNew: false })
            console.log('Fetch Error :', err);
        });
    }
    onSwitch(loan) {
        this.setState({ loading: true })
        let token = sessionStorage.getItem("ZeitroA")
        let js = { LoanID: loan.loanid }
        fetch('/auth/switch', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify(js)
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    this.setState({ loading: false })
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status === "OK") {
                        // sessionStorage.setItem("state", js.State);
                        // sessionStorage.setItem("originalstate", js.State);
                        let st = JSON.parse(js.State)
                        this.props.updateChangeApplication(st.application)
                        getCurrentState()

                        let u = "/application/interview/main"

                        if (st.application.product.mortgageapplied === "hardmoneyloan") {
                            u = "/application/hardMoneyLoan/interview/main/borrower"
                        }
                        this.setState({ loading: false })
                        window.location.href = u

                    } else {
                        this.setState({ loading: false })
                        alert("Error switchin the app")
                    }

                });
            }
        ).catch((err) => {
            console.log('Fetch Error :', err);
        });
    }
    displayLoan = (l) => {
        let myclass = ""
        if (['fundsreleased', 'applicationwithdrawn', 'applicationabandoned',
            'aplicationrejected', "borrowerwithdrew", "loancanceled", "loandenied"].includes(l.state.application.status)) {

            if (['applicationwithdrawn', 'applicationabandoned',
                'aplicationrejected', "borrowerwithdrew", "loancanceled", "loandenied"].includes(l.state.application.status)) {
                myclass = " crisscrossed"
            } else {
                myclass = " greencrisscrossed"
            }


        }

        let onNewApplication = (l) => {
            this.newApplication(l)
        }
        let onSwitch = (l) => {
            this.onSwitch(l)
        }
        let cls = "text-left drop-shadow mt-2 mr-5  p-2 d-inline-block"
        if (this.state.loanid === l.loanid)
            cls = "text-left drop-big-shadow mt-2 mr-5  p-2 d-inline-block"
        cls = cls + myclass
        let lasttouch = new Date(l.state.progress.lasttouch)
        let isPrequal = (l.state.application.property.purpose === "purchase" && l.state.application.property.hascontract === false) ? true : false
        let isHardMoneyLoan = l.state.application.product.mortgageapplied === "hardmoneyloan"
        let items = [
            {
                key: '1',
                label: (
                    <Button variant="plainText" onClick={e => { onNewApplication(l) }} >
                        Use to create new application
                    </Button>
                ),
            }
        ]
        if (this.state.loanid !== l.loanid) {
            items.push(
                {
                    key: '2',
                    label: (
                        <Button variant="plainText" onClick={e => { onSwitch(l) }}>
                            Switch to this application
                        </Button>
                    ),
                }
            )
        }
        const showPurpose = () => {
            if (l.state.application.property.purpose === null) return "LOAN APPLICATION"
            if (l.state.application.property.purpose === "cashoutrefinance") return "CASH-OUT"
            if (isHardMoneyLoan) return "HARD MONEY LOAN"
            return "HOME " + l.state.application.property.purpose.toUpperCase()
        }
        return <Col md={3} xs={12} key={l.start} className="myloans text-left p-0 mr-3 mb-3">
            {this.state.loanid === l.loanid && <div className="applicationLabel">Current application</div>}
            <div style={{ padding: this.state.loanid === l.loanid ? "5px 18px 15px 18px" : "26px 18px 15px 18px" }}>
                <div className='d-flex justify-content-between'>
                    <div className="d-flex">
                        <img src="/images/sidebaricons/application.png" style={{ width: "15px", height: "15px", marginTop: "3px", marginRight: 7 }} />
                        <div style={{ color: "#1F3988", fontWeight: 500, fontSize: 16, borderBottom: "1px solid #1F3988" }}>
                            {showPurpose()}
                        </div>
                    </div>
                    <div>
                        <Dropdown
                            menu={{
                                items,
                            }}
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <div style={{ width: 30, paddingLeft: 20, paddingRight: 5 }} >
                                        <img src="/images/threedots.png" style={{ height: 20 }} />
                                    </div>
                                </Space>
                            </a>
                        </Dropdown>
                    </div>
                </div>
                <div style={{ marginLeft: 20, fontSize: 16, fontWeight: 500 }} className="mt-2">
                    {isPrequal ? "Pre-qualification" : isHardMoneyLoan ? com.capitalize(l.state.application.property.purpose) : "Application"}
                </div>
                <div style={{ marginLeft: 20 }} className="mt-2">
                    {isPrequal ? "—" : l.state.application.property.address}
                </div>
                <div style={{ marginLeft: 20, color: "#6E6E70" }} className="mt-2">
                    Last edit at {lasttouch.toLocaleDateString()}
                </div>
            </div>
            {/* <div className="mt-2">
                {this.state.loanid !== l.loanid ?
                    <Button size="sm" onClick={e => { onSwitch(l) }}>Switch to this application</Button> : ""
                }
                <Button className="ml-3" onClick={e => { onNewApplication(l) }} size="sm">Use to create new application</Button>

            </div> */}
        </Col>

    }
    render() {
        /*
        let showDelete = e => {
            this.setState({ delete: true })
        }
        let hideDelete = e => {
            this.setState({ delete: false })
        }
        */
        let borrowerName = this.props.application.borrower.firstname
        let welcomeMessage = borrowerName === "" ? "Welcome!" : "Welcome back, " + borrowerName + "."
        return (
            <div className="mt-0 px-5 py-4 h-100">
                <Row className="welcomeMessage">{welcomeMessage}</Row>
                <Row className="mt-3" style={{ fontSize: 20, fontWeight: 500 }}>My Applications</Row>
                <Row style={{ fontSize: 16, color: "#6E6E70" }}>Select an application to continue</Row>
                {/* {this.props.header === "yourloans" ?
                    <h4 className="mb-5">Your loans</h4> :

                    (this.props.header === "oldloans" ?
                        (this.state.loans === [] ?
                            "" : <h4>Switch to another active loan, or use an older loan as a template</h4>
                     ) : "")
                } */}


                {this.state.loading ? <Skeleton active className="mt-4" /> :

                    <Row className="mt-3 ">
                        {this.state.loans.sort((a, b) => (this.state.loanid === a.loanid ? -1 : 1)).map(this.displayLoan)}
                        <Col md={3} xs={12} className="myloans text-center p-0 mr-3 mb-3" style={{ cursor: "pointer" }}>

                            <div className="vertical-center px-5" style={{ color: "#325CEB", fontSize: 16, fontWeight: 500 }} onClick={() => this.openNewApplication()}>
                                <img src="/images/bluePlusInCircleMark.png" style={{ width: 15, height: 15, marginRight: 5 }} />
                                {this.state.loadingOpenNew ? <Spinner animation='border' size="sm" /> : "Open a new application"}
                            </div>

                        </Col>
                    </Row>
                }
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(YourLoans)
