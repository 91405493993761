import React, { Component } from "react"
import dayjs from 'dayjs'
import 'bootstrap/dist/css/bootstrap.min.css'

import './ApplicationInterview.css'
import '../BorrowerApplication.css'
import DateOfBirth from "../DateOfBirth"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import * as st from "../State"
import { DollarInput } from "../Common/components/DollarInput"
import * as com from "../Common"
import { getCurrentState } from '../Store'
import { UpdateChangeProgress, UpdateChangeLastTouch } from "../Store/progress"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { tooltip, Tips } from '../Common/Tooltip'
import InputGroupComponent from "../Common/InputGroup"
import Collapsible from "../Common/Collapsible"
import AddressAutocomplete from "../Common/AddressAutocomplete"
import { mapFullNameAbbr } from "../Common"
import { Input, Space, InputNumber, Switch, Checkbox, Select, DatePicker } from 'antd'
import { isEmpty } from 'lodash'
import throttle from "lodash.throttle"
import StateSelector from "../StateSelector"
import CheckGroup, { CheckGroupWithImage } from "../Common/components/CheckGroup"
import eventBus from '../Bus'

const visas = [
    st.VT_A_1,
    st.VT_E_1,
    st.VT_E_2,
    st.VT_E_3,
    st.VT_F_1,
    st.VT_G_1,
    st.VT_G_2,
    st.VT_G_3,
    st.VT_G_4,
    st.VT_H_1B,
    st.VT_H_4,
    st.VT_L_1,
    st.VT_L_2,
    st.VT_O_1,
    st.VT_O_2,
    st.VT_O_3,
    st.VT_P_1,
    st.VT_P_2,
    st.VT_P_3,
    st.VT_P_4,
    st.VT_TN,
    /*
      st.VT_A_1, st.VT_A_2, st.VT_A_3, st.VT_B_1, st.VT_B_2, st.VT_B_3, st.VT_C_1, st.VT_C_2, st.VT_C_3,
  st.VT_C_4, st.VT_C_1D, st.VT_CP, st.VT_D_1, st.VT_D_2, st.VT_E_1, st.VT_E_2, st.VT_E_3, st.VT_F_1, st.VT_F_2, st.VT_F_3,
  st.VT_G_1, st.VT_G_2, st.VT_G_3, st.VT_G_4, st.VT_G_5, st.VT_H_1A, st.VT_H_1B, st.VT_H_1B1, st.VT_H_2, st.VT_H_2A, st.VT_H_2B,
  st.VT_H_3, st.VT_H_4, st.VT_I, st.VT_OR_1, st.VT_J_1, st.VT_J_2, st.VT_K_1, st.VT_K_2, st.VT_K_3, st.VT_K_4, st.VT_L_1,
  st.VT_L_2, st.VT_M_1, st.VT_M_2, st.VT_M_3, st.VT_N_8, st.VT_N_9, st.VT_NACARA, st.VT_NATO_1_5, st.VT_NATO_7,
  st.VT_O_1, st.VT_O_2, st.VT_O_3, st.VT_P_1, st.VT_P_2, st.VT_P_3, st.VT_P_4, st.VT_Q_1, st.VT_Q_2, st.VT_Q_3,
  st.VT_R_1, st.VT_R_2, st.VT_S_5, st.VT_S_6, st.VT_S_7, st.VT_T_1, st.VT_T_2, st.VT_T_3, st.VT_T_4, st.VT_TD, st.VT_TN,
  st.VT_U_1, st.VT_U_2, st.VT_U_3, st.VT_U_4, st.VT_V_1, st.VT_V_2, st.VT_V_3, */
]

const mapStateToProps = (state) => {
    let getOccupation = (employed, selfemployed) => {
        if (employed === "employed" && !selfemployed) return "employed"
        if (employed === "employed" && selfemployed) return "selfemployed"
        return "unemployed"

    }
    return {
        application: state.application,
        hascoborrower: state.application.hascoborrower,
        property: state.application.property,
        cohabiting: state.application.cohabiting,
        financingsameproperty: state.application.financingsameproperty,
        borroweroccupation: getOccupation(state.application.borrower.occupation.hasoccupation,
            state.application.borrower.occupation.selfemployed),
        coborroweroccupation: "co" + getOccupation(state.application.coborrower.occupation.hasoccupation,
            state.application.coborrower.occupation.selfemployed),
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    changeBorrowerInfoByValue: (val, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(val, who, verb))
    },
    changePhoneInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },
    updateSameProperty: (event) => {
        dispatch(act.UpdateSameProperty(event.target.checked))
    },
    updateCurrentResidence: (event) => {
        dispatch(act.UpdateCurrentResidence(event.target.value))
    },
    updateCoborrower: (event) => {
        dispatch(act.UpdateHasCoborrower(event.target.value))
    },
    updateCohabiting: (event) => {
        dispatch(act.UpdateCohabiting(event.target.checked))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb))
    },
    changeMainPropertyPurpose: (event) => {
        dispatch(act.ChangeMainPropertyPurpose(event.target.value))
    },
    changeMainPropertyAppraisal: (event) => {
        dispatch(act.ChangeMainPropertyAppraisal(event.target.value))
    },

    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb))
    },

    updateBorrowerAttribute: (who, value, verb) => {
        dispatch(act.UpdateBorrowerAttribute(who, value, verb))
    },

    changeMainPropertySalesPrice: (event) => {
        dispatch(act.ChangeMainPropertySalesPrice(event.target.value))
    },
    addPreviousAddress: (event, who) => {
        dispatch(act.AddPreviousAddress(event.target.value, who))
    },
    updatePreviousAddress: (event, who, verb, n) => {
        dispatch(act.UpdatePreviousAddress(event.target.value, who, verb, n))
    },
    removePreviousAddress: (event, who, n) => {
        dispatch(act.RemovePreviousAddress(event.target.value, who, n))
    },
    clearPreviousAddress: (event, who) => {
        dispatch(act.ClearPreviousAddress(event.target.value, who))
    },
    changeOccupationInfo: (event, who, verb) => {
        dispatch(act.ChangeOccupationInfo(event.target.value, who, verb))
    },
    changeOccupationCheck: (check, who, verb) => {
        dispatch(act.ChangeOccupationInfo(check, who, verb))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    updateApplicationAttribute: (input, verb) => {
        dispatch(act.UpdateApplicationAttribute(input, verb))
    },

    updateApplicationCheck: (event, verb) => {
        dispatch(act.UpdateApplicationAttribute(event.target.checked, verb))
    },
    changeMainPropertyPropertyType: (event) => {
        dispatch(act.ChangeMainPropertyPropertyType(event.target.value))
    },
    updateChangeProgress: (step, stage) => {
        dispatch(UpdateChangeProgress(step, stage))
    },
    addRemoteCoborrower: (i) => {
        dispatch(act.AddRemoteCoborrower(i))
    },
    changeMainPropertyRefinanceAttribute: (value, verb) => {
        dispatch(act.ChangeMainPropertyRefinanceAttribute(value, verb))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
    changeReviewedLoans: (value) => {
        dispatch(act.ChangeReviewedLoans(value))
    },
    changeMainPropertyOccupancy: (event) => {
        dispatch(act.ChangeMainPropertyOccupancy(event.target.value))
    },
    clearBorrowerGift: () => {
        dispatch(act.ClearBorrowerGift())
    },
    clearBorrowerInsurance: () => {
        dispatch(act.ClearBorrowerInsurance())
    },
    addBorrowerOwnedProperty: (t) => {
        dispatch(act.AddBorrowerOwnedProperty(t))
    },
    prependBorrowerOwnedProperty: () => {
        dispatch(act.PrependBorrowerOwnedProperty())
    },
    changeOwnedProperty: (t, i, v) => {
        dispatch(act.ChangeOwnedProperty(t, i, v))
    },
    removeBorrowerOwnedProperty: (i) => {
        dispatch(act.RemoveBorrowerOwnedProperty(i))
    },
    clearBorrowerOwnedProperty: (t) => {
        dispatch(act.ClearBorrowerOwnedProperty(t))
    },
    updateChangeLastTouch: (t) => {
        dispatch(UpdateChangeLastTouch(t))
    },
    updateExpenses: (event, who, verb) => {
        dispatch(act.UpdateExpenses(event, who, verb))
    },
})

class Applicant extends Component {

    constructor(props) {
        super(props)
        this.form = React.createRef()
        this.cform = React.createRef()
        let has = props.hascoborrower ? "" : "selected"
        let doenothave = props.hascoborrower ? "selected" : ""
        this.state = {
            selectedalone: has,
            withcoborrower: doenothave,
            show: false,
            email: '',
            isValidEmail: true,
            showSelf: false,
            showCashout: false,
            qualify: "",
            additional: "",
            residence: "",
            validated: false,
            loanLimits: {},
            sameAddress: false,
        }
        this.radiocoborrower = React.createRef()
        this.phoneref = React.createRef()
        this.dob = React.createRef()
        let needsReset = false
        if (this.props.progress.lasttouch == null) {
            needsReset = true
        } else {
            let now = new Date()
            let then = new Date(this.props.progress.lasttouch)
            let days = (now - then) / (3600 * 24 * 1000)
            if (days > 7)
                needsReset = true
        }
        let resettableStates = ['interviewstarted', 'productselected', 'interviewfinished']
        if (needsReset && resettableStates.includes(this.props.application.status)) {
            //   this.props.updateChangeProgress(0, "max")
            this.props.updateChangeProgress(0, "interview")
            let d = new Date()
            this.props.updateChangeLastTouch(d.toLocaleString())
            this.props.changeReviewedLoans(0)
            getCurrentState()
            this.forceUpdate()
        }
        this.fetchLoanLimits()
        if (sessionStorage.getItem("ZeitroA") !== null) {
            if (
                this.props.application.borrower.firstname === "" &&
                this.props.application.borrower.lastname === ""
            ) {
                this.getName()
            }
        }

        if (this.props.application.firsttimebuyer === null) {
            this.props.updateApplicationAttribute(false, "firsttimebuyer")
        }

    }

    onRef = (ref) => {
        this.dob = ref
    }

    getName = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch("/auth/getprofile", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-Borrower": this.props.borrowerid,
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.log(
                        "Looks like there was a problem. Status Code: " + response.status
                    )
                    return
                }
                response.json().then((js) => {
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                    } else {
                        this.props.changeBorrowerInfo(
                            { target: { value: js.FirstName } },
                            "borrower",
                            "firstname"
                        )
                        this.props.changeBorrowerInfo(
                            { target: { value: js.LastName } },
                            "borrower",
                            "lastname"
                        )
                        this.props.changeBorrowerInfo(
                            { target: { value: js.Email } },
                            "borrower",
                            "email"
                        )
                    }
                })
            })
            .catch((err) => {
                console.log("Fetch Error :", err)
            })
    };
    updateBorrower = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let bo = this.props.application.borrower
        let cb = this.props.application.coborrower
        fetch("/auth/updateborrower", {
            body: JSON.stringify({
                Email: bo.email,
                FirstName: bo.firstname,
                LastName: bo.lastname,
                CoborrowerFirstName: cb.firstname,
                CoborrowerLastName: cb.lastname,
                CoborrowerEmail: cb.email,
                CoborrowerPhone: cb.homephone,
            }),
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-Borrower": this.props.borrowerid,
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.log(
                        "Looks like there was a problem. Status Code: " + response.status
                    )
                    return
                }
            })
            .catch((err) => {
                console.log("Fetch Error :", err)
            })
    };

    fetchLoanLimits = () => {
        fetch("/data/loanlimits")
            .then((res) => {
                if (!res.ok) {

                    throw Error(res.statusText)
                }
                return res.json()
            })
            .then((res) => {
                this.setState({ loanLimits: res })
            })
            .catch((err) => {
                console.error(err)
            })
    }

    updateYears = (value) => {
        com.touch()
        if (
            value !== "" &&
            parseInt(value) < 2 &&
            0 === this.props.application[this.props.who].previousaddresses.length
        ) {
            this.props.addPreviousAddress({ target: { value: 0 } }, this.props.who)
            this.props.changeBorrowerInfo(
                { target: { value: "0" } },
                this.props.who,
                "presentaddressmonths"
            )
        }

        if (
            value !== "" &&
            parseInt(value) >= 2 &&
            0 !== this.props.application[this.props.who].previousaddresses.length
        ) {
            this.props.clearPreviousAddress({ target: { value: 0 } }, this.props.who)
        }

        this.props.changeBorrowerInfo(
            { target: { value } },
            this.props.who,
            "presentaddressyears"
        )
    };
    updateMonths = (value) => {
        com.touch()
        this.props.changeBorrowerInfo(
            { target: { value } },
            this.props.who,
            "presentaddressmonths"
        )
    };
    getStates = () => {
        let options = []
        let keys = Object.keys(this.state.loanLimits)
        options.push(<option value="">Select your state</option>)
        keys.forEach((x) => {
            options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
        })
        return options
    }
    getCounties = () => {
        let options = []
        options.push(<option value="">Select your county</option>)
        let st = this.props.application.borrower.presentaddressstate
        try {
            if (this.state.loanLimits[st.toUpperCase()]) {
                let keys = Object.keys(this.state.loanLimits[st.toUpperCase()])
                keys.forEach((x) => {
                    options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
                })
            }
        } catch (error) {
            console.log(error)
        }

        return options
    }
    componentDidMount() {
        // this.props.application[this.state.who].presentaddressownership

        if (this.props.application.hascoborrower === null) {
            this.updateCoborrower({ target: { value: st.HC_Alone } })
        }
        this.completion()
        this.setState({ sameAddress: this.hasSameAddress() })
        var inputs = document.querySelectorAll('input')

        // 遍历每一个输入框
        inputs.forEach(function (input) {
            // 设置autocomplete属性为off或new-password
            input.setAttribute('autocomplete', 'off')
            if (input.type === 'password') {
                input.setAttribute('autocomplete', 'new-password')
            }
        })
    }

    componentDidUpdate() {
        this.completion()
    }

    componentWillUnmount() { }
    completion = () => {
        const form = this.form.current
        const formElements = form.elements
        if (formElements.length === 0) {
            sessionStorage.setItem("progressValue", 100)
            return
        }
        let totalCount = 0
        let fillNum = 0
        for (let i = 0; i < formElements.length; i++) {
            const element = formElements[i]
            if (element.type !== 'button') {
                totalCount++
                if (!isEmpty(element.value)) {
                    fillNum++
                }
            }
        }
        const progressValue = Math.round((fillNum / totalCount) * 100)
        sessionStorage.setItem("progressValue", progressValue)
    }
    hasSameAddress = () => {
        const application = this.props.application
        const borrower = application.borrower
        const coBorrower = application.coborrower

        return (
            borrower.presentaddressstreet === coBorrower.presentaddressstreet &&
            borrower.presentaddressstate === coBorrower.presentaddressstate &&
            borrower.presentaddresscounty === coBorrower.presentaddresscounty &&
            borrower.presentaddresscity === coBorrower.presentaddresscity &&
            borrower.presentaddresszip === coBorrower.presentaddresszip
        )
    }
    isValidated() {
        // console.log(this.form.current);
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }
    handleSubmit = e => {
        if (this.props.application.borrower.homephone.length !== 14) {
            this.phoneref.current.setCustomValidity("Incorrect phone number format.")
            this.setState({ validated: true })
            return false
        } else {
            this.phoneref.current.setCustomValidity("")
        }
        if (this.form.current.reportValidity() === false) {

            e.preventDefault()
            this.setState({ validated: true })
            return false
        }
        if (this.props.application.hascoborrower === 'withcoborrower') {
            if (this.cform.current.reportValidity() === false) {
                e.preventDefault()
                e.stopPropagation()
                this.setState({ validated: true })
                return false
            }
        }
        if (this.props.property.purpose !== st.POL_Purchase && this.props.financingsameproperty)
            this.props.changeBorrowerInfo({ target: { value: st.O_Own } }, com.borrower, "presentaddressownership")

        this.updateBorrower()

        return true
    }
    chooseBorrower = (event) => {
        com.touch()
        this.radioself.current.checked = true
        this.updateCoborrower({ target: { value: st.HC_Alone } }) // this is a hack
    }
    chooseCoborrower = (event) => {
        com.touch()
        this.radiocoborrower.current.checked = true
        this.updateCoborrower({ target: { value: st.HC_Withcoborrower } })  // this is a hack
    }
    updateCoborrower = (event) => {
        com.touch()
        if (event.target.id === "multiple") {
            if (this.props.application.remotecoborrowers.length === 0)
                this.props.addRemoteCoborrower()
        }

        if (event.target.id === "withcoborrower") {
            this.updateMarried({ target: { value: "true" } })
        }
        if (event.target.id === "alone") {
            this.updateMarried({ target: { value: "false" } })
        }
        this.props.updateCoborrower(event)
    }
    updateCohabiting = (e) => {
        com.touch()
        let val = e.target.value === "true"
        this.props.updateCohabiting({ target: { checked: val } })
    }
    updateMarried = (e) => {
        com.touch()
        let val = e.target.value === "true"
        this.props.updateApplicationAttribute(val, st.MS_Married)
    }
    changeMainPropertyAppraisal = e => {
        com.touch()
        if (this.props.property.purpose !== st.POL_Purchase && this.props.financingsameproperty)
            this.props.changeBorrowerInfo(e, com.borrower, "marketvalue")
        this.props.changeMainPropertyAppraisal(e)
    }
    onFirst = e => {
        com.touch()
        if (true === this.props.application.firsttimebuyer) {
            this.props.updateApplicationAttribute(false, "firsttimebuyer")
        } else {
            this.props.updateApplicationAttribute(true, "firsttimebuyer")
        }
    }

    onMarried = e => {
        com.touch()
        if (true === this.props.application.cohabiting) {
            this.props.updateCohabiting({ target: { checked: false } })
            this.props.updateApplicationAttribute(false, st.MS_Married)
        } else {
            this.props.updateCohabiting({ target: { checked: true } })
            this.props.updateApplicationAttribute(true, st.MS_Married)
        }
    }

    firstTime = () => {
        if (this.props.property.purpose !== st.POL_Purchase || this.props.application.borrower.presentaddressownership === st.O_Own)
            return ""
        return (
            <div className="mx-1 mt-3 text-wrap text-left">

                <div className="d-flex sectionquestion text-left"><span>{tooltip("Are you a first time home buyer?",
                    <div>
                        A first time home buyer is someone who meets any of the following conditions:
                        <ul>
                            <li>
                                An individual who has not owned a principal residence for three years. If you’ve owned a home but your spouse has not, then you can purchase a place together as first-time homebuyers.
                            </li><li>
                                A single parent who has only owned a home with a former spouse while married.
                            </li><li>
                                A displaced homemaker who has only owned with a spouse.
                            </li><li>
                                An individual who has only owned a principal residence not permanently affixed to a permanent foundation in accordance with applicable regulations.
                            </li><li>
                                An individual who has only owned a property that was not in compliance with state, local, or model building codes—and that cannot be brought into compliance for less than the cost of constructing a permanent structure.
                            </li>
                        </ul>
                    </div>, "auto"

                )}</span></div>

                <Form.Group>
                    <fieldset>
                        <div className="d-flex  ">
                            <div className="mr-3">
                                <input required onChange={this.onFirst} value={true} checked={true === this.props.application.firsttimebuyer} type="radio" id="firsttimebuyer" name="freeandclear"></input>
                                <label id="firsttimebuyerlabel" className="zeitro-radio divlink" htmlFor="firsttimebuyer">Yes, I am</label></div>
                            <div><input required onChange={this.onFirst} value={false} checked={false === this.props.application.firsttimebuyer} type="radio" id="firsttimebuyernot" name="freeandclear"></input>
                                <label id="firsttimebuyernotlabel" className="zeitro-radio divlink" htmlFor="firsttimebuyernot">No, I'm not</label></div>
                        </div>
                    </fieldset>

                    <Form.Control.Feedback type="invalid" >
                        Please specify if you are are a first time buyer.
                    </Form.Control.Feedback>
                </Form.Group>

            </div>

        )
    }

    updateCoborrowerAssetsUsed = (e) => {
        com.touch()
        if (e.target.checked) {
            this.radiocoborrower.current.checked = true
            this.updateCoborrower({ target: { value: "withcoborrower" } })  // this is a hack
        }
        this.props.updateApplicationCheck(e, "assetsofotherpersonused")
    }
    updateCoborrowerLiabilitiesUsed = (e) => {
        com.touch()
        if (e.target.checked) {
            this.radiocoborrower.current.checked = true
            this.updateCoborrower({ target: { value: st.HC_Withcoborrower } })  // this is a hack
        }

        this.props.updateApplicationCheck(e, "liabilitiesofotherpersonrequired")
    }

    figureOut = () => {
        this.setState({ show: true })
    }
    handleClose = () => {
        this.setState({ show: false })
    }
    handleSelfClose = () => {
        this.setState({ showSelf: false })
    }
    selfEmployedAsessment = () => {
        this.setState({ showSelf: true })

    }
    qualifyForCashout = () => {
        this.setState({ showCashout: true })
    }
    handleCloseCashout = () => {
        this.setState({ showCashout: false })
    }
    changeMainPropertyPurpose = e => {
        com.touch()
        this.props.updateSelectField(new st.ChosenLoan(), "selectedloan")
        this.props.changeReviewedLoans(0)

        if (e.target.value === "cashoutrefinance") {
            this.setState({ showCashout: true })
        }
        if (this.props.property.purpose !== "purchase") { // if change from purpose, clear gifts/insurance
            this.props.clearBorrowerGift()
            this.props.clearBorrowerInsurance()
        }
        this.props.changeMainPropertyPurpose(e)
    }

    render() {
        let changeBorrowerDOB = (who, verb) => {
            return (event) => {
                com.touch()
                this.dob.current.setCustomValidity("")
                let date = com.fixDateInput(event.target.value)
                return this.props.updateBorrowerAttribute(who, date, verb)
            }
        }

        let schangeBorrowerInfo = (who, verb) => {
            return (event) => {

                console.log(event)
                com.touch()
                this.props.changeBorrowerInfo({
                    target: {
                        value
                            : event == null ? '' : event
                    }
                }, who, verb)
            }
        }
        let changeBorrowerInfo = (who, verb) => {
            return (event) => {
                console.log(event.target.value)
                com.touch()
                this.props.changeBorrowerInfo(event, who, verb)


            }
        }
        let changeCoBorrower = (who, verb) => {
            return (event) => {
                com.touch()
                this.props.changeBorrowerInfo(event, who, verb)
                this.updateCoborrower({ target: { value: st.HC_Withcoborrower } })
                if (this.props.application.hascoborrower === st.HC_Withcoborrower && this.props.application.cohabiting === null) {
                    this.props.updateCohabiting({ target: { checked: true } })
                }
            }
        }
        let changePhoneInfo = (who, verb) => {
            return (event) => {
                com.touch()
                this.phoneref.current.setCustomValidity("")
                let phone = com.fixPhoneInput(event.target.value)
                return this.props.changePhoneInfo(phone, who, verb)
            }
        }
        let appraisalVerbage = () => {
            switch (this.props.property.purpose) {
                case st.POL_Purchase:
                case st.POL_Refinance:
                case st.POL_Cashoutrefinance:
                    return "Estimated home value"
                default:
                    return "You should not see this line!"
            }
        }

        let onQualify = e => {
            this.setState({ qualify: e.target.value })
        }
        let onAdditional = e => {
            this.setState({ additional: e.target.value })
        }
        let removeCoborrower = e => {
            com.touch()
            let who = "coborrower"
            this.updateCoborrower({ target: { value: st.HC_Alone } })
            this.props.updateCohabiting({ target: { checked: false } })
            this.props.updateApplicationAttribute(false, st.MS_Married)

            this.props.changeBorrowerInfoByValue("", who, "firstname")
            this.props.changeBorrowerInfoByValue("", who, "lastname")
            this.props.changeBorrowerInfoByValue("", who, "email")
            this.props.changeBorrowerInfoByValue("", who, "homephone")
            this.props.changeBorrowerInfoByValue("", who, "citizenship")
        }
        let onAddressChange = (e) => {
            com.touch()
            return this.props.changeBorrowerInfo(
                e,
                "borrower",
                "presentaddressstreet"
            )
        }
        let defDate = new Date()
        defDate.setFullYear(defDate.getFullYear() + 10)

        let changeExpiration = (event, who) => {
            com.touch()
            this.expiration.current.setCustomValidity("")

            let date = com.fixDateInput(event.target.value)
            return this.props.changePhoneInfo(date, who, "visaexpiration")
        }
        function SelectBasicExample() {
            return (
                <Form.Select aria-label="Default select example">
                    <option>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </Form.Select>
            )
        }
        let displayCitizenship = (who) => {
            return (

                <Row >
                    <Col xs={12} md={6} className="mt-4">
                        <Form.Group controlId="citizenship">
                            <Form.Label className="text-left">Citizenship</Form.Label>
                            <div>
                                <Select defaultValue={this.props.application[who].citizenship}
                                    value={this.props.application[who].citizenship}

                                    onChange={schangeBorrowerInfo(
                                        who,
                                        "citizenship"
                                    )} style={{
                                        width: '100%',
                                        height: "44px",
                                        background: '#FCFCFC'
                                    }}>

                                    <option value="citizen">US Citizen</option>
                                    <option value="permanent">Permanent Resident Alien</option>
                                    <option value="alien">Non-Permanent Resident Alien</option>
                                </Select>
                            </div>
                            {/* <Form.Control
                                required={who === "borrower" ? true : false}
                                name="citizenship"
                                as="select"
                                size="md"
                                defaultValue={this.props.application[who].citizenship}
                                onChange={changeBorrowerInfo(who, "citizenship")}
                            >
                                <option value="">...</option>
                                <option value="citizen">US Citizen</option>
                                <option value="permanent">US Permanent Resident</option>
                                <option value="alien">Non-US Citizen</option>
                            </Form.Control> */}
                            <Form.Control.Feedback type="invalid">
                                Please provide your citizenship status.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={3} className="mt-4">
                        {this.props.application[who].citizenship === "alien" ? (
                            <Form.Group controlId="visa">
                                <Form.Label className="text-left">Visa:</Form.Label>
                                <div>
                                    <Select defaultValue={this.props.application[who].visa}
                                        value={this.props.application[who].visa}

                                        onChange={schangeBorrowerInfo(
                                            who,
                                            "visa"
                                        )} style={{
                                            width: '100%',
                                            height: "44px",
                                            background: '#FCFCFC'
                                        }}>

                                        {massage(visas, who).map(displayvisa)}
                                    </Select>
                                </div>
                                {/* <Form.Control
                                    name="visa"
                                    as="select"
                                    size="md"
                                    defaultValue={this.props.application[who].visa}
                                    onChange={changeBorrowerInfo(who, "visa")}
                                >
                                    {massage(visas, who).map(displayvisa)}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please provide your citizenship.
                                </Form.Control.Feedback> */}
                            </Form.Group>
                        ) : (
                            ""
                        )}
                    </Col>
                    <Col xs={12} md={3} className="mt-4">
                        {this.props.application[who].citizenship === "alien" ? (
                            <Form.Group controlId="expiration" className="text-left">
                                <Form.Label className="text-left">Expires:</Form.Label>
                                <div>
                                    <DatePicker
                                        disabledDate={(current) => {
                                            return current && current.valueOf() < dayjs().startOf('day').valueOf()
                                        }}
                                        name="dateofbirth"
                                        style={{ width: '100%' }}
                                        onRef={(ref) => {
                                            this.expiration = ref
                                        }}
                                        required
                                        size="sm"
                                        placeholder="MM/DD/YYYY"
                                        format="MM/DD/YYYY"
                                        value={this.props.application[who].visaexpiration ? dayjs(this.props.application[who].visaexpiration, "MM/DD/YYYY") : ''}
                                        onChange={e => {
                                            changeExpiration({ target: { value: dayjs(e).format('MM/DD/YYYY') } }, who)
                                        }}
                                    />
                                </div>
                                <div style={{ opacity: 0 }}>
                                    <DateOfBirth

                                        size="md"
                                        isValid={false}
                                        maxdate={defDate}
                                        //ref={this.refsfrom[index]}
                                        onRef={(ref) => {
                                            this.expiration = ref
                                        }}
                                        required={this.props.application[who].citizenship === "alien" ? true : false}
                                        placeholder="MM/DD/YYYY"
                                        name="expiration"
                                        type="text"
                                        value={this.props.application[who].visaexpiration}
                                        onChange={e => {
                                            changeExpiration(e, who)
                                        }}
                                        negativefeedback="Visa should be valid for at least 90 days."
                                        pattern="^(01|02|03|04|05|06|07|08|09|10|11|12)\/[0-9]{2,2}\/[1-9][0-9]{3,3}$"
                                    />
                                </div>
                            </Form.Group>
                        ) : (
                            ""
                        )}
                    </Col>
                </Row>

            )
        }
        let massage = (visas, who) => {
            if (who === "coborrower") return visas
            return visas.filter((x) => {
                if ([st.VT_H_4, st.VT_L_2, st.VT_O_3, st.VT_P_4].includes(x))
                    return false
                return true
            })
        }
        let displayvisa = (value, index) => {
            return (
                <option key={"visa" + index} value={value}>
                    {value}
                </option>
            )
        }

        let setLoanState = (v) => {
            this.props.changePhoneInfo(v, "borrower", "presentaddressstate")
        }
        let setLoanCounty = (v) => {
            this.props.changePhoneInfo(v, "borrower", "presentaddresscounty")
        }
        let setLoanCity = (v) => {
            this.props.changePhoneInfo(v, "borrower", "presentaddresscity")
        }
        let setZip = (v) => {
            v = v.replace(/\D/g, '')
            this.props.changePhoneInfo(v, "borrower", "presentaddresszip")
        }
        let onChangeSwitch = (checked) => {
            let value = checked ? 'withcoborrower' : 'alone'
            this.updateCoborrower({ target: { value } })
            if (!checked) {
                let who = "coborrower"
                this.props.updateCohabiting({ target: { checked: false } })
                this.props.updateApplicationAttribute(false, st.MS_Married)

                this.props.changeBorrowerInfoByValue("", who, "firstname")
                this.props.changeBorrowerInfoByValue("", who, "lastname")
                this.props.changeBorrowerInfoByValue("", who, "email")
                this.props.changeBorrowerInfoByValue("", who, "homephone")
                this.props.changeBorrowerInfoByValue("", who, "citizenship")
            }
        }
        let throttled = throttle(this.updateYears.bind(this), 400, {
            leading: false,
            trailing: true,
        })
        let onYearsThrottled = (e) => {
            throttled(e.target.value)
        }
        let changeResidence = (e) => {
            console.log(`checked = ${e.target.checked}`)
        }
        let changeFirstBuy = (e) => {
            console.log(`checked = ${e.target.checked}`)
        }
        const handleToggle = (key) => {
            this.updateCoborrower({ target: { value: key === "0" ? st.HC_Withcoborrower : st.HC_Alone } })
        }

        return (
            <div className=" text-left mb-3">

                <Modal dialogClassName="modal-75w align-center"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showCashout} onHide={this.handleCloseCashout} >
                    <Modal.Header closeButton>
                        <Modal.Title>Do you qualify for cash out refinance?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body as="div" className="">

                        <div className="my-4 ">The borrower must be on the property title for at least 6 months in order to qualify for cash out refinance.
                        </div>
                        <div>Have you been on the title for more than 6 months?</div>
                        <div className="mt-3 mb-3">
                            <input type="radio" checked={this.state.qualify === "yes6month"} onChange={onQualify} value="yes6month" name="forcashout" id="yes6month" />
                            <label id="yes6monthlabel" className="divlink" htmlFor="yes6month">Yes, I have</label>
                            <input className="ml-3" type="radio" checked={this.state.qualify === "no6month"} onChange={onQualify} value="no6month" name="forcashout" id="no6month" />
                            <label id="no6monthlabel" className="divlink" htmlFor="no6month">No, I have not</label>
                        </div>
                        {
                            this.state.qualify === "yes6month" ? <div style={{ fontSize: '1.2em', color: '#007700', fontWeight: 'bold' }} > Yes! Please continue filling out the application </div> : ""
                        }
                        {
                            this.state.qualify === "no6month" ?
                                <div  >
                                    Does the following apply to you?
                                    <ul>
                                        <li>You acquired the property through inheritance, divorce, separation or dissolution of domestic partnership.</li>
                                        <li>Purchased the property using all cash.</li>
                                        <li>Property was under an LLC or under a Trust for a minimum of six months.</li>
                                    </ul>
                                    <div className="mt-3 mb-3">
                                        <input type="radio" checked={this.state.additional === "yesapplies"} onChange={onAdditional} value="yesapplies"
                                            name="yesapplies" id="yesapplies" />
                                        <label className="divlink" id="yesapplieslabel"
                                            htmlFor="yesapplies">Yes, it does</label>
                                        <input className="ml-3" checked={this.state.additional === "doesnotapply"} type="radio" onChange={onAdditional} value="doesnotapply"
                                            name="yesapplies" id="doesnotapply" />
                                        <label id="noapplieslabel" className="divlink"
                                            htmlFor="doesnotapply">No, it doesn't</label>
                                    </div>
                                    {
                                        this.state.additional === "yesapplies" ? <div style={{ fontSize: '1.2em', color: '#007700', fontWeight: 'bold' }} >Yes! Please continue filling out the application </div>
                                            :
                                            (this.state.additional === "doesnotapply" ? <div style={{ fontSize: '1.2em', color: '#770000', fontWeight: 'bold' }} >Sorry, you can't apply for a cash out loan at this point in time.</div> : "")
                                    }
                                </div> : ""

                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseCashout}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Modal dialogClassName="modal-75w align-center"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.show} onHide={this.handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>When you need to apply with a coborrower:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body as="div" className="">
                        <Form id="modal" noValidate validated={false} onSubmit={this.handleSubmitModal}>

                            <div className="my-4 ">Applicants should complete this form as “Borrower” or “Additional Borrower,” as applicable.
                            </div>
                            <div className="mb-4">Additional Borrower information must also be provided (and the appropriate box checked) when </div>
                            <Row>
                                <Col>
                                    <input type="checkbox" id="assetsofotherpersonused" name="assetsofotherpersonused" defaultChecked={this.props.application.assetsofotherpersonused} onChange={this.updateCoborrowerAssetsUsed} ></input>
                                    <label id="assetsofotherpersonusedlabel" style={{ textDecoration: "underline" }} className="divlink" htmlFor="assetsofotherpersonused">The INCOME or ASSETS of a person other than the Borrower (including the Borrower’s spouse) will be used as a basis for loan qualification </label>
                                </Col>
                            </Row>
                            or
                            <Row>
                                <Col>
                                    <Form.Group controlId="liabilitiesofotherpersonrequired" className="text-left form-inline " >
                                        <Form.Check className="divlink" type="checkbox" id="liabilitiesofotherpersonrequired" name="liabilitiesofotherpersonrequired" defaultChecked={this.props.application.liabilitiesofotherpersonrequired} onChange={this.updateCoborrowerLiabilitiesUsed} label="The income or assets of the Borrower’s spouse or other person who has community property rights pursuant to state law will NOT be used as a basis for loan qualification, but his or her LIABILITIES must be considered because the spouse or other person has community property rights pursuant to applicable law and Borrower resides in a community property state, the security property is located in a community property state, or the Borrower is relying on other property located in a community property state as a basis for repayment of the loan."></Form.Check></Form.Group>
                                </Col> </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Modal dialogClassName="modal-75w align-center employed"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showSelf} onHide={this.handleSelfClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Are you self employed?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body as="div" className="">
                        <div className="mb-4">You only qualify for self employed status if you own 25% of your company or more.</div>
                        <div> Otherwise you are a salaried employee for the purpose of obtaining a loan.</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleSelfClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <Form ref={this.form} id="applicantform" noValidate validated={this.state.validated} autoComplete="false">
                    <Row>
                        <Col>
                            <div className="prequalInterviewTitle">Applicant Information</div>
                            <div className="prequalInterviewSubTitle pt-5">Basic</div>
                        </Col>
                    </Row>

                    <Row >
                        <Col md={6} xs={12} className="mt-4" >
                            <Form.Group>
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                    name="firstname"
                                    placeholderValue='Enter your first name'
                                    value={this.props.application["borrower"].firstname}
                                    onChange={changeBorrowerInfo("borrower", "firstname")}
                                    required={true}
                                />
                                {/* <InputGroupComponent 
                               
                                /> */}
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12} className="mt-4" >
                            <Form.Group>
                                <Form.Label>Last name</Form.Label>
                                <InputGroupComponent
                                    name="lastname"
                                    placeholderValue='Enter your last name'
                                    value={this.props.application["borrower"].lastname}
                                    onChange={changeBorrowerInfo("borrower", "lastname")}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row >
                        <Col>
                            <div className="nameBar" style={{ display: "flex", justifyContent: 'space-between' }}>
                                <Checkbox checked={true === this.props.application.firsttimebuyer} onChange={this.onFirst}>
                                    <span style={{ fontWeight: 600, whiteSpace: 'normal', width: '100%' }}> Select here if you are a first time home buyer   </span>
                                    <Tips


                                        content={
                                            <div style={{
                                                height: 400,
                                                overflow: 'auto'

                                            }}>
                                                A first time home buyer is someone who meets any of the following conditions:
                                                <ul>
                                                    <li>
                                                        An individual who has not owned a principal residence for three years. If you’ve owned a home but your spouse has not, then you can purchase a place together as first-time homebuyers.
                                                    </li><li>
                                                        A single parent who has only owned a home with a former spouse while married.
                                                    </li><li>
                                                        A displaced homemaker who has only owned with a spouse.
                                                    </li><li>
                                                        An individual who has only owned a principal residence not permanently affixed to a permanent foundation in accordance with applicable regulations.
                                                    </li><li>
                                                        An individual who has only owned a property that was not in compliance with state, local, or model building codes—and that cannot be brought into compliance for less than the cost of constructing a permanent structure.
                                                    </li>
                                                </ul>
                                            </div>}
                                        placement="bottom"
                                        title="What is first time buyer?" />
                                </Checkbox>
                                {/* <Form.Check
                                label="1"
                                name="group1"
                                type="checkbox"
                                id="checkbox1"
                            // onChange={this.onFirst}  
                            // checked={true === this.props.application.firsttimebuyer}
                            >

                                <Form.Check.Input
                                    type="checkbox"
                                    onChange={this.onFirst}
                                    checked={true === this.props.application.firsttimebuyer}
                                    isValid />
                            </Form.Check> */}
                                {/* <Form.Label className="ml-2" style={{ paddingTop: "2px" }}>

                            </Form.Label> */}
                            </div>
                        </Col></Row>

                    <Row >
                        <Col md={6} xs={12} className="mt-4">
                            <Form.Group controlId="dob" className="text-left d-flex flex-column">
                                <Form.Label className="text-left  text-nowrap">
                                    Date of birth
                                </Form.Label>

                                <DatePicker
                                    name="dateofbirth"
                                    style={{ width: '100%' }}
                                    required
                                    size="sm"
                                    placeholder="MM/DD/YYYY"
                                    format="MM/DD/YYYY"
                                    value={this.props.application.borrower.dateofbirth ? dayjs(this.props.application.borrower.dateofbirth, "MM/DD/YYYY") : ''}
                                    onChange={e => {
                                        let dat = new Date(e)
                                        this.props.changeBorrowerInfoByValue(e ? dayjs(dat).format('MM/DD/YYYY') : '', "borrower", "dateofbirth")
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12} className="mt-4">
                            <Form.Group controlId="MaritalStatus" className="text-left">
                                <Form.Label className="text-left">Marital status<Tips
                                    content={<div>
                                        If you are in a domestic partnership, single or
                                        divorced, select Unmarried
                                    </div>}
                                />

                                </Form.Label>
                                <div >
                                    <Select defaultValue={
                                        this.props.application[this.props.who].maritalstatus
                                    }
                                        value={this.props.application[this.props.who].maritalstatus}

                                        onChange={schangeBorrowerInfo(
                                            this.props.who,
                                            "maritalstatus"
                                        )} style={{
                                            width: '100%',
                                            height: "44px",
                                            background: '#FCFCFC'
                                        }}>

                                        <Select.Option value="married">Married</Select.Option>
                                        <Select.Option value="separated">Separated</Select.Option>
                                        <Select.Option value="unmarried">Unmarried</Select.Option>
                                    </Select>
                                </div>
                                <div className="invalid-feedback">Please provide your marital status</div>
                                {/* <Form.Control
                                    required
                                    name="maritalstatus"
                                    as="select"
                                    size="sm"
                                    defaultValue={
                                        this.props.application[this.props.who].maritalstatus
                                    }
                                    onChange={changeBorrowerInfo(
                                        this.props.who,
                                        "maritalstatus"
                                    )}
                                >
                                    <option required value="">
                                        ...
                                    </option>
                                    <option value="married">Married</option>
                                    <option value="separated">Separated</option>
                                    <option value="unmarried">Unmarried</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please provide your marital status
                                </Form.Control.Feedback> */}
                            </Form.Group>
                        </Col>
                    </Row>



                    <Row><Col>
                        <div className="mt-4">
                            <Form.Group >
                                <Form.Label>Contact phone</Form.Label>
                                <Form.Control
                                    isValid={false}
                                    ref={this.phoneref}
                                    name="phone"
                                    placeholderValue='Enter your phone number'
                                    value={this.props.application.borrower.homephone}
                                    onChange={changePhoneInfo("borrower", "homephone")}
                                    required
                                />
                                <Form.Control.Feedback type="invalid" >
                                    Please enter a valid phone number.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="subheading" style={{ marginTop: "5px" }}>We hate spam just as much as you do. You won't receive any unwanted calls or texts from us.</div>
                    </Col></Row>
                    {displayCitizenship("borrower")}
                    <Row className="mt-4" ><Col style={{ display: 'flex' }}><Switch defaultChecked={this.props.application.hascoborrower === "withcoborrower"} onChange={onChangeSwitch} /><span style={{ marginLeft: 18, fontSize: 14, fontWeight: 500, color: '#222' }}>I have a co-borrower</span></Col></Row>
                    {
                        this.props.application.hascoborrower === "withcoborrower" && (
                            <>
                                <Row className="prequalInterviewSubTitle mt-4"><Col style={{ color: '#000', fontWeight: 600 }}>Co-borrower</Col></Row>
                                <Form ref={this.cform}>
                                    <Row className="mt-3">
                                        <Col md={6} xs={12} className="mt-1">
                                            <Form.Group >
                                                <Form.Label>First name</Form.Label>
                                                <InputGroupComponent
                                                    required={true}
                                                    name="coborrowerfirstname"
                                                    placeholderValue="Co-borrower first name"
                                                    value={this.props.application["coborrower"].firstname}
                                                    onChange={changeCoBorrower("coborrower", "firstname")}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} xs={12} className="mt-1">
                                            <Form.Group>
                                                <Form.Label>Last name</Form.Label>
                                                <InputGroupComponent
                                                    required={true}
                                                    name="coborrowerlastname"
                                                    placeholderValue="Co-borrower last name"
                                                    value={this.props.application["coborrower"].lastname}
                                                    onChange={changeBorrowerInfo("coborrower", "lastname")}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row  >
                                    <Col md={6} xs={12} className="mt-1">
                                        <Form.Group >
                                            <Form.Label>Email</Form.Label>



                                            <InputGroupComponent
                                                onBlur={(e) => {
                                                    console.log(e.target.value)
                                                    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

                                                    if (emailRegex.test(e.target.value)) {
                                                        this.setState({

                                                            isValidEmail: emailRegex.test(e.target.value)
                                                        })
                                                    } else {
                                                        this.setState({

                                                            isValidEmail: emailRegex.test(e.target.value)
                                                        })
                                                        this.props.changeBorrowerInfo({
                                                            target: {
                                                                value: ''
                                                            }
                                                        }, 'coborrower', 'email')
                                                    }



                                                }}
                                                placeholderValue="Enter your co-applicant's email address"
                                                value={this.props.application["coborrower"].email}
                                                onChange={changeBorrowerInfo("coborrower", "email")}
                                            />
                                            {!this.state.isValidEmail ? <div style={{ color: 'red' }}>please enter your vaild email</div> : <></>}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} xs={12} className="mt-1">
                                        <Form.Group>
                                            <Form.Label>Phone</Form.Label>
                                            <InputGroupComponent
                                                placeholderValue="Enter your co-applicant's phone number"
                                                value={this.props.application["coborrower"].homephone}
                                                onChange={changePhoneInfo("coborrower", "homephone")}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: -15 }}>
                                    <Col md={6} xs={12} className="mt-4">
                                        <Form.Group controlId="dob" className="text-left d-flex flex-column">
                                            <Form.Label className="text-left  text-nowrap">
                                                Date of birth
                                            </Form.Label>

                                            <DatePicker
                                                name="dateofbirth"
                                                style={{ width: '100%' }}
                                                required
                                                size="sm"
                                                placeholder="MM/DD/YYYY"
                                                format="MM/DD/YYYY"
                                                value={this.props.application.coborrower.dateofbirth ? dayjs(this.props.application.coborrower.dateofbirth, "MM/DD/YYYY") : ''}
                                                onChange={e => {
                                                    let dat = new Date(e)
                                                    this.props.changeBorrowerInfoByValue(e ? dayjs(dat).format('MM/DD/YYYY') : '', "coborrower", "dateofbirth")
                                                }}
                                            />

                                        </Form.Group>
                                    </Col>
                                    <Col md={6} xs={12} className="mt-4">
                                        <Form.Group controlId="MaritalStatus" className="text-left">
                                            <Form.Label className="text-left">Marital status<Tips
                                                content={<div>
                                                    If you are in a domestic partnership, single or
                                                    divorced, select Unmarried
                                                </div>}
                                            />

                                            </Form.Label>
                                            <div >
                                                <Select defaultValue={
                                                    this.props.application["coborrower"].maritalstatus
                                                }
                                                    value={this.props.application["coborrower"].maritalstatus}

                                                    onChange={schangeBorrowerInfo(
                                                        "coborrower",
                                                        "maritalstatus"
                                                    )} style={{
                                                        width: '100%',
                                                        height: "44px",
                                                        background: '#FCFCFC'
                                                    }}>

                                                    <Select.Option value="married">Married</Select.Option>
                                                    <Select.Option value="separated">Separated</Select.Option>
                                                    <Select.Option value="unmarried">Unmarried</Select.Option>
                                                </Select>
                                            </div>
                                            {/* <div style={{ opacity: 0 }}>
                                                <Form.Control
                                                    required
                                                    name="maritalstatus"
                                                    as="select"
                                                    size="sm"
                                                    defaultValue={
                                                        this.props.application["coborrower"].maritalstatus
                                                    }
                                                    onChange={changeBorrowerInfo(
                                                        "coborrower",
                                                        "maritalstatus"
                                                    )}
                                                >
                                                    <option required value="">
                                                        ...
                                                    </option>
                                                    <option value="married">Married</option>
                                                    <option value="separated">Separated</option>
                                                    <option value="unmarried">Unmarried</option>
                                                </Form.Control>
                                            </div> */}
                                            <Form.Control.Feedback type="invalid">
                                                Please provide your marital status
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {this.props.application["coborrower"].maritalstatus === "married" &&
                                    <Row className="mt-4">
                                        <Col className="d-flex">
                                            <Checkbox onChange={this.onMarried}
                                                checked={true === this.props.application.cohabiting}>

                                                <span
                                                    style={{ fontWeight: 600 }}
                                                > I am married to this co-applicant.</span>
                                            </Checkbox>
                                            {/* <Form.Check
                                                label="1"
                                                name="group1"
                                                type="checkbox"
                                                id="checkbox1"
                                            >
                                                <Form.Check.Input
                                                    type="checkbox"
                                                    onChange={this.onMarried}
                                                    checked={true === this.props.application.cohabiting}
                                                    isValid />
                                            </Form.Check>
                                            <Form.Label className="ml-2">
                                            </Form.Label> */}
                                        </Col>
                                    </Row>
                                }

                                <div >
                                    {displayCitizenship("coborrower")}
                                </div>
                            </>
                        )
                    }
                </Form>
            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Applicant)
export class Residence extends Component {
    constructor(props) {
        super(props)
        this.form = React.createRef()
        this.state = {
            validated: false,
            loanLimits: {},
            tabAvtive: 0,
            who: com.borrower,
            tabs: [
                {
                    tab: 'Primary',
                    value: this.props.application.borrower.firstname + " " + this.props.application.borrower.lastname
                }
            ],
            sameAddress: false,
            errMsg: false,

        }
        this.fetchLoanLimits()
    }

    componentDidMount() {
        console.log(this.props.application.assetsandliabilities.ownedproperties, 99)

        this.completion('application')
        this.setState({ sameAddress: this.hasSameAddress() })
        if (this.personalCoborrower()) {
            this.setState(prevState => ({
                tabs: [
                    ...prevState.tabs,
                    {
                        tab: 'Co-borrower',
                        value: this.props.application.coborrower.firstname + " " + this.props.application.coborrower.lastname
                    }
                ]
            }))
        }
        if (this.props.application.borrower.presentaddressmonths === "") {
            this.props.changeBorrowerInfoByValue("0", "borrower", "presentaddressmonths")
        }
        if (this.props.application.coborrower.presentaddressmonths === "") {
            this.props.changeBorrowerInfoByValue("0", "coborrower", "presentaddressmonths")
        }
    }

    componentDidUpdate() {
        this.completion('application')
    }

    componentWillUnmount() { }
    completion = (stateKey) => {
        let keysToFilter = []
        keysToFilter = [
            `${this.state.who}.presentaddressstreet`,
            `${this.state.who}.presentaddressstate`,
            `${this.state.who}.presentaddresscounty`,
            `${this.state.who}.presentaddresscity`,
            `${this.state.who}.presentaddresszip`,
            `${this.state.who}.presentaddressownership`,
            `${this.state.who}.presentaddressyears`,
        ]
        if (this.props[stateKey][this.state.who].presentaddressyears !== "" && parseInt(this.props[stateKey][this.state.who].presentaddressyears) < 2) {
            keysToFilter.push(`${this.state.who}.presentaddressmonths`)
        }
        const filteredObj = keysToFilter.reduce((obj, key) => {
            if (key.includes('.')) {
                const nestedKeys = key.split('.')
                let value = this.props[stateKey]

                let nestedKeyString = ''

                for (let i = 0; i < nestedKeys.length - 1; i++) {
                    const nestedKey = nestedKeys[i]

                    if (value.hasOwnProperty(nestedKey)) {
                        value = value[nestedKey]
                        nestedKeyString += nestedKey + '.'
                    } else {
                        value = undefined
                        break
                    }
                }

                const lastNestedKey = nestedKeys[nestedKeys.length - 1]
                if (value !== undefined && value.hasOwnProperty(lastNestedKey)) {
                    obj[nestedKeyString + lastNestedKey] = value[lastNestedKey]
                }
            } else if (this.props[stateKey].hasOwnProperty(key)) {
                obj[key] = this.props[stateKey][key]
            }

            return obj
        }, {})
        const emptyNum = Object.keys(filteredObj).filter((key) => {
            const value = filteredObj[key]
            return value === '' || value === null || value === undefined
        }).length
        const progressValue = Math.round(((keysToFilter.length - emptyNum) / keysToFilter.length) * 100)
        sessionStorage.setItem("progressValue", progressValue)
    }
    hasSameAddress = () => {
        const application = this.props.application
        const borrower = application.borrower
        const coBorrower = application.coborrower

        return (
            borrower.presentaddressstreet === coBorrower.presentaddressstreet &&
            borrower.presentaddressstate === coBorrower.presentaddressstate &&
            borrower.presentaddresscounty === coBorrower.presentaddresscounty &&
            borrower.presentaddresscity === coBorrower.presentaddresscity &&
            borrower.presentaddresszip === coBorrower.presentaddresszip
        )
    }
    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }
    personalCoborrower = () => {
        return this.props.application.hascoborrower === "withcoborrower" ||
            (this.props.application.hascoborrower === "multiple" && this.props.application.married)

    }
    fetchLoanLimits = () => {
        fetch("/data/loanlimits")
            .then((res) => {
                if (!res.ok) {

                    throw Error(res.statusText)
                }
                return res.json()
            })
            .then((res) => {
                this.setState({ loanLimits: res })
            })
            .catch((err) => {
                console.error(err)
            })
    }
    onStreetAddressCompletion = (address, who) => {
        let num = com.getSubaddress(address, "street_number", "long_name")

        let city = com.getSubaddress(address, "locality", "long_name")
        let state = com.getSubaddress(
            address,
            "administrative_area_level_1",
            "short_name"
        )
        let zip = com.getSubaddress(address, "postal_code", "long_name")

        let county = com.getSubaddress(
            address,
            "administrative_area_level_2",
            "long_name"
        )
        let longstate = com.getSubaddress(
            address,
            "administrative_area_level_1",
            "long_name"
        )

        this.props.changePhoneInfo(city, who, "presentaddresscity")
        this.props.changePhoneInfo(longstate, who, "presentaddressstate")
        this.props.changePhoneInfo(zip, who, "presentaddresszip")
        this.props.changePhoneInfo(county, who, "presentaddresscounty")
        let sfx = ""
        let short = com.getSubaddress(address, "route", "short_name")

        let stradd = num + " " + short
        this.props.changeBorrowerInfo(
            { target: { value: stradd } },
            who,
            "presentaddressstreet"
        )

        if (who === "borrower" && this.props.application[who].presentaddressownership === st.O_Own && this.props.application.assetsandliabilities.ownedproperties.length >= 1) {
            this.props.changeOwnedProperty(stradd, 0, "address")
            this.props.changeOwnedProperty(longstate, 0, "state")
            this.props.changeOwnedProperty(county, 0, "county")
            this.props.changeOwnedProperty(city, 0, "city")
            this.props.changeOwnedProperty(zip, 0, "zipcode")
        }
        let cob = this.props.application.coborrower
        if (who === "borrower" &&
            cob.presentaddressstreet === "" &&
            cob.presentaddressstate === "" &&
            cob.presentaddresszip === "") {
            this.props.changePhoneInfo(city, "coborrower", "presentaddresscity")
            this.props.changePhoneInfo(longstate, "coborrower", "presentaddressstate")
            this.props.changePhoneInfo(zip, "coborrower", "presentaddresszip")
            this.props.changePhoneInfo(county, "coborrower", "presentaddresscounty")
            this.props.changePhoneInfo(stradd, "coborrower", "presentaddressstreet")
        }

        let shorts = short.split(" ")
        if (com.streetsuffixes.includes(shorts[shorts.length - 1].toUpperCase())) {
            sfx = shorts.pop()

            if (typeof com.streetTypeAbbreviations[sfx.toLowerCase()] !== "undefined")
                sfx = com.streetTypeAbbreviations[sfx.toLowerCase()].toUpperCase()

            short = shorts.join(" ")
        }
        let prefix = ""
        let s = shorts[0].toUpperCase()

        if (com.prefixes.includes(s)) {
            prefix = shorts[0]
            shorts = short.split(" ")
            short = shorts.slice(1, shorts.length).join(" ")
        }

    }
    updateMonths = (value) => {
        com.touch()
        this.props.changeBorrowerInfo(
            { target: { value } },
            this.state.who,
            "presentaddressmonths"
        )
    }
    updateYears = (value) => {
        com.touch()
        if (
            value !== "" &&
            parseInt(value) < 2 &&
            0 === this.props.application[this.state.who].previousaddresses.length
        ) {
            this.props.addPreviousAddress({ target: { value: 0 } }, this.state.who)
            this.props.changeBorrowerInfo(
                { target: { value: "0" } },
                this.state.who,
                "presentaddressmonths"
            )
        }

        if (
            value !== "" &&
            parseInt(value) >= 2 &&
            0 !== this.props.application[this.state.who].previousaddresses.length
        ) {
            this.props.clearPreviousAddress({ target: { value: 0 } }, this.state.who)
        }

        this.props.changeBorrowerInfo(
            { target: { value } },
            this.state.who,
            "presentaddressyears"
        )
    }
    getStates = () => {
        let options = []
        let keys = Object.keys(this.state.loanLimits)
        options.push(<option value="">Select your state</option>)
        keys.forEach((x) => {
            options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
        })
        return options
    }
    getCounties = () => {
        let options = []
        options.push(<option value="">Select your county</option>)
        let st = this.props.application.borrower.presentaddressstate
        try {
            if (this.state.loanLimits[st.toUpperCase()]) {
                let keys = Object.keys(this.state.loanLimits[st.toUpperCase()])
                keys.forEach((x) => {
                    options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
                })
            }
        } catch (error) {
            console.log(error)
        }

        return options
    }

    getCountiesForPrev = () => {
        let options = []
        options.push(<option value="">Select your county</option>)
        let st = this.props.application.borrower.previousaddresses[0].state
        try {
            if (this.state.loanLimits[st.toUpperCase()]) {
                let keys = Object.keys(this.state.loanLimits[st.toUpperCase()])
                keys.forEach((x) => {
                    options.push(<option value={com.capitalize(x)}> {com.capitalize(x)}</option>)
                })
            }
        } catch (error) {
            console.log(error)
        }

        return options
    }


    handleSubmit = e => {
        if (this.props.application[this.state.who].presentaddressstreet === '' || this.props.application[this.state.who].presentaddressownership == null) {
            this.setState({ errMsg: true })
            return false
        }
        if (this.state.tabAvtive === 0 && this.personalCoborrower()) {
            this.changeTab(1)
            return false
        }
        return true
    }
    handlePrevious = () => {
        if (this.state.tabAvtive === 1) {
            this.changeTab(0)
            return false
        }
        return true
    }
    changeTab = (tab) => {
        this.setState({ tabAvtive: tab, who: tab === 0 ? com.borrower : com.coborrower }, () => { this.completion('application'); eventBus.emit('changeProgressValue') })
    }
    displayPreviousAddress = (address, index) => {
        let achangeAddressInfo = (who, verb) => {
            return (event) =>
                this.props.updatePreviousAddress({ target: { value: event } }, who, verb, index)
        }
        let changeAddressInfo = (who, verb) => {
            return (event) =>
                this.props.updatePreviousAddress(event, who, verb, index)
        }
        let onPreviousStreetAddressCompletion = (address) => {
            let city = com.getSubaddress(address, "locality", "long_name")
            let state = com.getSubaddress(
                address,
                "administrative_area_level_1",
                "long_name"
            )
            let county = com.getSubaddress(
                address,
                "administrative_area_level_2",
                "long_name"
            )
            let zip = com.getSubaddress(address, "postal_code", "long_name")
            let zips = com.getSubaddress(address, "postal_code_suffix", "short_name")
            if (zips !== "") zip = zip + "-" + zips

            let num = com.getSubaddress(address, "street_number", "long_name")
            let short = com.getSubaddress(address, "route", "short_name")
            let stradd = num + " " + short

            this.props.updatePreviousAddress(
                { target: { value: stradd } },
                this.state.who,
                "street",
                index
            )
            this.props.updatePreviousAddress(
                { target: { value: city } },
                this.state.who,
                "city",
                index
            )
            this.props.updatePreviousAddress(
                { target: { value: county } },
                this.state.who,
                "county",
                index
            )
            this.props.updatePreviousAddress(
                { target: { value: state } },
                this.state.who,
                "state",
                index
            )
            this.props.updatePreviousAddress(
                { target: { value: zip } },
                this.state.who,
                "zip",
                index
            )
        }
        const states = {
            "AL": "Alabama",
            "AK": "Alaska",
            "AS": "American Samoa",
            "AZ": "Arizona",
            "AR": "Arkansas",
            "CA": "California",
            "CO": "Colorado",
            "CT": "Connecticut",
            "DE": "Delaware",
            "DC": "District Of Columbia",
            "FM": "Federated States Of Micronesia",
            "FL": "Florida",
            "GA": "Georgia",
            "GU": "Guam",
            "HI": "Hawaii",
            "ID": "Idaho",
            "IL": "Illinois",
            "IN": "Indiana",
            "IA": "Iowa",
            "KS": "Kansas",
            "KY": "Kentucky",
            "LA": "Louisiana",
            "ME": "Maine",
            "MH": "Marshall Islands",
            "MD": "Maryland",
            "MA": "Massachusetts",
            "MI": "Michigan",
            "MN": "Minnesota",
            "MS": "Mississippi",
            "MO": "Missouri",
            "MT": "Montana",
            "NE": "Nebraska",
            "NV": "Nevada",
            "NH": "New Hampshire",
            "NJ": "New Jersey",
            "NM": "New Mexico",
            "NY": "New York",
            "NC": "North Carolina",
            "ND": "North Dakota",
            "MP": "Northern Mariana Islands",
            "OH": "Ohio",
            "OK": "Oklahoma",
            "OR": "Oregon",
            "PW": "Palau",
            "PA": "Pennsylvania",
            "PR": "Puerto Rico",
            "RI": "Rhode Island",
            "SC": "South Carolina",
            "SD": "South Dakota",
            "TN": "Tennessee",
            "TX": "Texas",
            "UT": "Utah",
            "VT": "Vermont",
            "VI": "Virgin Islands",
            "VA": "Virginia",
            "WA": "Washington",
            "WV": "West Virginia",
            "WI": "Wisconsin",
            "WY": "Wyoming"
        }
        return (
            <div className="addresspadidng">
                <div className="addAddress mt-4" key={"prev_" + index}>
                    <Row>
                        <Col >
                            <Form.Row>
                                <Col xl={5} className="mt-4">
                                    <AddressAutocomplete
                                        label="Previous street address"
                                        required={true}
                                        name={"oldadressline" + index}
                                        id={"prevaddr" + index}
                                        defaultValue={address.street}
                                        onChange={changeAddressInfo(this.state.who, "street")}
                                        pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                        placeholder="No PO Box addresses are accepted"
                                        goodfeedback=""
                                        badfeedback="Please provide previous street address."
                                        onCompletion={onPreviousStreetAddressCompletion}
                                    />
                                </Col>
                                <Col xl={3} className="mt-4">
                                    <Form.Group
                                        controlId="previouspropertytype"
                                        className="text-left"
                                    >
                                        <Form.Label className="text-left">
                                            Ownership&nbsp;
                                        </Form.Label>
                                        <div>
                                            <Select value={address.ownership}
                                                className="w-100"
                                                style={{ height: 44 }}
                                                onChange={achangeAddressInfo(this.state.who, "ownership")}
                                            >
                                                <option value="own">Owned</option>
                                                <option value="rent">Rented</option>
                                                <option value="rentfree">Lived Rent Free</option>
                                            </Select>
                                        </div>
                                        {/* <Form.Control
                                        name={"propertytype" + index}
                                        as="select"
                                        size="sm"
                                        type="text"
                                        value={address.ownership}
                                        onChange={changeAddressInfo(this.state.who, "ownership")}
                                    >
                                        <option value="own">Owned</option>
                                        <option value="rent">Rented</option>
                                        <option value="rentfree">Lived Rent Free</option>
                                    </Form.Control> */}
                                    </Form.Group>
                                </Col>
                                <Col xl={2} className="mt-4">
                                    <Form.Group
                                        controlId={"PreviousYears" + index}
                                        className="text-left"
                                    >
                                        <Form.Label className="text-left">Years&nbsp;</Form.Label>
                                        <div >
                                            <InputNumber value={address.years}
                                                min={0}
                                                size="large"
                                                className="w-100"
                                                onChange={achangeAddressInfo(this.state.who, "years")}>


                                            </InputNumber>
                                        </div>
                                        {/* <Form.Control
                                        isValid={false}
                                        size="sm"
                                        required
                                        id={"prevyears" + index}
                                        name="oldyears"
                                        type="number"
                                        min={0}
                                        value={address.years}
                                        onChange={changeAddressInfo(this.state.who, "years")}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide years at this residence.
                                    </Form.Control.Feedback> */}
                                    </Form.Group>
                                </Col>
                                <Col xl={2} className="mt-4">

                                    <Form.Group
                                        controlId={"PreviousMonths" + index}
                                        className="text-left"
                                    >
                                        <Form.Label className="text-left">Months:&nbsp;</Form.Label>
                                        <Form.Control
                                            isValid={false}
                                            size="sm"
                                            required={parseInt(address.years) < 2}
                                            name="oldmonths"
                                            type="number"
                                            min={0}
                                            max={11}
                                            value={address.months}
                                            onChange={changeAddressInfo(this.state.who, "months")}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide months.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </Col>
                            </Form.Row>

                            <Form.Row >
                                <Col xl={3} className="mt-4">
                                    <Form.Group controlId="PrStte" className="text-left">
                                        <Form.Label className="text-left">State:</Form.Label>
                                        <div>
                                            <Select style={{ width: '100%', height: 44 }} placeholder='Select
                                         your
                                          state'  value={address.state} onChange={changeAddressInfo(this.state.who, "state")}>
                                                {this.getStates()}
                                            </Select>
                                        </div>
                                        {/* <Form.Control
                                            required
                                            size="sm"
                                            as="select"
                                            placeholder="State"
                                            name="previousstte"
                                            value={address.state}
                                            onChange={changeAddressInfo(this.state.who, "state")}
                                        >
                                            {this.getStates()}
                                        </Form.Control> */}

                                    </Form.Group>
                                </Col>
                                {/* <Col xl={3} className="mt-4">
                                    <Form.Group controlId="PrCty" className="text-left">
                                        <Form.Label className="text-left">County:</Form.Label>
                                        <Form.Control
                                            size="sm"
                                            required
                                            name="previouscty"
                                            as={"select"}
                                            value={address.county}
                                            onChange={changeAddressInfo(this.state.who, "county")}
                                        >
                                            {this.getCountiesForPrev()}
                                        </Form.Control>
                                    </Form.Group>
                                </Col> */}
                                <Col xl={4} className="mt-4">
                                    <Form.Group controlId="PrCty" className="text-left">
                                        <Form.Label className="text-left">City</Form.Label>
                                        <Form.Control
                                            isValid={false}
                                            size="sm"
                                            required
                                            name="previouscty"

                                            value={address.city}
                                            onChange={changeAddressInfo(this.state.who, "city")}
                                            autoComplete="off"
                                            pattern="^[\p{L}\p{N}\s,-@.\/#+]+$"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide the city.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col xl={2} className="mt-4">
                                    <Form.Group controlId="PrZp" className="text-left">
                                        <Form.Label className="text-left">ZIP code</Form.Label>
                                        <Form.Control
                                            isValid={false}
                                            size="sm"
                                            required
                                            name="previouszip"
                                            type="text"
                                            placeholder="#####"
                                            value={address.zip}
                                            onChange={changeAddressInfo(this.state.who, "zip")}
                                            pattern="^([0-9]{5}(?:-[0-9]{4})?|([a-zA-Z][0-9]){3})$"
                                            autoComplete="off"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide the ZIP code.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    };
    displayPreviousAddresses = () => {
        if (
            this.state.who === com.borrower &&
            this.props.application[this.state.who].presentaddressyears !== "" &&
            parseInt(this.props.application[this.state.who].presentaddressyears) < 2
        )
            return this.props.application[this.state.who].previousaddresses.map(
                this.displayPreviousAddress
            )

        if (this.state.who === com.coborrower) {
            if (
                this.props.application.cohabiting &&
                parseInt(this.props.application.borrower.presentaddressyears) < 2
            )
                return this.props.application[this.state.who].previousaddresses.map(
                    this.displayPreviousAddress
                )

            if (
                !this.props.application.cohabiting &&
                parseInt(this.props.application.coborrower.presentaddressyears) < 2
            )
                return this.props.application[this.state.who].previousaddresses.map(
                    this.displayPreviousAddress
                )
        }
        return ""
    };
    render() {
        let onAddressChange = (e) => {
            com.touch()
            return this.props.changeBorrowerInfo(
                e,
                this.state.who,
                "presentaddressstreet"
            )
        }
        let setLoanState = (v) => {
            this.props.changePhoneInfo(v, this.state.who, "presentaddressstate")
        }
        let setLoanCounty = (v) => {
            this.props.changePhoneInfo(v, this.state.who, "presentaddresscounty")
        }
        let setLoanCity = (v) => {
            this.props.changePhoneInfo(v, this.state.who, "presentaddresscity")
        }
        let setZip = (v) => {
            v = v.replace(/\D/g, '')
            this.props.changePhoneInfo(v, this.state.who, "presentaddresszip")
        }
        let changeFirstBuy = (e) => {
            this.props.updateApplicationAttribute(e.target.checked, "firsttimebuyer")
        }
        let onChangeCheckbox = (e) => {
            this.setState({ sameAddress: e.target.checked })
            if (e.target.checked) {
                this.props.changeBorrowerInfo(
                    { target: { value: this.props.application.borrower.presentaddressstreet } },
                    'coborrower',
                    "presentaddressstreet"
                )
                setLoanState(this.props.application.borrower.presentaddressstate, 'coborrower')
                setLoanCounty(this.props.application.borrower.presentaddresscounty, 'coborrower')
                setLoanCity(this.props.application.borrower.presentaddresscity, 'coborrower')
                setZip(this.props.application.borrower.presentaddresszip, 'coborrower')
            }
        }


        return (
            <div className="text-left mb-3">
                <Row>
                    <Col>
                        <div className="prequalInterviewSubTitle Residence"  >Current Residence</div>
                        {
                            this.personalCoborrower() && (
                                <div className="applicant-tab residence">
                                    {
                                        this.state.tabs.map((item, index) => (
                                            <div className={this.state.tabAvtive === index ? 'applicant-tab-active' : ''} key={index}>
                                                <div className="applicant-tab-title">{item.tab}</div>
                                                <div className="applicant-tab-name">{item.value}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }
                        {
                            this.state.who === 'coborrower' && (
                                <div className="mt-4">
                                    <Checkbox checked={this.state.sameAddress} onChange={onChangeCheckbox}><span style={{ fontSize: 14, fontWeight: 500, color: '#222', whiteSpace: "normal" }}>Co-borrower has the same address as the primary borrower.</span></Checkbox>
                                </div>
                            )
                        }
                        {
                            !(this.state.sameAddress && this.state.who === 'coborrower') && (
                                <div className="mt-4">
                                    <Form ref={this.form} id="applicantform" noValidate validated={this.state.validated} autoComplete="false">
                                        <AddressAutocomplete
                                            id="Address"
                                            label="Current residential address"
                                            required={true}
                                            name="addessline"
                                            defaultValue={
                                                this.props.application[this.state.who]
                                                    .presentaddressstreet
                                            }
                                            onChange={onAddressChange}
                                            pattern="^[0-9]+\s[\p{L}\p{N} !:,&()'?#+\/@-]+$"
                                            placeholder="No PO Box addresses are accepted"
                                            goodfeedback=""
                                            badfeedback="Please provide your street address."
                                            onCompletion={e => this.onStreetAddressCompletion(e, this.state.who)}
                                            size="md"
                                        />
                                    </Form>
                                </div>
                            )
                        }
                    </Col>
                </Row>
                {
                    !(this.state.sameAddress && this.state.who === 'coborrower') && (
                        <div>
                            <Row >
                                <Col md={3} xs={12} className="mt-4">
                                    <Form.Group >
                                        <Select style={{ width: '100%', height: 44 }} placeholder='Select
                                         your
                                          state'  value={this.props.application[this.state.who].presentaddressstate} onChange={(e) => {
                                                setLoanState(e)
                                            }}>
                                            {this.getStates()}
                                        </Select>
                                        {/* <Form.Control
                                            required
                                            size="sm"
                                            as="select"
                                            placeholder="State"
                                            onChange={(e) => {
                                                setLoanState(e.target.value)
                                            }}
                                            value={this.props.application[this.state.who].presentaddressstate}
                                        >

                                        </Form.Control> */}
                                    </Form.Group>
                                </Col>
                                <Col md={3} xs={12} className="mt-4">
                                    <Form.Group >
                                        <Select style={{ width: '100%', height: 44 }}
                                            onChange={(e) => {
                                                setLoanCounty(e)
                                            }}
                                            value={this.props.application[this.state.who].presentaddresscounty}
                                        >
                                            {this.getCounties()}

                                        </Select>
                                        {/* <Form.Control
                                            required
                                            size="sm"
                                            as="select"
                                            onChange={(e) => {
                                                setLoanCounty(e.target.value)
                                            }}
                                            value={this.props.application[this.state.who].presentaddresscounty}
                                        >
                                            {this.getCounties()}
                                        </Form.Control> */}
                                    </Form.Group>
                                </Col>
                                <Col md={3} xs={12} className="mt-4">
                                    <Form.Group >
                                        <Form.Control
                                            required
                                            size="sm"
                                            onChange={(e) => {
                                                setLoanCity(e.target.value)
                                            }}
                                            value={this.props.application[this.state.who].presentaddresscity}
                                            placeholder="City"
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={3} xs={12} className="mt-4">
                                    <Form.Group >
                                        <Form.Control
                                            required
                                            size="sm"

                                            onChange={(e) => {
                                                setZip(e.target.value)
                                            }}
                                            value={this.props.application[this.state.who].presentaddresszip}
                                            placeholder="Zip Code"
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    )
                }

                <Row className="mt-4">
                    <Col className="" md={12}>
                        <Form.Group className="inputSection" style={{ width: "100%" }}>
                            <Form.Label>
                                How about your current residence?
                            </Form.Label>
                            <CheckGroup
                                data={[{
                                    value: st.O_Own,
                                    label: "I own it",
                                },
                                {
                                    value: st.O_Rent,
                                    label: 'I rent it',
                                },
                                {
                                    value: st.O_Rentfree,
                                    label: 'No primary housing expense',
                                }
                                ]}
                                onChangeSelect={e => {

                                    if (e === this.props.application[this.state.who].presentaddressownership) return
                                    this.props.changeBorrowerInfoByValue(e, this.state.who, "presentaddressownership")
                                    this.setState({ errMsg: false })
                                    let properties = this.props.application.assetsandliabilities.ownedproperties


                                    if (e === st.O_Own) {
                                        if (this.state.who === "borrower" && (properties.length === 0 ||
                                            (properties.length > 0 && properties[0].address !== this.props.application[this.state.who].presentaddressstreet))) {
                                            this.props.prependBorrowerOwnedProperty()

                                            this.props.changeOwnedProperty(this.props.application[this.state.who].presentaddressstreet, 0, "address")
                                            this.props.changeOwnedProperty(this.props.application[this.state.who].presentaddressstate, 0, "state")
                                            this.props.changeOwnedProperty(this.props.application[this.state.who].presentaddresscounty, 0, "county")
                                            this.props.changeOwnedProperty(this.props.application[this.state.who].presentaddresscity, 0, "city")
                                            this.props.changeOwnedProperty(this.props.application[this.state.who].presentaddresszip, 0, "zipcode")
                                        }
                                    } else {
                                        if (this.state.who === "borrower" && properties.length > 0) {
                                            this.props.removeBorrowerOwnedProperty(0)
                                        }

                                    }
                                }}
                                value={this.props.application[this.state.who].presentaddressownership}
                                type='radio'
                                direction="horizontal"
                            />
                            {this.state.errMsg && <div style={{ color: 'red', marginTop: 10 }}>Please select current residence</div>}
                        </Form.Group>
                    </Col>
                </Row>
                {this.props.application[this.state.who].presentaddressownership === st.O_Rent &&

                    <Row className="mt-4">
                        <Col md={6} xs={12}>
                            <div className="formLabel">
                                Monthly rent
                            </div>

                            <InputNumber
                                prefix="$"
                                className="w-100"
                                defaultValue={this.props.application[this.props.who].expenses.currentrent}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={e => this.props.updateExpenses(e, this.props.who, "currentrent")}
                                size="large"
                            />
                        </Col>
                    </Row>}

                {
                    this.state.who === "borrower" && this.props.application.borrower.presentaddressownership === st.O_Own && this.props.application.assetsandliabilities.ownedproperties.length > 0 && <div><Row >

                        <Col className="mt-4" >
                            <div className="formLabel">
                                Property value
                            </div>
                            <DollarInput value={this.props.application.assetsandliabilities.ownedproperties[0].marketvalue}

                                onChange={e => this.props.changeOwnedProperty(e, 0, "marketvalue")}
                            ></DollarInput>
                            {/* <InputNumber
                                min={0}
                                step={1}
                                prefix="$"
                                className="w-100"
                                defaultValue={this.props.application.assetsandliabilities.ownedproperties[0].marketvalue}

                                onChange={e => this.props.changeOwnedProperty(e, 0, "marketvalue")}
                                size="large"
                            /> */}
                        </Col>
                        <Col className="mt-4">
                            <div className="formLabel">
                                Property status
                            </div>
                            <Select
                                style={{ height: 44 }}
                                className="w-100"
                                defaultValue={this.props.application.assetsandliabilities.ownedproperties[0].status}
                                options={[
                                    { value: "sold", label: "Sold" },
                                    { value: "pending", label: "Pending sale" },

                                    { value: "second", label: "Second Home" },
                                    { value: "rental", label: "Rental" },
                                ]}
                                onChange={e => this.props.changeOwnedProperty(e, 0, "status")}
                            />

                        </Col>
                        <Col className="mt-4">
                            <div className="formLabel">
                                Property intended occupancy
                            </div>
                            <Select
                                style={{ height: 44 }}
                                className="w-100"
                                defaultValue={this.props.application.assetsandliabilities.ownedproperties[0].occupancy}
                                options={[
                                    { value: "principal", label: "Primary Residence" },
                                    { value: "secondhome", label: "Second Home" },
                                    { value: "investment", label: "Investment" },
                                ]}
                                onChange={e => this.props.changeOwnedProperty(e, 0, "occupancy")}
                            />

                        </Col>
                    </Row>
                        <Row className="mt-4">
                            <Col md={8} xs={12}>
                                <div className="formLabel">
                                    Monthly insurance, taxes, association dues, etc.
                                </div>
                                <DollarInput
                                    onChange={e => this.props.changeOwnedProperty(e, 0, "insurancemaintenancetaxes")}
                                    value={
                                        this.props.application.assetsandliabilities.ownedproperties[0].insurancemaintenancetaxes

                                    }></DollarInput>
                                {/* <InputNumber
                                    prefix="$"
                                    className="w-100"
                                    defaultValue={this.props.application.assetsandliabilities.ownedproperties[0].insurancemaintenancetaxes}
                                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    onChange={e => this.props.changeOwnedProperty(e, 0, "insurancemaintenancetaxes")}
                                    size="large"
                                /> */}
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <Checkbox
                                    checked={
                                        this.props.application.assetsandliabilities.ownedproperties[0].propertytype === "twotofour"
                                    }
                                    className="formLabel"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            this.props.changeOwnedProperty("twotofour", 0, "propertytype")
                                        } else {
                                            this.props.changeOwnedProperty(null, 0, "propertytype")
                                        }
                                    }}
                                >
                                    This is a 2-4 unit property
                                </Checkbox>
                            </Col>
                        </Row>
                        {(
                            this.props.application.assetsandliabilities.ownedproperties[0].occupancy === "investment" ||
                            this.props.application.assetsandliabilities.ownedproperties[0].propertytype === "twotofour"
                        ) && <Row className="mb-4">
                                <Col md={8} xs={12}>
                                    <div className="formLabel">
                                        Expected Monthly Rental Income
                                    </div>
                                    <InputNumber
                                        prefix="$"
                                        className="w-100"
                                        defaultValue={this.props.application.assetsandliabilities.ownedproperties[0].netrentalincome}
                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange={e => this.props.changeOwnedProperty(String(e), 0, "netrentalincome")}
                                        size="large"
                                    />
                                </Col>
                            </Row>}


                    </div>
                }


                <Row className="mt-4"><Col md={6} xs={12}>
                    <div >

                        <Form.Label>
                            <span style={{ whiteSpace: "normal" }}>
                                How long have you lived at your current address?
                            </span>

                        </Form.Label>
                        {this.props.application[this.state.who].presentaddressyears !== "" &&
                            parseInt(
                                this.props.application[this.state.who].presentaddressyears
                            ) < 2 ?
                            <div className="addressMobile">
                                <Input required suffix="Years" size="large" controls={true}
                                    value={this.props.application[this.state.who].presentaddressyears}
                                    onChange={e => this.updateYears(e.target.value.replace(/[^0-9]/g, ''))}
                                />
                                <Input suffix="Months" size="large" style={{ marginLeft: 10 }}
                                    value={this.props.application[this.state.who].presentaddressmonths}
                                    onChange={e => this.updateMonths(e.target.value.replace(/[^0-9]/g, '') > 11 ? 11 : e.target.value.replace(/[^0-9]/g, ''))}
                                />
                            </div> : <div>
                                <Input required suffix="Years" size="large" controls={true}
                                    value={this.props.application[this.state.who].presentaddressyears}
                                    onChange={e => this.updateYears(e.target.value.replace(/[^0-9]/g, ''))}
                                />
                            </div>}

                    </div>
                </Col>
                </Row>
                {this.displayPreviousAddresses()}
            </div>
        )
    }
}
Residence = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Residence)