import { Button, Form, Input, Popconfirm, Table, Select } from "antd"
import { MinusCircleOutlined } from "@ant-design/icons"
import React, { useContext, useEffect, useRef, useState } from "react"
import * as act from "../Store/actions"
import * as com from "../Common.js"
import * as st from "../State.js"
import "../App.css"
import { connect } from "react-redux"
import { render } from "react-dom"

const mapStateToProps = (state) => {
    return {
        assetsandliabilities: state.application.assetsandliabilities,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({

    changeOwnedProperty: (t, i, v) => {
        dispatch(act.ChangeOwnedProperty(t, i, v))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb))
    },
})

const EditableContext = React.createContext(null)
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm()
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    )
}
const EditableCell = ({
    title,
    type,
    options,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef(null)
    const form = useContext(EditableContext)
    useEffect(() => {
        if (editing) {
            inputRef.current.focus()
        }
    }, [editing])
    const toggleEdit = () => {
        setEditing(!editing)
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        })
    }
    const save = async () => {
        try {
            const values = await form.validateFields()
            toggleEdit()
            handleSave({
                ...record,
                ...values,
            })
        } catch (errInfo) {
            console.log("Save failed:", errInfo)
        }
    }
    let childNode = children
    if (type === "operation") {
        return <td {...restProps}>{childNode}</td>
    }
    try {
        if (editing) {
            let ele = <Input ref={inputRef} onPressEnter={save} onBlur={save} style={{ height: 30 }} />
            if (type === "inputdollar") {
                ele = (
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} prefix="$" style={{ height: 30 }} />
                )
            } else if (type === "inputdollarmonth") {
                ele = (
                    <Input
                        style={{ height: 30 }}
                        ref={inputRef}
                        onPressEnter={save}
                        onBlur={save}
                        suffix="mo"
                        prefix="$"
                    />
                )
            } else if (type === "select") {
                ele = (
                    <Select
                        style={{ height: 30 }}
                        ref={inputRef}
                        onBlur={save}
                        onChange={save}
                        options={options}
                        size="small"
                    />
                )
            }
            childNode = (
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ]}
                >
                    {ele}
                </Form.Item>
            )
        } else {
            let text = record[dataIndex].toString().split(/(?=[A-Z][a-z])/).join(' ')
            if (type === "inputdollarmonth" || type === "inputdollar") {
                text = "$ " + com.commaize(text)
            }
            childNode = (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                    }}
                    onClick={toggleEdit}
                >
                    {text}
                </div>
            )
        }
        return <td {...restProps}>{childNode}</td>
    } catch (error) {
        console.log(error)
        return <td {...restProps}>No data</td>
    }

}


const PropertyMortgages = (props) => {
    const [dataSource, setDataSource] = useState(props.isSubjectProperty ? props.application.property.loans : props.application.assetsandliabilities.ownedproperties[props.i].loans)

    const handleDelete = (key) => {
        const newData = dataSource.filter((item) => item.key !== key)
        setDataSource(newData)
        sendData(newData)
    }
    const defaultColumns = [
        {
            title: "TYPE",
            width: "20%",
            dataIndex: "creditloantype",
            type: "select",
          
            options: [
                {
                    value: "ConventionalRealEstateMortgage",
                    label: "Conventional",
                },
                {
                    value: "FHARealEstateMortgage",
                    label: "FHA",
                },
                {
                    value: "VeteransAdministrationLoan",
                    label: "VA",
                },
                {
                    value: "Other",
                    label: "Other",
                },
            ],
           
        },
        {
            title: "CREDITOR NAME",
            dataIndex: "name",
            type: "input",
        },
        {
            title: "ACCOUNT NUMBER",
            dataIndex: "accountnumber",
            type: "input",
        },
        {
            title: "MONTHLY PAYMENT",
            dataIndex: "monthlypayment",
            type: "inputdollarmonth",
        },
        {
            title: "UNPAID BALANCE",
            dataIndex: "remainingbalance",
            type: "inputdollar",
        },
        {
            title: "DELETE",
            fixed: 'right',
            width: 80,
            dataIndex: "operation",
            type: "operation",
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => handleDelete(record.key)}
                    >
                        <MinusCircleOutlined style={{ fontSize: 16, color: "#325CEB", cursor: "pointer" }} />
                    </Popconfirm>
                ) : null,
        },
    ]
    const handleAdd = () => {
        let newData = new st.loan().toJson()
        newData = JSON.parse(newData)
        newData.creditloantype = "ConventionalRealEstateMortgage"
        newData.key = Math.random().toString()
        setDataSource([...dataSource, newData])
    }
    const handleSave = (row) => {
        const newData = [...dataSource]
        const index = newData.findIndex((item) => row.key === item.key)
        const item = newData[index]
        newData.splice(index, 1, {
            ...item,
            ...row,
        })
        setDataSource(newData)
        sendData(newData)
    }
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const columns = defaultColumns.map((col) => {
        return {
            ...col,
            ellipsis: true,
            onCell: (record) => ({
                record,
                type: col.type,
                options: col.options,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        }
    })
    const sendData = (newData) => {
        if (props.isSubjectProperty) {
            props.changeMainProperty(newData, "loans")
        } else {
            props.changeOwnedProperty(newData, props.i, "loans")
        }
    }
    return (
        <div className="incomeSourcesTable">
            {dataSource.length > 0 && (
                <Table
                    components={components}
                    rowClassName={() => "editable-row"}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 1000 }}
                />
            )}
            <Button onClick={handleAdd} type="form1003AddRow" >
                <img
                    src="/images/bluePlusInCircleMark.png"
                    style={{ width: 15, height: 15 }}
                    className="mr-2"
                />
                Add mortgage loans on this property
            </Button>
        </div>
    )
}
export const OwnedPropertyMortgagesTable = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(PropertyMortgages);


