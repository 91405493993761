import Navbar from 'react-bootstrap/Navbar'
import { useSelector, useDispatch } from 'react-redux'
import { Dropdown } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { CheckSignInMethod, checkCustomerInfo } from '../api/api'
import { useEffect, useState } from 'react'
import * as com from '../../Common'
function TopNavBar(props) {
    const isMobile = window.innerWidth < 768
    const [method, setMethod] = useState('')
    const [companyName, setCompanyName] = useState('')
    const subscription = useSelector(state => {
        return state.application
    })

    useEffect(async () => {
        try {
            const { Method } = await CheckSignInMethod({
                CustomerID: sessionStorage.getItem('cid'),
                Email: subscription.borrower.email
                ,

            })
            setMethod(Method)
            const { CompanyName } = await checkCustomerInfo({
                "X-CustomerId": com.getCustomerId()

            })
            setCompanyName(CompanyName)
            sessionStorage.setItem('CompanyName',CompanyName)
        } catch (error) {

        }
    }, [])
    const items = [
        {
            label: (
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='NameBar'>{subscription.borrower.firstname.charAt(0)}</div>
                    <div className='ml-2'>
                        <div style={{
                            fontSize: 16,
                            fontWeight: 500,
                        }}>{subscription.borrower.firstname}    {subscription.borrower.lastname}</div>
                        <div style={{
                            color: "#6E6E70",
                            fontSize: 14,
                            fontWeight: 400
                        }}>{subscription.borrower.email}</div>
                    </div>
                </div>

            ),
            key: '0',
        },
        {
            type: 'divider',
        },
        {
            label: (
                <Link
                    to={method === 'email' ? '/application/password' : '/application/changepassword'}

                    className='DropdownP'>

                    {
                        method === 'email' ? 'Set up password' : "Change password"
                    }
                </Link>
            ),
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: <Link to='/application/logout' className='DropdownP'>
                Sign out
            </Link>,
            key: '3',

        },
    ]
    return (
        <header className="navbar-fixed-top cbp-af-header" style={{borderBottom:"1px solid #e5e5e5"}}>
            <Navbar expand="lg" variant="prequalNav">
                <div className={isMobile?'d-flex justify-content-between w-100 h-100 align-items-center':'d-flex justify-content-between pl-5 pr-5 w-100 h-100 align-items-center'}>
                    {
                        props.children
                    }
                    <div style={{
                        fontSize: 30, fontWeight: 500
                    }}>
                        {
                            companyName

                        }
                        {/* <img src='/logo3.svg'></img> */}
                    </div>
                    <Dropdown menu={{ items }}>
                        <div className='NameBar'>{subscription.borrower.firstname.charAt(0)}</div>
                    </Dropdown>
                </div>

            </Navbar>

        </header>
    )
}

export default TopNavBar