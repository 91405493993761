import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import NumericalInput from "../NumericalInput"
import { Link } from 'react-router-dom'
import * as com from "../Common.js"
import { connect } from 'react-redux'
import * as act from "../Store/actions"
import { Tips } from '../Common/Tooltip'
import { Alert, Button as But } from "antd"
import { getCurrentState, updateState } from "../Store"
import { UpdateChangeProgressType } from "../Store/progress"
import * as prgr from "../Store/progress"


import {
    getFees, getDTI, getCitizenship, getOccupancy, getPropertyType,
    productReload
} from './ProductPicker'
import { Spinner } from "react-bootstrap"
const mapStateToProps = (state) => {
    return {
        hascoborrower: state.application.hascoborrower,
        cohabiting: state.application.cohabiting,
        application: state.application,
        property: state.application.property,
        selection: state.application.selection,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChangeApplication: (application) => {
        dispatch(act.updateApplication(application))
    },
    updateChangeProgressType: (step, stage) => {
        dispatch(UpdateChangeProgressType(step, stage))
    },
    updateChangeProgress: (tab, index) => {
        dispatch(prgr.UpdateChangeProgress(tab, index))
    },
    updateChangeProgressType: (tab) => {
        dispatch(prgr.UpdateChangeProgressType(tab))
    },
    updateChangeVisitedStage: (step, stage) => {
        dispatch(prgr.UpdateChangeVisitedStage(step, stage))
    },
    changeMainPropertyPurpose: (val) => {
        dispatch(act.ChangeMainPropertyPurpose(val))
    },
    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb))
    },
    changeBorrowerInfo: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.value, who, verb))
    },
    changeBorrowerInfoCheck: (event, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(event.target.checked, who, verb))
    },
    changePhoneInfo: (input, who, verb) => {
        dispatch(act.ChangeBorrowerInfo(input, who, verb))
    },

    updatePreviousAddress: (event, who, verb, n) => {
        dispatch(act.UpdatePreviousAddress(event.target.value, who, verb, n))
    },
    addPreviousAddress: (event, who) => {
        dispatch(act.AddPreviousAddress(event.target.value, who,))
    },
    removePreviousAddress: (event, who, n) => {
        dispatch(act.RemovePreviousAddress(event.target.value, who, n))
    },
    clearPreviousAddress: (event, who) => {
        dispatch(act.ClearPreviousAddress(event.target.value, who))
    },
    changeMainProperty: (value, verb) => {
        dispatch(act.ChangeMainProperty(value, verb))
    },
    changeMainPropertyUnits: (event) => {
        dispatch(act.ChangeMainPropertyUnits(event.target.value))
    },
    changeMainPropertyYearBuilt: (event) => {
        dispatch(act.ChangeMainPropertyYearBuilt(event.target.value))
    },
    changeMainPropertyRefinanceYearAcquired: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceYearAcquired(event))
    },
    changeMainPropertyRefinanceOriginalCost: (event) => {
        dispatch(act.ChangeMainPropertyRefinanceOriginalCost(event))
    },
    updateApplicationAttribute: (input, verb) => {
        dispatch(act.UpdateApplicationAttribute(input, verb))
    },
    updateSelectField: (input, verb) => {
        dispatch(act.UpdateSelectField(input, verb))
    },
})


class PrequalLetter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            loadingPdf: false,
            purpose: "Purchase", // propagated
            loansize: 0.8 * com.safeParseInt(this.props.property.salesprice),
            show: false,
            download: "",
            url: "",
            showContactModal: false,
            showCreateNewModal: false,
            loadingCreateNew: false,
        }
        this.aref = React.createRef()

        this.Loan25 = null
        this.Loan20 = null
        this.Loan10 = null
        // this.fhaLoan = null
        this.count = 0
        this.customerid = com.getCustomerId()
    }
    componentDidMount() {
        this.props.changeContinueButtonText("My rates")
        this.getFees()
    }
    getFees = getFees.bind(this)
    getDTI = getDTI.bind(this)
    getCitizenship = getCitizenship.bind(this)
    getOccupancy = getOccupancy.bind(this)
    getPropertyType = getPropertyType.bind(this)
    productReload = productReload.bind(this)

    getLTV = () => {
        let loansize = this.state.loansize
        let ltv = com.safeParseInt(loansize) * 100 / com.safeParseInt(this.props.property.salesprice)
        let cltv = (com.safeParseInt(loansize) + com.safeParseInt(this.cltv)) * 100 / com.safeParseInt(this.props.property.salesprice)
        let hcltv = (com.safeParseInt(loansize) + com.safeParseInt(this.hcltv)) * 100 / com.safeParseInt(this.props.property.salesprice)
        return [ltv, cltv, hcltv]
    }

    askedForAssistance = () => false
    failedProduct = () => {

        switch (this.count) {
            case 0: {
                this.count = 1
                let newloan = parseInt(0.90 * com.safeParseInt(this.props.property.salesprice))
                this.setState({
                    loading: true,
                    loansize: newloan,
                }, () => {
                    this.getFees()
                })
                break
            }
            case 1: {
                this.count = 2
                let newloan = parseInt(0.965 * com.safeParseInt(this.props.property.salesprice))
                this.setState({
                    loading: true,
                    loansize: newloan,
                }, () => {
                    this.getFees()
                })
                break
            }
            case 2: {
                this.setState({ loading: false })
                this.count = 3
                let newloan = parseInt(0.75 * com.safeParseInt(this.props.property.salesprice))
                this.setState({
                    loading: true,
                    loansize: newloan,
                }, () => {
                    this.getFees()
                })
                break
            }
            default:
                break
        }
    }
    renderProduct = (pr, ltv) => {
        let getType = (card) => {
            if (card.Arm === null || card.Arm === 0)
                return "Fixed rate"
            return "ARM " + card.Arm.fixedperiod + "/6"
        }

        let getPrequal = () => {
            let token = com.getUserToken()
            let disposition
            let loansize = parseInt(com.safeParseInt(this.props.property.salesprice) * ltv)
            let downpayment = parseInt(com.safeParseInt(this.props.property.salesprice) - loansize)
            this.props.updateSelectField(downpayment, "downpayment")
            this.props.updateSelectField(this.state.loansize, "loansize")

            let data = {
                price: this.props.property.salesprice.toString(),
                loan: (loansize).toString(),
                loanType: `${pr.Term} year ${getType(pr)}`,
                isFHA: pr.FHAMIP != null
            }
            this.setState({ loadingPdf: true })
            fetch('/borrower/getprequal', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache",
                    "X-Borrower": this.props.borrowerid
                },
                body: JSON.stringify(data),
            }).then(response => {
                disposition = response.headers.get("Content-Disposition").split('=')

                return response.body
            })
                .then(body => {
                    const reader = body.getReader()

                    return new ReadableStream({
                        start(controller) {
                            return pump()

                            function pump() {
                                return reader.read().then(({ done, value }) => {
                                    // When no more data needs to be consumed, close the stream
                                    if (done) {
                                        controller.close()
                                        return
                                    }

                                    // Enqueue the next data chunk into our target stream
                                    controller.enqueue(value)
                                    return pump()
                                })
                            }
                        }
                    })
                })
                .then(stream => new Response(stream))
                .then(r => r.blob())
                .then(blob => {


                    let download = disposition.slice(1).join().replace(/"/g, '')

                    const url = window.URL.createObjectURL(blob)
                    this.setState({ url: url, download: download })

                    this.aref.current.click()
                    this.forceUpdate()
                    this.setState({ loadingPdf: false })

                })
                .catch((err) => {
                    console.log(err)
                    alert("Sorry, something went wrong. Please try again later.")
                    this.setState({ loadingPdf: false })

                })

        }

        const getDownloadButton = () => {
            if (this.state.loadingPdf) return <Button variant="link"> <Spinner animation="border" size="sm" /></Button>
            if (this.customerid === "gmcc") return <Button onClick={() => this.contactLO()}>Contact my loan officer to obtain a pre-qualification letter</Button>

            return <Button variant="link" onClick={getPrequal}> <img src="/images/download.png" style={{ width: "21px", height: "20px", marginRight: "10px" }} />Download</Button>
        }

        return (
            <div className="mb-4 text-left prequalCard">
                {/* <Row>
                    <Col xs={6}>Name:</Col><Col xs={6} className="text-right">{pr.Name}</Col>
                </Row> */}
                <div className="pt-1 d-flex justify-content-between">
                    <div>Term, Type:</div ><div className="text-right">{pr.Term} years, {getType(pr)}</div>
                </div>
                <div className="pt-1 d-flex justify-content-between">
                    <div>Estimated Purchase Price:</div><div className="text-right">${com.commaizeFloat(this.props.property.salesprice)}</div>
                </div>
                <div className="pt-1 d-flex justify-content-between">
                    <div>Loan amount:</div><div className="text-right">Up to ${com.commaizeFloat(com.safeParseInt(this.props.property.salesprice) * ltv)}</div>
                </div>
                <div className="pt-1 d-flex justify-content-between">
                    <div>Downpayment:</div><div className="text-right">${com.commaizeFloat(Math.round((1 - ltv) * com.safeParseInt(this.props.property.salesprice)))} / {(100 * (1 - ltv)).toFixed(1)}%</div>
                </div>
                {/* <Row className="m-1" style={{backgroundColor:"white", padding:"15px 20px 15px 20px"}}>
                    <Col md={3} xs={6}>
                        <div>Rates:</div>
                        <div>{pr.base_rate.toFixed(3)}%</div>
                    </Col>
                    <Col md={3} xs={6}>
                        <div>APR:</div>
                        <div>{pr.APR}%</div>
                    </Col>
                    <Col md={3} xs={6} className="fixCol">
                        <div>Monthly payment:</div>
                        <div>${com.commaizeFloat(pr.monthly_payment)}</div>
                    </Col>
                    <Col md={3} xs={6} className="fixCol">
                        <div>Est. closing costs:</div>
                        <div>${com.commaizeFloat(pr.closing_cost > 0 ? com.safeParseInt(pr.closing_cost / 100) * 100 : 0)}</div>
                    </Col>
                </Row> */}


                {/* {pr.PMICost === 0 ? "" :
                    <div>
                        <Row>
                            <Col xs="auto">Private Mortgage Insurance:</Col><Col>${com.commaizeFloat(pr.PMICost)}/mo</Col>
                        </Row>
                    </div>
                } */}
                <Row><Col className="pt-2 pl-0">{getDownloadButton()}</Col></Row>
            </div>
        )
    }
    renderPrequal = () => {

        if (this.Loan20 == null && this.Loan10 == null && this.Loan25 == null && !this.state.loading)
            return (
                <div className="viewport text-left">
                    We could not find loan products for you. Try reducing the target property price.
                </div>
            )

        let out = []
        if (this.Loan25 != null)
            out.push(this.renderProduct(this.Loan25, 0.75))
        if (this.Loan20 != null)
            out.push(this.renderProduct(this.Loan20, 0.8))
        if (this.Loan10 != null)
            out.push(this.renderProduct(this.Loan10, 0.9))
        // if (this.fhaLoan != null)
        //     out.push(this.renderProduct(this.fhaLoan, 0.965))

        return out
    }
    cullRate = products => {
        if (products.length === 0)
            return products

        let newproducts = []
        newproducts.push(products[0])
        let base_rate = products[0].base_rate

        for (let i = 1; i < products.length; i++) {
            if (products[i].base_rate !== base_rate) {
                newproducts.push(products[i])
                base_rate = products[i].base_rate
            } else {

            }
        }
        return newproducts
    }
    processProducts = (products) => {
        this.setState(
            { loading: true }
        )
        let rearranged = []
        products.forEach(product => {
            let lender = product.Lender

            product.ProductRatesCosts.forEach(pr => {
                let commonproduct = pr.product
                let ausengine = pr.AUSEngine
                if (!this.props.application.property.propertytype === "mobilehome") {
                    if (commonproduct.Term !== 30)
                        return
                }

                pr.rateCosts.forEach(rate => {

                    let typ = commonproduct.Arm
                    if (typ === null || typeof typ === "undefined")
                        typ = 0
                    else
                        typ = typ.fixedperiod
                    // filter out non-30 day locks
                    if (rate.lockDay !== 30)
                        return

                    let pdkt = {
                        Lender: lender,
                        ...commonproduct,
                        Aus: ausengine,
                        ...rate
                    }
                    rearranged.push(pdkt)
                })
            })
        })
        rearranged.sort(this.compareRate)

        rearranged = this.cullRate(rearranged)
        // let getBest = (rearranged) => {
        //     let mn = 100000000
        //     let loan = rearranged.pop()

        //     while (rearranged.length > 1) {
        //         let ln = rearranged.pop()
        //         if (ln.closing_cost < 0)
        //             continue
        //         let lrate = Math.log(ln.base_rate)
        //         let lcost = Math.log(ln.closing_cost)
        //         let bal = 5 * lrate + lcost

        //         if (bal < mn) {
        //             console.log("new min")
        //             mn = bal
        //             loan = ln
        //         }
        //     }
        //     return loan
        // }
        let getBest = (rearranged) => {
            let minAPR = 99.9
            let maxClosingCost = 10000
            let loan = null
            for (let ln of rearranged) {
                if (ln.closing_cost < 0)
                    continue
                if (ln.closing_cost < maxClosingCost && ln.APR < minAPR) {
                    minAPR = ln.APR
                    loan = ln
                }
            }

            if (loan !== null) {
                return loan
            }

            for (let ln of rearranged) {
                if (ln.closing_cost > 0) {
                    return ln
                }
            }
            return rearranged[0]
        }

        switch (this.count) {
            case 0: {
                this.Loan20 = getBest(rearranged)
                let downpayment = Math.ceil(com.safeParseInt(this.props.property.salesprice) - this.state.loansize)
                this.props.updateSelectField(downpayment, "downpayment")
                this.props.updateSelectField(this.state.loansize, "loansize")
                this.count = 1
                let newloan = parseInt(0.90 * com.safeParseInt(this.props.property.salesprice))
                this.setState({
                    loading: true,
                    loansize: newloan,
                }, () => {
                    this.getFees()
                })
                break
            }
            case 1: {
                this.Loan10 = getBest(rearranged)
                this.count = 2
                let downpayment = Math.ceil(com.safeParseInt(this.props.property.salesprice) - this.state.loansize)
                this.props.updateSelectField(downpayment, "downpayment")
                this.props.updateSelectField(this.state.loansize, "loansize")
                let newloan = parseInt(0.75 * com.safeParseInt(this.props.property.salesprice))
                this.setState({
                    loading: true,
                    loansize: newloan,
                }, () => {
                    this.getFees()
                })
                break
            }
            case 2: {
                this.Loan25 = getBest(rearranged)
                let downpayment = Math.ceil(com.safeParseInt(this.props.property.salesprice) - this.state.loansize)
                this.props.updateSelectField(downpayment, "downpayment")
                this.props.updateSelectField(this.state.loansize, "loansize")
                this.setState({ loading: false })
                break
            }
            // case 3: {                
            //     this.Loan25 = getBest(rearranged)
            //     let downpayment = Math.ceil(com.safeParseInt(this.props.property.salesprice) - this.state.loansize)
            //     this.props.updateSelectField( downpayment, "downpayment")
            //     this.props.updateSelectField( this.state.loansize, "loansize")
            //     this.setState({ loading: false })
            //     break
            // }            
            default:
                break
        }
    }

    compareRate = (a, b) => {
        if (a.base_rate !== b.base_rate)
            return b.base_rate - a.base_rate
        return b.closing_cost - a.closing_cost // take into account remaining credits
    }

    handleSubmit = (e) => {


        if (this.state.loading === true) {
            e.preventDefault()
            e.stopPropagation()
            return false
        }
        return true
    }
    handleClose = () => {
        this.setState({ show: false, loansize: 0.8 * com.safeParseInt(this.props.property.salesprice) },
            () => {
                this.count = 0
                this.Loan25 = null
                this.Loan20 = null
                this.Loan10 = null
                // this.fhaLoan = null
                this.getFees()
            }
        )
    }
    contactLO = () => {
        this.setState({ showContactModal: true })
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/borrower/contactloaboutprequalletter', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-Borrower": this.props.borrowerid,
                "ErrorCode": this.state.errorCode,
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log(response)
                    return
                }
                return
            }
        ).catch((err) => {
            console.log('Fetch Error :', err)
        })

    }
    newApplication() {
        let token = sessionStorage.getItem("ZeitroA")
        let js = { LoanID: "" }
        fetch('/auth/createnewapplication', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
            body: JSON.stringify(js)
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    alert("Looks like there was a problem, please contact your loan officer for help.")
                    window.document.dispatchEvent(new Event('reauthenticate'), "")
                    return
                }
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        alert("Looks like there was a problem, please contact your loan officer for help.")
                    } else {
                        let state = JSON.parse(js.State)

                        state.progress.stage = "interview"
                        state.progress.interviewfinished = false
                        state.progress.interviewstarted = false
                        state.progress.productselectionstarted = false
                        state.progress.interview.step = 0
                        state.progress.interview.max = 0
                        state.application.status = 'interviewstarted'
                        state.application.selection.selectedloan = null
                        let jss = JSON.stringify(state)
                        // sessionStorage.setItem("state", jss);


                        this.props.updateChangeApplication(state.application)
                        this.props.updateChangeVisitedStage("interviewstarted")
                        this.props.updateChangeProgress(0, "max")
                        this.props.updateChangeProgress(0, "interview")
                        this.props.changeMainPropertyPurpose("purchase")
                        this.props.changeMainPropertyAttribute(true, "hascontract")

                        getCurrentState()

                        this.setState({ showCreateNewModal: false })
                        window.location.href = "/application/interview/main/0"
                    }

                })
            }
        ).catch((err) => {
            console.log('Fetch Error :', err)
        })
    }

    displayNext() {

        if (this.props.application.property.purpose === "purchase" && this.props.application.property.hascontract === false) {
            return <div className="mt-3">
                <div className="headline-small">Next step</div>
                <div className="d-flex p-4 mt-2 with-border">
                    <div>
                        <div className="fw-500">Let us know the address of the property you are going to purchase</div>
                        <div className="title-5">Finalize your mortgage application by entering the address of the property you wish to purchase. This step is essential for calculating your rate and moving forward.</div>
                    </div>
                    <Link to="/application/interview/main/property"><div className="ml-2"><But type="primary" className="text-nowrap">Add property detail</But></div></Link>
                </div>
            </div>
        }
        return <div></div>
        //     return <div className="mt-3">
        //     <div className="headline-small">Next step</div>
        //     <div className="p-4 mt-2 with-border">
        //         <div>
        //             <div className="fw-500">Secure your rates</div>
        //             <div className="title-5">It looks like your loan officer is reviewing your application and you will be notified by email when this page is updated with your rate.</div>
        //         </div>
        //     </div>
        // </div>
    }

    render = () => {
        let qualifyForLess = () => {
            return <Button variant="link" onClick={e => this.setState({ show: true })} style={{ color: "#325CEB" }}>Click here to try qualifying for a more affordable property</Button>
        }
        let onPriceChange = (e) => {

            this.props.changeMainProperty(e.target.value, "salesprice")
            this.props.changeMainProperty(e.target.value, "appraisal")

        }
        let errorMessage = () => {
            let msg = "Sorry, we're unable to generate the pre-qualification letter using the information provided. Please contact your loan officer to see your options."
            let desc = ""
            switch (this.state.errorCode) {
                default:
                    msg = "Sorry, we're unable to generate the pre-qualification letter using the information provided. Please contact your loan officer to see your options."
                    break
                case "0":
                    msg = "Sorry, we are unable to generate the pre-qualification letter based on the FICO score you provided. Please contact your loan officer to see your options."
                    break
                case "1":
                    msg = "Sorry, we are unable to generate the pre-qualification letter based on the FICO score you provided. Please contact your loan officer to see your options."
                    break
                case "2":
                    msg = "Sorry, we are unable to generate the pre-qualification letter based on the income level you provided. Please contact your loan officer to see your options."
                    desc = <div><div>You may consider increasing you income, or </div>{qualifyForLess()}</div>
                    break
                case "3":
                    msg = <div>
                        <div>Sorry, we're unable to generate the pre-qualification letter because your LTV ratio is too high. To see other options, you can try:"</div>
                        <li>Decrease your property price</li>
                        <li>Increase your down payment</li>
                        <li>Contact your loan officer</li>
                    </div>
                    desc = qualifyForLess()
                    break
                case "6":
                    msg = "Sorry, we're unable to generate the pre-qualification letter using the information provided. Please contact your loan officer to see your options."
                    break

            }
            let contact = <div style={{ color: "#325CEB", textDecoration: "underline", fontSize: "16px", marginTop: "10px", cursor: 'pointer' }} onClick={() => this.contactLO()}>Contact my loan officer to discuss my options.</div>
            return <div>
                <div className="mt-4"><Alert message={msg} type="warning" description={desc} showIcon style={{ fontSize: 16 }} /></div>
                <div>{contact}</div>
            </div>
        }
        let hasResult = this.Loan10 != null || this.Loan20 != null || this.Loan25 != null
        return (
            <div className="mb-4  text-left text-wrap" >
                {
                    !hasResult ? <div className="prequalInterviewTitle">Let's see if you qualify for a loan for a property valued up to ${com.commaizeFloat(this.props.property.salesprice)}...</div> : ""
                }
                <div className="preQualLetter">
                    <Modal className="employed" style={{top:200}}  show={this.state.showContactModal} onHide={() => this.setState({ showContactModal: false })} 
                        
                        >
                        <Modal.Header closeButton>
                            <Modal.Title>We've notified your loan officer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Your loan officer will contact you soon.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setState({ showContactModal: false })}>OK</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={this.state.showCreateNewModal} style={{top:200}} className="employed" onHide={() => this.setState({ showCreateNewModal: false })} >
                        <Modal.Header closeButton>
                            <Modal.Title>Are you sure you want to get approved? </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            If you have already signed a purchase agreement and would like to start a new application for a purchase loan, please click on the "Get approved" button below.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="zeitro-primary" onClick={() => this.newApplication()}>Get approved</Button>
                            <Button variant="secondary" onClick={() => this.setState({ showCreateNewModal: false })}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal style={{top:200}} className="employed" show={this.state.show} onHide={this.handleClose} >
                        <Modal.Header closeButton>
                            <Modal.Title>Enter the new target property price</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <NumericalInput
                                noarrow={true}
                                type="text"
                                name="description"
                                defaultValue={parseInt(this.props.property.salesprice)}
                                step={1000}
                                onChange={onPriceChange}
                                pattern="^([0-9]]+)$"

                            />


                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <a hidden ref={this.aref} href={this.state.url} download={this.state.download}>&nbsp;</a>

                <div className="text-center ">
                    {this.Loan10 != null || this.Loan20 != null || this.Loan25 != null ?
                        <div className="mb-2">
                            <div><img src="/images/congratulation.png" style={{ width: "87px", height: "106px" }} /></div>
                            <div className="prequalBigText pt-2">Great job!</div>
                            <div className="prequalBigText">You are qualified for a loan up to ${com.commaizeFloat(this.props.property.salesprice)}</div>
                            <div className="p-2 pl-5 pr-5 prequalInterviewDisclaimer">Here are just <b>some</b> of the loan products that you <b>qualify for</b>. These products are just <b>examples for the purpose of generating a prequalification letter</b>, there is no commitment on your part, and you will be able to explore rates that fit your needs in the next page<Tips
                                content={<div>
                                    This conditional prequalification is issued based on your current credit history and information that you have
                                    provided about income, assets, and debt. This conditional prequalification is not a commitment to lend, and
                                    any final approval is subject to further conditions per credit policy, including, but not
                                    limited to:<br /><ul>
                                        <li> Submission of a completed residential mortgage loan application;
                                        </li><li> Verification of the information that you have provided;
                                        </li><li> Confirmation that there has been no change in your financial condition;
                                        </li><li> Submission of a valid sales contract on a specific property;
                                        </li><li> Receipt of a satisfactory appraisal on such property; and
                                        </li><li> Confirmation that you have not been declared bankrupt or had a property foreclosed upon in the
                                            last 7 years.</li>
                                    </ul></div>}
                                placement="bottom"
                                title=""
                            /></div>

                            <Row><Col>
                                {this.renderPrequal()}</Col></Row>


                        </div>

                        : ""}
                    <Row><Col className="text-center">
                        {this.state.loading ? <Spinner animation="border" size="lg" className="my-5" /> : ""}
                    </Col></Row>
                    {this.Loan10 != null || this.Loan20 != null || this.Loan25 != null ?
                        <div>
                            <Row>
                                <Col>
                                    <div className="sectionquestion">What to do next?</div>
                                    <div className="mt-2">
                                        If you would like to receive a fully underwritten pre-approval, click the "Get Approved" button below and complete the application in full.
                                    </div>
                                    <div className="mt-2">
                                        Otherwise, come back and start a loan application when you have a purchase contract!
                                    </div>
                                    <div className="pl-0"><Button className=" pl-0" variant="link" onClick={e => this.setState({ show: true })}>Click here to change the property price</Button></div>
                                    <div className="mt-2">

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="zeitro-primary" onClick={() => this.setState({ showCreateNewModal: true })}>
                                        Get approved
                                    </Button>
                                </Col>
                            </Row>
                        </div> :
                        this.state.loading ? "" : <div>
                            <div></div>
                            <div className="">{errorMessage()}</div>
                        </div>
                    }
                </div>
                {this.displayNext()}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PrequalLetter)
