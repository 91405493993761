import React, {useEffect} from "react";
import "./App.css";

import LangingPage from "./LandingPage";
import LandingAbout from "./Landing/LandingAbout";
import HardMoneyLoanCalculator from "./Calculator/HardMoneyLoanCalculator/LoanRate";
import Error404 from "./Error404";
import PreApp, {PreAppEmailVerification, PreAppPersonalInfo} from "./PreApp/PreApp";
import AuthenticatedApplication from "./AuthenticatedApplication";
import LoginPage from "./LoginPage";
import Login from "./Login";
import LogoutPage from "./LogoutPage";
import ForgotPassword from "./ForgotPassword";
import ResourcePage from "./ResourcePage";
import WaitlistPage from "./WaitlistPage";
import RecoverPassword from "./RecoverPassword";
import SendRevalidate from "./SendRevalidate";
import Invitation from "./Invitation";
import CoborrowerCreated from "./CoborrowerCreated";
import CoborrowerExists from "./CoborrowerExists";
import ActionRequest from "./ActionRequest";
import Validate from "./Validate";
import Reset from "./Reset";
import HistoricalChart from "./HistoricalChart/HistoricalChart";
import SampleRates from "./SampleRates/SampleRates";
import MortgageCalculator from "./Calculator/MortgageCalculator";
import Affordability from "./Affordability/Affordability";
import PurchaseCalc from "./PurchaseCalc/PurchaseCalc";
import Refinance from "./Affordability/Refinance";
import {insertCssColors, getCustomerId} from "./Common";
import BorrowerInterviewForLO from "./BorrowerInterviewForLO";
import CoborrowerSignature from "./Application/HardMoneyLoan/CoborrowerSignature";
import NonQMBorrowerQualifier from "./NonQM/NonQMBorrowerQualifier/NonQMBorrowerQualifier";



import {ThemeProvider} from "styled-components";
import theme from "./config/theme";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Helmet from "react-helmet";

//Google Analytics
import usePageTracking from "./usePageTracking";
import AgentBorrowerSignUpPage from "./AgentBorrowerSignUpPage";
import AffordabilityPage from "./Affordability/AffordabilityPage";
import PurchaseCalcPage from "./PurchaseCalc/PurchaseCalcPage";
import CalHFA from "./CalHFA/CalHFA";
import GoogainCalculator from "./GoogainCalculator/GoogainCalculator";

let stylesNotApplied = true;

if (stylesNotApplied) {
  let searchparams = new URLSearchParams(window.location.search);
  let customerid = searchparams.get("customerid");
  if (!customerid) {
    customerid = getCustomerId();
  }
  insertCssColors(customerid);
  stylesNotApplied = false;
}

// let NotFoundRoute = BrowserRouter.NotFoundRoute;

const TrackingApp = () => {
  usePageTracking();
  return (
    <div className="App py-0 my-0 h-100">
      <Helmet>
        <title></title>
      </Helmet>

      <Switch>
        <Route
          exact
          path="/">
          <LangingPage />
        </Route>
        <Route
          exact
          path="/resources/calculator/:customerid?">
          <MortgageCalculator />
        </Route>
        <Route
          exact
          path="/resources/calhfa/:customerid?">
          <Helmet>
            <title> California Dream For All Program </title>
            <meta
              name="description"
              content="Zeitro - Digital Mortgage System"
            />
          </Helmet>
          <CalHFA />
        </Route>
        <Route
          exact
          path="/services/coborrowersignature/:customerid?">
          <CoborrowerSignature />
        </Route>
        <Route
          exact
          path="/resources/calcgoogain/:customerid?">
          <GoogainCalculator />
        </Route>
        <Route
          exact
          path="/incentivefinder">
          <GoogainCalculator />
        </Route>
        <Route
          exact
          path="/resources/hardmoneyloancalculator/:customerid?">
          <Helmet>
            <title> Hard Money Loan Calculator </title>
          </Helmet>
          <HardMoneyLoanCalculator />
        </Route>
        <Route
          exact
          path="/resources/nonqmcalculator/:customerid?">
          <Helmet>
            <title> Non-QM Loan Calculator </title>
          </Helmet>
          <NonQMBorrowerQualifier />
        </Route>

        <Route
          exact
          path="/resources/affordability/:customerid?">
          <Affordability />
        </Route>
        <Route
          exact
          path="/resources/purchase/:customerid?/:loid?">
          <PurchaseCalc />
        </Route>
        <Route
          exact
          path="/resources/ratequoterwithoutsignin/:customerid?/:loid?">
          <PurchaseCalc forDisplayOnly={true} />
        </Route>
        <Route
          exact
          path="/resources/refinance/:customerid?">
          <Refinance />
        </Route>
        <Route
          exact
          path="/resources/historicalchart/:customerid?">
          <HistoricalChart />
        </Route>
        <Route
          exact
          path="/resources/todayrates/:customerid?">
          <SampleRates />
        </Route>
        <Route
          exact
          path="/resources/about">
          <LandingAbout />
        </Route>
        <Route
          exact
          path="/resources/waitlist">
          <WaitlistPage />
        </Route>
        <Route
          exact
          path="/services/signin/:where?">
          <LoginPage
            signup={false}
            view="signin"
          />
        </Route>
        <Route path="/services/borrowerinterviewforlo/:customerid?/:loanid?">
          <BorrowerInterviewForLO />
        </Route>
        <Route
          exact
          path="/services/signup">
          <LoginPage
            signup={false}
            view="signup"
          />
        </Route>
        <Route
          exact
          path="/services/forgot-password">
          <ForgotPassword />
        </Route>
        <Route
          exact
          path="/services/forgot-password-bare">
          <ForgotPassword view="bare" />
        </Route>
        <Route
          exact
          path="/services/revalidate">
          <SendRevalidate />
        </Route>
        <Route
          exact
          path="/services/resetting">
          <RecoverPassword />
        </Route>
        <Route
          exact
          path="/services/resetting-bare">
          <RecoverPassword view="bare" />
        </Route>
        <Route
          exact
          path="/services/logout">
          <LogoutPage />
        </Route>
        <Route
          exact
          path="/services/barelogin">
          <Login view="bare" />
        </Route>
        <Route
          exact
          path="/services/baresignup">
          <Login view="baresignup" />
        </Route>
        <Route
          exact
          path="/services/agentborrowersignup">
          <AgentBorrowerSignUpPage view="baresignup" />
        </Route>
        <Route
          exact
          path="/services/agentborrowersignuppage">
          <AgentBorrowerSignUpPage
            view="baresignup"
            inLinkPage={true}
          />
        </Route>
        <Route
          exact
          path="/services/established">
          <CoborrowerCreated />
        </Route>
        <Route
          exact
          path="/services/coborrowerexists/:token/:names/:email">
          <CoborrowerExists />
        </Route>
        <Route path="/services/invitedcoborrower/:token/:names/:email">
          <Invitation />
        </Route>
        <Route path="/resources">
          <ResourcePage />
        </Route>
        <Route
          exact
          path="/services/preapp">
          <PreApp />
        </Route>

        <Route
          exact
          path="/services/preapp/emailverification">
          <PreAppEmailVerification />
        </Route>
        <Route path="/services/affordability">
          <AffordabilityPage />
        </Route>
        <Route path="/services/ratequote">
          <PurchaseCalcPage />
        </Route>
        <Route path="/application">
          <AuthenticatedApplication />
        </Route>

        <Route path="/validated/reset">
          <Reset />
        </Route>
        <Route path="/validated/:hex/:schema">
          <Validate />
        </Route>
        <Route path="/d/:hex/:schema">
          <ActionRequest />
        </Route>
        <Route status={404}>
          <Error404 />
        </Route>
      </Switch>
    </div>
  );
};

function App() {
  /*
    let path = window.location.href
    if (path.indexOf('/app/') >= 0) {
      document.body.style.marginBottom = "0px";
    } else {
      document.body.style.marginBottom = "209px";
    }
    */

  return (
    //GA tracking needs to be wrapped by BrowserRouter; the reverse cannot be true, so BrowserRouter is removed from AuthenticatedApp.js
    <ThemeProvider theme={theme}>
      <Router>
        <TrackingApp />
      </Router>
    </ThemeProvider>
  );
}

export default App;
