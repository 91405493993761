
import * as com from "./Common.js"

export const TASK_STATUS_UPDATE_EVENT = 'task-status-update';

export function dispatchTaskStatusUpdate(taskData) {
    const event = new CustomEvent(TASK_STATUS_UPDATE_EVENT, {
      detail: taskData 
    });
    document.dispatchEvent(event);
  }

export function addTaskStatusUpdateListener(handler) {
  document.addEventListener(TASK_STATUS_UPDATE_EVENT, handler);
}

export function removeTaskStatusUpdateListener(handler) {
  document.removeEventListener(TASK_STATUS_UPDATE_EVENT, handler);
}

export function updateTaskStatus() {
    const mergeTasks = (list) => {
      let updatedTaskList = []
      let tasksByType = {}
      list.forEach(task => {
          if (!(task.TaskType in tasksByType)) {
              tasksByType[task.TaskType] = []
          }
          tasksByType[task.TaskType].push(task)
      })
      for (let taskType in tasksByType) {
          let tasks = tasksByType[taskType]

          tasks.sort((a, b) => b.ID - a.ID)

          for (let i = 0; i < tasks.length; i++) {
              let currentTask = tasks[i]
              let mergedTask = { ...currentTask }

              if (taskType === 'action') {
                  for (let j = i + 1; j < tasks.length; j++) {
                      let nextTask = tasks[j]
                      mergedTask.Items.push(...nextTask.Items)
                  }
              }else {
                  for (let j = i + 1; j < tasks.length; j++) {
                      let nextTask = tasks[j]
                      mergedTask.Documents.push(...nextTask.Documents)
                  }
              }
              updatedTaskList.push(mergedTask)
              i = tasks.length
          }
      }
      return updatedTaskList
    } 
    let token = com.getUserToken()
    fetch('/auth/tasks', {
      method: 'GET',
      headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
              response.status)
          return
      }
      // Examine the text in the response
      response.json().then(js => {

          if (js.Status !== "OK") {
              console.log("Error: " + js.Text)
          } else {
              const data = js.Tasks
              const filterData = data.filter(item => item.TaskType !== 'upload')
              const tasks = filterData.length > 0 ? mergeTasks(filterData) : []
              let totalLength = 0
              if (tasks.length > 0) {
                tasks.forEach(task => {
                      if (task.TaskType === 'action') {
                          totalLength += task.Items.length
                      } else {
                          totalLength += task.Documents.length
                      }
                  })
              }
              dispatchTaskStatusUpdate(totalLength)
          }
        })
      }).catch(function (err) {
        console.log('Fetch Error :', err)
      })
}
