import { useEffect,useState } from "react"
// import { jwtDecode } from "jwt-decode"
import jwt_decode from "jwt-decode"
import { throttle } from 'lodash'
import { Link, useHistory } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../msalConfig'
import AppleLogin from 'react-apple-login'
import { State } from "../../State"
import DividerWithText from "../../Common/components/DividerLineWithText"
import * as com from "../../Common"

function LoginPage({ setLoading, hideGoogle }) {
    const { instance, accounts } = useMsal()
    const [showLine, setLine] = useState(false)
    const history = useHistory()
    let searchparams = new URLSearchParams(window.location.search)
    let customerID = searchparams.get("customerid")

    const renderGoogleBtn = () => {
        try {
            if (google && google.accounts && google.accounts.id) {

                google.accounts.id.renderButton(
                    document.getElementById("googleSignin"),
                    {
                        // 设置按钮的宽度
                        width: '350', // 宽度以像素为单位，例如 '200' 表示200像素宽

                        // 设置按钮上的文本
                        text: 'Sign up with Google', // 可选值有 'continue_with' 或 'signin_with'

                        // 其他可选配置，如主题、大小、形状等

                        size: 'large',
                        shape: 'pill',

                        logo_alignment: 'left',
                    }
                )
                setTimeout(() => {
                    setLine(true)
                }, 1000)
           
               
                // 
                // setGoogleReady(true)
            }
        } catch (error) {
            console.log("Error rendering Google button: ", error)
        }

    }
    const handleGoogleCallback = (response) => {
        let userObject = jwt_decode(response.credential)
        setLoading(true)

        fetch("/auth/googlesignin", {
            method: "POST",
            body: JSON.stringify(userObject),
            headers: {
                "Content-Type": "application/json",
                "X-GOOGLETOKEN": response.credential,
                "X-CUSTOMERID": customerID,
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    alert(
                        "Sorry, but we are unable to sign you in using your Google account at this time. Please consider using alternative sign-in methods."
                    )
                    setLoading(false)
                    return
                }
                response.json().then((js) => {
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        alert('Sorry, but we are unable to sign you in using your Google account at this time. Please consider using alternative sign-in methods.')
                        // setMsg('')
                        // setShow(true)
                        sessionStorage.removeItem("ZeitroA")
                        sessionStorage.removeItem("state")
                        setLoading(false)
                    } else {

                        sessionStorage.setItem("ZeitroA", js.Token)
                        let state = State.fromJson(JSON.parse(js.State))
                        let statejs = state.toJson()
                        sessionStorage.removeItem("state")
                        sessionStorage.setItem("state", statejs)
                        sessionStorage.setItem("originalstate", statejs)
                        sessionStorage.setItem("newcustomer", "false")
                        // history.push('/application/myloans')
                        let isHardMoney = com.isHardMoneyLoanCustomer()
                        if (isHardMoney) {
                            sessionStorage.setItem("isHardMoneyLoanCustomer", true)
                        }
                        window.location.href = "/application/myloans"
                        setLoading(false)
                    }
                })
            })
            .catch(function (err) {
                console.log("Fetch Error :-S", err)
            })
    }
    // const msLogin = async () => {
    //     // console.log(a);
    //     try {
    //         const loginResponse = await instance.loginPopup(loginRequest)
    //         console.log(loginResponse)

    //         // Check if the login response is valid and contains the account
    //         if (loginResponse && loginResponse.account) {
    //             // Proceed with any necessary actions after successful sign-in
    //             console.log('Successfully signed in:', loginResponse.account, loginResponse.account.name, loginResponse.account.username)


    //             fetch("/agent/microsoftsignin", {
    //                 method: "POST",
    //                 body: JSON.stringify({
    //                     name: loginResponse.account.name,
    //                     email: loginResponse.account.username,
    //                 }),
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //             })
    //                 .then((response) => {
    //                     if (response.status !== 200) {
    //                         alert(
    //                             "Looks like there was a problem. Please contact contact@zeitro.com for help."
    //                         )

    //                         return
    //                     }
    //                     response.json().then((js) => {
    //                         if (js.Status !== "OK") {
    //                             console.log("Error: " + js.Text)

    //                             sessionStorage.removeItem("ZeitroA")
    //                             sessionStorage.removeItem("state")

    //                         } else {
    //                             sessionStorage.setItem("ZeitroA", js.Token)
    //                             sessionStorage.setItem("state", js.State)
    //                             sessionStorage.setItem("originalstate", js.State)
    //                             sessionStorage.setItem(
    //                                 "roles",
    //                                 JSON.parse(atob(js.Token.split(".")[1])).roles
    //                             )



    //                         }
    //                     })
    //                 })
    //                 .catch(function (err) {
    //                     console.log("Fetch Error :-S", err)
    //                 })


    //         }
    //     } catch (error) {
    //         console.error('Sign-in error:', error)
    //     }
    // }
    // const appleLogin = (res) => {
    //     if (res && !res.error) {
    //         fetch("/agent/applesignin", {
    //             method: "POST",
    //             body: JSON.stringify({
    //                 code: res.authorization.code,
    //             }),
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //         })
    //             .then((response) => {
    //                 if (response.status !== 200) {
    //                     alert(
    //                         "Looks like there was a problem. Please contact contact@zeitro.com for help."
    //                     )

    //                     return
    //                 }
    //                 response.json().then((js) => {
    //                     if (js.Status !== "OK") {
    //                         console.log("Error: " + js.Text)
    //                         //   if (js.Text.includes("invalid email address")) {
    //                         //     setMsg('Sorry, but we were unable to find a valid email address associated with this Apple ID. Please try using a different Apple ID or alternative methods.')
    //                         //   } else {
    //                         //     setMsg('Sorry, but we are unable to sign you in using your Apple account at this time. Please consider using alternative sign-in methods.')
    //                         //   }

    //                         sessionStorage.removeItem("ZeitroA")
    //                         sessionStorage.removeItem("state")

    //                     } else {
    //                         if (js.Text === "Account authenticated" ||
    //                             (!js.AppleStatus.IsPrivateEmail && js.AppleStatus.IsNameIncluded)) {

    //                             sessionStorage.setItem("ZeitroA", js.Token)
    //                             sessionStorage.setItem("state", js.State)
    //                             sessionStorage.setItem("originalstate", js.State)
    //                             sessionStorage.setItem(
    //                                 "roles",
    //                                 JSON.parse(atob(js.Token.split(".")[1])).roles
    //                             )



    //                         } else {
    //                             // setApplePrivateEmail(js.AppleStatus.Email)
    //                             // if (!js.AppleStatus.IsPrivateEmail) {
    //                             //   setAppleEmail(js.AppleStatus.Email)
    //                             // }


    //                         }
    //                     }
    //                 })
    //             })
    //             .catch(function (err) {
    //                 console.log("Fetch Error :-S", err)
    //             })
    //     }
    // }
    useEffect(() => {

        setLine(false)
        /* global google */
        window.onload = () => {
            google.accounts.id.initialize({
                client_id: "265683149831-9a27eaupjqjt6vh5vuaa54bfld627fre.apps.googleusercontent.com",
                callback: handleGoogleCallback,
            })
            renderGoogleBtn()
        }
        const handleResize = throttle((event) => {
            renderGoogleBtn()
        }, 200)
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            setLine(false)
            window.removeEventListener('resize', handleResize)
        }

    }, [])
    return <div>
        {(showLine && ! hideGoogle )? <DividerWithText text="or sign in with" /> : <></>}
        <div className=" mt-2" style={{ margin: "auto", width: "350px", }}>

            <div id="googleSignin" >

            </div>
            {/* <div style={{
                background: "#0072C6",
                borderRadius: '50%',
                textAlign: 'center',
                lineHeight: "38px",
                width: "38px", height: "38px",
                cursor: "pointer"
            }} onClick={msLogin}>
                <img
                    src="/images/outlook.svg"
                    style={{ width: "18px", height: "18px" }}
                />

            </div>
            <div style={{ cursor: "pointer" }}>

                <AppleLogin
                    clientId={"com.zeitro.signinservice"}
                    redirectURI={"https://agent.zeitro.com"}
                    usePopup={true}

                    scope="email name"
                    responseMode="query"
                    render={(props) => (
                        <div style={{
                            background: "#000",
                            borderRadius: '50%',
                            textAlign: 'center',
                            lineHeight: "38px",
                            width: "38px", height: "38px"


                        }} callback={(res) => appleLogin(res)} onClick={props.onClick}>
                            <img
                                src="/images/apple.svg"
                                style={{ width: "18px", height: "18px" }}
                            />

                        </div>
                        // <Button
                        //     style={{
                        //         background: "#0072C6",
                        //         borderRadius: '50%'
                        //     }}

                        //     size="large"
                        //     shape="circle"
                        //     className="other-login sign-apple"
                        //     onClick={props.onClick}
                        //     icon={
                        //         <img
                        //             src="/images/apple.svg"
                        //             style={{ width: "18px", height: "18px" }}
                        //         />
                        //     }
                        // >
                        // </Button>
                    )}
                />

            </div> */}
        </div>
    </div>
}

export default LoginPage