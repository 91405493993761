import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import './HardMoneyLoanDocManager.css'

import Spinner from 'react-bootstrap/Spinner'
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import * as com from '../../Common'
import { humanReadableFileSize } from './utils'
import { Table } from 'antd';
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";

const mapStateToProps = (state) => {
    return {
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
});
class DocumentManager extends Component {

    dateSortFunction = (a, b, order, dataField) => {
        if (a === '') {
            a = '01/01/1000'
        }
        if (b === '') {
            b = '01/01/1000'
        }
        if (a === '01/01/1000' && b === '01/01/1000') {
            b = '01/02/1000'
        }
        let at = new Date(a)
        at = at.getTime()
        let bt = new Date(b)
        bt = bt.getTime()


        if (order === 'asc') {
            return (at - bt) < 0 ? -1 : 1
        } else {
            return (at - bt) < 0 ? 1 : -1
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            url: "",
            download: "",
            show: false,
            modalTitle: "",
            index: 0,
            id: 0,
            originalDocuments: [],
        }
        this.aref = React.createRef();
        this.columns = [
            {
                title: "FILE NAME",
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => { return a.name.localeCompare(b.name)},
                render: (text, row, index) => (
                    <a className="d-flex align-items-center" style={{ fontWeight: 500 }} onClick={() => this.openInNewTab(row.id, row.index)}>
                        <img src="/images/pdf.svg" style={{ height: 25, marginRight: 10 }} />
                        <div className="d-flex flex-column" style={{ display: 'inline-block' }}>
                            <span>{text}</span>
                            <span style={{ fontSize: 12, color: 'gray'}}>{row.size}</span>
                        </div>
                    </a>
                ),
            },
            {
                title: 'TYPE',
                dataIndex: 'type',
                key: 'type',
                render: (text, row, index) => <a>{text}</a>,
            },
            {
                title: 'DATE UPLOADED',
                dataIndex: 'created',
                key: 'created',
                sorter: (a, b) => a.created - b.created,
            },
            {
                title: ' ',
                dataIndex: 'action',
                key: 'action',
                render: (_, row, index) => (
                    <div className="d-flex">
                        <a
                            onClick={() => this.download(row.id, row.index)}
                        >
                            <DownloadOutlined style={{ fontSize: 25 }} />
                        </a>
                    </div>
                ),
            },
        ]
        if (this.props.type === "received") {
            this.columns.push(
                {
                    title: ' ',
                    dataIndex: 'action',
                    key: 'action',
                    render: (_, row, index) => (
                        <div className="d-flex">
                            <a
                                onClick={() => this.onShow(row.index)}
                            >
                                <DeleteOutlined style={{ fontSize: 25 }} />
                            </a>
                        </div>
                    ),
                }
            )
        }
    }
    componentDidMount() {
        this.reload()
    }

    componentDidUpdate(prevProps) {
        if (this.props.searchInput !== prevProps.searchInput) {
            this.setState({
                documents: this.state.originalDocuments.filter(this.searchFilter)
            })
        }
    }

    forWhomDecoded = (_forwhom) => {
        let forwhom = ""
        if (_forwhom === "joint" && this.props.application.hascoborrower === "alone") {
            _forwhom = "borrower"
        }
        try {
            switch (_forwhom) {
                case 'borrower':
                    forwhom = this.props.application.borrower.firstname + " " +
                        this.props.application.borrower.lastname
                    break
                case 'coborrower':
                    forwhom = this.props.application.coborrower.firstname + " " +
                        this.props.application.coborrower.lastname
                    break;
                case 'joint':
                    forwhom = this.props.application.borrower.firstname + " " +
                        this.props.application.borrower.lastname + " and " + this.props.application.coborrower.firstname + " " +
                        this.props.application.coborrower.lastname
                    break;
                default:
                    forwhom = _forwhom
                    break;
            }
        } catch (x) {
            forwhom = _forwhom
        }
        return forwhom
    }

    documentsJSON = (document, index) => {
        let type = ""
        let size = humanReadableFileSize(document.size)
        try {
            const extraInfo = JSON.parse(document.extraInfo)
            type = extraInfo.Type || ""
        } catch (e) {
        }
        return {
            progress: <Spinner className="position-relative" size="sm" variant="success" animation={((document.progress > 0) && (document.progress < 100)) ? "border" : ""} />,
            name: document.name,
            type,
            size,
            comment: document.comment,
            forwhom: this.forWhomDecoded(document.forwhom),
            created: document.created.toLocaleString(),
            delete: 'Delete',
            index: index,
            id: document.id,
        }
    }
    searchFilter = (doc) => {
        if (!this.props.searchInput) return true
        let val = this.props.searchInput.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
        val = val.trim().toLowerCase()
        return doc.name && doc.name.includes(val)
    }
    reload = () => {
        let token = sessionStorage.getItem("ZeitroA")
        let url = ""
        if (this.props.type === "uploaded") {
            url = "/borrower/docs/hardmoneyloan/uploaded"
        } else if (this.props.type === "received") {
            url = "/borrower/docs/hardmoneyloan/received"
        }
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");

                    return;
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Text)
                        window.document.dispatchEvent(new Event('reauthenticate'), "");
                    } else {
                        let docs = js.Docs.map(s => {
                            let dt = new Date(com.timeGoToJS(s.Uploaded))
                            return {
                                rand: Math.random(),
                                id: s.ID,
                                name: s.FileName,
                                comment: s.Comment,
                                mime: s.Mime,
                                forwhom: s.ForWhom,
                                created: dt.toLocaleString("en-US"),
                                progress: 0,
                                size: s.FileSize,
                                extraInfo: s.ExtraInfo,
                            }
                        })
                        //hide prequal letter temporarily
                        docs = docs.filter(doc => doc.name !== "prequal-letter.pdf")
                        this.setState({ documents: docs.filter(this.searchFilter), originalDocuments: docs })
                    }

                });
            }
        )
            .catch(function (err) {
                console.log('Fetch Error :', err);
            });
    }
    delete = (id) => {
        let token = sessionStorage.getItem("ZeitroA")
        if (this.props.type === "uploaded") {
            // prevent deleting user upload documents
            return
        }

        fetch('/docs/deletedoc/' + id, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    window.document.dispatchEvent(new Event('reauthenticate'), "");
                    this.setState({ show: false })
                    return;
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        window.document.dispatchEvent(new Event('reauthenticate'), "");
                        console.log("Error: " + js.Text)
                    } else {
                    }
                    this.setState({ show: false })
                    this.reload()
                });
            }
        )
            .catch(function (err) {
                this.setState({ show: false })
                console.log('Fetch Error :', err);
            });
    }
    openInNewTab = (id, index) => {
        let token = sessionStorage.getItem("ZeitroA")

        let docs = this.state.documents
        docs[index] = { ...docs[index], rand: Math.random(), progress: 1 }
        this.setState({ documents: Array.from(docs) })
        fetch('/docs/downloaddoc/' + id, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then((response) => response.blob())
            .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
                var _url = window.URL.createObjectURL(blob);
                window.open(_url, "_blank").focus(); // window.open + focus
            }).catch((err) => {
                console.log(err);
            });
    }
    download = (id, index) => {
        let token = sessionStorage.getItem("ZeitroA")

        let disposition
        let docs = this.state.documents
        docs[index] = { ...docs[index], rand: Math.random(), progress: 1 }
        this.setState({ documents: Array.from(docs) })
        fetch('/docs/downloaddoc/' + id, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(response => {
            disposition = response.headers.get("Content-Disposition").split('=')

            return response.body
        })
            .then(body => {
                const reader = body.getReader();

                return new ReadableStream({
                    start(controller) {
                        return pump();

                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                // When no more data needs to be consumed, close the stream
                                if (done) {
                                    controller.close();
                                    return;
                                }

                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(r => r.blob())
            .then(blob => {
                let docs = this.state.documents
                docs[index] = { ...docs[index], rand: Math.random(), progress: 0 }
                this.setState({ documents: docs })

                let download = disposition.slice(1).join().replace(/"/g, '')

                const url = window.URL.createObjectURL(blob);
                this.setState({ url: url, download: download })

                this.aref.current.click()
                this.forceUpdate();
            })
            .catch(function (err) {
                console.log('Fetch Error :', err);
                let docs = this.state.documents
                docs[index] = { ...docs[index], rand: Math.random(), progress: 0 }
                this.setState({ documents: docs })

            });
    }
    deleteSelected = () => {
        this.delete(this.state.id)
    }
    onHide = () => {
        this.setState({ show: false })
    }
    onShow = (index) => {
        this.setState({
            show: true,
            modalTitle: this.state.documents[index].comment,
            index: index,
            id: this.state.documents[index].id,
        })
    }
    render() {
        return (
            <div className="text-left mt-3 mx-3 whitebackground">
                <Modal show={this.state.show} onHide={this.onHide} >
                    <Modal.Header closeButton>
                        <Modal.Title className="capitalize">Do you really want to delete {this.state.modalTitle}?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        This operation is irreversible. The document will be deleted.
                    </Modal.Body>
                    <Modal.Footer className="text-center d-block" >
                        <Row className="text-center">
                            <Col>
                                <Button type="default" onClick={this.onHide}>Keep it</Button>
                            </Col>
                            <Col>
                                <Button type="primary" onClick={this.deleteSelected}>Delete</Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
                <a hidden ref={this.aref} href={this.state.url} download={this.state.download}>&nbsp;</a>
                <div className="py-3 px-5 currentTasksWrap">
                    <Table columns={this.columns} dataSource={this.state.documents.map(this.documentsJSON)} pagination={{
                        pageSize: 10,
                        hideOnSinglePage: true
                    }} />
                </div>

            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DocumentManager)
