import "./ScrollBar.css"
import "./public.css"
import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { commaize } from "../../Common";

const wipeoffComma = (e) => {
    return Number(e.replace(/,/g, ""))
}

export default function ScrollBar(props) {
    const { max, min, step, initVal, scrollChangeValue, noSign, hideSlider } = props;
    
    useEffect(() => {
        setMaxValue(max);
        setMinValue(min > max ? max : min);
        setStepValue(step);
        setInitValue(initVal);
        setInputValue(initVal);
    }, [initVal, max, min, step])

    const [initValue, setInitValue] = useState(1)
    const [inputValue, setInputValue] = useState(1)
    const [maxValue, setMaxValue] = useState(3000000)
    const [minValue, setMinValue] = useState(0)
    const [stepValue, setStepValue] = useState(null)

    const initValueChange = (e) => {
        setInitValue(e)
        setInputValue(commaize(e))
        scrollChangeValue(e)
    }

    const inputChange = (e) => {
        let n = Number(e.target.value.replace(/[^\d.-]/g, ''))
        if (n > maxValue) {
            setInitValue(maxValue)
            scrollChangeValue(maxValue)
            setInputValue(maxValue)
        } else {
            setInitValue(n)
            scrollChangeValue(n)
            setInputValue(n)
        }
    }

    return (
        <div className="scroll-bar" style={props.style}>
            <div className="bar-sign">{noSign !== true ? "$" : ""}</div>
            <input type='text' onChange={inputChange} className="input-text" style={{width:hideSlider===true?"100%":""}} placeholder="Paragraph Main" value={commaize(inputValue)}></input>
            {!hideSlider &&<Slider style={{ marginLeft: '1rem' }} className="custom-range" min={minValue} max={maxValue} step={stepValue} onChange={initValueChange} value={initValue} />}
        </div>
    )
}

export function ScrollBarPercentage(props) {
    const { max, min, step, initVal, scrollChangeValue, percentageOf } = props;

    useEffect(() => {
        setMaxValue(max);
        setMinValue(min);
        setStepValue(step);
        setInitValue(initVal);
        setInputValue(initVal);
        setPercentageOfValue(percentageOf);
        // if (typeof percentageOf !== "undefined") {
        //     setDollarValue(percentageOf * initVal * 0.01)
        // }
    }, [initVal, max, min, step, percentageOf])

    useEffect(() => {
        if (typeof percentageOf !== "undefined") {
            setDollarValue(percentageOf * initVal * 0.01)
        }
    }, [percentageOf])

    const [initValue, setInitValue] = useState(1)
    const [inputValue, setInputValue] = useState(1)
    const [maxValue, setMaxValue] = useState(3000000)
    const [minValue, setMinValue] = useState(0)
    const [stepValue, setStepValue] = useState(null)
    const [percentageOfValue, setPercentageOfValue] = useState(0)
    const [dollarValue, setDollarValue] = useState(typeof percentageOf !== "undefined" ? percentageOf * initVal * 0.01 : 0)
    const hasDollarInput = typeof percentageOf !== "undefined"
    
    const initValueChange = (e) => {
        let val = e
        if (e>maxValue) {
            val = maxValue
        }
        setInitValue(val)
        setInputValue(val)
        scrollChangeValue(val)
        if (hasDollarInput) {
            setDollarValue(0.01 * val * percentageOfValue)
        }
    }

    const inputChange = (e) => {
        let n = Number(e.target.value.replace(/[^\d.-]/g, ''))
        if (n > maxValue) {
            setInitValue(maxValue)
            scrollChangeValue(maxValue)
            setInputValue(maxValue)
            if (hasDollarInput) {
                setDollarValue(0.01 * maxValue * percentageOfValue)
            }
        } else {
            setInitValue(n)
            scrollChangeValue(n)
            setInputValue(n)
            if (hasDollarInput) {
                setDollarValue(0.01 * n * percentageOfValue)
            }
        }
    }

    const inputChangePercentageOf = (e) => {
        let val = Number(e.target.value.replace(/[^\d.-]/g, ''))
        let n = (val/percentageOf) * 100 
        if (n > maxValue) {
            setDollarValue(0.01 * maxValue * percentageOfValue)
            setInitValue(maxValue)
            scrollChangeValue(maxValue)
            setInputValue(maxValue)
        } else {
            setDollarValue(val)
            setInitValue(n)
            scrollChangeValue(n)
            setInputValue(n)
        }
    }

    

    return (
        <div className="scroll-bar" style={props.style}>
            <div className="prefix-percent">%</div>
            <input type='text' onChange={inputChange} className={hasDollarInput ? "input-text-percentage" : "input-text"} placeholder="" value={Math.round(inputValue) * 10 / 10}></input>
            {hasDollarInput && <>
                <div className="">/</div>
                <div className="bar-sign-percentage">$</div>
                <input type='text' onChange={inputChangePercentageOf} className="input-text-dollar" placeholder="" value={commaize(dollarValue)}></input>            
            </>}

            <Slider style={{ marginLeft: '1rem' }} className="custom-range" min={minValue} max={maxValue} step={stepValue} onChange={initValueChange} value={initValue} />
        </div>
    )
}

export function InputWithPercentage(props){
    const { max, min, step, initVal, inputChangePercentage, percentageOf } = props;

    useEffect(() => {
        setMaxValue(max);
        setMinValue(min);
        setStepValue(step);
        setInitValue(initVal);
        setInputValue(initVal);
        setPercentageOfValue(percentageOf);
        // if (typeof percentageOf !== "undefined") {
        //     setDollarValue(percentageOf * initVal * 0.01)
        // }
    }, [initVal, max, min, step, percentageOf])

    useEffect(() => {
        initValueChange(initVal)
    }, [initVal])

    useEffect(() => {
        if (typeof percentageOf !== "undefined") {
            setDollarValue(percentageOf * initVal * 0.01)
        }
    }, [percentageOf])

    const [initValue, setInitValue] = useState(1)
    const [inputValue, setInputValue] = useState(1)
    const [maxValue, setMaxValue] = useState(3000000)
    const [minValue, setMinValue] = useState(0)
    const [stepValue, setStepValue] = useState(null)
    const [percentageOfValue, setPercentageOfValue] = useState(0)
    const [dollarValue, setDollarValue] = useState(typeof percentageOf !== "undefined" ? percentageOf * initVal * 0.01 : 0)
    const hasDollarInput = typeof percentageOf !== "undefined"
    
    const initValueChange = (val) => {
        inputChangePercentage(val)
    }
    const inputChangePercentageOf = (e) => {
        let val = Number(e.target.value.replace(/[^\d.-]/g, ''))
        let n = (val/percentageOf) * 100 
        if (n > maxValue) {
            setDollarValue(0.01 * maxValue * percentageOfValue)
            setInitValue(maxValue)
            inputChangePercentage(maxValue)
            setInputValue(maxValue)
        } else {
            setDollarValue(val)
            setInitValue(n)
            inputChangePercentage(n)
            setInputValue(n)
        }
    }

    

    return (
        <div className="scroll-bar" style={{justifyContent:"space-between"}}>
            {/* <div className="prefix-percent">%</div>
            <input type='text' onChange={inputChange} className={hasDollarInput ? "input-text-percentage" : "input-text"} placeholder="" value={Math.round(inputValue) * 10 / 10}></input> */}
            {hasDollarInput && <div className="d-flex" style={{alignItems:"center"}}>
                <div className="bar-sign">$</div>
                <input type='text' onChange={inputChangePercentageOf} className="input-text" style={{width:"100%", height:"100%"}} placeholder="" value={commaize(dollarValue)}></input>            
            </div>}
            <div className="percentageBulb">{Math.round(inputValue*100)/100}%</div>
        </div>
    )
}
