let token = sessionStorage.getItem("ZeitroA")
export default async (url = '', data = {}, type = 'GET',header={}) => {

    type = type.toUpperCase()

    let requestConfig = {
        credentials: 'same-origin',
        method: type,
        headers: {
            ...header,
            Authorization: "Bearer " + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        mode: "cors",
        cache: "force-cache"
    }

    if (type == 'POST') {

        Object.defineProperty(requestConfig, 'body', {
            value: JSON.stringify(data)
        })
    }
    try {
        const response = await fetch(url, requestConfig)
       
        // response.json().then((reserves) => {
        //     console.log(reserves)
        // })
        const responseJson = await response.json()
        // console.log(responseJson)
        return responseJson
    } catch (error) {
        throw new Error(error)
    }
}