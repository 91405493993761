
import Auth from "./Auth"
import React, { useEffect } from "react"

import 'bootstrap/dist/css/bootstrap.min.css'
import Error404 from "./Error404.js"
import {
    Switch,
    Route
} from "react-router-dom"
import Helmet from "react-helmet"
import FaqPage from './FaqPage'
import Notifications from './Notifications'
import Glossary from './Glossary'
import LogoutPage from './LogoutPage'
import HomePageApplication from './HomePageApplication'
import Loans from "./newContent/page/loans"
import SetUpPassword from "./newContent/page/setUpPassword"
import ChangePassword from "./newContent/page/changePassword"

const AuthenticatedApplication = () => {


    return (
        <div className="mx-0 px-0 h-100">
            {sessionStorage.getItem("newcustomer") !== "true" && <Auth />}
            <Switch>



                <Route exact path="/application/changepassword">
                    <Helmet>
                        <title>My password </title>
                    </Helmet>

                    <ChangePassword />
                </Route>

                <Route exact path="/application/password">
                    <Helmet>
                        <title>My password </title>
                    </Helmet>

                    <SetUpPassword />
                </Route>

                <Route exact path="/application/myloans">
                    <Helmet>
                        <title>My Loans </title>
                    </Helmet>

                    <Loans />
                </Route>

                <Route path="/application/">
                    <HomePageApplication />
                </Route>

                <Route exact path="/resources/faq">
                    <FaqPage />
                </Route>
                <Route exact path="/resources/glossary">
                    <Glossary />
                </Route>
                <Route exact path="/services/logout">
                    <LogoutPage />
                </Route>
                <Route >
                    <Error404 />
                </Route>
            </Switch>
            <Notifications />
        </div>
    )
}

export default AuthenticatedApplication