import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
    DatePicker,
    Button,
    Select,
    Divider,
    Form,
    Input,
    InputNumber,
    Col,
    Row,
    Popover,
    Checkbox
} from 'antd';
import {
    QuestionCircleOutlined
} from '@ant-design/icons';
import "./Common.css";
import "./PropertyAndLoan.css";
import * as com from "../../Common"
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import GoogleAutocomplete from '../../Common/GoogleAutocomplete/GoogleAutocomplete'
import {
    PropertyTypeOptions,
    loanTypeOptions,
    loanPurposeOptions,
    loanTermOptions,
    exitStrategyOptions,
    YesNoOptions,
    validateMessages,
} from './constants'

const mapStateToProps = (state) => {
    return {
        status: state.application.status,
        property: state.application.property,
        application: state.application,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateHardMoneyLoanAttribute: (t, verb) => {
        dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    },
    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb));
    },
    changeMainPropertyPurpose: (val) => {
        dispatch(act.ChangeMainPropertyPurpose(val));
    },
    updateHasCoborrower: (val) => {
        dispatch(act.UpdateHasCoborrower(val))
    },
    updateProduct: (v, verb) => {
        dispatch(act.UpdateProduct(v, verb))
    },
    changeBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "borrower", verb))
    },
    changeCoBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "coborrower", verb))
    },
});

const loanTypeExitStrategyMap = {
    'fixflip': [
        {
            value: 'fixandflip',
            label: 'Fix / Sell'
        },
        {
            value: 'refitoconventional',
            label: 'Refi to Conventional'
        },
        {
            value: 'refitorent',
            label: 'Refi to Rent'
        }
    ],
    'fixrent': [
        {
            value: 'fixandrent',
            label: 'Fix / Rent'
        },
        {
            value: 'refitoconventional',
            label: 'Refi to Conventional'
        },
        {
            value: 'refitorent',
            label: 'Refi to Rent'
        }
    ],
    'bridge': [
        {
            value: 'fixandflip',
            label: 'Fix / Sell'
        },
        {
            value: 'fixandrent',
            label: 'Fix / Rent'
        },
        {
            value: 'buildandflip',
            label: 'Build and Sell'
        },
        {
            value: 'refitoconventional',
            label: 'Refi to Conventional'
        },
        {
            value: 'refitorrent',
            label: 'Refi to Rent'
        }
    ],
    'groundup': [
        {
            value: 'buildandflip',
            label: 'Build and Sell'
        },
        {
            value: 'buildandrent',
            label: 'Build and Rent'
        },
        {
            value: 'refitoconventional',
            label: 'Refi to Conventional'
        },
        {
            value: 'refitobuild',
            label: 'Refi to Construction'
        },
        {
            value: 'refitorent',
            label: 'Refi to Rent'
        }
    ],
    'scrapebuild': [
        {
            value: 'buildandflip',
            label: 'Build and Sell'
        },
        {
            value: 'buildandrent',
            label: 'Build and Rent'
        },
        {
            value: 'refitoconventional',
            label: 'Refi to Conventional'
        },
        {
            value: 'refitobuild',
            label: 'Refi to Construction'
        },
        {
            value: 'refitorent',
            label: 'Refi to Rent'
        }
    ],
    'rental': [
        {
            value: 'others',
            label: 'Others'
        }
    ],
}

export default function PropertyAndLoan(props) {
    const interviewfinished = props.status === 'interviewfinished'
    let disabledOnclick = interviewfinished ? props.onSubmittedDisableClick : null
    const [form] = Form.useForm();
    const [loanPurpose, setLoanPurpose] = useState(props.property.purpose);
    const [loanLimits, setLoanLimits] = useState(null)
    const [stateOptions, setStateOptions] = useState([])
    const [countyOptions, setCountyOptions] = useState([])
    const [choosedOthers, setChoosedOthers] = useState(false)
    const [otherSpecify, setOtherSpecify] = useState("")
    const [rehabAdded, setRehabAdded] = useState(props.application.hardmoneyloan.rehabloan)

    const loanPurposePopovercontent = <div className='popoverContentWrap'>
    <div><b>If you want to apply for a construction loan, please select "Purchase" or "Refinance", and select "Ground up" in loan type.</b></div>
</div>

    const loanTypePopovercontent = <div className='popoverContentWrap'>
        <div><b>Fix/Sell (Flip):</b> Short-term loan for purchasing and renovating a property to sell quickly for a profit.</div>
        <div><b>Fix/Rent:</b> Loan for buying and rehabbing a property to rent out and generate rental income.</div>
        <div><b>Bridge:</b> Temporary financing to bridge the gap between buying a new property and selling an existing one.</div>
        <div><b>Ground Up:</b> Loan for financing the construction of a new property from the ground up.</div>
        <div><b>Scrape/Build:</b> Loan for demolishing an existing structure and building a new one on the same site.</div>
        <div><b>Rental:</b> Long-term loan for purchasing a property to generate ongoing rental income.</div>
    </div>

    const exitStrategyPopovercontent = <div className='popoverContentWrap'>
        <div className='mb-3'>The exit strategy is your business model for the property, encompassing both borrowed and non-borrowed funds. Your available options depend on the <b>Loan Type</b> you choose.</div>
        <ul>
            <li><b>Fix/Sell (Flip):</b> Renovate and sell the property for profit.</li>
            <li><b>Fix/Rent:</b> Renovate and rent out the property.</li>
            <li><b>Build and Sell:</b> Construct a new property and sell it.</li>
            <li><b>Build and Rent:</b> Construct a new property and rent it out.</li>
            <li><b>Refi to Conventional:</b> Refinance into a conventional mortgage.</li>
            <li><b>Refi to Construction:</b> Refinance to secure additional funds for further construction.</li>
            <li><b>Refi to Rent:</b> Refinance to obtain a rental mortgage and generate rental income.</li>
        </ul>
    </div>

    useEffect(() => {
        if (otherSpecify !== "") {
            updateExitStrategyOthers(otherSpecify)
        }
    }, [otherSpecify])

    useEffect(() => {
        if (props.property.purpose === "construction"){
            props.changeMainPropertyPurpose("purchaserehab")
            props.updateHardMoneyLoanAttribute("buildandflip", "exitstrategy")
        }
        if (props.property.purpose === "purchase"){
            props.changeMainPropertyPurpose("purchaserehab")
        }
    }, [])

    const onFinish = (values) => {
        props.finish(values)
    }
    const disabledDate = (current) => {
        return current && current < dayjs().endOf('day');
    };
    const refiDisabledDate = (current) => {
        return current > dayjs().endOf('day');
    }
    const tcdDisabledDate = (current) => {
        return current && current < dayjs().endOf('day').add(10, 'day');
    }
    const inputFormatter = (value) => {
        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const inputParser = (value) => {
        return value.replace(/\\s?|(,*)/g, '')
    }
    const onChangeLoanPurpose = (v) => {
        setLoanPurpose(v)
        form.setFieldsValue({totalLoanAmountRequested: ""})
        props.updateHardMoneyLoanAttribute(v === "purchaserehab", "rehabloan")
        props.changeMainPropertyAttribute(v, "purpose")
    }
    const onChangeLiquidity = (e) => {
        let val = e?.target?.value
        if (val === undefined) {
            val = e
        }
        props.updateHardMoneyLoanAttribute(`${val}`, "liquidity")
    }
    const getAddress = (val) => {
        let num = com.getSubaddress(val.address_components, "street_number", "long_name");
        let street = com.getSubaddress(val.address_components, "route", "long_name")
        let city = com.getSubaddress(val.address_components, "locality", "long_name")
        let zipCode = com.getSubaddress(val.address_components, "postal_code", "long_name")
        let state = com.getSubaddress(val.address_components, "administrative_area_level_1", "long_name")
        let county = com.getSubaddress(val.address_components, "administrative_area_level_2", "long_name")
        let streetaddr = street;
        if (num) {
            streetaddr = num + " " + streetaddr;
        }
        form.setFieldsValue({
            zipCode: zipCode,
            streetAddress: streetaddr,
            city: city,
            state: state,
            county: county
        })
        props.changeMainPropertyAttribute(streetaddr, "address")
        props.changeMainPropertyAttribute(zipCode, "zipcode")
        props.changeMainPropertyAttribute(city, "city")
        props.changeMainPropertyAttribute(state, "state")
        props.changeMainPropertyAttribute(county, "county")
    }
    const fetchLoanLimits = () => {
        fetch("/data/loanlimits")
            .then((res) => {
                if (!res.ok) {
                    ;
                    throw Error(res.statusText);
                }
                return res.json();
            })
            .then((res) => {
                setLoanLimits(res)
                setStateOptions(Object.keys(res).map(item => ({ value: com.capitalize(item), label: com.capitalize(item) })))
            })
            .catch((err) => {
                console.error(err);
            });
    }
    const onChangeState = (val) => {
        props.changeMainPropertyAttribute(val, "state")
        form.setFieldsValue({
            county: undefined,
        })
        setCountyOptions(val ? Object.keys(loanLimits[val.toUpperCase()]).map(item => ({ value: com.capitalize(item), label: com.capitalize(item) })) : [])
    }

    const updateExitStrategyOthers = (value) => {
        let ent = "others"
        props.updateHardMoneyLoanAttribute(ent, "exitstrategy")
    }

    const updateLoanTypes = (e) => {
        props.updateHardMoneyLoanAttribute(e, "loantype")
        if (e === "rental") {
        form.setFieldsValue({exitStrategy: "others"})
        props.updateHardMoneyLoanAttribute("others", "exitstrategy")
        setChoosedOthers(true)
        }
        if (e === "scrapebuild") {
            form.setFieldsValue({exitStrategy: "buildandflip"})
            props.updateHardMoneyLoanAttribute("buildandflip", "exitstrategy")
            setChoosedOthers(false)
        }
        if (e === "groundup") {
            form.setFieldsValue({exitStrategy: "buildandflip"})
            props.updateHardMoneyLoanAttribute("buildandflip", "exitstrategy")
            setChoosedOthers(false)
        }
        if (e === "bridge") {
            form.setFieldsValue({exitStrategy: "fixandflip"})
            props.updateHardMoneyLoanAttribute("fixandflip", "exitstrategy")
            setChoosedOthers(false)
        }
        if (e === "fixrent") {
            form.setFieldsValue({exitStrategy: "fixandrent"})
            props.updateHardMoneyLoanAttribute("fixandrent", "exitstrategy")
            setChoosedOthers(false)
        }
        if (e === "fixflip") {
            form.setFieldsValue({exitStrategy: "fixandflip"})
            props.updateHardMoneyLoanAttribute("fixandflip", "exitstrategy")
            setChoosedOthers(false)
        }
    }

    const onChangeZip = (e) => {
        let formatVal = e.target.value.replace(/\-/g, "");
        form.setFieldsValue({
            zipCode: formatVal,
        })
        props.changeMainPropertyAttribute(String(formatVal), "zipcode")
    }
    useEffect(() => {
        fetchLoanLimits()
    }, []);
    const onChangePurchaseLoanAmountRequested = (val) => {
        props.updateHardMoneyLoanAttribute(String(val), "initialloanamount")
    }
    const onChangeRehabLoanAmountRequested = (val) => {
        if (!val) val = 0
        props.updateHardMoneyLoanAttribute(String(val), "rehabloanamount")
    }

    const onChangeRehabDetails = (val, key) => {
        let r = { ...props.application.hardmoneyloan.rehabdetails }
        r[key] = val
        props.updateHardMoneyLoanAttribute(r, "rehabdetails")
    }
    const onChangeRefinanceDetails = (val, key) => {
        let r = { ...props.application.hardmoneyloan.refinancedetails }
        r[key] = val
        props.updateHardMoneyLoanAttribute(r, "refinancedetails")
    }
    const cd = props.application.hardmoneyloan.targetclosingdate ? dayjs(props.application.hardmoneyloan.targetclosingdate, "MM/DD/YYYY") : ''
    const formInitial = {
        streetAddress: props.property.address,
        state: props.property.state,
        city: props.property.city,
        county: props.property.county,
        zipCode: props.property.zipcode,
        propertyType: props.property.propertytype,
        loanType: props.application.hardmoneyloan.loantype,
        loanPurpose: props.property.purpose==="construction"?"Select loan purpose":props.property.purpose==='purchase'?'purchaserehab':props.property.purpose,
        purchaseprice: props.property.salesprice,
        escrowDate: cd,
        initialAdvanceRequested: props.application.hardmoneyloan.initialadvancerequested,
        rehabRequested: props.application.hardmoneyloan.rehabloanamount || 0,
        totalLoanAmountRequested: props.property.purpose === "purchase" || props.property.purpose === "purchaserehab" ? props.application.hardmoneyloan.initialloanamount : props.application.hardmoneyloan.refinanceloanamount,
        loanTermRequested: props.application.hardmoneyloan.mortgagetermmonths || "",
        ARV: props.application.hardmoneyloan.projectedafterrepairvalue || 0,
        assignmentFee: props.application.hardmoneyloan.assignmentfee,
        liquidity: props.application.hardmoneyloan.liquidity,
        exitStrategy: props.application.hardmoneyloan.exitstrategy,
        rehabRemaining: props.application.hardmoneyloan.rehabdetails.rehabremaining,
        rehabCostSpentToDate: props.application.hardmoneyloan.rehabdetails.rehabcostspenttodate,
        addingSqft: props.application.hardmoneyloan.rehabdetails.addingsqft,
        sqftAdded: props.application.hardmoneyloan.rehabdetails.sqftadded,
        addingUnits: props.application.hardmoneyloan.rehabdetails.addingunits,
        originalPurchasePrice: props.application.hardmoneyloan.refinancedetails.originalPurchasePrice,
        dateOfPurchase: props.application.hardmoneyloan.refinancedetails.dateOfPurchase ? dayjs(props.application.hardmoneyloan.refinancedetails.dateOfPurchase, "MM/DD/YYYY") : '',
        estimatedCurrentValue: props.application.hardmoneyloan.refinancedetails.estimatedCurrentValue,
        existingLoanAmount: props.application.hardmoneyloan.refinancedetails.existingLoanAmount,
        existingLender: props.application.hardmoneyloan.refinancedetails.existingLender,
        existingLoanRate: props.application.hardmoneyloan.refinancedetails.existingLoanRate,
        existingLoanMaturityDate: props.application.hardmoneyloan.refinancedetails.existingLoanMaturityDate ? dayjs(props.application.hardmoneyloan.refinancedetails.existingLoanMaturityDate, "MM/DD/YYYY") : '',
        subordinateDebt: props.application.hardmoneyloan.refinancedetails.subordinateDebt,
        midConstruction: props.application.hardmoneyloan.refinancedetails.midConstruction,
        specifyothers: props.application.hardmoneyloan.exitstrategy,
    }

    const renderPurchaseLoanDetails = () => <>
        <Divider />
        <Row>
            <span className='form-sub-title'>Purchase Loan Details</span>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="purchaseprice"
                    label="Purchase price"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        min={0}
                        disabled={interviewfinished}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => props.changeMainPropertyAttribute(e, "salesprice")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="escrowDate"
                    label="Scheduled close of escrow date"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <DatePicker
                        style={{ width: '100%' }}
                        className='closing-date'
                        disabledDate={tcdDisabledDate}
                        placeholder="MM/DD/YYYY"
                        format="MM/DD/YYYY"
                        onChange={(e) => {
                            let dat = new Date(e)
                            props.updateHardMoneyLoanAttribute(e ? dayjs(dat).format('MM/DD/YYYY') : '', "targetclosingdate")
                        }}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="initialAdvanceRequested"
                    label="Initial advance requested"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => props.updateHardMoneyLoanAttribute(String(e), "initialadvancerequested")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="rehabRequested"
                    label="Rehab requested"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={onChangeRehabLoanAmountRequested}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="totalLoanAmountRequested"
                    label="Total loan amount requested"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={onChangePurchaseLoanAmountRequested}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="loanTermRequested"
                    label="Loan term requested"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={loanTermOptions}
                        placeholder="Select Loan term"
                        onChange={e => props.updateHardMoneyLoanAttribute(e, "mortgagetermmonths")}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="ARV"
                    label="Estimated after repair value (ARV)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => props.updateHardMoneyLoanAttribute(String(e), "projectedafterrepairvalue")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="assignmentFee"
                    label="Assignment fee (Y/N)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={YesNoOptions}
                        placeholder="Select Loan term"
                        onChange={e => props.updateHardMoneyLoanAttribute(e, "assignmentfee")}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="liquidity"
                    label="Liquidity"
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        placeholder='Optional'
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => onChangeLiquidity(e)}
                    />
                </Form.Item>
            </Col>
            
        </Row>
    </>

    const renderRehabLoanDetails = () => <>
        <Divider />
        <Row>
            <span className='form-sub-title'>Rehab Details</span>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="rehabRemaining"
                    label="Rehab remaining"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => onChangeRehabDetails(String(e), "rehabremaining")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="rehabCostSpentToDate"
                    label="Rehab cost spent to date"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        min={0}
                        disabled={interviewfinished}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => onChangeRehabDetails(String(e), "rehabcostspenttodate")}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="addingSqft"
                    label="Adding sqft (Y/N)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={YesNoOptions}
                        placeholder=""
                        onChange={e => onChangeRehabDetails(e, "addingsqft")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="sqftAdded"
                    label="Sqft added"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="N/A" onChange={e => onChangeRehabDetails(e.target.value, "sqftadded")} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="addingUnits"
                    label="Adding units (Y/N)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={YesNoOptions}
                        placeholder=""
                        onChange={e => onChangeRehabDetails(e, "addingunits")}
                    />
                </Form.Item>
            </Col>
        </Row>
    </>

    const renderRefinanceLoanDetails = () => <>
        <Divider />
        <Row>
            <span className='form-sub-title'>Refinance Loan Details</span>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="originalPurchasePrice"
                    label="Original purchase price"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => onChangeRefinanceDetails(String(e), "originalPurchasePrice")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="dateOfPurchase"
                    label="Date of purchase"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <DatePicker style={{ width: '100%' }} className='closing-date' disabledDate={refiDisabledDate} placeholder="MM/DD/YYYY" format="MM/DD/YYYY" onChange={e => {
                        let dat = new Date(e)
                        onChangeRefinanceDetails(e ? dayjs(dat).format('MM/DD/YYYY') : '', "dateOfPurchase")
                    }} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="initialAdvanceRequested"
                    label="Initial advance requested"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => props.updateHardMoneyLoanAttribute(String(e), "initialadvancerequested")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="rehabRequested"
                    label="Rehab requested"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        max={10000000}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={onChangeRehabLoanAmountRequested}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="totalLoanAmountRequested"
                    label="Total loan amount requested"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => props.updateHardMoneyLoanAttribute(String(e), "refinanceloanamount")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="loanTermRequested"
                    label="Loan term requested"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={loanTermOptions}
                        placeholder="Select Loan term"
                        onChange={e => props.updateHardMoneyLoanAttribute(e, "mortgagetermmonths")}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="estimatedCurrentValue"
                    label="Estimated current value (LTV)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => onChangeRefinanceDetails(String(e), "estimatedCurrentValue")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="ARV"
                    label="Estimated after repair value (ARV)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => props.updateHardMoneyLoanAttribute(String(e), "projectedafterrepairvalue")}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="existingLoanAmount"
                    label="Existing loan amount (Payoff)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => onChangeRefinanceDetails(String(e), "existingLoanAmount")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="existingLender"
                    label="Existing lender"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={e => onChangeRefinanceDetails(e.target.value, "existingLender")} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="existingLoanRate"
                    label="Existing loan rate"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        addonAfter="%"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        max={100}
                        min={0}
                        onChange={e => onChangeRefinanceDetails(String(e), "existingLoanRate")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="existingLoanMaturityDate"
                    label="Existing loan maturity date"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <DatePicker style={{ width: '100%' }} className='closing-date' disabledDate={disabledDate} placeholder="MM/DD/YYYY" format="MM/DD/YYYY" onChange={e => {
                        let dat = new Date(e)
                        onChangeRefinanceDetails(e ? dayjs(dat).format('MM/DD/YYYY') : '', "existingLoanMaturityDate")
                    }} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="subordinateDebt"
                    label="Subordinate debt (Y/N-$)"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={YesNoOptions}
                        placeholder="Select Loan term"
                        onChange={e => onChangeRefinanceDetails(e, "subordinateDebt")}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="liquidity"
                    label="Liquidity"
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        placeholder='Optional'
                        prefix="$"
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={0}
                        formatter={inputFormatter}
                        parser={inputParser}
                        onChange={e => onChangeLiquidity(e)}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            {/* <Col span={12}>
                <Form.Item
                    name="exitStrategy"
                    label="Exit strategy"
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={exitStrategyOptions}
                        placeholder="Optional"
                        onChange={e => props.updateHardMoneyLoanAttribute(e, "exitstrategy")}
                    />
                </Form.Item>
            </Col> */}
            <Col span={12}>
                <Form.Item
                    name="midConstruction"
                    label="Mid construction (Y/N)"
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={YesNoOptions}
                        placeholder=""
                        onChange={e => onChangeRefinanceDetails(e, "midConstruction")}
                    />
                </Form.Item>
            </Col>
        </Row>
    </>

    const renderActionButtons = () => <Row gutter={10}>
        <Col>
            <Form.Item>
                <Button
                    disabled={false}
                    type="default"
                    className='secondary-btn'
                    onClick={props.back}
                >
                    Back
                </Button>
            </Form.Item>
        </Col>
        <Col>
            <Form.Item>
                <Button
                    type="primary"
                    className='common-btn'
                    htmlType="submit"
                    disabled={false}
                    onClick={() => {
                        // Scroll to top after click continue
                        window.document.body.scrollIntoView({ behavior: "smooth", inline: "nearest" });
                    }}
                >
                    Continue
                </Button>
            </Form.Item>
        </Col>
    </Row>

    const onFieldsChanged = (changedValues, allValues) => {
        // console.log(changedValues, allValues)
        const hasError = form.getFieldsError().some(x => x.errors.length > 0)
        if (hasError) props.resetProgress()
    }

    const handleAddRehab = (e) => {
        setRehabAdded(e.target.checked)
        props.updateHardMoneyLoanAttribute(e.target.checked, "rehabloan")
    }

    return (
        <div className="borrower-form text-left">
            <Form
                className='application-form'
                layout='vertical'
                form={form}
                disabled={interviewfinished}
                onFieldsChange={onFieldsChanged}
                scrollToFirstError={{
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'center',
                    block: 'center',
                }}
                onFinish={onFinish}
                initialValues={formInitial}
                validateMessages={validateMessages}
                size="large"
            >
                <Row>
                    <span className='form-title'>Property Information</span>
                </Row>
                <Row gutter={30}>
                    <Col span={24}>
                        <Form.Item
                            name="streetAddress"
                            label="Property address"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <GoogleAutocomplete
                                disabled={interviewfinished}
                                placeholder="Street address"
                                getPlace={getAddress}
                                onChange={(e) => { props.changeMainPropertyAttribute(e, "address") }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="state"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                options={stateOptions}
                                onChange={onChangeState}
                                placeholder="Select your state"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="county"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                options={countyOptions}
                                placeholder="Select your county"
                                onChange={(e) => props.changeMainPropertyAttribute(e, "county")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="zipCode"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input
                                type='text'
                                style={{ width: '100%' }}
                                placeholder="Zip Code"
                                onChange={onChangeZip}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={30}>
                    <Col span={12}>
                        <Form.Item
                            name="propertyType"
                            label="Property type"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                options={PropertyTypeOptions}
                                placeholder="Select Property type"
                                onChange={(e) => props.changeMainPropertyAttribute(e, "propertytype")}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <span className='form-title'>Loan Information</span>
                </Row>
                <Row gutter={30}>
                    <Col span={12}>
                        <Form.Item
                            name="loanPurpose"
                            label={<div>Loan purpose<Popover rootClassName="loanPurposeTooltip" content={loanPurposePopovercontent} title={<div className='exitstrategyTipTitle'><img className='mr-2' src="/images/lightBulb.png" />Loan purpose</div>}>
                            <QuestionCircleOutlined className="tips-icon ml-1" />
                        </Popover></div>}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                options={loanPurposeOptions}
                                placeholder="Select Loan purpose"
                                onChange={onChangeLoanPurpose}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="loanType"
                            label={<div>Loan type<Popover rootClassName="loanTypeTooltip" content={loanTypePopovercontent} title={<div className='exitstrategyTipTitle'><img className='mr-2' src="/images/lightBulb.png" />What are the loan types?</div>}>
                                <QuestionCircleOutlined className="tips-icon ml-1" />
                            </Popover></div>}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                options={loanTypeOptions}
                                placeholder="Select Loan type"
                                onChange={e => updateLoanTypes(e)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="exitStrategy"
                            label={<div>Exit strategy<Popover rootClassName='exitstrategyTooltip' content={exitStrategyPopovercontent} title={<div className='exitstrategyTipTitle'><img className='mr-2' src="/images/lightBulb.png" />What are the exit strategies?</div>}>
                                <QuestionCircleOutlined className="tips-icon ml-1" />
                            </Popover></div>}
                            onClick={disabledOnclick}
                        >
                            <Select
                                allowClear
                                options={loanTypeExitStrategyMap[props.application.hardmoneyloan.loantype]}
                                placeholder="Select exit strategy"
                                onChange={e => {
                                    {
                                        if (e === "others"){
                                            setChoosedOthers(true)
                                        }
                                        props.updateHardMoneyLoanAttribute(e, "exitstrategy")
                                        
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    {choosedOthers && <Col span={12}>
                        <Form.Item
                            name="specifyothers"
                            label="Please specify"
                            onClick={disabledOnclick}
                        >
                            <Input placeholder="" onChange={e => {
                                // props.updateHardMoneyLoanAttribute(e.target.value, "exitstrategy")
                                setOtherSpecify(e.target.value)
                                }} 
                            />
                        </Form.Item>
                    </Col>}
                </Row>
                {(loanPurpose === 'purchaserehab' || loanPurpose === 'purchase') ? renderPurchaseLoanDetails() : null}
                {(loanPurpose === 'purchaserehab' || loanPurpose === 'purchase') && <Row gutter={80}>
                    <Col span={24} md={12}>
                        <Form.Item style={{marginBottom: '30px'}}>
                            <div className='check-item'>
                                <div><span style={{marginRight: '12px'}}>Include a rehab loan?</span><Checkbox checked={rehabAdded} onChange={handleAddRehab} /></div>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>}
                {(loanPurpose === 'purchaserehab' || loanPurpose === 'purchase') && rehabAdded ? renderRehabLoanDetails() : null}
                {/* Refinance Loan Details */}
                {loanPurpose === 'refinance' || loanPurpose === 'cashoutrefinance' ? renderRefinanceLoanDetails() : null}
                {renderActionButtons()}
            </Form>
        </div>
    )
}

PropertyAndLoan = connect(mapStateToProps, mapDispatchToProps)(PropertyAndLoan);
