import React, { Component } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Button from 'react-bootstrap/Button'
import { LinkContainer } from 'react-router-bootstrap'
import { withRouter } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import * as com from "../Common.js"
import '../App.css'
import './Landing.css'
import Auth from "../Auth"
import 'bootstrap/dist/css/bootstrap.min.css'
import { Dropdown, Space } from 'antd'
import { DownOutlined, SmileOutlined } from '@ant-design/icons'
import { connect } from "react-redux"
import {
    LeftOutlined
} from '@ant-design/icons'
import { matchPath } from "react-router"

class LandingMenu extends Component {
    constructor(props) {
        super(props)
        let sess = window.sessionStorage.getItem("ZeitroA")
        this.state = {
            noactivesession: (null == sess || sess.length === 0),
            redirect: "",
            showAuth: false,
            logo: '/logo.png',
            companyName: 'Zeitro',
        }
        this.timerId = null
        window.sessionStorage.setItem("step", 0)

        this.public = "ml-2 mr-2 pt-4 mb-3 py-0"
        this.private = "mt-1 mb-1 py-0 my-0"
    }
    ontimer = () => {
        let sess = window.sessionStorage.getItem("ZeitroA")
        let notalive = (null == sess || sess.length === 0)

        if (this.state.noactivesession !== notalive) {
            this.setState({ noactivesession: notalive })
        } else {
            if (window.location.pathname === "/") {
                this.setState({ showAuth: true })
                window.document.dispatchEvent(new Event('reauthenticate'), "")
            }
        }
        this.timerId = setTimeout(this.ontimer, 1000)
    }

    loadLoanOfficer = () => {
        let token = com.getUserToken()

        fetch('/borrower/getloanofficer', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    return
                }
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Status)
                    } else {
                        this.setState({ logo: js.Image, companyName: js.Name })
                    }
                })
            }
        )
            .catch(function (err) {
                console.log('Fetch Error :', err)
            })
    }

    componentDidMount() {
        this.timerId = setTimeout(this.ontimer, 1000)
        document.getElementById('')
        //style="background-color: rgb(50, 194, 77); color: rgb(255, 255, 255); fill: rgb(50, 194, 77);"

        let searchparams = new URLSearchParams(window.location.search)
        let customerid = searchparams.get("customerid")
        if (!customerid) {
            customerid = com.getCustomerId()
        }


        if (customerid === "individualcustomers") {
            this.loadLoanOfficer()
        } else {
            fetch('/data/companycustomization', {
                headers: {
                    'X-CustomerId': customerid || 'zeitro',
                }
            })
                .then(async response => {
                    if (!response.ok) {
                        return
                    }
                    response.json().then(json => {
                        this.setState({ companyName: json.CompanyName })
                        let customization = JSON.parse(json.Customization)
                        if (Object.keys(customization).includes('primaryColor')) {
                            this.setState({ logo: `data:image/png;base64, ${customization.icon}` })
                        }
                    })
                })
        }
    }

    componentWillUnmount() {
        if (this.timerId)
            clearTimeout(this.timerId)
        this.timerId = null
    }

    renderNav = () => {

        let path = this.props.location
        let inapp = false
        if (path.pathname.indexOf('/application/') >= 0 || path.pathname.indexOf('/application/') >= 0) {
            inapp = true
        }

        if (this.state.noactivesession)
            return (
                <div>
                    <LinkContainer to="/services/signup">
                        <Button id="signup" variant="primary" size="sm" className="mr-3 acct lpbutton" >Start Your Loan Application</Button>
                    </LinkContainer>
                    <LinkContainer to="/services/signin">
                        <Button id="login" variant="primary" size="sm" className="mr-3 acct lpbutton" >Login</Button>
                    </LinkContainer>
                </div>
            )
        else
            return (
                <div>
                    {!inapp ?
                        <LinkContainer to="/app/interview/main">
                            <Button variant="primary" size="sm" className="mr-3  acct lpbutton" >Your Loan</Button>
                        </LinkContainer>
                        : ""}
                    {!inapp ?
                        <LinkContainer to="/services/logout">
                            <Button variant="primary" className="mr-3 acct lpbutton" >Logout</Button>
                        </LinkContainer> : ""}

                </div>
            )
    }
    question = () => {
        document.getElementById("alterra-chat-button").click()
    }
    render = () => {
        let path = this.props.location
        let inapp = false
        if (path.pathname.indexOf('/app/') >= 0 || path.pathname.indexOf('/application/') >= 0 || path.pathname === "/preapp") {
            inapp = true
        }
        return (
            <div name="home" className="w-100 text-center deepestblue">
                <Navbar id={this.props.app ? "navbb" : "navb"}
                    style={this.props.app ? { marginLeft: '0px', paddingLeft: '0px', fontSize: '0.8em' } : { marginTop: '0px', fontSize: '0.8em' }}
                    className={this.props.app ? this.private : this.public} collapseOnSelect expand="lg" variant="light"  >
                    <Navbar.Brand className="text-left mr-2" >

                        {inapp ? <div className="ml-2">
                            <a href="#" onClick={e => { window.location.href = com.redirectPublicWebsite() }}>
                                <img className={this.props.app ? "logoprivate" : "logo"} src={this.state.logo} alt="zeitro" />
                                <div className="navbar-text pl-2 brandtext">{this.state.companyName}</div>
                            </a >       </div>
                            :
                            <Nav.Link as={HashLink} to="/#top">
                                <img className={this.props.app ? "logoprivate" : "logo"} src={this.state.logo} alt="zeitro" />
                                <div className="navbar-text pl-2 brandtext">{this.state.companyName}</div>
                            </Nav.Link >
                        }
                    </Navbar.Brand>


                </Navbar>
                {this.state.showAuth ? <Auth /> : ""}
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
    }
}

const mapDispatchToProps = (dispatch) => ({
})

export class PrequalHeader extends Component {
    constructor(props) {
        super(props)
        let sess = window.sessionStorage.getItem("ZeitroA")
        this.state = {
            noactivesession: (null == sess || sess.length === 0),
            redirect: "",
            showAuth: false,
            logo: '/logo.png',
            companyName: '',
            customerid: "",
            loname: "",
            lophone: "",
            loemail: "",
        }
        this.timerId = null
        window.sessionStorage.setItem("step", 0)

        this.public = "ml-2 mr-2 pt-4 mb-3 py-0"
        this.private = "mt-1 mb-1 py-0 my-0"
    }
    ontimer = () => {
        let sess = window.sessionStorage.getItem("ZeitroA")
        let notalive = (null == sess || sess.length === 0)

        if (this.state.noactivesession !== notalive) {
            this.setState({ noactivesession: notalive })
        } else {
            if (window.location.pathname === "/") {
                this.setState({ showAuth: true })
                window.document.dispatchEvent(new Event('reauthenticate'), "")
            }
        }
        this.timerId = setTimeout(this.ontimer, 1000)
    }

    loadLoanOfficer = (loid) => {
        fetch("/data/getindividualcustomerprofilebyid/" + loid, {
            method: "GET",
            headers: {
                Cache: "no-cache",
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.log(
                        "Looks like there was a problem. Status Code: " + response.status
                    )
                    return
                }
                response.json().then((js) => {
                    this.setState({ companyName: js.FirstName + " " + js.LastName, logo: js.Image })

                    sessionStorage.setItem("paths", JSON.stringify({
                        website: js.Domain,
                        login: js.LoginPath,
                    }))
                })
            })
            .catch(function (err) {
                console.log("Fetch Error /individualcustomers/getprofilebyid/:-S", err)
            })
    };
    getLoadLoanOfficer = () => {
        let token = com.getUserToken()

        fetch('/borrower/getloanofficer', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
                "X-Borrower": this.props.borrowerid
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status)
                    window.document.dispatchEvent(new Event('reauthenticate'), "")

                    return
                }
                // Examine the text in the response
                response.json().then(js => {

                    if (js.Status !== "OK") {
                        console.log("Error: " + js.Status)
                    } else {
                        this.setState({ loname: js.Name, lophone: js.Phone, loemail: js.Email })
                    }
                })
            }
        )
            .catch(function (err) {
                console.log('Fetch Error :', err)
            })
    }
    componentDidMount() {
        this.getLoadLoanOfficer()
        this.timerId = setTimeout(this.ontimer, 1000)
        document.getElementById('')

        let searchparams = new URLSearchParams(window.location.search)
        let customerid = searchparams.get("customerid")
        if (this.props.customerid) {
            customerid = this.props.customerid
        }
        let loid = searchparams.get("loid")
        if (!customerid) {
            customerid = com.getCustomerId()
        }
        this.setState({ customerid: customerid })
        if (this.props.setid) {
            sessionStorage.setItem("cid", customerid)
            if (loid !== null) {
                sessionStorage.setItem("loid", loid)
            }
        }
        if (customerid === null) {
            customerid = sessionStorage.getItem("cid")
        }
        if (loid === null) {
            loid = sessionStorage.getItem("loid")
        }
        if (customerid === "individualcustomers") {
            this.loadLoanOfficer(loid)
        } else {
            fetch('/data/companycustomization', {
                headers: {
                    'X-CustomerId': customerid,
                }
            })
                .then(async response => {
                    if (!response.ok) {
                        return
                    }
                    response.json().then(json => {
                        this.setState({ companyName: json.CompanyName })
                        let customization = JSON.parse(json.Customization)
                        sessionStorage.setItem("paths", JSON.stringify(json.Paths))
                        if (Object.keys(customization).includes('primaryColor')) {
                            this.setState({ logo: `data:image/png;base64, ${customization.icon}` })
                        }
                    })
                })
        }
    }

    componentWillUnmount() {
        if (this.timerId)
            clearTimeout(this.timerId)
        this.timerId = null
    }

    renderNav = () => {

        let path = this.props.location
        let inapp = false
        if (path.pathname.indexOf('/app/') >= 0 || path.pathname.indexOf('/application/') >= 0) {
            inapp = true
        }

        if (this.state.noactivesession)
            return (
                <div>
                    <LinkContainer to="/services/signup">
                        <Button id="signup" variant="primary" size="sm" className="mr-3 acct lpbutton" >Start Your Loan Application</Button>
                    </LinkContainer>
                    <LinkContainer to="/services/signin">
                        <Button id="login" variant="primary" size="sm" className="mr-3 acct lpbutton" >Login</Button>
                    </LinkContainer>
                </div>
            )
        else
            return (
                <div>
                    {!inapp ?
                        <LinkContainer to="/app/interview/main">
                            <Button variant="primary" size="sm" className="mr-3  acct lpbutton" >Your Loan</Button>
                        </LinkContainer>
                        : ""}
                    {!inapp ?
                        <LinkContainer to="/services/logout">
                            <Button variant="primary" className="mr-3 acct lpbutton" >Logout</Button>
                        </LinkContainer> : ""}

                </div>
            )
    }
    question = () => {
        document.getElementById("alterra-chat-button").click()
    }
    render = () => {
        let paths = sessionStorage.getItem("paths")
        let redirectSite = "/"
        if (paths !== null) {
            redirectSite = JSON.parse(paths)["website"]
        }
        let asklogin = sessionStorage.getItem("ZeitroA") === null
        let isNewApplication = window.location.pathname === '/services/preapp' || window.location.pathname === '/services/preapp/personalinfo'
        let isEmailverification = window.location.pathname === '/services/preapp/emailverification'
        let isPrequal = false
        try {
            if (this.props.application.property.purpose === "purchase" && !this.props.application.property.hascontract) {
                isPrequal = true
            }
        } catch (error) {

        }
        let propertyType = {
            'singlefamilydetached': 'Single Family',
            'singlefamilyattached': 'Townhouse',
            'twotofourfamily': 'Multi-family',
            'condominium': 'Condo',
            'mobilehome': 'Mobile home',
        }
        let navRight = () => {
            if (isNewApplication) {
                return (
                    <Navbar.Text className='d-flex ' style={{ fontSize: 16 }}>
                        <p className='hideOnMobile' style={{ paddingTop: 7, marginBottom: 0 }}>
                            <Dropdown
                                menu={{
                                    items,
                                }}
                            >
                                <a onClick={(e) => e.preventDefault()} className="d-flex" style={{ color: "#325CE9" }}>

                                    {com.getCustomerEmail()}
                                    <DownOutlined style={{ marginLeft: 5, marginTop: 4 }} />

                                </a>
                            </Dropdown>
                        </p>
                    </Navbar.Text>
                )
            } else {
                return (
                    <div className='nav-exit-btn'><a rel="noopener noreferrer" href="/application/logout">Exit</a></div>
                )
            }
        }
        const items = [
            {
                label: (
                    <a rel="noopener noreferrer" href={isPrequal ? "/application/myloans" : "/application/myloans"}>
                        My loans
                    </a>
                ),
                key: '0',
            },
            {
                label: (
                    <a rel="noopener noreferrer" href={isPrequal ? "/application/interview/main" : "/application/interview/main"}>
                        Current application
                    </a>
                ),
                key: '1',
            },
            {
                label: (
                    <a rel="noopener noreferrer" href={isPrequal ? "/application/tasks" : "/application/tasks"}>
                        Tasks
                    </a>
                ),
                key: '1',
            },
            {
                type: 'divider',
            },
            {
                label: (
                    <a rel="noopener noreferrer" href="/application/logout">
                        Log out
                    </a>
                ),
                key: '3',
            },
        ]
        let navText = () => {
            if (window.location.pathname.split("/").pop() === "yourloans") {
                return 'My loans'
            } else if (this.props.progress.interview.step < com.stageToNumberApplication.rates) {
                return sessionStorage.getItem('CompanyName')
            } else if (this.props.progress.interview.step === com.stageToNumberApplication.rates) {
                return 'My rates'
            } else if (this.props.progress.interview.step >= com.stageToNumberApplication.declarations) {
                return 'Declarations'
            }
        }
        let applicationInfo = [
            {
                label: 'Property type',
                value: propertyType[this.props.application.property.propertytype]
            }
        ]
        let isHardMoneyLoan = this.props.application.product.mortgageapplied === "hardmoneyloan"
        let pageGroupName = ''
        const matchHardMoneyLoanPath = matchPath(window.location.pathname, {
            path: "/application/hardMoneyLoan/interview/main/:page",
            exact: true,
            strict: false
        })
        if (matchHardMoneyLoanPath && matchHardMoneyLoanPath.params['page']) {
            isHardMoneyLoan = true
            switch (matchHardMoneyLoanPath.params['page']) {
                case 'borrower':
                case 'property':
                case 'experience':
                case 'declarations':
                    pageGroupName = 'My application'
                    break
                case 'myTasks':
                    pageGroupName = 'My tasks'
                    break
                case 'documentsUploaded':
                case 'documentsReceived':
                    pageGroupName = 'My documents'
                default:
                    break
            }
        }

        if (!isPrequal) {
            applicationInfo.push({
                label: 'Address',
                value: this.props.application.property.address === '' ? '-' : this.props.application.property.address
            })
        }
        const showPurpose = () => {
            if (this.props.application.property.purpose === null) return "Loan Application"
            if (this.props.application.property.purpose === "cashoutrefinance") return "Cashout"
            if (isHardMoneyLoan) return "Hard Money Loan"
            return "Home " + this.props.application.property.purpose.charAt(0).toUpperCase() + this.props.application.property.purpose.slice(1)
        }
        const { serialnumber } = this.props.application.hardmoneyloan
        return (
            // <div ></div>
            <header className="navbar-fixed-top cbp-af-header new-navbar">
                <Navbar expand="lg" variant="prequalNav justify-content-between" className={asklogin || isNewApplication ? 'asklogin-bar' : 'login-bar'}>
                    {
                        asklogin || isNewApplication ? (
                            <>
                                {
                                    (!asklogin && window.location.pathname !== '/services/preapp/personalinfo' && window.location.pathname !== '/services/preapp') || isEmailverification ? (
                                        <>
                                            <div className="header-back" onClick={() => this.props.history.go(-1)}><LeftOutlined style={{color: '#373737', fontSize: 16, marginRight: 12}} />Back</div>
                                            <Navbar.Brand href="#" className='companyName' style={{color: "black"}} onClick={()=>{
                                                window.location.href = redirectSite
                                            }}>{this.state.customerid === "gmcc" ? <img style={{height:"35px"}} src="/images/gmccLogo.png"/>:<span>{this.state.companyName}</span>}</Navbar.Brand>
                                        </>
                                    ) : (
                                        <Navbar.Brand href="#" className='pl-1' style={{ color: "black" }} onClick={() => {
                                            window.location.href = redirectSite
                                        }}> {this.state.customerid === "gmcc" ? <img style={{height:"35px"}} src="/images/gmccLogo.png"/>:<span>{this.state.companyName}</span>} </Navbar.Brand>
                                    )
                                }

                            </>
                        ) : (
                            <div style={{ paddingLeft: '15px', fontSize: '18px' }}>
                                {isHardMoneyLoan ? <>
                                    <a href="/application/myloans">My Loans</a>
                                    {
                                        serialnumber && <>
                                            <img style={{ width: 20, height: 20, margin: '0 10px 0 10px' }} src="/images/right-arrow.svg" />
                                            <span style={{ color: '#222222' }}>Loan {serialnumber}</span>
                                        </>
                                    }
                                    {
                                        pageGroupName && <>
                                            <img style={{ width: 20, height: 20, margin: '0 10px 0 10px' }} src="/images/right-arrow.svg" />
                                            <span style={{ color: '#222222' }}>{pageGroupName}</span>
                                        </>
                                    }
                                </>: <>
                                    <span style={{ color: '#6E6E70' }}>{navText()}</span>
                                    <img style={{ width: 20, height: 20, margin: '0 10px 0 10px' }} src="/images/right-arrow.svg" />
                                    <span style={{ color: '#222222' }}>{showPurpose()}</span>
                                </>}
                            </div>
                        )
                    }
                    {asklogin ? <Navbar.Text className='d-flex' style={{fontSize:16, alignItems:"center"}}>
                        <div className='sign-text' style={{color: '#222'}}>Already have an account?</div>
                        <div onClick={()=> window.location.href="/services/signin?customerid=" + this.state.customerid}
                            style={{cursor:"pointer",color: "white", backgroundColor:"#325CEB", padding:"5px 20px",borderRadius:"4px", marginLeft:"10px"}}>
                                Sign in
                        </div>
                    </Navbar.Text > : navRight()}
                </Navbar>
                {/* {asklogin || isNewApplication ? (
                    ''
                ) : (
                    <div className='quick-view'>
                        <span style={{ fontSize: '14px', color: '#222', fontWeight: 500 }}>Quick View</span>
                        <div className='nav-application'>
                            <div className='info-item align-items-center' style={{ paddingRight: '20px', borderRight: '1px solid #ECECEC' }}>
                                <div className='info-item-label'>Loan officer: </div>
                                <div className='info-item-value' style={{ color: '#325CEB', borderRight: '1px solid #ECECEC', paddingRight:"10px" }}>{this.state.loname}</div>
                                <div className='info-item-value' style={{ color: '#325CEB', borderRight: '1px solid #ECECEC', paddingRight:"10px", paddingLeft:"10px" }}>{this.state.lophone}</div>
                                <div className='info-item-value' style={{ color: '#325CEB',  paddingLeft:"10px" }}>{this.state.loemail}</div>


                            </div>
                        
                            {
                                applicationInfo.map((ele, index) => {
                                    return (
                                        <div className='info-item' key={index}>
                                            <div className='info-item-label'>{ele.label}: </div>
                                            <div className='info-item-value'>{ele.value}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )} */}
                {this.state.showAuth ? <Auth /> : ""}
            </header>
        )
    }
};

PrequalHeader = withRouter(connect(mapStateToProps, mapDispatchToProps)(PrequalHeader))


export default withRouter(LandingMenu)

export class CustomerHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect: "",
            showAuth: false,
            logo: '/logo.png',
            companyName: '',
            customerid: "",
        }

        this.public = "ml-2 mr-2 pt-4 mb-3 py-0"
        this.private = "mt-1 mb-1 py-0 my-0"
    }

    loadLoanOfficer = (loid) => {
        fetch("/data/getindividualcustomerprofilebyid/" + loid, {
            method: "GET",
            headers: {
                Cache: "no-cache",
            },
        })
            .then((response) => {
                if (response.status !== 200) {
                    console.log(
                        "Looks like there was a problem. Status Code: " + response.status
                    )
                    return
                }
                // Examine the text in the response
                response.json().then((js) => {
                    this.setState({ companyName: js.FirstName + " " + js.LastName, logo: js.Image })

                    sessionStorage.setItem("paths", JSON.stringify({
                        website: js.Domain,
                        login: js.LoginPath,
                    }))
                })
            })
            .catch(function (err) {
                console.log("Fetch Error /individualcustomers/getprofilebyid/:-S", err)
            })
    };
    componentDidMount() {
        let searchparams = new URLSearchParams(window.location.search)
        let customerid = searchparams.get("customerid")
        let loid = searchparams.get("loid")
        if (!customerid) {
            customerid = com.getCustomerId()
        }
        if (this.props.setid) {
            sessionStorage.setItem("cid", customerid)
            sessionStorage.setItem("loid", loid)
        }
        if (customerid === null) {
            customerid = sessionStorage.getItem("cid")
        }
        if (loid === null) {
            loid = sessionStorage.getItem("loid")
        }
        this.setState({ customerid: customerid })


        if (customerid === "individualcustomers") {
            this.loadLoanOfficer(loid)
        } else {
            fetch('/data/companycustomization', {
                headers: {
                    'X-CustomerId': customerid,
                }
            })
                .then(async response => {
                    if (!response.ok) {
                        return
                    }
                    response.json().then(json => {
                        this.setState({ companyName: json.CompanyName })
                        let customization = JSON.parse(json.Customization)
                        sessionStorage.setItem("paths", JSON.stringify(json.Paths))
                        if (Object.keys(customization).includes('primaryColor')) {
                            this.setState({ logo: `data:image/png;base64, ${customization.icon}` })
                        }
                    })
                })
        }
    }

    componentWillUnmount() {
        if (this.timerId)
            clearTimeout(this.timerId)
        this.timerId = null
    }


    question = () => {
        document.getElementById("alterra-chat-button").click()
    }
    render = () => {
        let paths = sessionStorage.getItem("paths")
        let redirectSite = "/"
        if (paths !== null) {
            redirectSite = JSON.parse(paths)["website"]
        }
        return (
            <header className="navbar-fixed-top cbp-af-header">
                <Navbar expand="lg" variant="prequalNav">
                    <a>
                        {/* <img className={this.props.app ? "logoprivate" : "logo"} src={this.state.logo} alt="zeitro" /> */}
                        <Navbar.Brand href="#" className='pl-1' style={{ color: "black" }} onClick={() => {
                            window.location.href = redirectSite
                        }}>{this.state.customerid === "gmcc" ? <img style={{ height: "35px" }} src="/images/gmccLogo.png" /> : <span>{this.state.companyName}</span>}</Navbar.Brand>
                    </a>

                </Navbar>
                {this.state.showAuth ? <Auth /> : ""}
            </header>
        )
    }
};
