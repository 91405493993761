import TopNavBar from './TopNavbar'
import { LeftOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Input, Button } from 'antd'
import { SetUpOrChangePassword } from '../api/api'
import { useState } from 'react'
function SetUpPassword() {
    const history = useHistory()
    const subscription = useSelector(state => {
        return state.application
    })
    const [form] = Form.useForm()
    const [isOk, setIsOk] = useState(false)
    const validateRules = [
        {
            validator: (_, value) => {
                if (value && value.length < 8) {
                    return Promise.reject(new Error('Must be at least 8 characters .'))
                }
                return Promise.resolve()
            },
        },

    ]
    const onFinish = async (values) => {


        let token = sessionStorage.getItem("ZeitroA")
        const res = await fetch('/auth/setuporchangepassword', {
            method: 'post',
            headers: {
                Authorization: "Bearer " + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                CurrentPassword: values.current,
                Email: subscription.borrower.email,
                Password: values.password
            })
        })
        if (res.status === 500) {
            alert('The current password is incorrect')
        } else {
            history.push('/application/myloans')
        }
        console.log(res)



    }
    return (
        <div className='d-flex ' style={{ height: '100vh', flexDirection: "column" }} >
            <TopNavBar >
                <div style={{
                    fontSize: 16,
                    fontWeight
                        : 500,

                }}>

                    <Link to='/application/myloans' style={{ color: '#222' }}>
                        <LeftOutlined />     Back
                    </Link>

                </div>
            </TopNavBar>
            <div style={{ width: '35%', margin: "auto", textAlign: 'center', minHeight: "60vh" }}>

                <div className='mt-4' style={{ color: "#222", fontSize: 32, fontWeight: 700 }}>
                    Change your password
                </div>
                {<Form form={form} layout="vertical" className='mt-4' onFinish={onFinish}>
                    <Form.Item

                        label="Enter your current password"
                        name="current"
                        rules={[
                            {
                                required: true,
                                message: 'Enter your current password!',
                            },

                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item

                        label="Enter your new password"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Enter your new password!',
                            },
                            ...validateRules
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Confirm your new password"
                        name="password"
                        dependencies={['username']}
                        rules={[
                            {
                                required: true,
                                message: 'Confirm your new password!',
                            }, ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('username') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(new Error('The new password that you entered do not match!'))
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Button type='primary' style={{ width: "100%", height: 44 }} htmlType="submit">Change now</Button>
                </Form>}


            </div>
        </div>
    )

}
export default SetUpPassword