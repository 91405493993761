import React, { Component } from "react";
import '../App.css';
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import Dropzone from "./Dropzone";
import { connect } from 'react-redux'
import "./Upload.css";
import Progress from "./Progress";
const defBanner = `Please drag the documents from your computer, drop them into the circle, and click Upload. All your documents will be validated.`
const loadingBanner = <div><Spinner  size="sm" className="ml-2 mr-1 ptext-center" 
animation="border"  variant="primary" role="status" />Please ensure the upload is complete before closing the window.</div>
const loadMore = "Your upload is done. You can drag'n'drop and upload additional files."


const mapStateToProps = (state) => {
  return {
      application: state.application,
  }
}

const mapDispatchToProps = (dispatch) => ({


});
class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      banner: defBanner,
      validated: false,
      forwhom: "borrower",
      taskid: "other",
    };
    this.form = React.createRef();
    this.comments = []
    
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  onFilesAdded = (files) => {

    if (this.state.successfullUploaded) {
      this.setState({ files: [], successfullUploaded: false })
    }
    let cleanfiles = []

    files.forEach(f => {
      if (this.state.files.every((x) => x.name !== f.name)) {
        cleanfiles.push(f)
      }
    })
    this.setState(prevState => ({
      files: prevState.files.concat(cleanfiles)
    }));
  }

  async _uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    this.setState({ banner: loadingBanner })
    const promises = [];
    this.state.files.forEach(file => {
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);

      this.setState({ successfullUploaded: true, uploading: false });
      this.setState({ banner: loadMore })
      if ( this.props.onUploaded ) {
        this.props.onUploaded()
      }

    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
      alert("Upload error! Please try again")
      this.setState({ banner: defBanner })
    }
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        resolve(req.response);
      });

      req.upload.addEventListener("error", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      const formData = new FormData();
      formData.append("file", file, file.name);

      let token = window.sessionStorage.getItem("ZeitroA");
      if (token === null) {
        window.location.href = "/#home";
        return;
      }
      req.open("POST", "/docs/upload");
      req.setRequestHeader("Authorization", "Bearer " + token)
      if ("undefined" === typeof this.props.taskid) {
        req.setRequestHeader("X-Comment", this.comments[file.name])
        req.setRequestHeader("X-ForWhom", this.state.forwhom)
        req.setRequestHeader("X-Taskid", this.state.taskid)
        req.setRequestHeader("X-Id", 0)

      } else {
        req.setRequestHeader("X-Comment", this.props.name)
        req.setRequestHeader("X-ForWhom", this.props.forwhom)
        req.setRequestHeader("X-Taskid", this.props.taskid)
        req.setRequestHeader("X-Id", this.props.id)

      }

      req.setRequestHeader("Cache", "no-cache")

      req.send(formData);
    });
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper p-0 m-0">

          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
        </div>
      );
    }
  }


  uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    this.setState({ banner: loadingBanner });
  
    let token = sessionStorage.getItem("ZeitroA");
  
    const uploadFile = async (file, comment, forwhom, taskid, id) => {
      const formData = new FormData();
      formData.append("upload", file);
  
      const response = await fetch("/docs/upload", {
        cache: 'no-cache',
        method: 'POST',
        body: formData,
        headers: {
          Authorization: "Bearer " + token,
          Cache: "no-cache",
          "X-Comment": comment,
          "X-ForWhom": forwhom,
          "X-Taskid": taskid,
          "X-Id": id,
        },
      });
      if (response.status !== 200) {
        return Promise.reject('Upload failed')
      }
      return await response.json()
    };
  
    const uploadPromises = this.state.files.map(file => {
      let comment = ("undefined" === typeof this.props.taskid) ? this.comments[file.name] : this.props.name;
      let forwhom = ("undefined" === typeof this.props.taskid) ? this.state.forwhom : this.props.forwhom;
      let taskid = ("undefined" === typeof this.props.taskid) ? this.state.taskid : this.props.taskid;
      let id = ("undefined" === typeof this.props.taskid) ? 0 : this.props.id;
  
      return uploadFile(file, comment, forwhom, taskid, id);
    });
  
    Promise.all(uploadPromises)
      .then(results => {
        this.setState({ successfullUploaded: true, uploading: false });
        this.setState({ banner: loadMore });
        if (this.props.onUploaded) {
          this.props.onUploaded();
        }
      })
      .catch(error => {
        this.setState({ successfullUploaded: false, uploading: false });
        alert("Upload error! Please try again");
        this.setState({ banner: defBanner });
      });
  }
  

  onClear = e => {
    this.setState({ files: [], successfullUploaded: false })
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <Button variant="primary"
        onClick={this.onClear}
        >
          Clear
        </Button>
      );
    } else {
      return (
        <Button type="submit" variant="zeitro-primary"
          disabled={this.state.files.length <= 0 || this.state.uploading}
        >
          Upload
        </Button>
      );
    }
  }
  isValidated() {
    if (!this.form.current.reportValidity()) {
      this.setState({ validated: true })
      return false
    };
    return true;
  }
  handleSubmit = (event) => {

    if (this.form.current.reportValidity() === false) {
      event.preventDefault();
      this.setState({ validated: true })
      return false
    }
    event.preventDefault();
    this.setState({ validated: false })
    event.stopPropagation();
    if (this.state.successfullUploaded) {
      this.setState({ files: [], successfullUploaded: false });
      this.setState({ banner: defBanner })
    } else {
      this.uploadFiles()
    }
    return false
  }
  changeForWhom = (e) => {
    this.setState({ forwhom: e.target.value })
  }
  render() {
    let updateFilename = (name) => {
      return event => {
        return this.comments[name] = event.target.value
      }
    }

    return (
      <div className="m-0">
        <Form ref={this.form} id="personalform" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
          <div className="text-dark mt-1 ml-2">{this.state.banner}</div>
          <div className="Content px-2">
            <div className="mx-4">
              <Dropzone
                onFilesAdded={this.onFilesAdded}
                disabled={this.state.uploading || this.state.successfullUploaded}
              />
            </div>
            <div className="text-dark d-flex align-left w-100">
              <Container className="mt-4">
                <Row className="w-100 border-bottom border-dark mb-3 pb-2">
                  {
                    (typeof this.props.name === "undefined") ?
                      <Col xs="auto" className="text-left" style={{ width: "330px" }} >
                        Describe Document:
                    </Col> : ""
                  }
                  <Col xs="auto" className="d-flex position-relative p-0 m-0 mr-1 pr-2 pt-1 align-center ">
                  </Col>
                  <Col xs="auto" className="d-block text-nowrap text-left">
                    File Name
                    </Col>
                </Row>

                {this.state.files.map(file => {
                  return (
                    <Row key={file.name} className="w-100 text-nowrap">
                      {(typeof this.props.name === "undefined") ?
                        (
                          <Col xs="auto" className="text-left">
                            <Form.Group controlId={"comment" + file.name} className="text-left" >
                              <Form.Control isValid={false} size="sm"
                                required
                                type="text"
                                style={{ width: "300px" }}
                                onChange={updateFilename(file.name)}
                                pattern="^[a-zA-Z0-9 !-:,&()'?#+/@]+$"
                              />
                              
                              <Form.Control.Feedback type="invalid" >
                                Provide description of the document.
                                </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        ) : ""
                      }
                      <Col sm="auto" className="d-flex position-relative p-0 m-0 mr-1 pr-2 pt-1 align-center ">
                        {this.renderProgress(file)}
                      </Col>
                      <Col xs="auto" className="d-block text-nowrap text-left">
                        {(file.name.length > 64) ? file.name.substring(0, 64) + "..." : file.name}
                      </Col>
                    </Row>
                  );
                })}
                {(this.state.files.length > 0 && this.props.application.hascoborrower !== "alone" ) ?
                  (<Row className="mt-4 border-top border-dark pt-4 mr-3">

                    <Col xs="auto">
                      <Form.Group controlId="purpose" >
                        <Form.Label className="text-left" >{(this.state.files.length > 1) ? (<div>These documents are for:</div>) : (<div>This document is for:</div>)}</Form.Label>
                        <Form.Control name="forwhom" as="select" size="sm"
                          defaultValue="borrower"
                          onChange={this.changeForWhom}
                        >
                          <option value="borrower">Borrower</option>
                          <option value="coborrower">Co-applicant</option>
                          <option value="joint">Joint</option>

                        </Form.Control>
                      </Form.Group>
                    </Col>


                  </Row>) : ""}
              </Container>
            </div>
          </div>
          <div className="w-100 text-center pb-4">{this.renderActions()}</div>
        </Form>
      </div>

    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Upload)
