import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { Link } from 'react-router-dom'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { Badge } from 'antd';
import { addTaskStatusUpdateListener, removeTaskStatusUpdateListener, updateTaskStatus } from '../AppEvents';
import * as com from "../Common.js"
import './MainSidebar.css';
import { UpdateChangeProgress } from "../Store/progress"

import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    progress: state.progress,
    application: state.application
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateChangeProgress: (step, stage) => {
    dispatch(UpdateChangeProgress(step, stage))
  },
});

export default class MainSidebar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      latest: "",
      oldest: "",
      shownew: false,
      showalert: false,
      hideBell: true,
      hideExclamation: true,
      landingurl: "",
      taskCount: 0,
    };
    this.lastCheck = 0
    this.icons = false

  }


  getLoanOfficerUrl = () => {
    let token = com.getUserToken()
    fetch('/borrower/getloanofficerlandingurl', {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        response.json().then(js => {
          if (js.Status !== "OK") {
            console.log("Error: " + js.Status)
          } else {
            this.setState({ landingurl: js.LandingUrl })
          }
        });
      }
    )
      .catch(function (err) {
        console.log('Fetch Error :', err);
      });
  }

  handleTaskStatusUpdate = (event) => {
    const taskDataLength = event.detail;
    if (taskDataLength !== null) {
      this.setState({ taskCount: taskDataLength })
    }
  };
  componentDidMount() {
    updateTaskStatus();
    addTaskStatusUpdateListener(this.handleTaskStatusUpdate);
  }

  componentWillUnmount() {
    removeTaskStatusUpdateListener(this.handleTaskStatusUpdate);
  }

  render() {
    let entry = (start, end, name, token) => {
      let myclass = (this.props.progress.interview.step >= start && this.props.progress.interview.step < end) ?
        "text-left selectsign" : "text-left selectsigntransparent"
      if (this.props.progress.interview.max >= start) {
        return (
          <Link className="selectionoffset" to={'/app/interview/main/' + token}
          ><Row className="p-0 m-0 row-nowrap">
              <Col md="auto" className={myclass} >

              </Col>
              <Col md="auto" className="text-left selectpad" >
              </Col>

              <Col className="p-0 m-0 text-nowrap">{this.icons ? <i className="fa fa-money-bill mr-1"></i> : ""}
                <span className="sidebartext subsidebar">{name}</span>
              </Col>
              <Col md="auto" className="text-right m-0 p-0">
                {this.props.progress.interview.max >= end ?
                  (<i style={{ fontSize: '0.7em' }} className="fa fa-check green"></i>) :
                  (<i style={{ fontSize: '0.7em' }} className="fas fa-pencil-alt yellow" aria-hidden="true"></i>)}
              </Col>
            </Row>
          </Link>
        )
      }

      return (
        <Button variant="link" className="pr-0 m-0 selectionoffset w-100 notalink" disabled>
          <Row className="p-0 m-0 row-nowrap">
            <Col md="auto" className="text-left selectsigntransparent" >

            </Col>
            <Col md="auto" className="text-left selectpad" >
            </Col>
            <Col className="p-0 m-0 text-nowrap" style={{ cursor: 'hand' }}>
              {this.icons ? <i className="fa fa-money-bill mr-1"></i> : ""}
              <span className="sidebartext">{name}</span>
            </Col>
            <Col md="auto" className="text-right m-0 p-0">
            </Col>
          </Row>
        </Button>
      )

    }
    let zeroclass = (this.props.progress.interview.step >= 0 && this.props.progress.interview.step < com.stageToNumber.personal) ?
      "text-left selectsign" : "text-left selectsigntransparent"
    let isPreapproval = false === this.props.application.property.hascontract &&
      this.props.application.property.purpose === "purchase"

    let siderbarItem = (link, imgName, text, needAuthorization, type) => {
      let selected = false
      if (link.includes("interview/main") && window.location.href.includes("interview/main")) {
        selected = true
      }
      if (window.location.href.includes(link)) {
        selected = true
      }
      let token = sessionStorage.getItem("ZeitroA")
      if (needAuthorization && token === null) {
        return ""
      }
      imgName = selected ? `${imgName}-active` : imgName
      let SidebarTextStyle = ''
      if (selected) {
        SidebarTextStyle = 'mainSidebarTextSelected-app'
      } else if (!selected) {
        SidebarTextStyle = 'mainSidebarText-app'
      }
      return <Link to={link} >
        <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className='text-center p-0 m-0 mb-3'>
          <div className="mainSiderbarItem">
            {text === "Tasks" ? <Badge count={this.state.taskCount} size='small' offset={[10, -10]}>
              <img src={"/images/sidebaricons/" + imgName + ".svg"}
                className="mainSiderbarImg" />
            </Badge> : <img src={"/images/sidebaricons/" + imgName + ".svg"}
              className="mainSiderbarImg" />}

          </div>
          <span className={SidebarTextStyle}>{text}</span>

        </Col>
      </Link>
    }
    // wizard length is 11
    let interviewIndex = Math.min(11, parseInt(this.props.progress.interview.step))
    let isHardMoneyLoan = this.props.application.product.mortgageapplied === "hardmoneyloan"
    if (this.props.application.property.purpose === "purchase" && !this.props.application.property.hascontract && !isHardMoneyLoan) {
      return (
        <div className='mainSideBar applicationMainSideBar'>
          <div>
            {siderbarItem('/application/myloans', "loans2", "Loans", true, 'prequal')}
            {siderbarItem('/application/interview/main/' + interviewIndex, "app2", "App", false, 'prequal')}
            {siderbarItem('/application/tasks', "tasks2", "Tasks", true, 'prequal')}
            {siderbarItem('/application/docs', "docs2", "Docs", true, 'prequal')}
            {siderbarItem('/application/logout', "logout2", "Logout", true, 'prequal')}
          </div>
          {/* <img className='applicationMore' src="/images/moreFeatures.svg" /> */}
        </div>
      )
    }

    if (isHardMoneyLoan) {
      return <div className='mainSideBar applicationMainSideBar'>
        <div>
          {siderbarItem('/application/myloans', "loans2", "Loans", true, 'application')}
        </div>
        {siderbarItem('/application/logout', "logout2", "Logout", true, 'application')}
      </div>
    }

    return (
      <div className='mainSideBar applicationMainSideBar'>
        <div>
          {siderbarItem('/application/myloans', "loans2", "Loans", true, 'application')}
          {siderbarItem(isHardMoneyLoan ? "/application/hardMoneyLoan/interview/main/borrower" : '/application/interview/main/' + interviewIndex, "app2", "App", false, 'application')}
          {/* {siderbarItem('/application/profile', "account", "Account", true)} */}
          {siderbarItem('/application/tasks', "tasks2", "Tasks", true, 'application')}
          {siderbarItem('/application/docs', "docs2", "Docs", true, 'application')}
          {siderbarItem('/application/logout', "logout2", "Logout", true, 'application')}
        </div>
        {/* <img className='applicationMore' src="/images/moreFeatures.svg" />     */}
      </div>

    );
  }
}

MainSidebar = connect(mapStateToProps, mapDispatchToProps)(MainSidebar)
