import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import * as com from "../Common.js"
import { getCurrentState } from '../Store'
import { connect } from 'react-redux'
import * as act from "../Store/actions"

const mapStateToProps = (state) => {
    return {
        hascoborrower: state.application.hascoborrower,
        cohabiting: state.application.cohabiting,
        application: state.application,
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateEqualOpportunity: (value, who, verb) => {
        dispatch(act.UpdateEqualOpportunity(value, who, verb))
    },
    updateEqualOpportunitySub: (value, who, section, verb) => {
        dispatch(act.UpdateEqualOpportunitySub(value, who, section, verb))
    },
})

class Race extends Component {

    constructor(props) {
        super(props)
        this.state = {
            validated: false,
            raceSelected: {},
        }
        this.myrefs = {}

        this.myrefs[com.borrower] = React.createRef()
        this.myrefs[com.coborrower] = React.createRef()

        this.isValidated = this.isValidated.bind(this)
        this.form = React.createRef()
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    isValidated() {
        if (!this.form.current.reportValidity()) {
            this.setState({ validated: true })
            return false
        };
        return true
    }
    hide = () => {
        com.touch()
        this.props.updateEqualOpportunity("donttell", com.borrower, "ethnicity")
        this.props.updateEqualOpportunity("donttell", com.borrower, "sex")
        this.props.updateEqualOpportunitySub(true, com.borrower, "race", "notfurnished")
        if (this.showCoborrower()) {
            this.props.updateEqualOpportunity("donttell", com.coborrower, "ethnicity")
            this.props.updateEqualOpportunity("donttell", com.coborrower, "sex")
            this.props.updateEqualOpportunitySub(true, com.coborrower, "race", "notfurnished")
        }
        getCurrentState()
    }
    renderBorrower = (who) => {

        let updateEqualOpportunityCheck = (who, section, verb) => {
            return event => {
                com.touch()

                this.props.updateEqualOpportunitySub(event.target.checked, who, section, verb)
            }
        }

        let onOtherHispanic = (event) => {
            com.touch()

            this.props.updateEqualOpportunitySub(event.target.value, who, "hispanic", "otherdesctiption")
        }
        let onOtherAsian = (event) => {
            com.touch()

            this.props.updateEqualOpportunitySub(event.target.value, who, "race|asiandetails", "otherdesctiption")
        }

        let onPacific = (event) => {
            com.touch()
            this.props.updateEqualOpportunitySub(event.target.value, who, "race|pacificdetails", "otherdetails")
        }

        let onEthnicity = (event) => {
            com.touch()
            this.props.updateEqualOpportunity(event.target.value, who, "ethnicity")
        }

        let onTribe = (event) => {
            com.touch()
            this.props.updateEqualOpportunitySub(event.target.value, who, "race", "indiantribe")
        }
        let onSex = (event) => {
            com.touch()
            this.props.updateEqualOpportunity(event.target.value, who, "sex")
        }
        let isHispanicRequired = () => {
            if (this.props.application[who].equalopportunity.ethnicity !== "hispanic")
                return false
            return !this.props.application[who].equalopportunity.hispanic.mexican &&
                !this.props.application[who].equalopportunity.hispanic.puertorican &&
                !this.props.application[who].equalopportunity.hispanic.cuban &&
                !this.props.application[who].equalopportunity.hispanic.other
        }
        let isRaceRequired = () => {
            if (this.props.application[who].equalopportunity.race.notfurnished)
                return false
            return !this.props.application[who].equalopportunity.race.asian &&
                !this.props.application[who].equalopportunity.race.pacific &&
                !this.props.application[who].equalopportunity.race.black &&
                !this.props.application[who].equalopportunity.race.americanindian &&
                !this.props.application[who].equalopportunity.race.white
        }
        let isPacificRequired = () => {
            if (!this.props.application[who].equalopportunity.race.pacific)
                return false
            return !this.props.application[who].equalopportunity.race.pacificdetails.hawaian &&
                !this.props.application[who].equalopportunity.race.pacificdetails.samoan &&
                !this.props.application[who].equalopportunity.race.pacificdetails.guamanian &&
                !this.props.application[who].equalopportunity.race.pacificdetails.other
        }
        let isAsianRequired = () => {
            if (!this.props.application[who].equalopportunity.race.asian)
                return false
            return !this.props.application[who].equalopportunity.race.asiandetails.otherasian &&
                !this.props.application[who].equalopportunity.race.asiandetails.asianindian &&
                !this.props.application[who].equalopportunity.race.asiandetails.chinese &&
                !this.props.application[who].equalopportunity.race.asiandetails.korean &&
                !this.props.application[who].equalopportunity.race.asiandetails.vietnamese &&
                !this.props.application[who].equalopportunity.race.asiandetails.filipino &&
                !this.props.application[who].equalopportunity.race.asiandetails.japanese
        }

        return (
            <div className="text-left   pt-2" key={"oppo" + who}>
                {this.showCoborrower() && <div style={{ fontSize: '1.3em' }} className="sectionquestion border-bottom pb-2">{this.props.application[who].firstname} {this.props.application[who].middlename} {this.props.application[who].lastname}</div>}


                <Form.Row>
                    <Col className="mt-4 sectionquestion">Would you please specify your ethnicity</Col>
                </Form.Row>
                <Form.Row >
                    <Col >
                        <fieldset>
                            <Row className="mt-1 ">

                                <Col md="auto">
                                    <input required type="radio" checked={this.props.application[who].equalopportunity.ethnicity === "hispanic"}
                                        onChange={onEthnicity} value="hispanic" name={"ethnicity" + who} id={"hispanic" + who} />
                                    <label id={"hispaniclabel" + who} className="zeitro-radio   ml-1" htmlFor={"hispanic" + who}>Hispanic or Latino</label>
                                </Col><Col md="auto">
                                    <input required className="" type="radio" checked={this.props.application[who].equalopportunity.ethnicity === "nonhispanic"}
                                        onChange={onEthnicity} value="nonhispanic" name={"ethnicity" + who} id={"nothispanic" + who} />
                                    <label id={"nothispaniclabel" + who} className="zeitro-radio   ml-1" htmlFor={"nothispanic" + who}>Not Hispanic or Latino</label>
                                </Col><Col md="auto">
                                    <input required type="radio" className="" checked={this.props.application[who].equalopportunity.ethnicity === "donttell"}
                                        onChange={onEthnicity} value="donttell"
                                        id={"ethnicitydonttell" + who} name={"ethnicity" + who} />
                                    <label id={"ethnicitydonttelllabel" + who} className="zeitro-radio   ml-1 text-wrap" htmlFor={"ethnicitydonttell" + who} style={{ fontWeight: 'bold' }}>I do not wish to provide this information</label>
                                </Col><Col></Col>

                            </Row>
                        </fieldset>
                    </Col>
                </Form.Row>
                {null !== this.props.application[who].equalopportunity.ethnicity && this.props.application[who].equalopportunity.ethnicity === "hispanic" ?
                    <fieldset>
                        <Form.Row>
                            <Col className="ml-3 ">Check one or more

                                <Form.Row className="mt-1">

                                    <Col md="auto" className="" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input required={isHispanicRequired()} type="checkbox" id="mexican" checked={this.props.application[who].equalopportunity.hispanic.mexican} className="mr-1 " onChange={updateEqualOpportunityCheck(who, "hispanic", "mexican")}></input>
                                        <label className=" zeitro-checkbox" htmlFor="mexican">Mexican</label>
                                    </Col>
                                    <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input required={isHispanicRequired()} type="checkbox" id="puertorican" checked={this.props.application[who].equalopportunity.hispanic.puertorican} className="mr-1" onChange={updateEqualOpportunityCheck(who, "hispanic", "puertorican")}></input>
                                        <label className="zeitro-checkbox" htmlFor="puertorican">Puerto Rican</label>
                                    </Col>
                                    <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input required={isHispanicRequired()} type="checkbox" id="cuban" checked={this.props.application[who].equalopportunity.hispanic.cuban} className="mr-1 " onChange={updateEqualOpportunityCheck(who, "hispanic", "cuban")}></input>
                                        <label className=" zeitro-checkbox" htmlFor="cuban">Cuban</label>
                                    </Col>
                                    <Col >  </Col>
                                </Form.Row>
                                <Form.Row className="" style={{ marginTop: 10 }}>

                                    <Col md="auto" className="pt-1" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input required={isHispanicRequired()} type="checkbox" id="other" className="mr-1" checked={this.props.application[who].equalopportunity.hispanic.other} onChange={updateEqualOpportunityCheck(who, "hispanic", "other")}></input>
                                        <label className=" zeitro-checkbox" htmlFor="other">Other Hispanic or Latino </label>
                                    </Col>
                                    {this.props.application[who].equalopportunity.hispanic.other ?
                                        <Col md="3">
                                            <input required type="text" id="othervalue" style={{ width: '200px' }}
                                                className="gubmint"
                                                defaultValue={this.props.application[who].equalopportunity.hispanic.otherdesctiption}
                                                onChange={onOtherHispanic}
                                                placeholder="Chilean, Perivuan, and so on.">

                                            </input>
                                        </Col> : ""
                                    }
                                    <Col >  </Col>
                                </Form.Row>
                            </Col>
                        </Form.Row>
                    </fieldset>
                    : ""
                }

                <Form.Row className="mt-4">
                    <Col className="sectionquestion">Would you please specify your race (check all boxes that apply)</Col>
                </Form.Row>

                <Form.Row className="mt-1">
                    <Col md="auto" style={{ display: 'flex', alignItems: 'center', }}>

                        <input type="checkbox" className="ml-3  pl-3 "
                            id={"dontwantto" + who}
                            required={isRaceRequired()}

                            checked={this.props.application[who].equalopportunity.race.notfurnished}
                            onChange={updateEqualOpportunityCheck(who, "race", "notfurnished")}
                        ></input>
                        <label className="ml-1  zeitro-checkbox  text-wrap" htmlFor={"dontwantto" + who} style={{ fontWeight: 'bold', marginTop: 3 }}>I do not wish to provide this information</label>

                    </Col>
                </Form.Row >
                {this.props.application[who].equalopportunity.race.notfurnished ? "" :
                    <div><Form.Row className="mt-1">
                        <Col md="auto" style={{ display: 'flex', alignItems: 'center' }} >
                            <input type="checkbox" className="ml-3  pl-3"

                                defaultChecked={this.props.application[who].equalopportunity.race.black}
                                onChange={updateEqualOpportunityCheck(who, "race", "black")}
                                required={isRaceRequired()}
                                id={"black" + who} ></input>
                            <label className=" ml-1 zeitro-checkbox" htmlFor={"black" + who}>Black or African American</label>

                        </Col>
                        <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                            <input type="checkbox" className="ml-3  pl-3 "
                                required={isRaceRequired()}
                                defaultChecked={this.props.application[who].equalopportunity.race.white}
                                onChange={updateEqualOpportunityCheck(who, "race", "white")}
                                id={"white" + who}   ></input>
                            <label className=" ml-1 zeitro-checkbox" htmlFor={"white" + who}>White</label>

                        </Col>
                    </Form.Row>
                        <Form.Row className="mt-0" >
                            <Col md="auto" className="mt-1" style={{ display: 'flex', alignItems: 'center' }}>
                                <input type="checkbox" className="ml-3  pl-3 "

                                    defaultChecked={this.props.application[who].equalopportunity.race.pacific}
                                    onChange={updateEqualOpportunityCheck(who, "race", "pacific")}
                                    required={isRaceRequired()}
                                    id={"pacific" + who}
                                ></input>
                                <label className=" ml-1 zeitro-checkbox  text-wrap" htmlFor={"pacific" + who}>Native Hawaian or Other Pacific Islander </label>

                            </Col>
                        </Form.Row>
                        {this.props.application[who].equalopportunity.race.pacific ?
                            <fieldset>
                                <Form.Row>
                                    <Col className="ml-4 ">Check one or more
                                        <Form.Row className="mt-1">

                                            <Col md="auto" className=" " style={{ display: 'flex', alignItems: 'center' }}>
                                                <input required={isPacificRequired()} defaultChecked={this.props.application[who].equalopportunity.race.pacificdetails.hawaian} onChange={updateEqualOpportunityCheck(who, "race|pacificdetails", "hawaian")} type="checkbox" id={"hawaian" + who} className="mr-1 "></input>
                                                <label className=" zeitro-checkbox" htmlFor={"hawaian" + who}>Native Hawaian</label>
                                            </Col>
                                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                                <input required={isPacificRequired()} defaultChecked={this.props.application[who].equalopportunity.race.pacificdetails.guamanian} onChange={updateEqualOpportunityCheck(who, "race|pacificdetails", "guamanian")} type="checkbox" id={"guamanian" + who} className="mr-1 "></input>
                                                <label className=" zeitro-checkbox" htmlFor={"guamanian" + who}>Guamanian or Chamorro</label>
                                            </Col>
                                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                                <input required={isPacificRequired()} defaultChecked={this.props.application[who].equalopportunity.race.pacificdetails.samoan} onChange={updateEqualOpportunityCheck(who, "race|pacificdetails", "samoan")} type="checkbox" id={"samoan" + who} className="mr-1 "></input>
                                                <label className=" zeitro-checkbox" htmlFor={"samoan" + who}>Samoan</label>
                                            </Col>
                                            <Col >  </Col>
                                        </Form.Row>
                                        <Form.Row className="">

                                            <Col md="auto" className=" pt-1" style={{ display: 'flex', alignItems: 'center' }}>
                                                <input required={isPacificRequired()} defaultChecked={this.props.application[who].equalopportunity.race.pacificdetails.other}
                                                    onChange={updateEqualOpportunityCheck(who, "race|pacificdetails", "other")}


                                                    type="checkbox" id={"other" + who} className="mr-1"></input>
                                                <label className=" zeitro-checkbox" htmlFor={"other" + who}>Other Pacific Islander</label>

                                            </Col>
                                            {this.props.application[who].equalopportunity.race.pacificdetails.other ?
                                                <Col md="3" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <input type="text" id="othervalue" style={{ width: '200px' }}
                                                        required
                                                        className="gubmint"
                                                        placeholder="Fijian, Tongan, and so on."
                                                        onChange={onPacific}
                                                        defaultValue={this.props.application[who].equalopportunity.race.pacificdetails.otherdetails}
                                                    >
                                                    </input>
                                                </Col> : ""}
                                            <Col >  </Col>
                                        </Form.Row>
                                    </Col>
                                </Form.Row>

                            </fieldset>
                            : ""}
                        <Form.Row className="mt-0" >

                            <Col md="auto" className="ml-3 pt-1" style={{ display: 'flex', alignItems: 'center' }}>
                                <input required={isRaceRequired()} defaultChecked={this.props.application[who].equalopportunity.race.americanindian}
                                    onChange={updateEqualOpportunityCheck(who, "race", "americanindian")} type="checkbox" value={true}
                                    id={"americanindian" + who} className="mr-1"></input>
                                <label className=" zeitro-checkbox  text-wrap" htmlFor={"americanindian" + who}>American Indian or Alaska Native</label>
                            </Col>
                            <Col></Col>
                        </Form.Row>
                        {this.props.application[who].equalopportunity.race.americanindian ?
                            <Form.Row className="my-0 py-0" >
                                <Col md="auto" className="ml-5 pt-1">
                                    Print name of enrolled or principal tribe:
                                </Col>
                                <Col md="auto" >
                                    <input type="text" required
                                        onChange={onTribe} defaultValue={this.props.application[who].equalopportunity.race.indiantribe}
                                        className="gubmint ml-2"
                                    ></input>
                                </Col>
                            </Form.Row> : ""}

                        <Form.Row className="mt-0" >
                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }} className="mt-1">
                                <input type="checkbox" className="ml-3 pl-3"
                                    id={"asian" + who}
                                    required={isRaceRequired()}
                                    defaultChecked={this.props.application[who].equalopportunity.race.asian}
                                    onChange={updateEqualOpportunityCheck(who, "race", "asian")}
                                ></input><label className="ml-1  zeitro-checkbox" htmlFor={"asian" + who}>Asian</label>
                            </Col>
                        </Form.Row>
                        {this.props.application[who].equalopportunity.race.asian ?
                            <fieldset>
                                <Form.Row>
                                    <Col className="ml-4 ">Check one or more

                                        <Form.Row className="mt-1">

                                            <Col md="auto" className="" style={{ display: 'flex', alignItems: 'center' }}>
                                                <input type="checkbox" id={"asianindian" + who} className="mr-1"
                                                    required={isAsianRequired()}
                                                    defaultChecked={this.props.application[who].equalopportunity.race.asiandetails.asianindian}
                                                    onChange={updateEqualOpportunityCheck(who, "race|asiandetails", "asianindian")} ></input>
                                                <label className=" zeitro-checkbox" htmlFor={"asianindian" + who}>Asian Indian</label>

                                            </Col>
                                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                                <input type="checkbox" id={"chinese" + who} className="mr-1"
                                                    required={isAsianRequired()}
                                                    defaultChecked={this.props.application[who].equalopportunity.race.asiandetails.chinese}
                                                    onChange={updateEqualOpportunityCheck(who, "race|asiandetails", "chinese")} ></input>
                                                <label className=" zeitro-checkbox" htmlFor={"chinese" + who}>Chinese</label>

                                            </Col>
                                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                                <input type="checkbox" id={"filipino" + who} className="mr-1"
                                                    required={isAsianRequired()}
                                                    defaultChecked={this.props.application[who].equalopportunity.race.asiandetails.filipino}
                                                    onChange={updateEqualOpportunityCheck(who, "race|asiandetails", "filipino")} ></input>
                                                <label className=" zeitro-checkbox" htmlFor={"filipino" + who}>Filipino</label>

                                            </Col>
                                            <Col >  </Col>
                                        </Form.Row>

                                        <Form.Row className="mt-1">

                                            <Col md="auto" className=" " style={{ display: 'flex', alignItems: 'center' }}>
                                                <input type="checkbox" id={"japanese" + who} className="mr-1"
                                                    required={isAsianRequired()}
                                                    defaultChecked={this.props.application[who].equalopportunity.race.asiandetails.japanese}
                                                    onChange={updateEqualOpportunityCheck(who, "race|asiandetails", "japanese")} ></input>
                                                <label className=" zeitro-checkbox" htmlFor={"japanese" + who}>Japanese</label>

                                            </Col>
                                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                                <input type="checkbox" id={"korean" + who} className="mr-1"
                                                    required={isAsianRequired()}
                                                    defaultChecked={this.props.application[who].equalopportunity.race.asiandetails.korean}
                                                    onChange={updateEqualOpportunityCheck(who, "race|asiandetails", "korean")} ></input>
                                                <label className=" zeitro-checkbox" htmlFor={"korean" + who}>Korean</label>
                                            </Col>
                                            <Col md="auto" style={{ display: 'flex', alignItems: 'center' }}>
                                                <input type="checkbox" id={"vietnamese" + who} className="mr-1"
                                                    required={isAsianRequired()}
                                                    defaultChecked={this.props.application[who].equalopportunity.race.asiandetails.vietnamese}
                                                    onChange={updateEqualOpportunityCheck(who, "race|asiandetails", "vietnamese")} ></input>
                                                <label className=" zeitro-checkbox" htmlFor={"vietnamese" + who}>Vietnamese</label>

                                            </Col>
                                            <Col >  </Col>
                                        </Form.Row>
                                        <Form.Row className="">

                                            <Col md="auto" className=" pt-1" style={{ display: 'flex', alignItems: 'center' }}>
                                                <input type="checkbox" id={"otherasian" + who} className="mr-1"
                                                    required={isAsianRequired()}
                                                    defaultChecked={this.props.application[who].equalopportunity.race.asiandetails.otherasian}
                                                    onChange={updateEqualOpportunityCheck(who, "race|asiandetails", "otherasian")} ></input>
                                                <label className=" zeitro-checkbox" htmlFor={"otherasian" + who}>Other Asian</label>

                                            </Col>
                                            {this.props.application[who].equalopportunity.race.asiandetails.otherasian ?
                                                <Col md="3 ">
                                                    <input type="text" required id={"othervalue" + who} style={{ width: '200px' }}
                                                        onChange={onOtherAsian}
                                                        className="gubmint"
                                                        defaultValue={this.props.application[who].equalopportunity.race.asiandetails.otherdesctiption}
                                                        placeholder="Hmong, Laotian, and so on.">

                                                    </input>
                                                </Col> : ""}
                                            <Col ></Col>
                                        </Form.Row>
                                    </Col>
                                </Form.Row>
                            </fieldset>
                            : ""}
                    </div>
                }
                <Form.Row>
                    <Col className=" sectionquestion mt-4" >Would you please specify your legal gender</Col>
                </Form.Row>

                <Form.Row >
                    <Col >
                        <fieldset>
                            <Row className="mt-1 ">
                                <Col md="auto" >
                                    <input required type="radio" checked={this.props.application[who].equalopportunity.sex === "male"}
                                        onChange={onSex} value="male" name={"sex" + who} id={"male" + who} />
                                    <label id="malelabel" className="zeitro-radio   ml-1" htmlFor={"male" + who}>Male</label>
                                </Col><Col md="auto" >
                                    <input required className="" type="radio" checked={this.props.application[who].equalopportunity.sex === "female"}
                                        onChange={onSex} value="female" name={"sex" + who} id={"female" + who} />
                                    <label id="femalelabel" className="zeitro-radio   ml-1" htmlFor={"female" + who}>Female</label>
                                </Col>
                                {/* <Col md="auto" >
                                    <input required className="" type="radio" checked={this.props.application[who].equalopportunity.sex === "other"}
                                        onChange={onSex} value="other" name={"sex" + who} id={"sexother" + who} />
                                    <label id="sexotherlabel" className="zeitro-radio   ml-1" htmlFor={"sexother" + who}>Other</label>
                                </Col> */}
                                <Col md="auto" >
                                    <input required className="" type="radio" checked={this.props.application[who].equalopportunity.sex === "donttell"}
                                        onChange={onSex} value="donttell" name={"sex" + who} id={"sexdonttell" + who} />
                                    <label id="sexdonttelllabel"
                                        className="zeitro-radio   ml-1  text-wrap" htmlFor={"sexdonttell" + who} style={{ fontWeight: 'bold' }}>I do not wish to provide this information</label>
                                </Col><Col> </Col>
                            </Row>

                        </fieldset>
                    </Col>
                </Form.Row>



            </div>
        )
    }
    showCoborrower = () => {
        if (this.props.hascoborrower === "withcoborrower")
            return true
        if (this.props.hascoborrower === "multiple" && this.props.application.married)
            return true
        return false
    }
    render() {

        return (
            <div className="p-4 text-left" key={this.props.who}>
                <div className="prequalInterviewTitle ">Information for government monitoring purposes</div>
                <div className="text-left text-wrap  mt-2 subheading">
                    <b>The purpose of collecting this information</b> is to help ensure that all applicants are treated fairly
                    and that the housing needs of communities
                    and neighborhoods are being fulfilled. For residential mortgage lending, Federal law requires
                    that we ask applicants for their demographic
                    information (ethnicity, sex, and race) in order to monitor our compliance with equal
                    credit opportunity, fair housing, and home mortgage
                    disclosure laws. You are not required to provide this information, but are encouraged to do so.
                    You may select one or more designations for "Ethnicity" and one or more designations for "Race." <br />
                    <b>The law provides that we may not discriminate</b> on the basis of this information, or on
                    whether you choose to provide it. However, if you choose not to provide the information and you
                    have made this application in person, Federal regulations require us to note your ethnicity, sex,
                    and race on the basis of visual observation or surname. The law also provides that we may not
                    discriminate on the basis of age or marital status information you provide in this application.
                </div>

                <div className="mt-1" style={{ fontWeight: 500, display: "flex", alignItems: "center", fontSize: 14, whiteSpace: 'normal' }}>If you do not wish to provide all of this information, please <Button style={{ marginLeft: '-6px' }} onClick={this.hide} className="" variant="link" size="sm">click here</Button></div>

                {this.renderBorrower(com.borrower)}
                {this.showCoborrower() ? this.renderBorrower(com.coborrower) : ""}

            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Race)

