import React from "react"
import { InputNumber } from 'antd'


export const DollarInput = (props) => {
    return <InputNumber
        prefix="$"
        suffix={props.suffix}
        style={props.style}
        min={0}
        max={99999999999}
        className={props.className ? props.className : "w-100"}
        defaultValue={props.defaultValue}
        value={props.value}
        formatter={(value) => {
            const data = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            return data.replace(/[\u4e00-\u9fa5]/g, '').replaceAll('-', '').replace('.', '')

        }}
        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        onChange={props.onChange}
        size="large"
    />
}

