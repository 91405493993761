import { useSelector, useDispatch } from 'react-redux'
import Button from 'react-bootstrap/Button'
import {
    CheckOutlined
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
let subMenuList = [
    {
        type: "Personal",
        id: 1,
        path: "/application/interview/main/applicant",
        list: [
            {
                menu: 'You & co-borrower',
                path: "/application/interview/main/applicant",
                id: 2
            },
            {
                menu: 'Current residence',
                path: "/application/interview/main/3",
                id: 3
            }
        ]
    },
    {
        type: "Property",
        path: "/application/interview/main/property",
        id: 3.5,
        list: [
            {
                menu: 'Basic',
                path: "/application/interview/main/property",

                id: 4
            },
            {
                menu: 'Detail',
                path: "/application/interview/main/5",
                id: 5
            }
        ],
    },
    {
        type: "Income",
        path: '/application/interview/main/income',
        id: 5.5,
        list: [
            {
                menu: 'Employment',
                path: '/application/interview/main/income',

                id: 6
            },
            {
                menu: 'Other income',
                path: '/application/interview/main/7',

                id: 7
            }
        ]
    },
    {
        type: "Finances",
        path: '/application/interview/main/finances',
        id: 8,
        list: []
    },
    {
        type: "Liabilities",
        path: '/application/interview/main/liabilities',
        id: 11,
        list: []
    }

]
function StepApplication(props) {
    const progress = useSelector(state => {

        return state.progress
    })
    return (
        <div className="stepApplication p-4">
            {
                subMenuList.map((item, index) => {
                    return (
                        <div key={index} style={{ cursor: 'pointer' }}>

                            <div className='d-flex  mt-3 align-items-center stepInfo' style={item.id <= progress.interview.step ? { color: "#325CEB" } : {}}>
                                <div className={item.id <= progress.interview.step ? ' stepCircle stepCurrent ' : "stepCircle"}>


                                    {item.id <= progress.interview.step ? <CheckOutlined /> : index + 1}


                                </div>
                                <div style={{ marginLeft: 10 }}>
                                    {progress.interview.max >= item.id ? <Link to={item.path} style={item.id <= progress.interview.step ? { color: "#325CEB" } : { color: "#6E6E70" }}>
                                        {item.type}
                                    </Link> : <Button style={{ height: '41px', padding: 0, border: 'none' }} variant="link" className="m-0 selectionoffset w-100" disabled> {item.type}</Button>}


                                </div>

                            </div>
                            <div className="text-left pl-2 ml-1 mt-2">

                                {progress.interview.max >= item.id ?
                                    item.list.map((listElem, ListIndex) => {


                                        return (
                                            <div className="d-flex align-items-center">
                                                <div className={listElem.id <= progress.interview.step ? 'stepLine stepLineCurrent' : "stepLine"}></div>
                                                <div className="ml-3" >
                                                    <Link to={listElem.path} style={listElem.id <= progress.interview.step ? { color: "#325CEB" } : {
                                                        color: "#6E6E70"
                                                    }}>
                                                        {listElem.menu}
                                                    </Link> </div>

                                            </div>
                                        )
                                    }) : <></>
                                }
                            </div>


                        </div>
                    )

                })

            }
        </div>
    )
}

export default StepApplication