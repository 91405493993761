
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { withRouter, Link } from "react-router-dom";
import * as act from "../../Store/actions"
import * as com from "../../Common"
import { Button, Col, Row, Tooltip, Input } from 'antd';
import EmailVerification from '../../Application/EmailVerification';
import "./loanRateResult.css";
import {
  QuestionCircleFilled
} from '@ant-design/icons';
import { totalmem } from 'os';

const eligibleCities = ["Fremont", "Newark", "Union City", "Pleasanton", "Dublin", "Livermore", "San Ramon", "Danville"]
const eligibleCounties = ["Santa Clara County", "San Mateo County"]

const mapStateToProps = (state) => {
  return {
      application: state.application,
      progress: state.progress,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeMainPropertyAttribute: (t, verb) => {
      dispatch(act.ChangeMainPropertyAttribute(t, verb))
  },
  changeBorrowerInfo: (event, verb) => {
      dispatch(act.ChangeBorrowerInfo(event, "borrower", verb))
  },
  updateChangeApplication: (application) => {
      dispatch(act.updateApplication(application))
  },
});

export function CalcHardMoneyLoanRate(props)  {
// to get max loan amount:
// if it's a purchase loan (+rehab)，the max loan amount is the lesser of 85% LTV or 70% LTARV. 
// if it's a rate-term refi loan, the max loan amount is the lesser of 70% LTV or 70% LTARV. 
// If it's a cash-out refi loan, the max is the lesser of 65% LTV or 65% LTARV.

  let result = {
    unqualified: false,
    unqualifiedReason: "", 
    floorRate: 0,
    point: 0,
    monthlyPayment: 0,
    closingCost: 0,
    maxLoanAmount: 0,

  }
  const {
    property,
    hardmoneyloan,
  } = props.application;

  let propertyType = property.propertytype
  let propertyCounty = property.county
  let propertyCity = property.city.trim()
  let rehabAdded = hardmoneyloan.rehabloan
  let scre = hardmoneyloan.estimatedfico
  let borrowerExperience = hardmoneyloan.timesofflippingaproperty + hardmoneyloan.timesofpurchasingarentalproperty
  let loanTermMonths = hardmoneyloan.mortgagetermmonths
  let loanamount = ""
  let maxLoanAmount = ""
  let loanAmountLimit = ""
  let totalLoanAmount = ""
  let ltv = 0
  let ltarv = 0
  let ltc
  let floorRate = 8.99
  let point = 1
  let maxLTV = 0
  let maxLTARV = 0
  let maxLTC = 0
  let minFICO = 0
  let minExp = 3
  let minTermMonths = 3
  let dayOneEquity = 0
  let cashoutAmount = 0
  let cashoutPercentage = 0


    //find min of purPrice * maxltv, cost * maxltc, arv * maxltarv
  const calcMaxLoanAmount = (purPrice, cost, arv ,maxltv, maxltc, maxltarv) => {
    let maxLoanAmount = 0
    let purPriceMax = purPrice * maxltv
    let costMax = cost * maxltc
    let arvMax = arv * maxltarv
    if (!rehabAdded) {
      arvMax = purPriceMax
      costMax = purPriceMax
    }
    console.log("purPriceMax: " + purPrice+ "*" + maxltv + "="+purPriceMax)
    console.log("costMax: " + cost+ "*" + maxltc + "="+costMax)
    console.log("arvMax: " + arv+ "*" + maxltarv + "="+arvMax)
    console.log("maxLoanAmount: " + Math.min(purPriceMax, costMax, arvMax))

    maxLoanAmount = Math.min(purPriceMax, costMax, arvMax)
    return maxLoanAmount
  }


  let salesPrice = com.safeParseInt(property.salesprice)
  let purpose = property.purpose
  let initialAdvance = com.safeParseInt(hardmoneyloan.initialadvancerequested)
  let rehabLoanAmount = com.safeParseInt(hardmoneyloan.rehabloanamount)
  let projectAfterRepairValue = com.safeParseInt(hardmoneyloan.projectedafterrepairvalue)
  let estimatedCurrentValue = com.safeParseInt(hardmoneyloan.refinancedetails.estimatedCurrentValue)
  let constructionBudget = com.safeParseInt(hardmoneyloan.constructionbudget)
  let constructionLoanAmount = com.safeParseInt(hardmoneyloan.constructionloanamount)
  let refinanceLoanAmount = com.safeParseInt(hardmoneyloan.refinanceloanamount)
  let existingLoanAmount = com.safeParseInt(hardmoneyloan.refinancedetails.existingLoanAmount)

  if (salesPrice==="") {
    ltv = 0
  } else {
    switch (purpose) {
      case "purchaserehab":
        loanAmountLimit = 5000000
        point = 1
        maxLTV = 80
        maxLTARV = 70
        maxLTC = 85
        minFICO = 650
        minExp = 3
        minTermMonths = 3

        loanamount = initialAdvance
        dayOneEquity = salesPrice - loanamount
        ltv = 100 * loanamount / salesPrice
        if (rehabAdded) {
          totalLoanAmount = loanamount + rehabLoanAmount
          ltarv = 100 * rehabLoanAmount / projectAfterRepairValue
        } else {
          totalLoanAmount = loanamount
          ltarv = ltv
        }
        ltc = 100 * totalLoanAmount / (salesPrice + rehabLoanAmount)
        maxLoanAmount = calcMaxLoanAmount(salesPrice, salesPrice + rehabLoanAmount, projectAfterRepairValue, 0.80, 0.85, 0.7)
        if (maxLoanAmount > loanAmountLimit) {
          maxLoanAmount = loanAmountLimit
        }
        if(totalLoanAmount > maxLoanAmount) {
          totalLoanAmount = maxLoanAmount
          if (rehabAdded) {
            dayOneEquity = salesPrice - (maxLoanAmount - rehabLoanAmount)
            ltv = 100 * (maxLoanAmount - rehabLoanAmount) / salesPrice
            ltarv = 100 * maxLoanAmount / projectAfterRepairValue
          } else{
            dayOneEquity = salesPrice - maxLoanAmount
            ltv = 100 * maxLoanAmount / salesPrice
            ltarv = ltv
          }
          ltc = 100 * maxLoanAmount / (salesPrice + rehabLoanAmount)
        }
        break;
      case "refinance":
        loanamount = refinanceLoanAmount
        loanAmountLimit = 2000000
        totalLoanAmount = loanamount
        point = 1.5
        maxLTV = 70
        maxLTARV = 70
        maxLTC = 70
        minFICO = 700
        minExp = 3
        minTermMonths = 3
        dayOneEquity = estimatedCurrentValue - loanamount
        ltv = 100 * loanamount / estimatedCurrentValue
        ltarv = 100 * totalLoanAmount / estimatedCurrentValue
        ltc = 100 * totalLoanAmount / estimatedCurrentValue
        maxLoanAmount = calcMaxLoanAmount(estimatedCurrentValue, estimatedCurrentValue, estimatedCurrentValue, 0.7, 0.7, 0.7)
        if (maxLoanAmount > loanAmountLimit) {
          maxLoanAmount = loanAmountLimit
        }
        if(totalLoanAmount > maxLoanAmount) {
          totalLoanAmount = maxLoanAmount
          dayOneEquity = estimatedCurrentValue - maxLoanAmount
          ltv = 100 * maxLoanAmount / estimatedCurrentValue
          ltarv = 100 * maxLoanAmount / estimatedCurrentValue
          ltc = 100 * maxLoanAmount / estimatedCurrentValue
        }
        break;
      case "cashoutrefinance":
        loanamount = refinanceLoanAmount
        loanAmountLimit = 2000000
        totalLoanAmount = loanamount
        point = 1.5
        maxLTV = 65
        maxLTARV = 65
        maxLTC = 65
        minFICO = 700
        minExp = 10
        minTermMonths = 3
        dayOneEquity = estimatedCurrentValue - loanamount
        cashoutAmount = loanamount - existingLoanAmount
        cashoutPercentage = 100* cashoutAmount / estimatedCurrentValue
        ltv = 100 * loanamount / estimatedCurrentValue
        ltarv = 100 * totalLoanAmount / estimatedCurrentValue
        ltc = 100 * totalLoanAmount / estimatedCurrentValue
        maxLoanAmount = calcMaxLoanAmount(estimatedCurrentValue, estimatedCurrentValue, estimatedCurrentValue, 0.65, 0.65, 0.65)
        if (maxLoanAmount > loanAmountLimit) {
          maxLoanAmount = loanAmountLimit
        }
        if(totalLoanAmount > maxLoanAmount) {
          totalLoanAmount = maxLoanAmount
          dayOneEquity = estimatedCurrentValue - maxLoanAmount
          cashoutAmount = maxLoanAmount - existingLoanAmount
          cashoutPercentage = 100* cashoutAmount / estimatedCurrentValue
          ltv = 100 * maxLoanAmount / estimatedCurrentValue
          ltarv = 100 * maxLoanAmount / estimatedCurrentValue
          ltc = 100 * maxLoanAmount / estimatedCurrentValue
        }
        break;
      case "construction":
        loanamount = constructionLoanAmount
        loanAmountLimit = 5000000
        totalLoanAmount = loanamount
        point = 2
        maxLTV = 70
        maxLTARV = 70
        maxLTC = 85
        minFICO = 700
        minExp = 20
        minTermMonths = 3
        dayOneEquity = constructionBudget - loanamount
        ltv = 100 * totalLoanAmount / constructionBudget
        ltarv = 100 * totalLoanAmount / projectAfterRepairValue
        ltc = 100 * totalLoanAmount / constructionBudget
        maxLoanAmount = calcMaxLoanAmount(constructionBudget, constructionBudget, projectAfterRepairValue, 0.7, 0.85, 0.7)
        if (maxLoanAmount > loanAmountLimit) {
          maxLoanAmount = loanAmountLimit
        }
        if(totalLoanAmount > maxLoanAmount) {
          totalLoanAmount = maxLoanAmount
          dayOneEquity = constructionBudget - maxLoanAmount
          ltv = 100 * maxLoanAmount / constructionBudget
          ltarv = 100 * maxLoanAmount / projectAfterRepairValue
          ltc = 100 * maxLoanAmount / constructionBudget
        }
        break;
    }

  }

  console.log("******start parsing rate-point-cost********")
  // check lending area 
  if (!eligibleCities.includes(propertyCity) && !eligibleCounties.includes(propertyCounty)) {
    console.log("not in lending area")
    result.unqualified = true
    result.unqualifiedReason = "The subject property address is not in the lending area of this program."
  }
  // check multifamily or mixeduse, rates for these are subject to pre-approval
  if (propertyType === "multifamily" || propertyType === "mixeduse") {
    console.log("is multifamily or mixeduse, need pre-approval")
    result.unqualified = true
    result.unqualifiedReason = "Program's rate and cost is subject to pre-approval for multifamily or mixed used property, please procced to the applicaton and discuss it with your loan officer."
  }

  // check loan amount limit
  if (totalLoanAmount > loanAmountLimit) {
    console.log("loan amount exceed: " + loanAmountLimit)
    result.unqualified = true
    result.unqualifiedReason = "The maximum loan amount is $"+ loanAmountLimit/1000000 + "M, while your current is $"+ totalLoanAmount + "."
  }

  // check fico score above minFICO
  if (scre < minFICO) {
    console.log("fico score below minFICO: " + minFICO)
    result.unqualified = true
    result.unqualifiedReason = "The minimum FICO score is "+ minFICO +", whereas the FICO score you have provided is " + scre
  }

  // check borrower experience above minExp
  if (borrowerExperience < minExp) {
    console.log("borrower experience below minExp: " + minExp)
    result.unqualified = true
    result.unqualifiedReason = "The program requires a minimum total experience of "+ minExp + " for the last 3 years, whereas the number of experience you have provided is " + borrowerExperience
  }

  // make sure purchase price entered is valid
  if (property.salesprice === "" || property.salesprice == 0) {
    console.log("purchase price is empty or zero")
    result.unqualified = true
    result.unqualifiedReason = "Please enter a valid purchase price/as-is-value, can't be empty or zero"
  }

  // make sure projected after repair value is entered valid if rehab added
  if (rehabAdded && (hardmoneyloan.projectedafterrepairvalue === "" || hardmoneyloan.projectedafterrepairvalue === 0)) {
    console.log("projected after repair value is empty or zero")
    result.unqualified = true
    result.unqualifiedReason = "Please enter a valid projected after repair value, can't be empty or zero"
  }

  // determine base rate for single family residence based on ltarv
  if (propertyType === "singlefamilydetached") {
    console.log("property type is singlefamilydetached")
    if (ltarv <= 55){
      console.log("ltarv <= 55, base rate is 8.99")
      floorRate = 8.99
    } else if (ltarv > 55 && ltarv <=60){
      console.log("ltarv > 55 && ltarv <=60, base rate is 9.24")
      floorRate = 9.24
    } else if (ltarv > 60 && ltarv <= 65){
      console.log("ltarv > 60 && ltarv <= 65, base rate is 9.49")
      floorRate = 9.49
    } else if (ltarv > 65 && ltarv <= 70){
      console.log("ltarv > 65 && ltarv <= 70, base rate is 9.74")
      floorRate = 9.74
    } else {
      console.log("ltarv > 70, rate and cost is subject to pre-approval")
      result.unqualified = true
      result.unqualifiedReason = "For single family residence and loan to after repair value is above 70%, rate and cost is subject to pre-approval. Please procced to the applicaton and discuss it with your loan officer."
    }
  }

  // determine base rate for 2-4 unit residential property based on ltarv
  if (propertyType === "twotofourfamily") {
    console.log("property type is twofourunit")
    if (ltarv <= 55){
      console.log("ltarv <= 55, base rate is 9.24")
      floorRate = 9.24
    } else if (ltarv > 55 && ltarv <=60){
      console.log("ltarv > 55 && ltarv <=60, base rate is 9.49")
      floorRate = 9.49
    } else if (ltarv > 60 && ltarv <= 65){
      console.log("ltarv > 60 && ltarv <= 65, base rate is 9.74")
      floorRate = 9.74
    } else {
      console.log("ltarv > 65, rate and cost is subject to pre-approval")
      result.unqualified = true
      result.unqualifiedReason = "For 2-4 unit residential property and loan to after repair value is above 65%, rate and cost is subject to pre-approval. Please procced to the applicaton and discuss it with your loan officer."
    }
  }

  // rate adjustment when day one equity is less than 85k
  if (dayOneEquity <= 85000) {
    console.log("dayOneEquity <= 85000, rate +0.25")
    floorRate += 0.25
  }

  // rate adjustment based on cashout percentage
  if (cashoutPercentage>=1.5 && cashoutPercentage <=5) {
    console.log("cashoutPercentage>=1.5 && cashoutPercentage <=5, rate +0.125")
    floorRate += 0.125
  }
  if (cashoutPercentage>5) {
    console.log("cashoutPercentage>5, rate +0.5")
    floorRate += 0.5
  }

  // rate adjustment based on ltv
  if (ltv >= 60 && ltv <= 70){
    console.log("in ltv 60-70, rate +0.5")
    floorRate += 0.5
  }
  else if (ltv > 70 && ltv <= maxLTV){
    console.log("in ltv 70-ceiling, rate +0.5")
    floorRate += 1
  }

  // rate and point adjustment based on fico score
  if (scre >= minFICO) {
    if ( scre >= 700 && scre <= 719) {
      console.log("in scre 700-719, rate +0.25")
      floorRate += 0.25
    }
    if ( scre >=680 && scre <= 699) {
      console.log("in scre 680-699, rate +0.5, point +0.5")
      floorRate += 0.5
      point += 0.5
    }
  } 

  //rate adjustment based on borrower experience
  if (borrowerExperience >= 10 && borrowerExperience <= 20) {
    console.log("in experience 10-20, rate +0.25")
    floorRate += 0.25
  }
  if (borrowerExperience >= 3 && borrowerExperience < 10) {
    console.log("in experience 3-10, rate +0.5")
    floorRate += 0.5
  }
  
  // rate and point adjustment based on loan term
  if (com.safeParseInt(loanTermMonths) > 12){
    console.log("in term > 12, rate +0.5, point +0.5")
    floorRate += 0.5
    point += 0.5
  }

  // addtional fee based on loan amount
  if (totalLoanAmount >= 85000 && totalLoanAmount <= 125000) {
    let extraFeePoint = 1000/com.safeParseInt(totalLoanAmount)
    point += extraFeePoint
    console.log("in loan amount 85000-125000, point +" + extraFeePoint + "as extra fee")
  }
  console.log("check type!!!!!!", propertyType)
  // rate and point adjustment based on property type
  switch (propertyType) {
    case "singlefamilydetached":
      break;
    case "mixeduse":
      console.log("property type is mixeduse, rate +2, point +1")
      floorRate += 2
      point += 1
      break;
    case "multistory":
      break;
    case "twotofourunit":
      break;
    case "commercial":
      console.log("property type is commercial, rate +3, point +1")
      floorRate += 3
      point += 1
      break;
  }
  console.log("final total loan amount: " + totalLoanAmount)
  // calc final monthly payment and closing cost
  let mp = Math.round(com.MonthlyPayment(0.01 * floorRate/12, com.safeParseInt(totalLoanAmount), com.safeParseInt(props.application.hardmoneyloan.mortgagetermmonths)))
  let cc = Math.round(com.safeParseInt(totalLoanAmount) * point * 0.01)

  console.log("******end parsing rate-point-cost********")
  
  result.floorRate = floorRate
  result.point = point
  result.monthlyPayment = mp
  result.closingCost = cc
  result.maxLoanAmount = maxLoanAmount
  return result
}

const LoanRateResult = (props) => {
  const [showSignup, setShowSignup] = useState(false)
  const [showEmailVerification, setShowEmailVerification] = useState(false)
  const [rate, setRate] = useState("")
  const [maxLoanAmount, setMaxLoanAmount] = useState("")
  const [monthlyPayment, setMonthlyPayment] = useState("")
  const [closingCost, setClosingCost] = useState("")
  const [unqualified, setUnqualified] = useState(false)
  const [unqualifiedReason, setUnqualifiedReason] = useState("")

  const cardList = [
    {
        title: 'Interest Rate',
        value: rate
    },
    {
        title: 'Maximum Loan amount',
        value: maxLoanAmount
    },
    {
        title: 'Monthly Payment',
        value: monthlyPayment
    },
    {
        title: 'Closing Cost',
        value: closingCost
    }
  ]

  useEffect(()=>{
    let result = CalcHardMoneyLoanRate(props)
    setRate(result.floorRate.toFixed(3) + "%")
    setMonthlyPayment("$" + com.commaize(result.monthlyPayment))
    setClosingCost("$" + com.commaize(result.closingCost))
    setUnqualified(result.unqualified)
    setUnqualifiedReason(result.unqualifiedReason)
    setMaxLoanAmount("$"+ com.commaize(result.maxLoanAmount))
    console.log("on props update")
  }, [props.application])

  const back = (type) => {
    if (type === 1) {
      setShowEmailVerification(false)
    }
  }

  const signup = () => {
    if (showEmailVerification) {
      return (
        <div style={{padding: '50px 0'}}>
          <EmailVerification purpose="hardmoneyloan" back={(type) => back(type)} />
        </div>
      )
    }
    return (
      <div className='creat-account'>
        <div className='creat-title'>Create Account</div>
        <div className='creat-subtitle'>To explore your best loan option and get connected with our experienced loan officers </div>
        <Row gutter={20}>
          <Col style={{marginBottom: '20px'}} sm={12} span={24}><Input size="large" placeholder="First name" onChange={(e)=> props.changeBorrowerInfo(e.target.value, "firstname")}/></Col>
          <Col style={{marginBottom: '20px'}} sm={12} span={24}><Input size="large" placeholder="Last name" onChange={(e)=> props.changeBorrowerInfo(e.target.value, "lastname")}/></Col>
        </Row>
        <Row>
          <Col span={24}><Input size="large" placeholder='Email'onChange={(e)=> props.changeBorrowerInfo(e.target.value, "email")}/></Col>
        </Row>
        <div><Button className='continue-btn' type="primary" onClick={()=>setShowEmailVerification(true)}>Continue</Button></div>
        <div className='to-login'><span>Already have an account?</span> <Link to={"/services/signin?customerid=" + props.customerid}>Log in</Link></div>
        <div className='to-login mt-3'> <a className='text-blue ' onClick={()=>setShowSignup(false)}>Go back</a></div>
      </div>
    )
  }

  const showUnqualified = () => {
    return <div className='mb-5'>
      <div className='bold mb-2'>Sorry, we are unable to offer a loan based on the input provided. This is due to:</div>
      <div style={{fontSize: 20, fontWeight: 500}}>{unqualifiedReason}</div>
    </div>
  }

  return (
    <div className='loan-rate-result center'>
      {showSignup ? signup() : <div>
      <div className='title center'>Fill in the table on the left and click <span className='bold'>Calculate</span> to see your rates</div>
      <Row className='result-card'>
        {
          !props.showResult ? cardList.map((item, index) =>
            <Col span={24} sm={12} className='card-item center' key={index}>
              <div className='result-title'>{item.title}</div>
              <div className='result-value'>---</div>
            </Col>
          )
          :
          (
            unqualified ? showUnqualified() :
            cardList.map((item, index) => 
              <Col span={24} sm={12} className='card-item center' key={index}>
                <div className='result-title'>{item.title}</div>
                <div className='result-value'>{item.value}</div>
              </Col>
            )
          )
        }
      </Row>
      <div className='describe info-first center'>To obtain an accurate rate tailored to your specific situation, simply sign up below. </div>
      <div className='describe info-second center'>Our experienced loan officers will promptly reach out to assist you in finding the best rate available.</div>
      <div className='btn-wrap center'><Button className="sign-btn" type="primary" onClick={()=>setShowSignup(true)}>Sign Up</Button></div>
      <div className='login center'>Already have an account? <span className='text-blue'><Link to={"/services/signin?customerid=" + props.customerid}>Login</Link></span></div>
      {/* <div className='privacy center'>Privacy Statement</div> */}
    </div>}
    
    </div>
  );
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LoanRateResult))
