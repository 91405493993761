import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
    DatePicker,
    Button,
    Checkbox,
    Radio,
    Select,
    Switch,
    Modal,
    Divider,
    Form,
    Input,
    InputNumber,
    Col,
    Row
} from 'antd';
import { QuestionCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import "./Common.css";
import "./Borrower.css";
import * as com from "../../Common"
import GoogleAutocomplete from '../../Common/GoogleAutocomplete/GoogleAutocomplete'
import { connect } from 'react-redux'
import * as act from "../../Store/actions"
import { updateIsHardMoneyLoan } from '../../Store/accountinfo';
import { HardMoneyLoanEntityInfo, E_Nonhispanic, E_Hispanic, E_Donttell } from '../../State';
import {
    maritalStatusOptions, borrowerTypeOptions, validateMessages, initialRaceVal
} from './constants'
import './Common.css'

const mapStateToProps = (state) => {
    return {
        status: state.application.status,
        application: state.application,
        progress: state.progress,
        accountInfo: state.accountInfo
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateIsHardMoneyLoan: (payload) => {
        dispatch(updateIsHardMoneyLoan(payload))
    },
    updateHardMoneyLoanAttribute: (t, verb) => {
        dispatch(act.UpdateHardMoneyLoanAttribute(t, verb));
    },
    changeMainPropertyAttribute: (t, verb) => {
        dispatch(act.ChangeMainPropertyAttribute(t, verb));
    },
    changeMainPropertyPurpose: (val) => {
        dispatch(act.ChangeMainPropertyPurpose(val));
    },
    updateHasCoborrower: (val) => {
        dispatch(act.UpdateHasCoborrower(val))
    },
    updateProduct: (v, verb) => {
        dispatch(act.UpdateProduct(v, verb))
    },
    changeBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "borrower", verb))
    },
    changeCoBorrowerInfo: (v, verb) => {
        dispatch(act.ChangeBorrowerInfo(v, "coborrower", verb))
    },
    updateEqualOpportunity: (value, who, verb) => {
        dispatch(act.UpdateEqualOpportunity(value, who, verb))
    },
    updateEqualOpportunitySub: (value, who, section, verb) => {
        dispatch(act.UpdateEqualOpportunitySub(value, who, section, verb))
    },
});

function getFormRace(race) {
    let res = []

    if (race.americanindian) {
        res.push("americanindian")
    }
    if (race.asian) {
        res.push("asian")
    }
    if (race.black) {
        res.push("black")
    }
    if (race.white) {
        res.push("white")
    }
    if (race.notfurnished) {
        res.push("notfurnished")
    }
    if (race.pacific) {
        if (race.pacificdetails.hawaian) {
            res.push("pacifichawaiian")
        }
        if (race.pacificdetails.guamanian || race.pacificdetails.samoan) {
            res.push("pacificguamaniansamoanian")
        }
        if (race.pacificdetails.other) {
            res.push("pacificother")
        }
    }

    return res
}

function getFormHispanic(hispanic) {
    let res = []
    if (hispanic.mexican) {
        res.push("mexican")
    }
    if (hispanic.cuban) {
        res.push("cuban")
    }
    if (hispanic.other) {
        res.push("other")
    }
    return res
}

function getFormAsianRace(raceVal) {
    let res = []
    if (raceVal.asiandetails.chinese) {
        res.push("chinese")
    }
    if (raceVal.asiandetails.filipino) {
        res.push("filipino")
    }
    if (raceVal.asiandetails.japanese) {
        res.push("japanese")
    }
    if (raceVal.asiandetails.korean) {
        res.push("korean")
    }
    if (raceVal.asiandetails.vietnamese) {
        res.push("vietnamese")
    }
    if (raceVal.asiandetails.asianindian) {
        res.push("asianindian")
    }
    if (raceVal.asiandetails.otherasian) {
        res.push("otherasian")
    }
    return res
}

export default function Borrower(props) {
    const interviewfinished = props.status === 'interviewfinished'
    let disabledOnclick = interviewfinished ? props.onSubmittedDisableClick : null
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hasCoBorrower, setHasCoBorrower] = useState(props.application.hascoborrower === "withcoborrower");
    const [borrowerType, setBorrowerType] = useState(props.application.hardmoneyloan.borrowertype);
    const [loanLimits, setLoanLimits] = useState(null)
    const [stateOptions, setStateOptions] = useState([])
    const [countyOptions, setCountyOptions] = useState([])
    const [coborrowerCountyOptions, setCoborrowerCountyOptions] = useState([])
    const [entityContactFirstName, setEntityContactFirstName] = useState("")
    const [entityContactLastName, setEntityContactLastName] = useState("")
    const [entityContactPosition, setEntityContactPosition] = useState("")
    const { hispanic, ethnicity, race } = props.application.borrower.equalopportunity
    const { asiandetails, pacificdetails } = race

    useEffect(() => {
        if (props.application.hardmoneyloan.entityinfo !== null && entityContactFirstName === "" && entityContactLastName === "" && entityContactPosition === "") {
            updateEntityContactName(getEntityContactNames("firstname", "contactname"), getEntityContactNames("lastname", "contactname"), getEntityContactNames("position", "contactname"))
        } else {
            updateEntityContactName(entityContactFirstName, entityContactLastName, entityContactPosition)
        }
    },[entityContactFirstName, entityContactLastName, entityContactPosition])

    useEffect(() => {
        props.updateIsHardMoneyLoan(true)
    }, [])

    useEffect(() => {
        if (props.application.property.purpose === "purchase"){
            props.changeMainPropertyPurpose("purchaserehab")
        }
    }, [])

    const onFinish = (val) => {
        props.finish(val)
    }
    const disabledDate = (current) => {
        return current && current > dayjs().endOf('day');
    };
    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    }
    const onChangeSex = (e) => {
        props.updateEqualOpportunity(e.target.value, "borrower", "sex")
    }
    const onChangeEthnicity = (e) => {
        props.updateEqualOpportunity(e.target.value, "borrower", "ethnicity")
    }
    const onChangeHispanic = (values) => {
        props.updateEqualOpportunitySub(values.includes("cuban"), "borrower", "hispanic", "cuban")
        props.updateEqualOpportunitySub(values.includes("mexican"), "borrower", "hispanic", "mexican")
        props.updateEqualOpportunitySub(values.includes("other"), "borrower", "hispanic", "other")
    }
    const onChangeRace = (values) => {
        if (values[values.length-1] === "notfurnished") {
            values = ["notfurnished"]
            form.setFieldsValue({formAsianRace: []})
            form.setFieldsValue({formOtherAsian: ""})
            form.setFieldsValue({formPacificOther: ""})
            form.setFieldsValue({formIndianTribe: ""})
            props.updateEqualOpportunitySub({
                chinese: false,
                filipino: false,
                japanese: false,
                korean: false,
                vietnamese: false,
                asianindian: false,
                otherasian: false,
                otherdescription: "",
            }, "borrower","race", "asiandetails")
            props.updateEqualOpportunitySub("", "borrower","race","indiantribe")
            props.updateEqualOpportunitySub({
                hawaian: false,
                samoan: false,
                guamanian: false,
                other: false,
                otherdetails: "",
            }, "borrower","race","pacificdetails")
        }
        if (values[0] === "notfurnished" && values.length > 1) {
            values = values.slice(1)
        }
        form.setFieldsValue({formRace: values})
        props.updateEqualOpportunitySub(values.includes("americanindian"), "borrower", "race", "americanindian")
        props.updateEqualOpportunitySub(values.includes("asian"), "borrower", "race", "asian")
        props.updateEqualOpportunitySub(values.includes("black"), "borrower", "race", "black")
        props.updateEqualOpportunitySub(values.includes("white"), "borrower", "race", "white")
        props.updateEqualOpportunitySub(values.includes("notfurnished"), "borrower", "race", "notfurnished")
        props.updateEqualOpportunitySub(
            values.includes("pacifichawaiian") ||
            values.includes("pacificguamaniansamoanian") ||
            values.includes("pacificother"),
            "borrower", "race", "pacific"
        )
        props.updateEqualOpportunitySub({
            ...pacificdetails,
            hawaian: values.includes("pacifichawaiian"),
            samoan: values.includes("pacificguamaniansamoanian"),
            guamanian: values.includes("pacificguamaniansamoanian"),
            other: values.includes("pacificother"),
        },"borrower", "race", "pacificdetails"
        )
    }
    const onChangeAsianRace = (values) => {
        props.updateEqualOpportunitySub({
            ...props.application.borrower.equalopportunity.race.asiandetails,
            chinese: values.includes("chinese"),
            filipino: values.includes("filipino"),
            japanese: values.includes("japanese"),
            korean: values.includes("korean"),
            vietnamese: values.includes("vietnamese"),
            asianindian: values.includes("asianindian"),
            otherasian: values.includes("otherasian"),
        }, "borrower", "race", "asiandetails")
    }
    const onChangeAsianOther = (e) => {
        props.updateEqualOpportunitySub({
            ...initialRaceVal.asiandetails,
            otherasian: true,
            otherdesctiption: e.target.value,
        }, "borrower", "race", "asiandetails")
    }
    const onChangePacificOther = (e) => {
        props.updateEqualOpportunitySub({
            ...initialRaceVal.pacificdetails,
            other: true,
            otherdetails: e.target.value,
        }, "borrower", "race", "pacificdetails")
    }
    const handleGo = () => {
        setIsModalOpen(false);
    }
    const showModal = () => {
        setIsModalOpen(true);
    }
    const handleCoborrower = (val) => {
        setHasCoBorrower(val)
        if (val) {
            props.updateHasCoborrower("withcoborrower")
        } else {
            props.updateHasCoborrower("alone")
        }
    }
    const onChangeBorrowerType = (val) => {
        setBorrowerType(val)
        props.updateHardMoneyLoanAttribute(val, "borrowertype")
    }
    const getAddress = (val, isCoborrower) => {
        let num = com.getSubaddress(val.address_components, "street_number", "long_name");
        let street = com.getSubaddress(val.address_components, "route", "long_name")
        let city = com.getSubaddress(val.address_components, "locality", "long_name")
        let zipCode = com.getSubaddress(val.address_components, "postal_code", "long_name")
        let state = com.getSubaddress(val.address_components, "administrative_area_level_1", "long_name")
        let county = com.getSubaddress(val.address_components, "administrative_area_level_2", "long_name")
        let streetaddr = street;
        if (num) {
            streetaddr = num + " " + streetaddr;
        }

        if (isCoborrower) {
            form.setFieldsValue({
                coborrower: {
                    ...form.getFieldValue('coborrower'),
                    streetAddress: streetaddr,
                    city: city,
                    zipCode: zipCode,
                    state: state,
                    county: county
                },
            })
            props.changeCoBorrowerInfo(streetaddr, "presentaddressstreet")
            props.changeCoBorrowerInfo(city, "presentaddresscity")
            props.changeCoBorrowerInfo(zipCode, "presentaddresszip")
            props.changeCoBorrowerInfo(state, "presentaddressstate")
            props.changeCoBorrowerInfo(county, "presentaddresscounty")
        } else {
            form.setFieldsValue({
                streetAddress: streetaddr,
                city: city,
                zipCode: zipCode,
                state: state,
                county: county
            })
            props.changeBorrowerInfo(streetaddr, "presentaddressstreet")
            props.changeBorrowerInfo(city, "presentaddresscity")
            props.changeBorrowerInfo(zipCode, "presentaddresszip")
            props.changeBorrowerInfo(state, "presentaddressstate")
            props.changeBorrowerInfo(county, "presentaddresscounty")
        }
    }

    const getEntityAddress = (val) => {
        let num = com.getSubaddress(val.address_components, "street_number", "long_name");
        let street = com.getSubaddress(val.address_components, "route", "long_name")
        let city = com.getSubaddress(val.address_components, "locality", "long_name")
        let zipCode = com.getSubaddress(val.address_components, "postal_code", "long_name")
        let state = com.getSubaddress(val.address_components, "administrative_area_level_1", "long_name")
        let county = com.getSubaddress(val.address_components, "administrative_area_level_2", "long_name")
        let streetaddr = street;
        if (num) {
            streetaddr = num + " " + streetaddr;
        }

        form.setFieldsValue({
            entityStreet: streetaddr,
            entityCity: city,
            entityZip: zipCode,
            entityState: state,
        })
        updateEntityInfoObj({
            streetline1: streetaddr,
            city,
            zip: zipCode,
            state,
        })
    }
    const fetchLoanLimits = () => {
        fetch("/data/loanlimits")
            .then((res) => {
                if (!res.ok) {
                    ;
                    throw Error(res.statusText);
                }
                return res.json();
            })
            .then((res) => {
                setLoanLimits(res)
                setStateOptions(
                    Object.keys(res).map(
                        item => ({
                            value: com.capitalize(item),
                            label: com.capitalize(item)
                        })
                    )
                )
            })
            .catch((err) => {
                console.error(err);
            });
    }
    const onChangeState = (val, coborrower = false) => {
        if (coborrower) {
            props.changeCoBorrowerInfo(val, "presentaddressstate")
            form.setFieldsValue({
                coborrower: {
                    ...form.getFieldValue('coborrower'),
                    county: undefined,
                }
            })
            setCoborrowerCountyOptions(val ? Object.keys(loanLimits[val.toUpperCase()]).map(item => ({ value: com.capitalize(item), label: com.capitalize(item) })) : [])
        } else {
            props.changeBorrowerInfo(val, "presentaddressstate")
            form.setFieldsValue({
                county: undefined,
            })
            setCountyOptions(val ? Object.keys(loanLimits[val.toUpperCase()]).map(item => ({ value: com.capitalize(item), label: com.capitalize(item) })) : [])
        }
    }
    const onChangeEntityState = (val) => {
        updateEntityInfo({ target: { value: val } }, "state")
    }
    const onChangeZip = (e, coborrower = false) => {
        let formatVal = e.target.value.replace(/\-/g, "");
        if (coborrower) {
            form.setFieldsValue({
                coborrower: {
                    ...form.getFieldValue('coborrower'),
                    zipCode: formatVal,
                }
            })
            props.changeCoBorrowerInfo(String(formatVal), "presentaddresszip")
        } else {
            form.setFieldsValue({
                zipCode: formatVal,
            })
            props.changeBorrowerInfo(String(formatVal), "presentaddresszip")
        }
    }
    const formatSSN = (value) => {
        const ssn = value.replace(/[^\d]/g, "")
        const ssnLength = ssn.length
        if (ssnLength < 4) return ssn
        if (ssnLength < 6) {
            return `${ssn.slice(0, 3)}-${ssn.slice(3)}`
        }
        return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`
    }
    const onChangesocialsecurity = (e, type) => {
        let formatVal = formatSSN(e.target.value)
        if (type === 'Borrower') {
            form.setFieldsValue({
                ssn: formatVal,
            })
            props.changeBorrowerInfo(formatVal, "socialsecurity")
        } else {
            const updatedValues = {
                ...form.getFieldsValue(),
                coborrower: {
                    ...form.getFieldValue('coborrower'),
                    ssn: formatVal,
                }
            }
            form.setFieldsValue(updatedValues)
            props.changeCoBorrowerInfo(formatVal, "socialsecurity")
        }
    }
    const onChangedriverlicense = (e, type) => {
        let formatVal = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
        if (type === 'Borrower') {
            form.setFieldsValue({
                driverlicense: formatVal,
            })
            props.changeBorrowerInfo(formatVal, "driverlicense")
        } else {
            form.setFieldsValue({
                coborrower: {
                    ...form.getFieldValue('coborrower'),
                    driverlicense: formatVal,
                }
            })
            props.changeCoBorrowerInfo(formatVal, "driverlicense")
        }
    }
    const onHispanicOtherInputChange = (e) => {
        props.updateEqualOpportunitySub(e.target.value, "borrower", "hispanic", "otherdesctiption")
    }
    const onChangePhone = (e, type) => {
        // let formatVal = e.target.value.replace(/\D/g, "");
        let formatVal = com.fixPhoneInput(e.target.value)
        if (type === 'Borrower') {
            form.setFieldsValue({
                phone: formatVal,
            })
            props.changeBorrowerInfo(formatVal, "homephone")
        } else {
            form.setFieldsValue({
                coborrower: {
                    ...form.getFieldValue('coborrower'),
                    phone: formatVal,
                }
            })
            props.changeCoBorrowerInfo(formatVal, "homephone")
        }
    }
    const onChangeIndianTribe = (e) => {
        props.updateEqualOpportunitySub(e.target.value, "borrower", "race", "indiantribe")
    }
    const checkInputLength = (_, value) => {
        if (value && value.length !== 9) {
            return Promise.reject(new Error('Please enter a 9-digit Social security number'))
        }
        return Promise.resolve()
    }
    const checkSSNLength = (_, value) => {
        if (value && value.replace(/[^\d]/g, "").length !== 9) {
            return Promise.reject(new Error('Please enter a 9-digit Social security number'))
        }
        return Promise.resolve()
    }
    const validatePhoneNumber = (_, value) => {
        const regex = /^\(\d{3}\)-\d{3}-\d{4}$/;
        if (!regex.test(value)) {
            return Promise.reject(new Error('Please enter a valid phone number'))
        }
        return Promise.resolve()
    }
    const checkDriverLicenseLength = (_, value) => {
        return Promise.resolve()
    }
    const updateEntityInfo = (e, k) => {
        let ent
        console.log("e", props.application.hardmoneyloan.entityinfo)
        let val = e?.target?.value
        if (val === undefined) {
            val = e
        }
        if (props.application.hardmoneyloan.entityinfo === null) {
            console.log("innnn here!!!!")
            ent = new HardMoneyLoanEntityInfo()
        } else {
            ent = { ...props.application.hardmoneyloan.entityinfo }
        }
        if (k === 'phone') {
            let formatVal = com.fixPhoneInput(val)
            form.setFieldsValue({
                entityPhone: formatVal,
            })
            ent[k] = formatVal
        } else {
            ent[k] = val
        }
        props.updateHardMoneyLoanAttribute(ent, "entityinfo")
    }

    const updateEntityContactName = (first, last, pos) => {
        let value = first + " " + last + " (" + pos + ")"
        let ent
        if (props.application.hardmoneyloan.entityinfo === null) {
            return
        } else{
            ent = { ...props.application.hardmoneyloan.entityinfo }
        }
        ent["contactname"] = value
        props.updateHardMoneyLoanAttribute(ent, "entityinfo")
    }

    const updateEntityInfoObj = (obj) => {
        let ent
        console.log("obj", props.application.hardmoneyloan.entityinfo)
        if (props.application.hardmoneyloan.entityinfo === null) {
            console.log("innnn here wrong?!!!!")
            let temp = new HardMoneyLoanEntityInfo()
            ent = {
                ...temp,
                ...obj,
            }
        } else {
            ent = {
                ...props.application.hardmoneyloan.entityinfo,
                ...obj,
            }
        }
        props.updateHardMoneyLoanAttribute(ent, "entityinfo")
    }
    const getEntityInfo = (k) => {
        if (props.application.hardmoneyloan.entityinfo === null) {
            return ""
        }
        return props.application.hardmoneyloan.entityinfo[k]
    }

    const getEntityContactNames = (verb ,k) => {
        if (props.application.hardmoneyloan.entityinfo === null){
            return ""
        }
        if (verb === "firstname") {
            return props.application.hardmoneyloan.entityinfo[k].split(" ")[0]
        }
        if (verb === "lastname") {
            return props.application.hardmoneyloan.entityinfo[k].split(" ")[1]
        }
        if (verb === "position") {
            if (props.application.hardmoneyloan.entityinfo[k].split(" ")[2] === undefined){
                return ""
            } else{
                return props.application.hardmoneyloan.entityinfo[k].split(" ")[2].replace(/\(/g, "").replace(/\)/g, "");
            }
        }
    }
    const dob = props.application.borrower.dateofbirth ? dayjs(props.application.borrower.dateofbirth, "MM/DD/YYYY") : ''
    const cdob = props.application.coborrower.dateofbirth ? dayjs(props.application.coborrower.dateofbirth, "MM/DD/YYYY") : ''
    const formInitial = {
        hasCoBorrower: props.application.hascoborrower === "withcoborrower",
        firstname: props.application.borrower.firstname,
        lastname: props.application.borrower.lastname,
        middlename: props.application.borrower.middlename,
        streetAddress: props.application.borrower.presentaddressstreet,
        zipCode: props.application.borrower.presentaddresszip,
        state: props.application.borrower.presentaddressstate,
        city: props.application.borrower.presentaddresscity,
        county: props.application.borrower.presentaddresscounty,
        phone: props.application.borrower.homephone,
        email: props.application.borrower.email,
        ssn: props.application.borrower.socialsecurity,
        driverlicense: props.application.borrower.driverlicense,
        birthday: dob,
        maritalStatus: props.application.borrower.maritalstatus === null ? "" : props.application.borrower.maritalstatus,
        ecs: props.application.hardmoneyloan.estimatedfico,
        coborrower: {
            firstname: props.application.coborrower.firstname,
            lastname: props.application.coborrower.lastname,
            middlename: props.application.coborrower.middlename,
            streetAddress: props.application.coborrower.presentaddressstreet,
            zipCode: props.application.coborrower.presentaddresszip,
            city: props.application.coborrower.presentaddresscity,
            state: props.application.coborrower.presentaddressstate,
            county: props.application.coborrower.presentaddresscounty,
            phone: props.application.coborrower.homephone,
            email: props.application.coborrower.email,
            ssn: props.application.coborrower.socialsecurity,
            birthday: cdob,
            driverlicense: props.application.coborrower.driverlicense,
            maritalStatus: props.application.coborrower.maritalstatus === null ? "" : props.application.coborrower.maritalstatus,
            ecs: props.application.hardmoneyloan.coborrowerestimatedfico,
        },
        borrowerType: props.application.hardmoneyloan.borrowertype,
        entityName: getEntityInfo("entityname"),
        TIN: getEntityInfo("tin"),
        Own: getEntityInfo("percentageown"),
        entityPhone: getEntityInfo("phone"),
        entityEmail: getEntityInfo("email"),
        entityContact: getEntityInfo("contactname"),
        entityStreet: getEntityInfo("streetline1"),
        entityCity: getEntityInfo("city"),
        entityState: getEntityInfo("state"),
        entityZip: getEntityInfo("zip"),
        sex: props.application.borrower.equalopportunity.sex,
        formEthnicity: ethnicity,
        formHispanic: getFormHispanic(hispanic),
        formOtherHispanic: hispanic.otherdesctiption,
        formRace: getFormRace(props.application.borrower.equalopportunity.race),
        formAsianRace: getFormAsianRace(props.application.borrower.equalopportunity.race),
        formIndianTribe: props.application.borrower.equalopportunity.race.indiantribe,
        formOtherAsian: props.application.borrower.equalopportunity.race.asiandetails.otherdesctiption,
        formPacificOther: props.application.borrower.equalopportunity.race.pacificdetails.otherdetails,
        entityContactFirstName:getEntityContactNames("firstname", "contactname"),
        entityContactLastName: getEntityContactNames("lastname", "contactname"),
        entityContactPosition: getEntityContactNames("position", "contactname")
    }


    useEffect(() => {
        fetchLoanLimits()
    }, []);

    const renderBorrower = () => <>
        <Row>
            <span className='form-title'>Borrower Information</span>
        </Row>
        <Row gutter={30}>
            <Col span={8}>
                <Form.Item
                    name="firstname"
                    label="First name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={(e) => { props.changeBorrowerInfo(e.target.value, "firstname") }} />
                </Form.Item>
            </Col>
            <Col span={8}>
                    <Form.Item
                        name="middlename"
                        label="Middle name"
                        rules={[
                            {
                                required: false,
                            }
                        ]}
                        onClick={disabledOnclick}
                    >
                        <Input placeholder="" onChange={(e) => {props.changeBorrowerInfo(e.target.value, "middlename")}} />
                    </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    name="lastname"
                    label="Last name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={(e) => { props.changeBorrowerInfo(e.target.value, "lastname") }} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={24}>
                <Form.Item
                    name="streetAddress"
                    label="Primary address"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <GoogleAutocomplete
                        placeholder="Street address"
                        disabled={interviewfinished}
                        getPlace={val => getAddress(val, false)}
                        onChange={(e) => { props.changeBorrowerInfo(e, "presentaddressstreet") }}
                    />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    name="city"
                    label="City"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={e => props.changeBorrowerInfo(e, "city")} />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    name="state"
                    label="State"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={stateOptions}
                        onChange={onChangeState}
                        placeholder="Select your state"
                    />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    name="zipCode"
                    label="Zip Code"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input
                        type='text'
                        style={{ width: '100%' }}
                        placeholder="Zip Code"
                        onChange={onChangeZip}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="phone"
                    label="Phone number"
                    validateTrigger="onBlur"
                    rules={[
                        {
                            required: true,
                            validator: validatePhoneNumber
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={(e) => { onChangePhone(e, 'Borrower') }} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="email"
                    label="Email address"
                    validateTrigger="onBlur"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={(e) => { props.changeBorrowerInfo(e.target.value, "email") }} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="birthday"
                    label="Date of birth"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <DatePicker
                        style={{ width: '100%' }}
                        className='closing-date'
                        disabledDate={disabledDate}
                        placeholder="MM/DD/YYYY"
                        format="MM/DD/YYYY"
                        onChange={(e) => {
                            let dat = new Date(e)
                            props.changeBorrowerInfo(e ? dayjs(dat).format('MM/DD/YYYY') : '', "dateofbirth")
                        }}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="ssn"
                    label="Social security number"
                    validateTrigger="onBlur"
                    rules={[
                        {
                            required: true,
                            validator: checkSSNLength
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={(e) => { onChangesocialsecurity(e, 'Borrower') }} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="driverlicense"
                    label="Driver license number"
                    rules={[
                        {
                            required: false,
                            validator: checkDriverLicenseLength
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={(e) => { onChangedriverlicense(e, 'Borrower') }} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="maritalStatus"
                    label="Marital status"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={maritalStatusOptions}
                        placeholder="Select your marital status"
                        onChange={(e) => { props.changeBorrowerInfo(e, "maritalstatus") }}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="ecs"
                    label="Estimated credit score"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={500}
                        max={1000}
                        precision={0}
                        onChange={(e) => { props.updateHardMoneyLoanAttribute(e, "estimatedfico") }}
                    />
                </Form.Item>
            </Col>
        </Row>
    </>

    const renderCoborrower = () => <>
        <Row>
            <span className='form-title'>Co-borrower Information</span>
        </Row>
        <Row gutter={30}>
            <Col span={8}>
                <Form.Item
                    name={['coborrower', 'firstname']}
                    label="First name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={(e) => { props.changeCoBorrowerInfo(e.target.value, "firstname") }} />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    name={["coborrower","middlename"]}
                    label="Middle name"
                    rules={[
                        {
                            required: false,
                        }
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={(e) => {props.changeCoBorrowerInfo(e.target.value, "middlename")}} />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    name={['coborrower', 'lastname']}
                    label="Last name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={(e) => { props.changeCoBorrowerInfo(e.target.value, "lastname") }} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={24}>
                <Form.Item
                    name={['coborrower', 'streetAddress']}
                    label="Primary address"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <GoogleAutocomplete
                        placeholder="Street address"
                        disabled={interviewfinished}
                        getPlace={val => getAddress(val, true)}
                        onChange={(e) => { props.changeCoBorrowerInfo(e, "presentaddressstreet") }}
                    />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    name={['coborrower', 'city']}
                    label="City"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={e => props.changeCoBorrowerInfo(e, "city")} />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    name={['coborrower', 'state']}
                    label="State"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={stateOptions}
                        onChange={(e) => onChangeState(e, true)}
                        placeholder="Select your state"
                    />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    name={['coborrower', 'zipCode']}
                    label="Zip Code"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input
                        type='text'
                        style={{ width: '100%' }}
                        placeholder="Zip Code"
                        onChange={(e) => onChangeZip(e, true)}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name={['coborrower', 'phone']}
                    label="Phone number"
                    validateTrigger="onBlur"
                    rules={[
                        {
                            required: true,
                            validator: validatePhoneNumber
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={(e) => { onChangePhone(e, 'coBorrower') }} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name={['coborrower', 'email']}
                    label="Email address"
                    validateTrigger="onBlur"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={(e) => { props.changeCoBorrowerInfo(e.target.value, "email") }} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name={['coborrower', 'birthday']}
                    label="Date of birth"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <DatePicker
                        style={{ width: '100%' }}
                        className='closing-date'
                        disabledDate={disabledDate}
                        placeholder="MM/DD/YYYY"
                        format="MM/DD/YYYY"
                        onChange={(e) => {
                            let dat = new Date(e)
                            props.changeCoBorrowerInfo(e ? dayjs(dat).format('MM/DD/YYYY') : '', "dateofbirth")
                        }}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name={['coborrower', 'ssn']}
                    label="Social security number"
                    validateTrigger="onBlur"
                    rules={[
                        {
                            required: true,
                            validator: checkSSNLength
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={(e) => { onChangesocialsecurity(e, 'coBorrower') }} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name={['coborrower', 'maritalStatus']}
                    label="Marital status"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={maritalStatusOptions}
                        placeholder="Select your marital status"
                        onChange={(e) => { props.changeCoBorrowerInfo(e, "maritalstatus") }}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name={['coborrower', 'ecs']}
                    label="Estimated credit score"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        disabled={interviewfinished}
                        min={500}
                        max={1000}
                        precision={0}
                        onChange={(e) => { props.updateHardMoneyLoanAttribute(e, "coborrowerestimatedfico") }}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name={['coborrower', 'driverlicense']}
                    label="Driver license number"
                    rules={[
                        {
                            required: false,
                            validator: checkDriverLicenseLength
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={(e) => { onChangedriverlicense(e, 'CoBorrower') }} />
                </Form.Item>
            </Col>
        </Row>
        <Divider />
    </>

    const renderEntity = () => <>
        <Row gutter={30}>
            <Col span={20}>
                <Form.Item
                    name="entityStreet"
                    label="Street Address"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <GoogleAutocomplete
                        placeholder=""
                        disabled={interviewfinished}
                        getPlace={val => getEntityAddress(val)}
                        // onChange={e => updateEntityInfo(e, "streetline1")}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={8}>
                <Form.Item
                    name="entityCity"
                    label="City"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={e => updateEntityInfo(e, "city")} />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    name="entityState"
                    label="State"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={stateOptions}
                        onChange={onChangeEntityState}
                        placeholder="Select your state"
                    />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    name="entityZip"
                    label="Zip Code"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={e => updateEntityInfo(e, "zip")} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={9}>
                <Form.Item
                    name="entityName"
                    label="Entity / Individual name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input
                        placeholder=""
                        onChange={e => updateEntityInfo(e, "entityname")}
                    />
                </Form.Item>
            </Col>
            <Col span={9}>
                <Form.Item
                    name="TIN"
                    label="TIN"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={e => updateEntityInfo(e, "tin")} />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    name="Own"
                    label="% Own"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <InputNumber
                        disabled={interviewfinished}
                        min={0}
                        max={100}
                        precision={0}
                        onChange={e => updateEntityInfo(e, "percentageown")}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="entityPhone"
                    label="Phone number"
                    validateTrigger="onBlur"
                    rules={[
                        {
                            required: true,
                            validator: validatePhoneNumber
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={e => updateEntityInfo(e, "phone")} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    name="entityEmail"
                    label="Email address"
                    validateTrigger="onBlur"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={e => updateEntityInfo(e, "email")} />
                </Form.Item>
            </Col>
            {/* <Col span={8}>
                <Form.Item
                    name="entityContact"
                    label="Contact Name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={e => updateEntityInfo(e, "contactname")} />
                </Form.Item>
            </Col> */}
            <Col span={8}>
                <Form.Item
                    name="entityContactFirstName"
                    label="Contact first name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={e => setEntityContactFirstName(e.target.value)} />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    name="entityContactLastName"
                    label="Contact last name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={e => setEntityContactLastName(e.target.value)} />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    name="entityContactPosition"
                    label="Contact position/role"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Input placeholder="" onChange={e => setEntityContactPosition(e.target.value)} />
                </Form.Item>
            </Col>
        </Row>
    </>

    const renderBorrowerTypeSelect = () =>
        <Row gutter={30}>
            <Col span={12}>
                <Form.Item
                    name="borrowerType"
                    label="Borrower type"
                    placeholder="Select your borrower type"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    onClick={disabledOnclick}
                >
                    <Select
                        allowClear
                        options={borrowerTypeOptions}
                        placeholder="Select your borrower type"
                        onChange={onChangeBorrowerType}
                    />
                </Form.Item>
            </Col>
        </Row>

    const renderCoborrowerToggle = () =>
        <Row gutter={30}>
            <Col span={12} className='co-borrower'>
                <Form.Item
                    className='co-borrower-item'
                    name="hasCoBorrower"
                    onClick={disabledOnclick}
                >
                    <Switch
                        onChange={handleCoborrower}
                        defaultChecked={props.application.hascoborrower === "withcoborrower"}
                    />
                </Form.Item>
                <span className='co-borrower-text'>I have a co-borrower</span>
            </Col>
        </Row>

    const renderSex = () =>
        <Row>
            <Form.Item
                name="sex"
                label="Sex"
                rules={[
                    {
                        required: true,
                    },
                ]}
                onClick={disabledOnclick}
            >
                <Radio.Group onChange={onChangeSex}>
                    <Radio value="female">Female</Radio>
                    <Radio value="male">Male</Radio>
                    <Radio value="other">Others</Radio>
                    <Radio value="donttell">I do not wish to provide this information</Radio>
                </Radio.Group>
            </Form.Item>
        </Row>

    const renderEnthnicity = () =>
        <Row style={{ position: 'relative' }}>
            <Form.Item
                name="formEthnicity"
                label="Ethnicity (Check one or more)"
                rules={[
                    {
                        required: true,
                    },
                ]}
                onClick={disabledOnclick}
            >
                <Radio.Group onChange={onChangeEthnicity}>
                    <Row>
                        <Col span={24}><Radio value={E_Nonhispanic}>Not Hispanic or Latino</Radio></Col>
                        <Col span={24}><Radio value={E_Hispanic}>Hispanic or Latino</Radio></Col>
                        {
                            ethnicity === E_Hispanic && (
                                <Form.Item
                                    name="formHispanic"
                                    style={{ position: 'relative', left: 24, marginBottom: 0 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Choose at least one option'
                                        },
                                    ]}
                                    onClick={disabledOnclick}
                                >
                                    <Checkbox.Group onChange={onChangeHispanic}>
                                        <Row>
                                            <Col span={24}><Checkbox value="mexican">Mexican</Checkbox></Col>
                                            <Col span={24}><Checkbox value="cuban">Cuban</Checkbox></Col>
                                            <Col span={24}><Checkbox value="other">Other Hispanic or Latino</Checkbox></Col>
                                            {
                                                hispanic.other && (
                                                    <Form.Item
                                                        style={{ width: 320, position: 'relative', marginBottom: 0 }}
                                                        name="formOtherHispanic"
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: ''
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            onChange={onHispanicOtherInputChange}
                                                            size='default'
                                                            placeholder="Please specify (e.g., Colombian, Dominican, etc)"
                                                        />
                                                    </Form.Item>
                                                )
                                            }
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            )
                        }
                        <Col span={24}><Radio value={E_Donttell}>I do not wish to provide this information</Radio></Col>
                    </Row>
                </Radio.Group>
            </Form.Item>
        </Row>

    const renderRace = () =>
        <Row style={{ position: 'relative' }}>
            <Form.Item
                name="formRace"
                label="Race (Check one or more)"
                rules={[
                    {
                        required: true,
                    },
                ]}
                onClick={disabledOnclick}
            >
                <Checkbox.Group onChange={onChangeRace}>
                    <Row>
                        <Col><Checkbox value="americanindian">American Indian or Alaska Native</Checkbox></Col>
                        <Col span={24}>
                            <Checkbox value="asian">Asian</Checkbox>
                            {
                                race.asian && (
                                    <Form.Item
                                        name="formAsianRace"
                                        style={{ position: 'relative', left: 24, marginBottom: 0 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Choose at least one option'
                                            },
                                        ]}
                                        onClick={disabledOnclick}
                                    >
                                        <Checkbox.Group onChange={onChangeAsianRace}>
                                            <Row>
                                                <Col><Checkbox value="asianindian">Asian Indian</Checkbox></Col>
                                                <Col><Checkbox value="chinese">Chinese</Checkbox></Col>
                                                <Col><Checkbox value="filipino">Filipino</Checkbox></Col>
                                                <Col><Checkbox value="japanese">Japanese</Checkbox></Col>
                                                <Col><Checkbox value="korean">Korean</Checkbox></Col>
                                                <Col><Checkbox value="vietnamese">Vietnamese</Checkbox></Col>
                                                <Col span={24}>
                                                    <Checkbox value="otherasian">Other Asian</Checkbox>
                                                    {
                                                        asiandetails.otherasian && (
                                                            <Form.Item
                                                                name='formOtherAsian'
                                                                style={{ width: 320, position: 'relative', marginBottom: 0}}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: ''
                                                                    },
                                                                ]}
                                                                onClick={disabledOnclick}
                                                            >
                                                                <Input
                                                                    size='default'
                                                                    onChange={onChangeAsianOther}
                                                                    placeholder="Please specify"
                                                                />
                                                            </Form.Item>
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                        </Checkbox.Group>
                                    </Form.Item>
                                )
                            }
                        </Col>
                        <Col span={24}><Checkbox value="black">Black or African American</Checkbox></Col>
                        <Col span={24}><Checkbox value="pacifichawaiian">Native Hawaiian or Other Pacific Islander</Checkbox></Col>
                        <Col span={24}><Checkbox value="pacificguamaniansamoanian">Native Hawaiian Guamanian or Chamorro Samoan</Checkbox></Col>
                        <Col span={24}>
                            <Checkbox value="pacificother">Other Pacific Islander</Checkbox>
                            {
                                race.pacific && pacificdetails.other && (
                                    <Form.Item
                                        name="formPacificOther"
                                        style={{ width: 320, position: 'relative', marginBottom: 0 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: ''
                                            },
                                        ]}
                                        onClick={disabledOnclick}
                                    >
                                        <Input size='default' onChange={onChangePacificOther} placeholder="Please specify" />
                                    </Form.Item>
                                )
                            }
                        </Col>
                        <Col span={24}>
                            <Checkbox value="white">White</Checkbox>
                        </Col>
                        <Col span={24}><Checkbox value="notfurnished">I do not wish to provide this information</Checkbox></Col>
                    </Row>
                </Checkbox.Group>
            </Form.Item>
            {
                race.americanindian && (
                    <Form.Item
                        name="formIndianTribe"
                        style={{ width: 320, position: 'absolute', top: '24px', left: '240px' }}
                        rules={[
                            {
                                required: true,
                                message: ''
                            },
                        ]}
                        onClick={disabledOnclick}
                    >
                        <Input onChange={onChangeIndianTribe} size='default' placeholder="Please specify" />
                    </Form.Item>
                )
            }
        </Row>

    const renderDemographics = () => <>
        <Row>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className='form-title'>Borrower Demographic Information</span><QuestionCircleOutlined className='question-icon' onClick={showModal} />
            </div>
        </Row>
        {renderSex()}
        {renderEnthnicity()}
        {renderRace()}
    </>

    const renderModal = () => (
        <Modal
            className='borrower-modal'
            open={isModalOpen}
            title="Why do I see these questions? "
            footer={[
                <Button className='go-btn' key="submit" type="primary" size="large" onClick={handleGo}>
                    Go it
                </Button>,
            ]}
            width={767}
        >
            <div className='borrower-modal-content'>
                <p>The purpose of collecting this information is to help ensure that all applicants are treated fairly and that the housing needs of communities and neighborhoods are being fulfilled.</p>
                <p>For residential mortgage lending, Federal law requires that we ask applicants for their demographic information (ethnicity, sex, and race) in order to monitor our compliance with equal credit opportunity, fair housing, and home mortgage disclosure laws.</p>
                <p>You are not required to provide this information, but are encouraged to do so. You may select one or more designations for “ethnicity” and one or more designations for “race.” </p>
                <p>The law provides that we may not discriminate on the basis of this information, or on where you choose to provide it. </p>
                <p>However, if you choose not to provide the information and you have made this application in person, Federal regulations require us to note your ethnicity, sex and race on the basis of visual observation or surname. </p>
                <p>The law also provides that we may not discriminate on the basis of age or marital status information provide in this application.</p>
                <p>If you do not wish to provide some or all of this information, please indicate so.</p>
            </div>
        </Modal>
    )

    const onFieldChanged = (changedValues, allValues) => {
        const hasError = form.getFieldsError().some(x => x.errors.length > 0)
        if (hasError) props.resetProgress()
        console.log("all values", allValues)
        console.log("changed values", changedValues)

    }

    return (
        <div className="borrower-form text-left">
            <Form
                className='application-form'
                layout='vertical'
                form={form}
                initialValues={formInitial}
                onFieldsChange={onFieldChanged}
                scrollToFirstError={{
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'center',
                    block: 'center',
                }}
                disabled={interviewfinished}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
                size="large"
            >
                {renderBorrower()}
                {renderCoborrowerToggle()}
                <Divider />
                {hasCoBorrower ? renderCoborrower() : null}
                {renderBorrowerTypeSelect()}
                {borrowerType ? renderEntity() : null}
                <Divider />
                {renderDemographics()}
                <Row>
                    <Form.Item>
                        <Button
                            type="primary"
                            className='common-btn'
                            htmlType="submit"
                            onClick={() => {
                                // Scroll to top after click continue
                                window.document.body.scrollIntoView({ behavior: "smooth", inline: "nearest" });
                            }}
                            disabled={false}
                        >
                            Continue
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
            {renderModal()}
        </div>
    )
}

Borrower = connect(mapStateToProps, mapDispatchToProps)(Borrower);
