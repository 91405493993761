import applicationReducer from './application'
import progressReducer from './progress'
import commonReducer from './common'
import { combineReducers } from 'redux';
import updateStatusReducer from './status';
import accountInfoReducer from './accountinfo';

let rootReducer =  combineReducers({
    application: applicationReducer,
    progress: progressReducer,
    updateStatus: updateStatusReducer,
    accountInfo: accountInfoReducer,
    common:commonReducer
  });

export default rootReducer;