import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import * as com from '../../Common';
import CodeVerification from "../../Common/components/CodeVerification";
import Spinner from "react-bootstrap/Spinner";
// import "./CoborrowerSignature.css";
import "./CoborrowerSignature.css";
import { Button, Modal, Spin } from "antd";
import {Document, Page, pdfjs} from "react-pdf";
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep';

function blobToURL(blob) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            resolve(base64data);
        };
    });
}

function base64ToArrayBuffer(base64) {
    const binaryString = atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)

    for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i)
    }

    return bytes.buffer
}


function PassCodeVerification(props) {
    const [code, setCode] = useState("");
    const [showError, setShowError] = useState(false);
    const [verifyingCode, setVerifyingCode] = useState(false);
    const [showNoValidMessage, setShowNoValidMessage] = useState(false);

    const verifycode = () => {
        setVerifyingCode(true)
        fetch('/auth/verifysignaturepasscode', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Cache: 'no-cache'
            },
            body: JSON.stringify({
                customerid: props.customerid,
                loanid: props.loanid,
                code: code
            })
        }).then(response => {
            if (response.status !== 200) {
                alert("Looks like there was a problem. Status Code: " + response.status + ", Please try again later");
            }
            response.json().then(js => {
                if (js.Status !== "OK"){
                    setVerifyingCode(false)
                    setShowError(true)
                }
                else {
                    setVerifyingCode(false)
                    setShowError(false)
                    props.setCodePassed(true)
                    props.setSignStatus(js.State)
                }
            })
        }
        )
    }

    const showErrorMessage = () => {
        return(
            <div className="d-flex justify-content-center">
                <div style={{ display:"flex",backgroundColor: "#FFE6BF", borderRadius: "4px", padding: "10px", marginTop: "15px", width: "100%", fontSize:"12px" }}>
                    <img style={{ width: "18px", height: "18px", marginRight: "10px" }} src="/images/warningSignYellow.png" />
                    <div>The code you entered is not valid, please try again.</div>
                </div>
                
            </div>
        )
    }
    return(
        <div className="codeVerifyPane">
            <div className="codeVerifyHeader">
                <img style={{width:"115px", height:"42px"}} src="/images/yeeleeLogo.png" alt="Yeelee Capital Logo" />
            </div>
            <div className="codeVerifyBody">
                {!showNoValidMessage?
                    <div className="codeVerifyWrap">
                        <div className="codeVerifyTittle">Hello {props.coborrowerName}! </div>
                        <div className='codeVerifyBodyText'>Please enter the 4-digit verification code provided by the primary borrower to access the application form</div>
                        {verifyingCode ? 
                            <Spinner animation='border' size='lg' /> 
                            :
                            <CodeVerification 
                                value={code} 
                                onChange={
                                    (e) => {
                                        setCode(e)
                                        // if (e.length === 4) {
                                        //     verifycode(e)
                                        // }
                                    }
                                } 
                            />
                        }
                        {showError && showErrorMessage()}
                        <Button className="verifyCodeButton" onClick={verifycode}>Continue</Button>
                        <div className="noValidCode" onClick={()=> setShowNoValidMessage(true)}>I do no have a valid verification code</div>
                    </div>
                    :
                    <div className="codeVerifyWrap">
                        <div className="noValidMessageTitle">Invalid verification code?</div>
                        <div className="noValidMessageText">Please contact the primary borrower for the verification code. They can find it in their email. If they don't see it, please check the spam folder.</div>
                        <div className="noValidMessageText" style={{marginBottom:"40px"}}>For further assistance, please contact us at <span style={{color:"#325CEB"}}>info@yeeleecapital.com</span>.</div>
                        <Button className="closeNoValidMessage" onClick={() => setShowNoValidMessage(false)}>Got it</Button>
                    </div>
                }
            </div>
        </div>
    )
}

const signCoordinates = {
    "page2": {
        "borrower.signature": { Left: 149.938012, Top: 699.655642, Right: 448.457415, Bottom: 724.989162 },
        "borrower.signature.date": { Left: 492.877884, Top: 700.794918, Right: 576.247894, Bottom: 724.431574 },
        "coborrower.signature": { Left: 149.194504, Top: 740.631587, Right: 447.174893, Bottom: 764.115037 },
        "coborrower.signature.date": { Left: 494.044096, Top: 739.106156, Right: 575.960673, Bottom: 764.784832 },
    },
    "page6": {
        "borrower.signature": { Left: 154.992455, Top: 692.501348, Right: 412.125321, Bottom: 712.885257 },
        "borrower.signature.date": { Left: 455.208178, Top: 690.486024, Right: 592.914131, Bottom: 712.987128 },
        "coborrower.signature": { Left: 169.909857, Top: 737.242870, Right: 421.305854, Bottom: 758.380672 },
        "coborrower.signature.date": { Left: 465.925736, Top: 734.865661, Right: 585.810826, Bottom: 758.224112 },
    },
    "page7": {
        "borrower.signature": { Left: 150.868699, Top: 690.059578, Right: 414.187342, Bottom: 713.687426 },
        "borrower.signature.date": { Left: 456.687334, Top: 685.539165, Right: 547.043647, Bottom: 710.956520 },
        "coborrower.signature.date": { Left: 465.536239, Top: 726.710112, Right: 553.345275, Bottom: 756.527421 },
        "coborrower.signature": { Left: 169.022238, Top: 732.849779, Right: 423.397619, Bottom: 759.467932 },
    },
    "page8": {
        "borrower.signature": { Left: 156.361317, Top: 679.203119, Right: 409.458129, Bottom: 702.315068 },
        "borrower.signature.date": { Left: 454.912180, Top: 678.029100, Right: 587.751260, Bottom: 700.909247 },
        "coborrower.signature": { Left: 175.000186, Top: 723.278574, Right: 424.748534, Bottom: 748.862620 },
        "coborrower.signature.date": { Left: 468.006864, Top: 719.840562, Right: 590.132241, Bottom: 746.434448 },
    },
    "page9": {
        "borrower.signature": { Left: 152.011897, Top: 463.909080, Right: 407.620574, Bottom: 487.300107 },
        "borrower.signature.date": { Left: 452.712552, Top: 463.862488, Right: 588.684658, Bottom: 485.761183 },
        "coborrower.signature": { Left: 168.497061, Top: 512.377038, Right: 421.376242, Bottom: 534.365661 },
        "coborrower.signature.date": { Left: 463.001602, Top: 508.093347, Right: 601.608988, Bottom: 532.818064 },
    },
}
const PT_PX_RATIO = 1.46;

let signMarks = [
    {
        page: 1,
        signid: "page2signature",
        pageName: "page2",
        type: 'signature',
        key: "borrower.signature",
        style: {
        }
    },
    {
        page: 1,
        signid: "page2signature",
        pageName: "page2",
        type: 'date',
        key: "borrower.signature.date",
        style: {
        }
    },
    {
        page: 5,
        signid: "page6signature",
        pageName: "page6",
        type: 'signature',
        key: "borrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 5,
        signid: "page6signature",
        pageName: "page6",
        type: 'date',
        key: "borrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 6,
        signid: "page7signature",
        pageName: "page7",
        type: 'signature',
        key: "borrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 6,
        signid: "page7signature",
        pageName: "page7",
        type: 'date',
        key: "borrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 7,
        signid: "page8signature",
        pageName: "page8",
        type: 'signature',
        key: "borrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 7,
        signid: "page8signature",
        pageName: "page8",
        type: 'date',
        key: "borrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 8,
        signid: "page9signature",
        pageName: "page9",
        type: 'signature',
        key: "borrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 8,
        signid: "page9signature",
        pageName: "page9",
        type: 'date',
        key: "borrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
]
let cosignMarks = [
    {
        page: 1,
        signid: "page2cosignature",
        pageName: "page2",
        type: 'signature',
        key: "coborrower.signature",
        style: {
        }
    },
    {
        page: 1,
        signid: "page2cosignature",
        pageName: "page2",
        type: 'date',
        key: "coborrower.signature.date",
        style: {
        }
    },
    {
        page: 5,
        signid: "page6cosignature",
        pageName: "page6",
        type: 'signature',
        key: "coborrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 5,
        signid: "page6cosignature",
        pageName: "page6",
        type: 'date',
        key: "coborrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 6,
        signid: "page7cosignature",
        pageName: "page7",
        type: 'signature',
        key: "coborrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 6,
        signid: "page7cosignature",
        pageName: "page7",
        type: 'date',
        key: "coborrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 7,
        signid: "page8cosignature",
        pageName: "page8",
        type: 'signature',
        key: "coborrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 7,
        signid: "page8cosignature",
        pageName: "page8",
        type: 'date',
        key: "coborrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 8,
        signid: "page9cosignature",
        pageName: "page9",
        type: 'signature',
        key: "coborrower.signature",
        style: {
            top: 0,
            left: 0,
        }
    },
    {
        page: 8,
        signid: "page9cosignature",
        pageName: "page9",
        type: 'date',
        key: "coborrower.signature.date",
        style: {
            top: 0,
            left: 0,
        }
    }
]


export default function CoborrowerSignature() {
    pdfjs.GlobalWorkerOptions.workerSrc =
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [codePassed, setCodePassed] = useState(false);
    const [pdf, setPdf] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [docData, setDocData] = useState(null);
    const [loadingDocs, setLoadingDocs] = useState(false);
    const [signAuthorized, setSignAuthorized] = useState(false);
    const [showSignSuccessModal, setShowSignSuccessModal] = useState(false);
    const [showSignDeclineModal, setShowSignDeclineModal] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [primarySignItems, setPrimarySignItems] = useState({});
    const [status, setStatus] = useState("");
    const [signItems, setSignItems] = useState({});
    const [actionCompleted, setActionCompleted] = useState(false);
    const [signStatus, setSignStatus] = useState("");
    const {customerid} = useParams();
    const documentRef = useRef(null);
    const pageRefs = useRef({});
    const containerRef = useRef(null);
    let searchparams = new URLSearchParams(window.location.search);
    let loanid = searchparams.get("loanid")
    let coborrowerFirstName= searchparams.get("firstname")
    let coborrowerLastName= searchparams.get("lastname")
    let coborrowerName = coborrowerFirstName + " " + coborrowerLastName
    const WIDTH = 900

    useEffect(() => {
        if (signStatus === "cosigned"){
            setStatus("submitted")
            setActionCompleted(true)
        }
    }, [signStatus])

    useEffect(() => {
        const fetchData = async () => {
            signMarks = [
                ...signMarks,
                ...cosignMarks
            ]
            if (codePassed) {
                for (const signMark of signMarks) {
                    const { pageName, key } = signMark
                    signMark.style = {
                        top: signCoordinates[pageName][key].Top * PT_PX_RATIO,
                        left: signCoordinates[pageName][key].Left * PT_PX_RATIO,
                        width: (signCoordinates[pageName][key].Right - signCoordinates[pageName][key].Left) * PT_PX_RATIO,
                        height: (signCoordinates[pageName][key].Bottom - signCoordinates[pageName][key].Top) * PT_PX_RATIO,
                    }
                }
        
                let initSignItems = {}
                for (const { pageName, key, type } of signMarks) {
                    if (!(pageName in initSignItems)) {
                        initSignItems[pageName] = {}
                    }
                    if (!(key in initSignItems[pageName])) {
                        initSignItems[pageName][key] = ''
                    }
                }
                setSignItems(initSignItems)
                setLoadingDocs(true);
                try {
                    const response = await fetch("/borrower/docs/hardmoneyloan/receivedformsforcosigner", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Cache: "no-cache"
                        },
                        body: JSON.stringify({
                            customerid: customerid,
                            loanid: loanid
                        })
                    });
    
                    if (response.status !== 200) {
                        if (response.Text.includes("no rows in result set")) {
                            setLoadingDocs(false);
                            alert("Your primary borrower has not yet completed the application form. Please try again later.");
                            return;
                        } else{
                            setLoadingDocs(false);
                            alert("Looks like there was an internal server problem. Status Code: " + response.status + ", Please try again later.");
                            return;
                        }
                    }
    
                    const jsonResponse = await response.json();
                    const docData = await jsonResponse.Doc.Data;
                    const file = base64ToArrayBuffer(docData);
                    const blob = new Blob([file]);
                    const URL =  await blobToURL(blob);
                    setPdf(URL);
                    setLoadingDocs(false);
                } catch (error) {
                    console.error("Error fetching document data:", error);
                    setLoadingDocs(false);
                }
            }
        };
    
        fetchData();
        getPrimarySignItems();
    }, [codePassed, status]);

    function getPrimarySignItems() {
        fetch('/borrower/hardmoneyloan/getprimarysignitems', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Cache: 'no-cache'
            },
            body: JSON.stringify({
                customerid: customerid,
                loanid: loanid
            })
        }).then(response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                    response.status);
                return;
            }
            response.json().then(data => {
                setPrimarySignItems(data)
            })
        })
    }

    function submitSignatures(signItems) {
        setSubmitLoading(true)
        fetch(`/borrower/hardmoneyloan/saveyeeleeformwithcosign?loanid=${loanid}&customerid=${customerid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Cache: "no-cache"
          },
          body: JSON.stringify({signItems})
        }).then(
          response => {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' +
                response.status);
              return;
            }

            
            setSubmitLoading(false)
            setActionCompleted(true)
            setShowSignSuccessModal(true)
            // setSubmitLoading(false)
            // props.onSubmit(signItems)
          }
        )
          .catch(function (err) {
            console.log('Fetch Error :-S', err);
          });
    }

    function insertSignature() {
        signMarks = [
            ...signMarks,
            ...cosignMarks
        ]
        let newSignItems= JSON.parse(JSON.stringify(signItems))
        for (const {pageName, key, type} of signMarks) {
            if (type === 'signature') {
                if (key === 'coborrower.signature') {
                    newSignItems[pageName][key] = coborrowerName
                }
                if (key === 'borrower.signature') {
                    newSignItems[pageName][key] = primarySignItems.signItems[pageName][key]
                }
            } else if (type === 'date') {
                if (key === 'coborrower.signature.date') {
                    newSignItems[pageName][key] = moment().format('MM/DD/YYYY')
                } else {
                    newSignItems[pageName][key] = primarySignItems.signItems[pageName][key]
                }
            }
        }
        setSignItems(newSignItems)
        setSignAuthorized(true)
        submitSignatures(newSignItems)
    }

    function confirmSuccess() {
        setShowSignSuccessModal(false)
        setStatus("submitted")
    }

    function confirmDecline() {
        setShowSignDeclineModal(false)
        setStatus("declined")
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const onPageLoadSuccess = (page) => {
    };

    return(
        <div className="h-100">
            {/* <div className="signPage-Header">Header</div> */}
            <div className="signPage-Body">
                {!codePassed?
                    <PassCodeVerification 
                        customerid={customerid} 
                        loanid={loanid} 
                        coborrowerName={coborrowerName} 
                        setCodePassed={setCodePassed} 
                        setSignStatus={setSignStatus}
                    />
                    :
                    <div className="PDFPage-pane">
                        <Modal
                            open={showSignSuccessModal}
                            closable={false}
                            className="signSuccessModal"
                            footer={null}
                        >
                            <div className="confirmModalTitle">Signature Submitted</div>
                            <div className="confirmModalBodyText">You have successfully signed the loan application form.</div>
                            <div className="confirmModalBodyText">Once all borrowers review and sign the form, we will send you a confirmation email. The Yeelee Capital team will then promptyly begin processing your loan application.</div>
                            <div className="w-100 d-flex justify-content-center"><Button className="confirmModalBtn" type="primary" onClick={confirmSuccess}>Got it</Button></div>
                        </Modal>
                        <Modal
                            open={showSignDeclineModal}
                            closable={false}
                            className="signDeclineModal"
                            footer={null}
                        >
                            <div className="confirmModalTitle">Signature Declined</div>
                            <div className="confirmModalBodyText">You have declined to sign the loan application form.</div>
                            <div className="confirmModalBodyText">Please contact the primary borrower to make the necessary edits and resubmit the application form.</div>
                            <div className="confirmModalBodyText">If you have any questions or need assistance, please contact us at <span style={{color:"#325CEB"}}>info@yeeleecapital.com</span>.</div>
                            <div className="w-100 d-flex justify-content-center"><Button className="confirmModalBtn" type="primary" onClick={confirmDecline}>Got it</Button></div>
                        </Modal>

                        <div className="PDFPage-topnav">
                            <img style={{position:"absolute", left:"20px"}} src="/images/yeeleeLogo.png" alt="Yeelee Capital Logo" />
                            {status === ""?
                                <div style={{fontSize:"0.95rem"}}>Please review the application form, read the disclosures and consents carefully, and click "Authorize all signatures" to sign the form. </div>
                                :
                            status === "submitted"?
                                <div className="signatureStatus" style={{backgroundColor:"#E1F2E2"}}>Signature Submitted </div>
                                :
                                <div className="signatureStatus" style={{backgroundColor:"#EFEFEF"}}>Signature declined </div>
                            }
                        </div>
                        {!loadingDocs?
                            <div>
                                <center className='pdf-container'>
                                    <Document
                                        className='pdf-content'
                                        file={pdf}
                                        ref={documentRef}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        {Array.from(
                                            new Array(numPages),
                                            (el, index) => (
                                                <Page
                                                    inputRef={ref =>{
                                                        pageRefs.current[index + 1] = ref
                                                    }}
                                                    width={WIDTH}
                                                    key={`page_${index + 1}`}
                                                    pageNumber={index + 1}
                                                    renderTextLayer={false}
                                                    onLoadSuccess={onPageLoadSuccess}
                                                    onRenderSuccess={(props)=>{}}
                                                >
                                                    {!actionCompleted && <div
                                                        className="page-sign-layer"
                                                        style={{
                                                            width: WIDTH,
                                                            marginLeft: -WIDTH/2,
                                                            left: '50%',
                                                        }}
                                                    >
                                                        {cosignMarks.map((signMark, signIdx) => {
                                                            if (signMark.page === index) {
                                                                let signContent = signItems[signMark.pageName][signMark.key]
                                                                if (signMark.type === 'signature') {
                                                                    return <>
                                                                        <div
                                                                            key={signMark.key}
                                                                            className="PDFsign-mark"
                                                                            // onClick={() => openSignPad(signMark.signid)}
                                                                            style={{
                                                                                position: 'absolute',
                                                                                ...signMark.style
                                                                            }}
                                                                        >
                                                                            {!signAuthorized?<div 
                                                                                style={{
                                                                                    backgroundColor: "#EFEFEF",
                                                                                    border: "1px solid #EFEFEF",
                                                                                    borderRadius: "4px",
                                                                                    color: "#616161",
                                                                                    fontSize: "14px",
                                                                                    padding: "8px",
                                                                                    fontWeight: "500",
                                                                                    width: "100%",
                                                                                    height: "99%",
                                                                                }}
                                                                            >
                                                                                Awaiting your signature
                                                                            </div>
                                                                            :
                                                                            <div>{signContent}</div>
                                                                            }
                                                                        </div>
                                                                        {/* { !signContent ? <div
                                                                            className='sign-thumb-wrapper'
                                                                            // onClick={() => openSignPad(signMark.signid)}
                                                                        >
                                                                            <div
                                                                                className='sign-thumb'
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    top: signMark.style.top,
                                                                                    left: -140,
                                                                                }}
                                                                            >
                                                                                Awating your signature
                                                                            </div>
                                                                            <div
                                                                                className="sign-thumb-triangle"
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    top: signMark.style.top,
                                                                                    left: -40,
                                                                                }}
                                                                            ></div>
                                                                        </div> : null} */}
                                                                    </>
                                                                } else if (signMark.type === 'date') {
                                                                    return (
                                                                        <div
                                                                            key={signMark.key}
                                                                            className={signMark.type === 'signature' ? "sign-mark" : "sign-mark-date"}
                                                                            // onClick={() => openSignPad(signMark.signid)}
                                                                            style={{
                                                                                position: 'absolute',
                                                                                ...signMark.style
                                                                            }}
                                                                        >
                                                                            {signContent}
                                                                        </div>
                                                                    )
                                                                }
                                                            }
                                                            return null;
                                                        })}
                                                    </div>}
                                                </Page>
                                            ),
                                        )

                                        }
                                    </Document>
                                </center>
                            </div>
                            :
                            <Spinner animation='border' size='lg' />
                        }
                        <div className='PDFPage-bottomnav'>
                            {actionCompleted?
                                ""
                                :
                                <>
                                    <span 
                                        className="declineSign" 
                                        style={{color:"#325CEB", cursor:"pointer"}} 
                                        onClick={() => {
                                            setShowSignDeclineModal(true)
                                            setActionCompleted(true)
                                        }}
                                    >
                                        Decline signatures
                                    </span>
                                    <Button className="authorizeSign" type="primary" onClick={insertSignature}>{submitLoading?<Spin /> :"Authorize all signatures"}</Button>
                                </>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}