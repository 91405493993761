import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'
import {
  CaretUpFilled,
  CaretDownFilled
} from '@ant-design/icons';
import { Button, Menu } from 'antd';
import './SidebarHardMoneyLoan.css';
import { LeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import { stepMap, stepNumMap } from './constants'
import { Tooltip, Dropdown } from 'antd';
import { useSelector } from 'react-redux';
import * as com from '../Common'

const mapStateToProps = (state) => {
  return {
    property: state.application.property,
    purpose: state.application.property.purpose,
    hardmoney: state.application.hardmoneyloan,
    progress: state.progress,
  }
}
export default function SidebarHardMoneyLoan(props) {
  const { section } = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const application = useSelector(state => state.application);
  const [companyName, setCompanyName] = useState('');
  const renderExpandIcon = (props) => {
    if (props.isSubMenu && props.isOpen) {
      return <CaretUpFilled />;
    } else if (props.isSubMenu && !props.isOpen) {
      return <CaretDownFilled />;
    }
    return null;
  };

  const onClick = (e) => {
    props.menuClick(e);
  };

  const getCompanyName = () => {
    fetch('/data/companycustomization', {
      headers: {
        'X-CustomerId': com.getCustomerId(),
      }
    }).then(async response => {
      if (!response.ok) {
        return;
      }
      response.json().then(js => {
        setCompanyName(js.CompanyName)
      })
    })
  }

  useEffect(() => {
    getCompanyName();
  }, [])

  useEffect(() => {
    if (section in stepMap) {
      let stepval = Math.min(props.progress.interview.step + 1, 4)
      stepval = Math.max(stepval, 1)
      setSelectedItem([stepNumMap[stepval]])
    } else {
      setSelectedItem([])
    }
  }, [props.progress.interview.step])

  function getItem(label, key, noIcon, children, type) {
    let icon = null
    if (!noIcon && key in stepMap) {
      if (props.progress.interview.step + 1 === stepMap[key]) icon = <img src="/images/navSignSelected.png" />
      else {
        icon = props.progress.interview.max >= stepMap[key] ? <img src="/images/navSignFinished.png" /> : <img src="/images/navSignUnselected.png" />
      }
    }
    return {
      key,
      icon,
      children,
      label,
      type,
      disabled: stepMap[key] > props.progress.interview.max + 1
    };
  }

  const items = [
    getItem('Overview', 'overview', true),
    getItem('My Application', 'myApplication', true, [
      getItem('Borrower', 'borrower'),
      getItem('Property and Loan', 'property'),
      getItem('Experience', 'experience'),
      getItem('Declarations', 'declarations'),
    ]),
    // getItem('My Tasks', 'myTasks', true),
    // getItem('My Documents', 'myDocuments', true, [
    //   getItem('Uploaded Documents', 'documentsUploaded'),
    //   getItem('Received Documents', 'documentsReceived'),
    // ]),
    getItem('Documents', 'documents', true)
  ];

  const profileItems = [
    {
      label: <Link to='/application/logout' className='DropdownP'>
        Sign out
      </Link>,
      key: '3',
    }
  ]


  return (
    <div className='sidenavPrequal'>
      <div>
        <div style={{display:"flex", width:"100%", paddingLeft:"20px"}}>
          <div className='sidebarCompanyLogo'>{companyName}</div>
        </div>
        <div className='mt-2 mb-4 text-left' style={{ cursor: 'pointer', paddingLeft: 26 }} onClick={() => {
            window.open('/application/myloans', '_self')
        }}>
            <LeftOutlined /> <span style={{ color: '#222', fontSize: 14, marginLeft: '10px' }}>Back to Loans</span>
        </div>
        <Menu
          className='hardMoneyLoan-menu'
          defaultSelectedKeys={['overview']}
          defaultOpenKeys={['myApplication', 'myDocuments']}
          selectedKeys={selectedItem}
          expandIcon={renderExpandIcon}
          onClick={onClick}
          mode="inline"
          inlineCollapsed={collapsed}
          items={items}
        />
      </div>
      <div style={{
          borderTop
              : '1px solid #DBDCDE'
      }}>
          <div style={{ padding: '0 26px' }}>
              <Dropdown menu={{ items:profileItems}} >
                  <div className='d-flex  align-items-center pt-2 pb-2' >
                      <div className='NameBar'>{application.borrower.firstname.charAt(0)}</div>
                      <div className='ml-2 '>
                          <Tooltip placement="top" style={{ width: 200 }} title={<><span>{application.borrower.firstname}</span> <span>{application.borrower.lastname}</span></>} >
                              <div className='text-left overflowH' style={{
                                  fontSize: 16,
                                  fontWeight: 500,
                              }}>{application.borrower.firstname}    {application.borrower.lastname}</div>
                          </Tooltip>
                          <Tooltip placement="top" title={application.borrower.email} >
                              <div className='text-left overflowH' style={{
                                  color: "#6E6E70",
                                  fontSize: 14,
                                  fontWeight: 400
                              }}>{application.borrower.email}</div>
                          </Tooltip>

                      </div>
                  </div>
              </Dropdown>
          </div>
      </div>
    </div>
    
  )
}

SidebarHardMoneyLoan = connect(mapStateToProps)(SidebarHardMoneyLoan);
