import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import "./HardMoneyLoanInterView.css";
import Overview from "./HardMoneyLoan/Overview";
import Borrower from "./HardMoneyLoan/Borrower";
import PropertyAndLoan from "./HardMoneyLoan/PropertyAndLoan";
import Declarations from "./HardMoneyLoan/Declarations";
import Experience from "./HardMoneyLoan/Experience";
import SidebarHardMoneyLoan from "../SidebarHardMoneyLoan/SidebarHardMoneyLoan";
import MyTasks from "./HardMoneyLoan/MyTasks"
import DocumentsReceived from "./HardMoneyLoan/DocumentsReceived"
import DocumentsUploaded from "./HardMoneyLoan/DocumentsUploaded"
import Submitted from "./HardMoneyLoan/Submitted"
import { connect } from 'react-redux'
import { UpdateChangeProgress, UpdateChangeLastTouch, UpdateChangeProgressStep } from "../Store/progress"
import { UpdateApplicationAttribute } from "../Store/actions";
import { withRouter } from "react-router-dom";
import * as com from "../Common"
import { stepMap } from '../SidebarHardMoneyLoan/constants'
import PDFPreview from './HardMoneyLoan/PDFPreview'
import { Modal } from 'antd'
import Tasks from "../Tasks";
import Docs from "../Documents/Documents"
const mapStateToProps = (state) => {
  return {
    fullstate: state,
    progress: state.progress,
    application: state.application,
    status: state.application.status,
    borrower: state.application.borrower,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateChangeLastTouch: (t) => {
    dispatch(UpdateChangeLastTouch(t))
  },
  updateApplicationAttribute: (t, verb) => {
    dispatch(UpdateApplicationAttribute(t, verb))
  },
  updateChangeProgress: (step, stage) => {
    dispatch(UpdateChangeProgress(step, stage))
  },
  updateChangeProgressStep: (step) => {
    dispatch(UpdateChangeProgressStep(step))
  }
});

export default function HardMoneyLoanInterView(props) {
  const [clickMenuKey, setClickMenuKey] = useState("");
  const [pdfVisible, setPDFVisible] = useState(false)
  const [showSubmittedModal, setShowSubmittedModal] = useState(false)
  const history = useHistory();

  let section = props.match.params["section"]
  useEffect(() => {
    setClickMenuKey(section)
  }, [section])

  useEffect(() => {
    let $el = document.getElementById('myapp')
    if ($el) $el.style.overflow = pdfVisible ? 'hidden' : 'auto'
  }, [pdfVisible])

  const checkAndCreateTasks = (docNames) => {

    let tasks = []
    let now = new Date()
    let tenDaysAfterNow = new Date(now);
    tenDaysAfterNow.setDate(tenDaysAfterNow.getDate() + 10);

    for (let d of docNames) {
      let taskid = "other"
      for (let key in com.documents) {
        if (key.startsWith("yeelee")) {
          for (let name of com.documents[key]) {
            if (d.includes(name)) {
              taskid = key
              continue
            }
          }
        }
      }
      tasks.push({
        TaskName: d,
        TaskType: "upload",
        Created: now.toUTCString(), //('en-US'),
        Duedate: tenDaysAfterNow.toUTCString(),
        TaskID: taskid,
        ForWhom: "borrower",
        Key: Math.random(),
      })
    }

    let token = sessionStorage.getItem("ZeitroA")
    let body = {
      Tasks: tasks
    }

    fetch('/borrower/hardmoneyloan/createtasks', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      }
    }).then(
      response => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        response.json().then(js => {
          console.log(js)
        });
      }
    )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  }

  const generateSerialNumber = async (save, overwrite) => {
    let token = sessionStorage.getItem("ZeitroA")

    return fetch(window.location.origin + "/borrower/hardmoneyloan/generatehardmoneyloanserialnumber", {
      cache: 'no-cache',
      method: 'POST',
      body: JSON.stringify({
        NewState: com.ascii2hex(JSON.stringify(props.fullstate)),
        Save: save,
        Overwrite: overwrite,
      }),
      headers: {
        Authorization: "Bearer " + token,
        Cache: "no-cache"
      },
    }).then(response => response.json())

  }

  const onSubmit = async (signItems) => {
    setPDFVisible(false)

    const docNames = getDocNames()
    checkAndCreateTasks(docNames)

    // submit
    await generateSerialNumber(true, false)
    props.updateApplicationAttribute("interviewfinished", "status")
    history.push('/application/hardMoneyLoan/interview/main/submitted')
  }

  const handleMenu = (value) => {
    setPage(value.key)
    props.updateChangeProgressStep(stepMap[value.key] - 1)
  }
  const setPage = (key) => {
    setClickMenuKey(key);
    history.push(`/application/hardMoneyLoan/interview/main/${key}`);
  }
  const borrowerFinish = (val) => {
    let d = new Date()
    props.updateChangeLastTouch(d.toLocaleString())
    if (props.progress.interview.max < 1) props.updateChangeProgress(1, "max")
    props.updateChangeProgressStep(1)
    setPage("property")
  };

  const propertyFinish = (val) => {
    let d = new Date()
    props.updateChangeLastTouch(d.toLocaleString())
    if (props.progress.interview.max < 2) props.updateChangeProgress(2, "max")
    props.updateChangeProgressStep(2)
    generateSerialNumber(false, false)

    setPage("experience")
  };

  const experienceFinish = (val) => {
    let d = new Date()
    props.updateChangeLastTouch(d.toLocaleString())
    if (props.progress.interview.max < 3) props.updateChangeProgress(3, "max")
    props.updateChangeProgressStep(3)
    setPage("declarations")
  }

  const declarationsFinish = (val) => {
    let d = new Date()
    setPDFVisible(true)
    props.updateChangeLastTouch(d.toLocaleString())
    if (props.progress.interview.max < 4) props.updateChangeProgress(4, "max")
    props.updateChangeProgressStep(5)

  };
  const getStep = () => {
    if (props.status === "interviewfinished") {
      return 2
    }
    return 1
  }
  function hardMoneyLoanForm() {
    switch (clickMenuKey) {
      case "overview":
        return <Overview onSubmittedDisableClick={() => setShowSubmittedModal(true)} step={getStep()} />;
      case "borrower":
        return <Borrower
          onSubmittedDisableClick={() => setShowSubmittedModal(true)}
          finish={borrowerFinish}
          resetProgress={() => {
            props.updateChangeProgress(0, "max")
            props.updateChangeProgressStep(0)
          }}
        />;
      case "property":
        return <PropertyAndLoan
          onSubmittedDisableClick={() => setShowSubmittedModal(true)}
          finish={propertyFinish}
          back={() => handleMenu({ key: 'borrower' })}
          resetProgress={() => {
            props.updateChangeProgress(1, "max")
            props.updateChangeProgressStep(1)
          }}
        />;
      case "experience":
        return <Experience
          onSubmittedDisableClick={() => setShowSubmittedModal(true)}
          finish={experienceFinish}
          back={() => handleMenu({ key: 'property' })}
          resetProgress={() => {
            props.updateChangeProgress(2, "max")
            props.updateChangeProgressStep(2)
          }}

        />;
      case "declarations":
        return <Declarations
          onSubmittedDisableClick={() => setShowSubmittedModal(true)}
          finish={declarationsFinish}
          back={() => handleMenu({ key: 'experience' })}
        />;
      case "submitted":
        return <Submitted />
      case "myTasks":
        return <Tasks />
      case "documentsReceived":
        return <DocumentsReceived />
      case "documentsUploaded":
        return <DocumentsUploaded />
      case "documents":
        return <Docs />
      default:
        return <Overview step={getStep()} />;
    }
  }
  const getDocNames = () => {
    let docNames = [
      "ID",
      "Purchase Agreement",
      "Evidence of Insurance",
      "Preliminary Title report",
      "Real Estate Experience Proof",
      "Bank statement for recent two months",
    ]
    if (props.application.hascoborrower === 'withcoborrower') {
      docNames.push("ID (Coborrower)")
    }
    if (props.borrower.maritalstatus === "married" || props.borrower.maritalstatus === "separated") {
      docNames.push("Spousal Consent")
    }

    if (props.application.hardmoneyloan.borrowertype !== 'Individual') {
      docNames.push(...["Operating Agreement", "Tax ID", "Certificate of Good Standing", "Certificate of Formation"])
    }
    if (props.application.property.purpose === "purchaserehab") {
      docNames.push(...["Scope of work", "Total Cost and Breakdown", "Permit"])
    }
    if (props.application.property.purpose === "refinance" || props.application.property.purpose === "cashoutrefinance") {
      docNames.push("Current mortgage statement")
    }
    if (props.application.property.purpose === "cashoutrefinance") {
      docNames.push("Signed letter explaining how cash-out funds will be used")
    }
    if (["twotofourfamily", "twofamily", "threefamily", "fourfamily", "multistory"].includes(props.application.property.propertytype)) {
      docNames.push(...["Rent roll", "Current Leases", "Borrower's Pro Forma", "Property Info: Unit mix and Sq.ft. for each unit", "Property Manager Contact Information"])
    }
    return docNames
  }
  return (
    <div className="d-flex hardmoneyloaninterview h-100">
      <SidebarHardMoneyLoan menuClick={handleMenu} />
      <Modal
        open={showSubmittedModal}
        onOk={() => setShowSubmittedModal(false)}
        onCancel={() => setShowSubmittedModal(false)}
      >
        Please do not modify your application until the processor review is complete.
      </Modal>
      <div className="hardmoneyloaninterviewpane">
        {hardMoneyLoanForm()}
      </div>
      {pdfVisible ? <PDFPreview
        visible={pdfVisible}
        onSubmit={onSubmit}
        onClose={() => setPDFVisible(false)}
      /> : null}
    </div>
  );
}

HardMoneyLoanInterView = withRouter(connect(mapStateToProps, mapDispatchToProps)(HardMoneyLoanInterView));
