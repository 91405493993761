import React, { useState, useEffect, useRef, useContext } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { Tabs } from "antd";
import DocumentManager from "./DocumentManager";
import DocumentChecklist from "./DocumentChecklist";
import DocumentSidebar from "./DocumentSidebar";
import Upload from "./Upload";
import {
  UpdateChangeProgress,
  UpdateChangeProgressType,
} from "../Store/progress";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    application: state.application,
    progress: state.progress,
    hascoborrower: state.application.hascoborrower,
    borrower: state.borrower,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateChangeProgress: (step, stage) => {
    dispatch(UpdateChangeProgress(step, stage));
  },
  updateChangeProgressType: (step, stage) => {
    dispatch(UpdateChangeProgressType(step, stage));
  },
});
export default function Documents(props) {
    const [selectMenu, setSelectMenu] = useState('')
    const items = [
      {
        key: "1",
        label: `My Documents`,
        children: <DocumentManager />,
      },
      {
        key: "2",
        label: `Upload New Documents`,
        children: <Upload />,
      },
    ];
    const changeMenu = (menu) => {
      setSelectMenu(menu)
    }
    return (
      <div className="borrower-document-wrap">
        {/* <DocumentSidebar changeMenu={changeMenu} /> */}
        <div style={{flex: 1}}>
          <DocumentChecklist selectMenu={selectMenu}/>
        </div>
      </div>
    )

}
Documents = connect(mapStateToProps, mapDispatchToProps)(Documents);
